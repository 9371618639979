import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";

export const extractErrorMessage = (
    error: FetchBaseQueryError | SerializedError | undefined
): string => {
    if (!error) {
        return "An unknown error occurred";
    }

    if ("message" in error) {
        return error.message || "An error occurred";
    } else if ("status" in error && typeof error.status === "number") {
        return `Error status: ${error.status}`;
    } else {
        return "An error occurred";
    }
};
