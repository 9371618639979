import { Typography } from "@mui/material";

import "./termsConditions.less";

import PageWrapper from "../../basic/PageWrapper/PageWrapper";

export default function TermsConditions() {
    return (
        <PageWrapper>
            <Typography
                sx={{
                    color: "#333",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    margin: "63px auto 0px"
                }}
            >
                Terms and Conditions
            </Typography>
            <Typography className="tc-title" sx={{ marginTop: "44px" }}>
                Terms and Conditions
            </Typography>
            <Typography className="tc-text">
                Welcome to DATA TRANSMUTATIONS LTD trading as Meal-lords! We are
                a company registered in England and Wales under company number
                15216339, with our registered office located at 71-75 Shelton
                Street, Covent Garden, London, WC2H 9JQ, United Kingdom.
                Throughout this document, "Meal-lords," "we," "us," and "our"
                refer to our company. Our website, accessible at
                www.meal-lords.com, is referred to as the "Site," and our mobile
                app that will be released in future is referred to as the "APP."
                By accessing and using the Site and/or the APP (whether through
                a desktop or mobile device), you agree to comply with the terms
                and conditions ("Terms") set forth herein. These Terms apply to
                (i) downloading the APP from the Site or any App Store and (ii)
                utilizing the Site.
            </Typography>
            <Typography className="tc-text">
                Please carefully read and understand these Terms before using
                the Site and/or downloading or using the APP. If you do not
                agree to abide by these Terms, kindly refrain from using the
                Site and/or downloading or using the APP.
            </Typography>

            <Typography className="tc-title">Section 1: General</Typography>

            <Typography className="tc-sub-title">
                1.1 Acceptance of Terms
            </Typography>
            <Typography className="tc-text">
                By installing the APP and/or using the Site, you acknowledge and
                accept these Terms. We urge you to review them thoroughly before
                installation and acceptance.
            </Typography>
            <Typography className="tc-sub-title">1.2 Access</Typography>
            <Typography className="tc-text">
                The APP will be available for download and use, some cost might
                apply for certain functionalities.
            </Typography>
            <Typography className="tc-text">
                The Site is available for use online. Pricing for any features
                is described in the pricing section further below.
            </Typography>
            <Typography className="tc-sub-title">1.3 Uninstallation</Typography>
            <Typography className="tc-text">
                You have the right to uninstall the APP at any time by utilizing
                the uninstall option within the APP.
            </Typography>
            <Typography className="tc-sub-title">1.4 Your Capacity</Typography>
            <Typography className="tc-text">
                By using the Site and/or the APP, you warrant that you have the
                legal right, authority, and capacity to enter into and be bound
                by these Terms.
            </Typography>
            <Typography className="tc-sub-title">
                1.5 Geographical Limitations
            </Typography>
            <Typography className="tc-text">
                We do not assert that the material on the Site and/or the APP is
                suitable for use outside the United Kingdom. Any losses
                resulting from such use in other locations are not our
                responsibility. If you choose to access the Site and/or the APP
                from locations outside the United Kingdom, you are responsible
                for compliance with local laws, if applicable.
            </Typography>
            <Typography className="tc-sub-title">
                1.6 Virus Disclaimer
            </Typography>
            <Typography className="tc-text">
                We make no guarantee that the contents of the Site and/or the
                APP are free from viruses or other harmful elements, and we
                disclaim any liability for such issues.
            </Typography>
            <Typography className="tc-sub-title">
                1.7 Material Accuracy
            </Typography>
            <Typography className="tc-text">
                We do not guarantee the accuracy, reliability, or truthfulness
                of any material on the Site or obtained through the APP. All
                materials are provided for informational purposes only and do
                not constitute advice. You acknowledge that your reliance on
                such material is at your own risk.
            </Typography>
            <Typography className="tc-sub-title">
                1.8 Content Preparation
            </Typography>
            <Typography className="tc-text">
                While we take great care in preparing the Site and the APP's
                content, we cannot ensure continuous, uninterrupted access, nor
                can we guarantee that the information will always be completely
                up-to-date and free from errors. To the extent permitted by
                applicable law, we disclaim all warranties, whether express or
                implied, regarding the accuracy of the information contained in
                any materials on the Site and/or obtained through the APP, and
                we assume no liability for losses or damages arising from access
                problems.
            </Typography>
            <Typography className="tc-sub-title">1.9 No Liability</Typography>
            <Typography className="tc-text">
                We shall not be liable to any person for any loss or damage
                resulting from the use of information and materials on the Site
                and/or obtained through the APP.
            </Typography>
            <Typography className="tc-sub-title">
                1.10 Third-Party Content
            </Typography>
            <Typography className="tc-text">
                By using the Site and/or the APP, you may access information
                provided by third parties about their products and/or services.
                We are not responsible for the data they upload or provide to
                you. If they include hyperlinks leading to other websites not
                under our control, we disclaim any responsibility or liability
                for the content of such websites.
            </Typography>
            <Typography className="tc-sub-title">
                1.11 Third-Party Software
            </Typography>
            <Typography className="tc-text">
                We may provide software owned or operated by third-party
                companies. You must use this software in accordance with the
                terms and conditions imposed by the third-party provider.
            </Typography>
            <Typography className="tc-sub-title">
                1.12 Suspension of Services
            </Typography>
            <Typography className="tc-text">
                We reserve the right to suspend, turn off or terminate the Site
                and/or the operation of the APP, or any part of it, at any time
                for various reasons. Including but not limited to repairs or
                upgrades and shall not be liable for such suspensions or
                termination of service.
            </Typography>
            <Typography className="tc-sub-title">
                1.13 Modification of Terms
            </Typography>
            <Typography className="tc-text">
                We retain the right to modify these Terms at any time by posting
                new terms and conditions on the Site. Your continued use of the
                Site and/or the APP (or any part thereof) following such changes
                signifies your acceptance of these modifications. It is your
                responsibility to regularly check for updates to these Terms. If
                you disagree with any changes, you must immediately cease using
                the Site and the APP.
            </Typography>
            <Typography className="tc-sub-title">1.14 Severability</Typography>
            <Typography className="tc-text">
                If any provision of these Terms is deemed illegal, invalid, or
                unenforceable by a competent authority, such provision shall be
                considered omitted, and the validity of the remaining provisions
                shall remain unaffected.
            </Typography>
            <Typography className="tc-sub-title">
                1.15 Liability Exclusions
            </Typography>
            <Typography className="tc-text">
                To the fullest extent permitted by law, all liability is
                excluded, including implied terms.
            </Typography>
            <Typography className="tc-sub-title">
                1.16 No Third-Party Beneficiaries
            </Typography>
            <Typography className="tc-text">
                These Terms do not confer any enforceable benefit of any kind to
                any third party against us, whether directly or indirectly.
            </Typography>
            <Typography className="tc-sub-title">
                1.17 Waiver of Rights
            </Typography>
            <Typography className="tc-text">
                Failure or delay by us to enforce any of these Terms shall not
                constitute a waiver of our right to enforce such terms and
                conditions.
            </Typography>

            <Typography className="tc-title">Section 2: The APP</Typography>

            <Typography className="tc-sub-title">
                2.1 Free Personal Use
            </Typography>
            <Typography className="tc-text">
                The APP will be available for user's personal and business use,
                with some functionalities having the right to require payments
                to be accessed. Meal-lords reserves the right to modify or
                withdraw the future APP, or charge for the APP or services
                provided under these Terms, at any time and for any reason.
            </Typography>
            <Typography className="tc-text">
                Your right to download, install, and use the APP will also be
                governed by terms and conditions presented to you as part of the
                access procedure to the relevant app store where you acquired
                the APP.
            </Typography>

            <Typography className="tc-title">
                Section 3: Your Details
            </Typography>

            <Typography className="tc-sub-title">
                3.1 Registration and Personal Information
            </Typography>
            <Typography className="tc-text">
                To download and use the APP or the Site, you must register and
                provide specific information about yourself ("Personal
                Information"). By doing so, you agree:
                <ul style={{ marginTop: "5px" }}>
                    <li>
                        To provide true, accurate, current, and complete
                        Personal Information as prompted by the relevant
                        registration form.
                    </li>
                    <li>
                        To promptly update your Personal Information to keep it
                        true, accurate, current, and complete (either through
                        the Site's profile section or by sending an email to
                        "contact@meal-lords.com").
                    </li>
                    <li>
                        Not to impersonate any other entity or use a false name
                        without authorization.
                    </li>
                </ul>
            </Typography>
            <Typography className="tc-sub-title">
                3.2 Withdrawal of Consent
            </Typography>
            <Typography className="tc-text">
                You may withdraw your consent at any time by uninstalling the
                APP and requesting the removal of your Personal Information and
                profile. And by requesting the removal of your Personal
                Information and profile from the Site.
            </Typography>

            <Typography className="tc-title">
                Section 4: Prohibitions of Use
            </Typography>

            <Typography className="tc-sub-title">4.1 Unlawful Use</Typography>
            <Typography className="tc-text">
                The Site and/or the APP must not be used for unlawful purposes.
                You also agree not to permit any person under your control to
                use the Site and/or the APP for the following purposes:
                <ul style={{ marginTop: "5px" }}>
                    <li>
                        Posting incomplete, false, or inaccurate information.
                    </li>
                    <li>
                        Posting information subject to confidentiality
                        provisions (implied or express).
                    </li>
                    <li>
                        Posting information that we, in our reasonable opinion,
                        deem defamatory, offensive, obscene, threatening,
                        racist, sexist, or discriminatory.
                    </li>
                    <li>
                        Deleting, amending, or altering any material not posted
                        by you.
                    </li>
                    <li>
                        Posting materials that are not your own work without the
                        consent of the original author.
                    </li>
                    <li>
                        Using the Site, the APP, and/or the materials in a way
                        that infringes the intellectual property rights of any
                        other party.
                    </li>
                </ul>
            </Typography>
            <Typography className="tc-sub-title">4.2 Enforcement</Typography>
            <Typography className="tc-text">
                If we believe that you are making illegal and/or unauthorized
                use of the Site and/or the APP, or if your use is in violation
                of these Terms, we reserve the right to take appropriate
                actions, including the termination of your Site and/or APP
                access without notice and, in cases of illegality, initiating
                criminal proceedings.
            </Typography>
            <Typography className="tc-sub-title">4.3 Termination</Typography>
            <Typography className="tc-text">
                Notwithstanding any other provision of these Terms, we may, at
                any time and for any reason, terminate these Terms with you and
                deny you access to the Site and/or APP.
            </Typography>
            <Typography className="tc-sub-title">
                4.4 Restrictions on APP and Site
            </Typography>
            <Typography className="tc-text">
                You must not, and must not allow third parties on your behalf
                to:
                <ul style={{ marginTop: "5px" }}>
                    <li>Make and distribute copies of the APP and/or Site.</li>
                    <li>
                        Attempt to copy, reproduce, alter, modify, reverse
                        engineer, disassemble, decompile, transfer, exchange, or
                        translate the APP and/or Site.
                    </li>
                    <li>
                        Create derivative works of the APP and/or Site in any
                        form.
                    </li>
                </ul>
            </Typography>

            <Typography className="tc-title">
                Section 5: Intellectual Property
            </Typography>

            <Typography className="tc-sub-title">
                5.1 Ownership of Content
            </Typography>
            <Typography className="tc-text">
                All copyright and other intellectual property rights in the
                material found on the Site and the APP, including website
                design, images, and source code, belong to us, and all rights
                are reserved.
            </Typography>
            <Typography className="tc-sub-title">
                5.2 Posting Authority
            </Typography>
            <Typography className="tc-text">
                You warrant that you have the authority to post any material on
                the Site.
            </Typography>
            <Typography className="tc-sub-title">5.3 License Grant</Typography>
            <Typography className="tc-text">
                You grant us a non-exclusive, perpetual, irrevocable,
                royalty-free license to use any material you post on the Site or
                through the use of the APP in accordance with these Terms.
            </Typography>
            <Typography className="tc-sub-title">
                5.4 User Obligations
            </Typography>
            <Typography className="tc-text">
                You agree not to:
                <ul style={{ marginTop: "5px" }}>
                    <li>
                        Resell, transfer, or provide the use of or access to the
                        Site or the APP to any other person.
                    </li>
                    <li>
                        Allow any other person access to any password, user ID,
                        or account information associated with you in connection
                        with the Site or the APP.
                    </li>
                </ul>
            </Typography>
            <Typography className="tc-sub-title">
                5.5 License to Users
            </Typography>
            <Typography className="tc-text">
                We grant you a non-transferable, non-exclusive, revocable,
                royalty-free license to:
                <ul style={{ marginTop: "5px" }}>
                    <li>
                        Use the APP and the Site for your personal and business
                        use as specified in these Terms.
                    </li>
                    <li>
                        View and print Site content for your personal and
                        business use.
                    </li>
                </ul>
            </Typography>

            <Typography className="tc-title">
                Section 6: Data Protection
            </Typography>

            <Typography className="tc-text">
                Any personal information you provide to Meal-lords when using
                the APP and/or the Site will be processed in accordance with our
                Privacy Policy, available at www.meal-lords.com/privacy-policy.
            </Typography>

            <Typography className="tc-title">
                Section 7: Mobile Provider
            </Typography>

            <Typography className="tc-sub-title">
                7.1 Mobile Network Agreement
            </Typography>
            <Typography className="tc-text">
                You acknowledge that your respective mobile network provider
                ("Mobile Provider") terms of agreement continue to apply when
                using the APP and/or the Site. Therefore, you may be charged by
                the Mobile Provider for network connection services while
                accessing the APP and/or the Site or other third-party charges
                that may arise. You are responsible for these charges.
            </Typography>
            <Typography className="tc-sub-title">
                7.2 Bill Payer Consent
            </Typography>
            <Typography className="tc-text">
                If you are not the bill payer for the mobile telephone or
                handheld device used to access the APP and/or the Site, you are
                assumed to have received permission from the bill payer for
                using the APP and/or the Site.
            </Typography>

            <Typography className="tc-title">
                Section 8: Availability
            </Typography>

            <Typography className="tc-sub-title">
                8.1 Internet and Mobile Networks
            </Typography>
            <Typography className="tc-text">
                The APP is available for handheld mobile devices running Apple
                iOS and Android OS Operating Systems. While we make reasonable
                efforts to ensure its availability, please understand that the
                quality and availability of the APP may be influenced by factors
                beyond our control.
            </Typography>
            <Typography className="tc-sub-title">
                8.2 Unavailability Disclaimer
            </Typography>
            <Typography className="tc-text">
                We do not accept any responsibility for the unavailability of
                the APP and/or the Site, difficulties in downloading or
                accessing content, or any other communication system failures
                resulting in the unavailability of the APP and/or the Site.
            </Typography>
            <Typography className="tc-sub-title">
                8.3 Support and Maintenance
            </Typography>
            <Typography className="tc-text">
                We are not responsible for providing support or maintenance for
                the APP and/or the Site.
            </Typography>

            <Typography className="tc-title">
                Section 9: System Requirements
            </Typography>

            <Typography className="tc-sub-title">9.1 Compatibility</Typography>
            <Typography className="tc-text">
                To use the APP, you must have a compatible mobile phone or
                handheld device, internet access, and meet the minimum
                specifications ("Software Requirements").
            </Typography>
            <Typography className="tc-sub-title">
                9.2 Software Requirements include:
            </Typography>
            <Typography className="tc-text">
                <ul style={{ marginTop: "5px" }}>
                    <li>Apple iOS devices running at least iOS 8.</li>
                    <li>Android OS devices running at least Android OS 4.4.</li>
                    <li>Language: English.</li>
                </ul>
            </Typography>
            <Typography className="tc-sub-title">
                9.3 Software Upgrades
            </Typography>
            <Typography className="tc-text">
                The APP software version may be upgraded from time to time to
                add support for new functions and services.
            </Typography>

            <Typography className="tc-title">
                Section 10: Termination
            </Typography>

            <Typography className="tc-sub-title">
                10.1 Termination by Meal-lords
            </Typography>
            <Typography className="tc-text">
                Meal-lords may terminate your use of the APP and/or Site at any
                time by providing notice of termination to you.
            </Typography>
            <Typography className="tc-sub-title">
                10.2 Termination by User
            </Typography>
            <Typography className="tc-text">
                You may terminate your use of the APP and/or Site at any time by
                uninstalling the APP and requesting Site access termination via
                email.
            </Typography>
            <Typography className="tc-sub-title">
                10.3 Effect of Termination Upon termination:
            </Typography>
            <Typography className="tc-text">
                <ul style={{ marginTop: "5px" }}>
                    <li>
                        All rights and licenses granted to you herein shall
                        terminate.
                    </li>
                    <li>You must cease all use of the APP and/or Site.</li>
                </ul>
            </Typography>

            <Typography className="tc-title">
                Section 11: Limitation of Liability
            </Typography>

            <Typography className="tc-sub-title">11.1 Limitation</Typography>
            <Typography className="tc-text">
                Meal-lords shall not be liable for any direct, indirect,
                special, punitive, exemplary, or consequential losses or damages
                arising from your use or access to the APP or the Site. This
                includes but is not limited to loss of profit, even if such
                losses were foreseeable, whether based on breach of contract,
                tort (including negligence), product liability, or otherwise.
            </Typography>
            <Typography className="tc-sub-title">
                11.2 Equipment Damage
            </Typography>
            <Typography className="tc-text">
                Meal-lords is not liable for any damage or alteration to your
                equipment, including but not limited to computer equipment,
                handheld devices, or mobile phones, resulting from the
                installation or use of the APP and/or the Site.
            </Typography>
            <Typography className="tc-sub-title">11.3 Exceptions</Typography>
            <Typography className="tc-text">
                Nothing in these Terms shall exclude or limit Meal-lords'
                liability for death or personal injury caused by negligence,
                fraud, fraudulent misrepresentation, or any other liability that
                cannot be excluded or limited under applicable law.
            </Typography>

            <Typography className="tc-title">
                Section 12: Disclaimer of Warranties
            </Typography>

            <Typography className="tc-text">
                To the maximum extent permitted by law, Meal-lords disclaims all
                implied warranties regarding the APP and/or the Site. The APP,
                the Site and software are provided "as is" and "as available"
                without any warranty of any kind.
            </Typography>

            <Typography className="tc-title">Section 13: Pricing</Typography>

            <Typography className="tc-text">
                When purchasing a package on the website you agree that you will
                have a limited number of calculations available for you to
                perform in the account on the Site, based on the package
                purchased.
                <ul style={{ marginTop: "5px" }}>
                    <li>Starter package: 25 calculations for £49.95</li>
                    <li>Restaurant package: 100 calculations for £99.95</li>
                    <li>Enterprise package: 250 calculations for £199.95</li>
                </ul>
            </Typography>

            <Typography className="tc-text">
                The calculations can be performed using the calculator service
                on the Site and the Results would be visible in the account.
            </Typography>

            <Typography className="tc-text">
                Meal-lords doesn’t guarantee continuous eternal availability of
                its service and the access to the calculator service, Site
                and/or App which can be stopped/turned off at any time.
            </Typography>

            <Typography className="tc-title">
                Section 13.1: Promotional period.
            </Typography>

            <Typography className="tc-text">
                In addition to the number of calculations purchased by you, you
                also get access to display your Results on the search page on
                the Site on a promotional basis described below:
                <ul style={{ marginTop: "5px" }}>
                    <li>
                        Visibility of the calculation Results from your account
                        on the Search page is free for the first 12 months from
                        the date you signed up for the service.
                    </li>
                    <li>
                        After 12 months, your Results might stop appearing on
                        the search page. You will need to reach out to
                        Meal-lords, or you would have a way to enable the
                        functionality after the 12 months at your choice. A
                        monthly payment will be applied to have the Results be
                        visible on the search page after the expiration of the
                        free promotional period.
                    </li>
                    <li>
                        You are not getting automatically enrolled into the
                        monthly payments after expiration of 12 months period.
                    </li>
                    <li>
                        Meal-lords reserves the right to stop offering the free
                        promotional period to new users and/or change the
                        pricing of its services at any time.
                    </li>
                </ul>
            </Typography>

            <Typography className="tc-title">
                Section 14: Governing Law
            </Typography>

            <Typography className="tc-text" sx={{ marginBottom: "130px" }}>
                These Terms shall be governed by and construed in accordance
                with the laws of England and Wales. If any provision of these
                Terms is found to be unlawful, void, or unenforceable, that
                provision shall be deemed severable, and the validity and
                enforceability of the remaining provisions shall not be
                affected. By accepting these Terms, you submit to the
                non-exclusive jurisdiction of the courts of England and
                Wales.These Terms and Conditions constitute the entire agreement
                between you and Meal-lords regarding your use of the Site and
                the APP.
            </Typography>
        </PageWrapper>
    );
}
