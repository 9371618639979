import { ChangeEvent, useEffect, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    TextField,
    Button,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import { Link } from "react-router-dom";

import { useSnackbar } from "../../../../context/SnackbarContext";
import useDebounce from "../../../../hooks/helpers/useDebounce";
import { useLocalStorage } from "../../../../hooks/helpers/useLocalStorage";
import { useAppDispatch } from "../../../../redux/hooks";
import { login } from "../../../../redux/reducers/auth";
import { useSignUpMutation } from "../../../../services/auth";
import {
    isEmailValid,
    isPasswordLengthValid
} from "../../../../utils/validation";

const SignUp = () => {
    const dispatch = useAppDispatch();
    const [signUp] = useSignUpMutation();
    const { showSnackbar } = useSnackbar();

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);

    const [emailTouched, setEmailTouched] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const debouncedEmail = useDebounce(formData.email, 300);
    const debouncedPassword = useDebounce(formData.password, 200);

    const [rememberMe, setRememberMe] = useLocalStorage<boolean | null>(
        "rememberMe",
        null
    );

    const handleRememberMe = (e: any) => {
        setRememberMe(e.target.checked);
    };

    const handleChange =
        (prop: keyof typeof formData) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, [prop]: event.target.value });
                if (prop === "email") {
                    setEmailTouched(true);
                } else if (prop === "password") {
                    setPasswordTouched(true);
                }
            };

    useEffect(() => {
        if (emailTouched) {
            setEmailValid(isEmailValid(debouncedEmail));
        }
    }, [debouncedEmail, emailTouched]);

    useEffect(() => {
        if (passwordTouched) {
            setPasswordValid(isPasswordLengthValid(debouncedPassword));
        }
    }, [debouncedPassword, passwordTouched]);

    const handleSignUp = async () => {
        try {
            const payload = await signUp(formData).unwrap();
            showSnackbar("Sign up successful!", "success");
            dispatch(login(payload.token));
        } catch (error: any) {
            showSnackbar(
                "Sign up failed: " + (error?.data?.message || "Unknown error"),
                "error"
            );
        }
    };

    return (
        <div className="auth__form-container">
            <h2 className="auth__title">Sign Up</h2>
            <div className="auth__form">
                <TextField
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange("email")}
                    required
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={emailTouched && !emailValid}
                    helperText={
                        emailTouched && !emailValid
                            ? "Invalid email format"
                            : ""
                    }
                />
                <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={handleChange("password")}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={passwordTouched && !passwordValid}
                    helperText={
                        passwordTouched && !passwordValid
                            ? "Password should be at least 6 characters"
                            : ""
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "16px" }}
                    className="auth__button"
                    onClick={handleSignUp}
                    disabled={
                        !emailValid ||
                        !passwordValid ||
                        !formData.password ||
                        !formData.email
                    }
                    fullWidth
                >
                    Create Account
                </Button>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!rememberMe}
                            onChange={handleRememberMe}
                            className="auth__remember-me-checkbox"
                        />
                    }
                    label={
                        <p className="auth__text" style={{ margin: "0px" }}>
                            Remember me
                        </p>
                    }
                />
                <p className="auth__text">
                    By pressing “Create Account” you are agreeing to{" "}
                    <Link
                        className="auth__link-text"
                        to="/terms-and-conditions"
                    >
                        Terms & Conditions
                    </Link>
                </p>
                <p className="auth__text">
                    Already have an account?{" "}
                    <Link className="auth__link-text" to="/auth?type=login">
                        Log in
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default SignUp;
