const IngredientsDictionary: { [key: string]: number } = {
    "Milk, human": 11000000,
    "Milk, NFS": 11100000,
    "Milk, whole": 11111000,
    "Milk, reduced fat (2%)": 11112110,
    "Milk, low fat (1%)": 11112210,
    "Milk, fat free (skim)": 11113000,
    "Milk, lactose free, low fat (1%)": 11114300,
    "Milk, lactose free, fat free (skim)": 11114320,
    "Milk, lactose free, reduced fat (2%)": 11114330,
    "Milk, lactose free, whole": 11114350,
    "Buttermilk, fat free (skim)": 11115000,
    "Buttermilk, whole": 11115300,
    "Kefir, NS as to fat content": 11115400,
    "Goat's milk, whole": 11116000,
    "Milk, dry, reconstituted, NS as to fat content": 11120000,
    "Milk, dry, reconstituted, whole": 11121100,
    "Milk, dry, reconstituted, low fat (1%)": 11121210,
    "Milk, dry, reconstituted, fat free (skim)": 11121300,
    "Milk, evaporated, NS as to fat content": 11210050,
    "Milk, evaporated, whole": 11211050,
    "Milk, evaporated, reduced fat (2%)": 11211400,
    "Milk, evaporated, fat free (skim)": 11212050,
    "Milk, condensed, sweetened": 11220000,
    "Non-dairy milk, NFS": 11300100,
    "Soy milk": 11320000,
    "Soy milk, light": 11320100,
    "Soy milk, nonfat": 11320200,
    "Soy milk, chocolate": 11321000,
    "Soy milk, light, chocolate": 11321100,
    "Almond milk, sweetened": 11350000,
    "Almond milk, sweetened, chocolate": 11350010,
    "Almond milk, unsweetened": 11350020,
    "Almond milk, unsweetened, chocolate": 11350030,
    "Rice milk": 11360000,
    "Coconut milk": 11370000,
    "Yogurt, NFS": 11400000,
    "Yogurt, Greek, NS as to type of milk or flavor": 11400010,
    "Yogurt, NS as to type of milk or flavor": 11410000,
    "Yogurt, NS as to type of milk, plain": 11411010,
    "Yogurt, whole milk, plain": 11411100,
    "Yogurt, low fat milk, plain": 11411200,
    "Yogurt, nonfat milk, plain": 11411300,
    "Yogurt, Greek, NS as to type of milk, plain": 11411390,
    "Yogurt, Greek, whole milk, plain": 11411400,
    "Yogurt, Greek, low fat milk, plain": 11411410,
    "Yogurt, Greek, nonfat milk, plain": 11411420,
    "Yogurt, NS as to type of milk, fruit": 11430000,
    "Yogurt, whole milk, fruit": 11431000,
    "Yogurt, low fat milk, fruit": 11432000,
    "Yogurt, nonfat milk, fruit": 11433000,
    "Yogurt, Greek, NS as to type of milk, fruit": 11433990,
    "Yogurt, Greek, whole milk, fruit": 11434000,
    "Yogurt, Greek, low fat milk, fruit": 11434010,
    "Yogurt, Greek, nonfat milk, fruit": 11434020,
    "Yogurt, NS as to type of milk, flavors other than fruit": 11434090,
    "Yogurt, whole milk, flavors other than fruit": 11434100,
    "Yogurt, low fat milk, flavors other than fruit": 11434200,
    "Yogurt, nonfat milk, flavors other than fruit": 11434300,
    "Yogurt, Greek, NS as to type of milk, flavors other than fruit": 11435000,
    "Yogurt, Greek, whole milk, flavors other than fruit": 11435010,
    "Yogurt, Greek, low fat milk, flavors other than fruit": 11435020,
    "Yogurt, Greek, nonfat milk, flavors other than fruit": 11435030,
    "Yogurt, Greek, with oats": 11435100,
    "Yogurt, liquid": 11436000,
    "Chipotle dip, yogurt based": 11440010,
    "Onion dip, yogurt based": 11440030,
    "Ranch dip, yogurt based": 11440040,
    "Spinach dip, yogurt based": 11440050,
    "Tzatziki dip": 11440060,
    "Vegetable dip, yogurt based": 11440070,
    "Yogurt parfait, low fat, with fruit": 11446000,
    "Frozen yogurt, NFS": 11459990,
    "Frozen yogurt, vanilla": 11460000,
    "Frozen yogurt, chocolate": 11460100,
    "Frozen yogurt, soft serve, vanilla": 11460500,
    "Frozen yogurt, soft serve, chocolate": 11460510,
    "Frozen yogurt sandwich": 11461200,
    "Frozen yogurt bar, vanilla": 11461210,
    "Frozen yogurt bar, chocolate": 11461220,
    "Frozen yogurt cone, chocolate": 11461250,
    "Frozen yogurt cone, vanilla": 11461260,
    "Frozen yogurt cone, vanilla, waffle cone": 11461300,
    "Frozen yogurt cone, chocolate, waffle cone": 11461320,
    "Baby Toddler food, NFS": 11480000,
    "Baby Toddler yogurt, plain": 11480010,
    "Baby Toddler yogurt, with fruit": 11480100,
    "Chocolate milk, NFS": 11511000,
    "Chocolate milk, ready to drink, whole": 11511100,
    "Chocolate milk, ready to drink, reduced fat": 11511200,
    "Chocolate milk, ready to drink, fat free": 11511300,
    "Chocolate milk, ready to drink, low fat": 11511400,
    "Chocolate milk, ready to drink, reduced sugar, NS as to milk": 11511550,
    "Chocolate milk, ready to drink, low fat (Nesquik)": 11511600,
    "Chocolate milk, ready to drink, low fat, no sugar added (Nesquik)": 11511700,
    "Hot chocolate / Cocoa, ready to drink": 11512010,
    "Hot chocolate / Cocoa, ready to drink, made with nonfat milk": 11512020,
    "Hot chocolate / Cocoa, ready to drink, made with non-dairy milk": 11512030,
    "Hot chocolate / Cocoa, ready to drink, with whipped cream": 11512100,
    "Chocolate milk, made from dry mix with whole milk": 11513100,
    "Chocolate milk, made from dry mix with reduced fat milk": 11513150,
    "Chocolate milk, made from dry mix with fat free milk": 11513300,
    "Chocolate milk, made from dry mix with non-dairy milk": 11513310,
    "Chocolate milk, made from dry mix, NS as to type of milk (Nesquik)": 11513380,
    "Chocolate milk, made from dry mix with whole milk (Nesquik)": 11513381,
    "Chocolate milk, made from dry mix with reduced fat milk (Nesquik)": 11513382,
    "Chocolate milk, made from dry mix with low fat milk (Nesquik)": 11513383,
    "Chocolate milk, made from dry mix with fat free milk (Nesquik)": 11513384,
    "Chocolate milk, made from dry mix with non-dairy milk (Nesquik)": 11513385,
    "Chocolate milk, made from no sugar added dry mix with reduced fat milk (Nesquik)": 11513392,
    "Chocolate milk, made from no sugar added dry mix with non-dairy milk (Nesquik)": 11513395,
    "Chocolate milk, made from syrup, NS as to type of milk": 11513400,
    "Chocolate milk, made from syrup with whole milk": 11513500,
    "Chocolate milk, made from syrup with reduced fat milk": 11513550,
    "Chocolate milk, made from syrup with low fat milk": 11513600,
    "Chocolate milk, made from syrup with fat free milk": 11513700,
    "Chocolate milk, made from syrup with non-dairy milk": 11513750,
    "Chocolate milk, made from light syrup with whole milk": 11513801,
    "Chocolate milk, made from light syrup with reduced fat milk": 11513802,
    "Chocolate milk, made from light syrup with low fat milk": 11513803,
    "Chocolate milk, made from light syrup with fat free milk": 11513804,
    "Chocolate milk, made from sugar free syrup with whole milk": 11513851,
    "Hot chocolate / Cocoa, made with dry mix and water": 11514100,
    "Hot chocolate / Cocoa, made with dry mix and whole milk": 11514110,
    "Hot chocolate / Cocoa, made with dry mix and reduced fat milk": 11514120,
    "Hot chocolate / Cocoa, made with dry mix and low fat milk": 11514130,
    "Hot chocolate / Cocoa, made with dry mix and fat free milk": 11514140,
    "Hot chocolate / Cocoa, made with dry mix and non-dairy milk": 11514150,
    "Hot chocolate / Cocoa, made with no sugar added dry mix and water": 11514310,
    "Hot chocolate / Cocoa, made with no sugar added dry mix and whole milk": 11514320,
    "Hot chocolate / Cocoa, made with no sugar added dry mix and reduced fat milk": 11514330,
    "Hot chocolate / Cocoa, made with no sugar added dry mix and low fat milk": 11514340,
    "Hot chocolate / Cocoa, made with no sugar added dry mix and fat free milk": 11514350,
    "Hot chocolate / Cocoa, made with no sugar added dry mix and non-dairy milk": 11514360,
    "Strawberry milk, NFS": 11519040,
    "Strawberry milk, whole": 11519050,
    "Strawberry milk, reduced fat": 11519105,
    "Strawberry milk, low fat": 11519200,
    "Strawberry milk, fat free": 11519205,
    "Strawberry milk, reduced sugar": 11519210,
    "Strawberry milk, non-dairy": 11519215,
    "Milk, malted": 11526000,
    "Eggnog": 11531000,
    "Milk shake, home recipe, chocolate": 11541110,
    "Milk shake, home recipe, flavors other than chocolate": 11541120,
    "Milk shake, home recipe, chocolate, light": 11541130,
    "Milk shake, home recipe, flavors other than chocolate, light": 11541135,
    "Milk shake with malt": 11541400,
    "Milk shake, fast food, chocolate": 11542100,
    "Milk shake, fast food, flavors other than chocolate": 11542200,
    "Milk shake, bottled, chocolate": 11543000,
    "Milk shake, bottled, flavors other than chocolate": 11543010,
    "Licuado or Batido": 11551050,
    "Fruit smoothie, NFS": 11553100,
    "Fruit smoothie, with whole fruit and dairy": 11553110,
    "Fruit smoothie, with whole fruit and dairy, added protein": 11553120,
    "Fruit smoothie juice drink, with dairy": 11553130,
    "Chocolate milk drink": 11560000,
    "Infant formula, NFS": 11710000,
    "Infant formula, Similac, NFS": 11710030,
    "Infant formula, Similac Alimentum, ready-to-feed": 11710051,
    "Infant formula, Similac Alimentum, powder, made with water": 11710056,
    "Infant formula, Similac Advance, ready-to-feed": 11710351,
    "Infant formula, Similac Advance, powder, made with tap water": 11710357,
    "Infant formula, Similac Advance, powder, made with bottled water": 11710358,
    "Infant formula, Similac Advance, powder, made with baby water": 11710359,
    "Infant formula, Similac Sensitive, ready-to-feed": 11710371,
    "Infant formula, Similac Sensitive, powder, made with tap water": 11710377,
    "Infant formula, Similac Sensitive, powder, made with bottled water": 11710378,
    "Infant formula, Similac Sensitive, powder, made with baby water": 11710379,
    "Infant formula, Similac for Spit-Up, ready-to-feed": 11710381,
    "Infant formula, Similac for Spit-Up, powder, made with water": 11710383,
    "Toddler formula, Similac Go and Grow": 11710481,
    "Infant formula, Enfamil, NFS": 11710605,
    "Infant formula, Enfamil Premium Newborn, ready-to-feed": 11710621,
    "Infant formula, Enfamil Premium Newborn, powder, made with water": 11710629,
    "Infant formula, Enfamil Infant, ready-to-feed": 11710631,
    "Infant formula, Enfamil Infant, powder, made with tap water": 11710637,
    "Infant formula, Enfamil Infant, powder, made with bottled water": 11710638,
    "Infant formula, Enfamil Infant, powder, made with baby water": 11710639,
    "Infant formula, Enfamil AR, ready-to-feed": 11710661,
    "Infant formula, Enfamil AR, powder, made with water": 11710669,
    "Infant formula, Enfamil Gentlease, ready-to-feed": 11710671,
    "Infant formula, Enfamil Gentlease, powder, made with tap water": 11710677,
    "Infant formula, Enfamil Gentlease, powder, made with bottled water": 11710678,
    "Infant formula, Enfamil Gentlease, powder, made with baby water": 11710679,
    "Toddler formula, Enfamil Enfagrow": 11710689,
    "Toddler formula, PediaSure": 11710801,
    "Toddler formula, Nido Kinder": 11710807,
    "Toddler formula, store brand, beginning or next stage": 11710810,
    "Toddler formula, store brand, pediatric shake": 11710815,
    "Infant formula, Gerber, NFS": 11710905,
    "Infant formula, Gerber Good Start Gentle, Stage 1, ready-to-feed": 11710911,
    "Infant formula, Gerber Good Start Gentle, Stage 1, powder, made with tap water": 11710917,
    "Infant formula, Gerber Good Start Gentle, Stage 1, powder, made with bottled water": 11710918,
    "Infant formula, Gerber Good Start Gentle, Stage 1, powder, made with baby water": 11710919,
    "Infant formula, Gerber Good Start Gentle, Stage 2": 11710921,
    "Toddler formula, Gerber Good Start, Stage 3": 11710930,
    "Infant formula, premature, powder, made with water": 11710935,
    "Infant formula, premature, ready-to-feed": 11710945,
    "Infant formula, organic, powder, made with water": 11710954,
    "Infant formula, organic, ready-to-feed": 11710955,
    "Infant formula, store brand, advantage or tender, powder, made with tap water": 11710967,
    "Infant formula, store brand, advantage or tender, powder, made with bottled water": 11710968,
    "Infant formula, store brand, advantage or tender, powder, made with baby water": 11710969,
    "Infant formula, store brand, gentle or sensitivity": 11710970,
    "Infant formula, store brand, added rice": 11710980,
    "Infant formula, Enfamil ProSobee, ready-to-feed": 11720311,
    "Infant formula, Enfamil ProSobee, powder, made with tap water": 11720317,
    "Infant formula, Enfamil ProSobee, powder, made with bottled water": 11720318,
    "Infant formula, Enfamil ProSobee, powder, made with baby water": 11720319,
    "Infant formula, Similac Isomil Soy, ready-to-feed": 11720411,
    "Infant formula, Similac Isomil Soy, powder, made with tap water": 11720417,
    "Infant formula, Similac Isomil Soy, powder, made with bottled water": 11720418,
    "Infant formula, Similac Isomil Soy, powder, made with baby water": 11720419,
    "Infant formula, Similac for Diarrhea": 11720430,
    "Infant formula, Gerber Good Start Soy, Stage 1, ready-to-feed": 11720611,
    "Infant formula, Gerber Good Start Soy, Stage 1, powder, made with tap water": 11720617,
    "Infant formula, Gerber Good Start Soy, Stage 1, powder, made with bottled water": 11720618,
    "Infant formula, Gerber Good Start Soy, Stage 1, powder, made with baby water": 11720619,
    "Infant formula, store brand, soy": 11720809,
    "Infant formula, Enfamil Nutramigen, ready-to-feed": 11740311,
    "Infant formula, Enfamil Nutramigen, powder, made with water": 11740313,
    "Infant formula, Enfamil Pregestimil, ready-to-feed": 11740401,
    "Infant formula, Enfamil Pregestimil, powder, made with water": 11740403,
    "Infant formula, amino acids": 11740405,
    "Infant formula, low iron": 11740560,
    "Milk, dry, not reconstituted, NS as to fat content": 11810000,
    "Milk, dry, not reconstituted, fat free (skim)": 11813000,
    "Whey, sweet, dry": 11825000,
    "Cocoa powder, not reconstituted": 11830150,
    "Chocolate beverage powder, dry mix, not reconstituted": 11830160,
    "Chocolate beverage powder, light, dry mix, not reconstituted": 11830165,
    "Milk, malted, dry mix, not reconstituted": 11830260,
    "Strawberry beverage powder, dry mix, not reconstituted": 11830400,
    "Cream, NS as to light, heavy, or half and half": 12100100,
    "Cream, light": 12110100,
    "Cream, half and half": 12120100,
    "Cream, half and half, flavored": 12120106,
    "Cream, half and half, fat free": 12120110,
    "Cream, heavy": 12130100,
    "Cream, whipped": 12140000,
    "Coffee creamer, NFS": 12200100,
    "Coffee creamer, liquid": 12210200,
    "Coffee creamer, liquid, flavored": 12210210,
    "Coffee creamer, liquid, fat free": 12210260,
    "Coffee creamer, liquid, fat free, flavored": 12210270,
    "Coffee creamer, liquid, fat free, sugar free, flavored": 12210280,
    "Coffee creamer, liquid, sugar free, flavored": 12210310,
    "Coffee creamer, powder": 12210400,
    "Coffee creamer, powder, flavored": 12210420,
    "Coffee creamer, powder, fat free": 12210430,
    "Coffee creamer,powder, fat free, flavored": 12210440,
    "Coffee creamer,powder, sugar free, flavored": 12210505,
    "Coffee creamer, soy, liquid": 12210520,
    "Whipped topping": 12220200,
    "Whipped topping, fat free": 12220270,
    "Whipped topping, sugar free": 12220280,
    "Sour cream, regular": 12310100,
    "Sour cream, light": 12310350,
    "Sour cream, fat free": 12310370,
    "Sour cream, imitation": 12320100,
    "Dip, NFS": 12350010,
    "Chipotle dip, regular": 12350200,
    "Chipotle dip, light": 12350205,
    "Onion dip, regular": 12350220,
    "Onion dip, light": 12350225,
    "Ranch dip, regular": 12350230,
    "Ranch dip, light": 12350235,
    "Spinach dip, regular": 12350240,
    "Spinach dip, light": 12350245,
    "Vegetable dip, regular": 12350250,
    "Vegetable dip, light": 12350255,
    "Ice cream, NFS": 13110000,
    "Ice cream, vanilla": 13110100,
    "Ice cream, vanilla, with additional ingredients": 13110102,
    "Ice cream, chocolate": 13110110,
    "Ice cream, chocolate, with additional ingredients": 13110112,
    "Ice cream, soft serve, vanilla": 13110200,
    "Ice cream, soft serve, chocolate": 13110210,
    "Gelato, vanilla": 13110460,
    "Gelato, chocolate": 13110470,
    "Ice cream bar, vanilla": 13120050,
    "Ice cream bar, vanilla, chocolate coated": 13120100,
    "Ice cream candy bar": 13120110,
    "Ice cream bar, chocolate": 13120140,
    "Ice cream sandwich, vanilla": 13120500,
    "Ice cream sandwich, chocolate": 13120510,
    "Ice cream cookie sandwich": 13120550,
    "Ice cream cone, scooped, vanilla": 13120730,
    "Ice cream cone, scooped, vanilla, waffle cone": 13120735,
    "Ice cream cone, NFS": 13120740,
    "Ice cream cone, scooped, chocolate": 13120770,
    "Ice cream cone, scooped, chocolate, waffle cone": 13120775,
    "Ice cream cone, soft serve, vanilla": 13120782,
    "Ice cream cone, soft serve, chocolate": 13120784,
    "Ice cream cone, soft serve, vanilla, waffle cone": 13120786,
    "Ice cream cone, soft serve, chocolate, waffle cone": 13120788,
    "Ice cream cone, vanilla, prepackaged": 13120790,
    "Ice cream cone, chocolate, prepackaged": 13120792,
    "Ice cream soda, flavors other than chocolate": 13120800,
    "Ice cream soda, chocolate": 13120810,
    "Ice cream sundae, NFS": 13121000,
    "Ice cream sundae, fruit topping": 13121100,
    "Banana split": 13121120,
    "Ice cream sundae, hot fudge topping": 13121300,
    "Ice cream sundae, caramel topping": 13121400,
    "Ice cream, fried": 13126000,
    "Light ice cream, NFS": 13130100,
    "Light ice cream, vanilla": 13130300,
    "Light ice cream, chocolate": 13130310,
    "Soft serve, blended with candy or cookies, from fast food": 13130700,
    "Light ice cream sandwich, vanilla": 13135000,
    "Light ice cream sandwich, chocolate": 13135010,
    "Light ice cream bar, vanilla": 13140000,
    "Light ice cream bar, vanilla, chocolate coated": 13140100,
    "Light ice cream bar, chocolate": 13140115,
    "Creamsicle": 13140700,
    "Fudgesicle": 13140900,
    "Light ice cream cone, vanilla, prepackaged": 13142100,
    "Light ice cream cone, chocolate, prepackaged": 13142110,
    "Sherbet, all flavors": 13150000,
    "Fudgesicle, light": 13161600,
    "Pudding, chocolate, NFS": 13200110,
    "Pudding, bread": 13210110,
    "Pudding, flavors other than chocolate, NFS": 13210280,
    "Custard": 13210300,
    "Flan": 13210350,
    "Creme brulee": 13210370,
    "Pudding, rice": 13210410,
    "Firni, Indian pudding": 13210450,
    "Pudding, tapioca, made from dry mix": 13210520,
    "Pudding, flavors other than chocolate, made from dry mix": 13220110,
    "Pudding, chocolate, made from dry mix": 13220120,
    "Pudding, flavors other than chocolate, made from dry mix, sugar free": 13220210,
    "Pudding, chocolate, made from dry mix, sugar free": 13220220,
    "Pudding, flavors other than chocolate, ready-to-eat": 13230110,
    "Pudding, flavors other than chocolate, ready-to-eat, sugar free": 13230120,
    "Pudding, chocolate, ready-to-eat": 13230130,
    "Pudding, chocolate, ready-to-eat, sugar free": 13230140,
    "Pudding, tapioca, ready-to-eat": 13230500,
    "Banana pudding": 13241000,
    "Mousse": 13250000,
    "Dulce de leche": 13252200,
    "Barfi or Burfi, Indian dessert": 13252500,
    "Trifle": 13252590,
    "Tiramisu": 13252600,
    "White sauce or gravy": 13411000,
    "Cheese, NFS": 14010000,
    "Cheese, Blue or Roquefort": 14101010,
    "Cheese, Brick": 14102010,
    "Cheese, Camembert": 14103010,
    "Cheese, Brie": 14103020,
    "Cheese, Cheddar": 14104100,
    "Cheese, Cheddar, reduced fat": 14104110,
    "Cheese, Cheddar, nonfat or fat free": 14104115,
    "Cheese, Colby": 14104200,
    "Cheese, Colby Jack": 14104250,
    "Cheese, Feta": 14104400,
    "Cheese, Fontina": 14104600,
    "Cheese, goat": 14104700,
    "Cheese, Gouda or Edam": 14105010,
    "Cheese, Gruyere": 14105200,
    "Cheese, Limburger": 14106010,
    "Cheese, Monterey": 14106200,
    "Cheese, Monterey, reduced fat": 14106500,
    "Cheese, Mozzarella, NFS": 14107010,
    "Cheese, Mozzarella, part skim": 14107030,
    "Cheese, Mozzarella, reduced sodium": 14107040,
    "Cheese, Mozzarella, nonfat or fat free": 14107060,
    "Cheese, Muenster": 14107200,
    "Cheese, Muenster, reduced fat": 14107250,
    "Cheese, Parmesan, dry grated": 14108010,
    "Cheese, Parmesan, dry grated, reduced fat": 14108015,
    "Cheese, Parmesan, hard": 14108020,
    "Cheese, Parmesan, dry grated, fat free": 14108060,
    "Cheese, Port du Salut": 14108200,
    "Cheese, Provolone": 14108400,
    "Cheese, provolone, reduced fat": 14108420,
    "Cheese, Swiss": 14109010,
    "Cheese, Swiss, reduced sodium": 14109020,
    "Cheese, Swiss, reduced fat": 14109030,
    "Cheese, Swiss, nonfat or fat free": 14109040,
    "Cheese, Cheddar, reduced sodium": 14110010,
    "Cheese, paneer": 14110050,
    "Cheese, Mexican blend": 14120010,
    "Cheese, Mexican blend, reduced fat": 14120020,
    "Queso Anejo, aged Mexican cheese": 14131000,
    "Queso Asadero": 14131500,
    "Queso Fresco": 14133000,
    "Queso cotija": 14134000,
    "Cheese, cottage, NFS": 14200100,
    "Cheese, cottage, creamed, large or small curd": 14201010,
    "Cottage cheese, farmer's": 14201200,
    "Cheese, Ricotta": 14201500,
    "Cheese, cottage, with fruit": 14202010,
    "Cheese, cottage, with vegetables": 14202020,
    "Cheese, cottage, dry curd": 14203010,
    "Cheese, cottage, salted, dry curd": 14203020,
    "Puerto Rican white cheese": 14203510,
    "Cheese, cottage, low fat": 14204010,
    "Cheese, cottage, lowfat, with fruit": 14204020,
    "Cheese, cottage, lowfat, low sodium": 14206010,
    "Cheese, cottage, lowfat, lactose reduced": 14207010,
    "Cream cheese, regular, plain": 14301010,
    "Cream cheese, regular, flavored": 14301100,
    "Cream cheese, light": 14303010,
    "Cheese, American and Swiss blends": 14410100,
    "Cheese, American": 14410110,
    "Cheese, American, reduced fat": 14410120,
    "Cheese, American, nonfat or fat free": 14410130,
    "Cheese, American, reduced sodium": 14410210,
    "Cheese spread, American or Cheddar cheese base, reduced fat": 14410330,
    "Cream cheese spread, fat free": 14410380,
    "Cheese, processed cheese food": 14410500,
    "Cheese, processed, with vegetables": 14410600,
    "Cheese,  with wine": 14410620,
    "Cheese spread, American or Cheddar cheese base": 14420100,
    "Cheese spread, Swiss cheese base": 14420160,
    "Cheese spread, cream cheese, regular": 14420200,
    "Cheese spread, cream cheese, light": 14420210,
    "Cheese spread, pressurized can": 14420300,
    "Imitation cheese": 14502000,
    "Cheese, cottage cheese, with gelatin dessert": 14610200,
    "Cheese, cottage cheese, with gelatin dessert and fruit": 14610210,
    "Cheese, cottage cheese, with gelatin dessert and vegetables": 14610250,
    "Cheese ball": 14610520,
    "Artichoke dip": 14620110,
    "Spinach and artichoke dip": 14620115,
    "Seafood dip": 14620130,
    "Cheese dip with chili pepper": 14620150,
    "Cheese dip": 14620200,
    "Topping from cheese pizza": 14620300,
    "Topping from vegetable pizza": 14620310,
    "Topping from meat pizza": 14620320,
    "Topping from meat and vegetable pizza": 14620330,
    "Cheese fondue": 14630100,
    "Cheese souffle": 14630200,
    "Welsh rarebit": 14630300,
    "Cheese sandwich, NFS": 14640000,
    "Cheese sandwich, American cheese, on white bread": 14640002,
    "Cheese sandwich, American cheese, on wheat bread": 14640004,
    "Cheese sandwich, cheddar cheese, on white bread": 14640008,
    "Cheese sandwich, cheddar cheese, on wheat bread": 14640010,
    "Cheese sandwich, reduced fat cheese, on white bread": 14640014,
    "Cheese sandwich, reduced fat cheese, on wheat bread": 14640016,
    "Grilled cheese sandwich, NFS": 14640100,
    "Grilled cheese sandwich, American cheese, on white bread": 14640105,
    "Grilled cheese sandwich, American cheese, on wheat bread": 14640110,
    "Grilled cheese sandwich, cheddar cheese, on white bread": 14640125,
    "Grilled cheese sandwich, cheddar cheese, on wheat bread": 14640130,
    "Grilled cheese sandwich, reduced fat cheese, on white bread": 14640155,
    "Grilled cheese sandwich, reduced fat cheese, on wheat bread": 14640160,
    "Cheese sauce": 14650100,
    "Alfredo sauce": 14650160,
    "Alfredo sauce with added vegetables": 14650165,
    "Alfredo sauce with meat": 14650170,
    "Alfredo sauce with meat and added vegetables": 14650175,
    "Alfredo sauce with poultry": 14650180,
    "Alfredo sauce with poultry and added vegetables": 14650185,
    "Alfredo sauce with seafood": 14650190,
    "Alfredo sauce with seafood and added vegetables": 14650195,
    "Mozzarella sticks, breaded, baked, or fried": 14660200,
    "Mozzarella cheese, tomato, and basil, with oil and vinegar dressing": 14670000,
    "Cheddar cheese soup, home recipe, canned or ready-to-serve": 14710100,
    "Meat, NFS": 20000000,
    "Meat, ground, NFS": 20000200,
    "Baby Toddler meat, NFS": 20000300,
    "Beef, NFS": 21000100,
    "Steak, NS as to type of meat, NS as to fat eaten": 21001000,
    "Beef, steak, NFS": 21101000,
    "Beef, steak, chuck": 21101140,
    "Beef, steak, cube": 21101150,
    "Beef, steak, flank": 21101160,
    "Beef, steak, ribeye, NS as to fat eaten": 21101170,
    "Beef, steak, ribeye, lean and fat eaten": 21101180,
    "Beef, steak, ribeye, lean only eaten": 21101190,
    "Beef, steak, round": 21102140,
    "Beef, steak, sirloin, NS as to fat eaten": 21102150,
    "Beef, steak, sirloin, lean and fat eaten": 21102160,
    "Beef, steak, sirloin, lean only eaten": 21102170,
    "Beef, steak, strip, NS as to fat eaten": 21102180,
    "Beef, steak, strip, lean and fat eaten": 21102190,
    "Beef, steak, strip, lean only eaten": 21103140,
    "Beef, steak, T-bone, NS as to fat eaten": 21103150,
    "Beef, steak, T-bone,  lean and fat eaten": 21103160,
    "Beef, steak, T-bone, lean only eaten": 21103170,
    "Beef, steak, tenderloin": 21103180,
    "Beef, steak, country fried": 21104110,
    "Beef, oxtails": 21301000,
    "Beef, neck bones": 21302000,
    "Beef, shortribs": 21304200,
    "Beef, cow head": 21305000,
    "Beef, roast": 21401000,
    "Beef, pot roast": 21407000,
    "Beef, stew meat": 21410000,
    "Beef, corned": 21416000,
    "Beef, brisket": 21417100,
    "Beef, sandwich steak": 21420100,
    "Beef, ground, raw": 21500000,
    "Beef, ground": 21500100,
    "Beef, ground, patty": 21500310,
    "Beef, bacon, cooked": 21601000,
    "Beef, bacon, reduced sodium, cooked": 21601010,
    "Beef, dried, chipped, uncooked": 21602000,
    "Beef, dried, chipped, cooked in fat": 21602010,
    "Beef jerky": 21602100,
    "Baby Toddler beef": 21701010,
    "Pork, NFS": 22000100,
    "Pork, ground": 22002000,
    "Pork, carnitas": 22002050,
    "Pork jerky": 22002800,
    "Pork, chop, NS as to fat eaten": 22101000,
    "Pork, chop, lean and fat eaten": 22101010,
    "Pork, chop, lean only eaten": 22101020,
    "Pork, chop, coated, NS as to fat eaten": 22101300,
    "Pork, chop, coated, lean and fat eaten": 22101310,
    "Pork, chop, coated, lean only eaten": 22101320,
    "Pork, chop, stuffed": 22101330,
    "Pork, steak, NS as to fat eaten": 22201100,
    "Pork, steak, lean and fat eaten": 22201110,
    "Pork, steak, lean only eaten": 22201120,
    "Pork, steak, coated": 22201400,
    "Pork, tenderloin": 22210300,
    "Ham": 22311000,
    "Ham, prosciutto": 22311450,
    "Ham, canned": 22311500,
    "Ham, ground": 22321110,
    "Pork, roast": 22400100,
    "Fried pork chunks, Puerto Rican style": 22402510,
    "Pork, roll": 22431000,
    "Canadian bacon, cooked": 22501010,
    "Bacon, NS as to type of meat, cooked": 22600100,
    "Bacon, NS as to type of meat, reduced sodium, cooked": 22600110,
    "Pork bacon, NS as to fresh, smoked or cured, cooked": 22600200,
    "Pork bacon, NS as to fresh, smoked or cured, reduced sodium, cooked": 22600210,
    "Pork bacon, smoked or cured, cooked": 22601000,
    "Bacon or side pork, fresh, cooked": 22601040,
    "Pork bacon, smoked or cured, reduced sodium, cooked": 22602010,
    "Fat back, cooked": 22621100,
    "Ribs, NFS": 22700000,
    "Pork, ribs": 22701000,
    "Pork, cracklings": 22704010,
    "Pork, ears": 22705010,
    "Pork, bones": 22706010,
    "Pork, pig's feet": 22707010,
    "Pork, pig's feet, pickled": 22707020,
    "Pork, ham hocks": 22708010,
    "Pork, belly": 22708020,
    "Pork skin rinds": 22709010,
    "Baby Toddler ham": 22810010,
    "Baby Toddler meat stick": 22820000,
    "Lamb, NS as to cut": 23000100,
    "Lamb, chop": 23101000,
    "Lamb, ground": 23132000,
    "Goat": 23150100,
    "Veal, chop": 23201010,
    "Veal, ground": 23220010,
    "Mock chicken legs": 23220020,
    "Rabbit": 23310000,
    "Venison, NFS": 23321000,
    "Venison, steak": 23321200,
    "Venison/deer jerky": 23321900,
    "Venison sausage": 23322100,
    "Moose": 23323100,
    "Bear": 23323500,
    "Caribou": 23324100,
    "Bison": 23326100,
    "Groundhog": 23331100,
    "Opossum": 23332100,
    "Squirrel": 23333100,
    "Beaver": 23334100,
    "Raccoon": 23335100,
    "Armadillo": 23340100,
    "Wild pig": 23345100,
    "Ostrich": 23350100,
    "Chicken, NS as to part and cooking method, NS as to skin eaten": 24100000,
    "Chicken, NS as to part and cooking method, skin eaten": 24100010,
    "Chicken, NS as to part and cooking method, skin not eaten": 24100020,
    "Chicken, NS as to part, baked, broiled, or roasted, NS as to skin eaten": 24102000,
    "Chicken, NS as to part, baked, broiled, or roasted, skin eaten": 24102010,
    "Chicken, NS as to part, baked, broiled, or roasted, skin not eaten": 24102020,
    "Chicken, NS as to part, rotisserie, NS as to skin eaten": 24102050,
    "Chicken, NS as to part, rotisserie, skin eaten": 24102060,
    "Chicken, NS as to part, rotisserie, skin not eaten": 24102070,
    "Chicken, NS as to part, stewed, NS as to skin eaten": 24103000,
    "Chicken, NS as to part, stewed, skin eaten": 24103010,
    "Chicken, NS as to part, stewed, skin not eaten": 24103020,
    "Chicken, NS as to part, grilled without sauce, NS as to skin eaten": 24103050,
    "Chicken, NS as to part, grilled without sauce, skin eaten": 24103055,
    "Chicken, NS as to part, grilled without sauce, skin not eaten": 24103060,
    "Chicken, NS as to part, grilled with sauce, NS as to skin eaten": 24103070,
    "Chicken, NS as to part, grilled with sauce, skin eaten": 24103075,
    "Chicken, NS as to part, grilled with sauce, skin not eaten": 24103080,
    "Chicken, NS as to part, sauteed, skin eaten": 24104049,
    "Chicken, NS as to part, sauteed, skin not eaten": 24104051,
    "Chicken, NS as to part, fried, coated, skin / coating eaten": 24107070,
    "Chicken, NS as to part, fried, coated, skin / coating not eaten": 24107071,
    "Chicken, NS as to part, baked, coated, skin / coating eaten": 24107080,
    "Chicken, NS as to part, baked, coated, skin / coating not eaten": 24107081,
    "Chicken breast, NS as to cooking method, skin eaten": 24120110,
    "Chicken breast, NS as to cooking method, skin not eaten": 24120120,
    "Chicken breast, baked, broiled, or roasted, skin eaten, from raw": 24122130,
    "Chicken breast, baked, broiled, or roasted, skin not eaten, from raw": 24122131,
    "Chicken breast, baked or broiled, skin eaten, from pre-cooked": 24122140,
    "Chicken breast, baked or broiled, skin not eaten, from pre-cooked": 24122141,
    "Chicken breast, baked or broiled, skin eaten, from fast food / restaurant": 24122150,
    "Chicken breast, baked or broiled, skin not eaten, from fast food / restaurant": 24122151,
    "Chicken breast, baked, broiled, or roasted with marinade, skin eaten, from raw": 24122160,
    "Chicken breast, baked, broiled, or roasted with marinade, skin not eaten, from raw": 24122161,
    "Chicken breast, rotisserie, skin eaten": 24122170,
    "Chicken breast, rotisserie, skin not eaten": 24122171,
    "Chicken breast, stewed, skin eaten": 24123110,
    "Chicken breast, stewed, skin not eaten": 24123120,
    "Chicken breast, grilled without sauce, skin eaten": 24123300,
    "Chicken breast, grilled without sauce, skin not eaten": 24123301,
    "Chicken breast, grilled with sauce, skin eaten": 24123310,
    "Chicken breast, grilled with sauce, skin not eaten": 24123311,
    "Chicken breast, sauteed, skin eaten": 24124200,
    "Chicken breast, sauteed, skin not eaten": 24124201,
    "Chicken breast, fried, coated, skin / coating eaten, from raw": 24127200,
    "Chicken breast, fried, coated, skin / coating not eaten, from raw": 24127201,
    "Chicken breast, fried, coated, prepared skinless, coating eaten, from raw": 24127202,
    "Chicken breast, fried, coated, skin / coating eaten, from pre-cooked": 24127210,
    "Chicken breast, fried, coated, skin / coating not eaten, from pre-cooked": 24127211,
    "Chicken breast, fried, coated, skin / coating eaten, from fast food / restaurant": 24127220,
    "Chicken breast, fried, coated, skin / coating not eaten, from fast food / restaurant": 24127221,
    "Chicken breast, baked, coated, skin / coating eaten": 24127500,
    "Chicken breast, baked, coated, skin / coating not eaten": 24127501,
    "Chicken leg, drumstick and thigh, NS as to cooking method, skin eaten": 24130210,
    "Chicken leg, drumstick and thigh, NS as to cooking method, skin not eaten": 24130220,
    "Chicken leg, drumstick and thigh, baked or broiled, skin eaten": 24132230,
    "Chicken leg, drumstick and thigh, baked or broiled, skin not eaten": 24132231,
    "Chicken leg, drumstick and thigh, rotisserie, skin eaten": 24132240,
    "Chicken leg, drumstick and thigh, rotisserie, skin not eaten": 24132241,
    "Chicken leg, drumstick and thigh, stewed, skin eaten": 24133210,
    "Chicken leg, drumstick and thigh, stewed, skin not eaten": 24133220,
    "Chicken leg, drumstick and thigh, grilled without sauce, skin eaten": 24134100,
    "Chicken leg, drumstick and thigh, grilled without sauce, skin not eaten": 24134101,
    "Chicken leg, drumstick and thigh, grilled with sauce, skin eaten": 24134150,
    "Chicken leg, drumstick and thigh, grilled with sauce, skin not eaten": 24134151,
    "Chicken leg, drumstick and thigh, sauteed, skin eaten": 24134300,
    "Chicken leg, drumstick and thigh, sauteed, skin not eaten": 24134301,
    "Chicken leg, drumstick and thigh, fried, coated, skin / coating eaten": 24137300,
    "Chicken leg, drumstick and thigh, fried, coated, skin / coating not eaten": 24137301,
    "Chicken leg, drumstick and thigh, baked, coated, skin / coating eaten": 24137310,
    "Chicken leg, drumstick and thigh, baked, coated, skin / coating not eaten": 24137311,
    "Chicken drumstick, NS as to cooking method, skin eaten": 24140210,
    "Chicken drumstick, NS as to cooking method, skin not eaten": 24140220,
    "Chicken drumstick, baked, broiled, or roasted, skin eaten, from raw": 24142300,
    "Chicken drumstick, baked, broiled, or roasted, skin not eaten, from raw": 24142301,
    "Chicken drumstick, baked or broiled, skin eaten, from pre-cooked": 24142310,
    "Chicken drumstick, baked or broiled, skin not eaten, from pre-cooked": 24142311,
    "Chicken drumstick, baked or broiled, skin eaten, from fast food / restaurant": 24142320,
    "Chicken drumstick, baked or broiled, skin not eaten, from fast food / restaurant": 24142321,
    "Chicken drumstick, rotisserie, skin eaten": 24142400,
    "Chicken drumstick, rotisserie, skin not eaten": 24142401,
    "Chicken drumstick, grilled without sauce, skin eaten": 24142500,
    "Chicken drumstick, grilled without sauce, skin not eaten": 24142501,
    "Chicken drumstick, grilled with sauce, skin eaten": 24142510,
    "Chicken drumstick, grilled with sauce, skin not eaten": 24142511,
    "Chicken drumstick, stewed, skin eaten": 24143210,
    "Chicken drumstick, stewed, skin not eaten": 24143220,
    "Chicken drumstick, sauteed, skin eaten": 24144300,
    "Chicken drumstick, sauteed, skin not eaten": 24144301,
    "Chicken drumstick, fried, coated, skin / coating eaten, from raw": 24147300,
    "Chicken drumstick, fried, coated, skin / coating not eaten, from raw": 24147301,
    "Chicken drumstick, fried, coated, prepared skinless, coating eaten, from raw": 24147302,
    "Chicken drumstick, fried, coated, skin / coating eaten, from pre-cooked": 24147310,
    "Chicken drumstick, fried, coated, skin / coating not eaten, from pre-cooked": 24147311,
    "Chicken drumstick, fried, coated, skin / coating eaten, from fast food / restaurant": 24147320,
    "Chicken drumstick, fried, coated, skin / coating not eaten, from fast food / restaurant": 24147321,
    "Chicken drumstick, baked, coated, skin / coating eaten": 24147400,
    "Chicken drumstick, baked, coated, skin / coating not eaten": 24147401,
    "Chicken thigh, NS as to cooking method, skin eaten": 24150210,
    "Chicken thigh, NS as to cooking method, skin not eaten": 24150220,
    "Chicken thigh, baked, broiled, or roasted, skin eaten, from raw": 24152230,
    "Chicken thigh, baked, broiled, or roasted, skin not eaten, from raw": 24152231,
    "Chicken thigh, baked or broiled, skin eaten, from pre-cooked": 24152240,
    "Chicken thigh, baked or broiled, skin not eaten, from pre-cooked": 24152241,
    "Chicken thigh, baked or broiled, skin eaten, from fast food / restaurant": 24152250,
    "Chicken thigh, baked or broiled, skin not eaten, from fast food / restaurant": 24152251,
    "Chicken thigh, rotisserie, skin eaten": 24152300,
    "Chicken thigh, rotisserie, skin not eaten": 24152301,
    "Chicken thigh, stewed, skin eaten": 24153210,
    "Chicken thigh, stewed, skin not eaten": 24153220,
    "Chicken thigh, grilled without sauce, skin eaten": 24154010,
    "Chicken thigh, grilled without sauce, skin not eaten": 24154011,
    "Chicken thigh, grilled with sauce, skin eaten": 24154020,
    "Chicken thigh, grilled with sauce, skin not eaten": 24154021,
    "Chicken thigh, sauteed, skin eaten": 24154300,
    "Chicken thigh, sauteed, skin not eaten": 24154301,
    "Chicken thigh, fried, coated, skin / coating eaten, from raw": 24157300,
    "Chicken thigh, fried, coated, skin / coating not eaten, from raw": 24157301,
    "Chicken thigh, fried, coated, prepared skinless, coating eaten, from raw": 24157302,
    "Chicken thigh, fried, coated, skin / coating eaten, from pre-cooked": 24157310,
    "Chicken thigh, fried, coated, skin / coating not eaten, from pre-cooked": 24157311,
    "Chicken thigh, fried, coated, skin / coating eaten, from fast food": 24157320,
    "Chicken thigh, fried, coated, skin / coating not eaten, from fast food": 24157321,
    "Chicken thigh, fried, coated, skin / coating eaten, from restaurant": 24157330,
    "Chicken thigh, fried, coated, skin / coating not eaten, from restaurant": 24157331,
    "Chicken thigh, baked, coated, skin / coating eaten": 24157400,
    "Chicken thigh, baked, coated, skin / coating not eaten": 24157401,
    "Chicken wing, NS as to cooking method": 24160110,
    "Chicken wing, baked, broiled, or roasted, from raw": 24162130,
    "Chicken wing, baked or broiled, from pre-cooked": 24162140,
    "Chicken wing, baked or broiled, from fast food / restaurant": 24162150,
    "Chicken wing, rotisserie": 24162200,
    "Chicken wing, stewed": 24163110,
    "Chicken wing, grilled without sauce": 24164000,
    "Chicken wing, grilled with sauce": 24164010,
    "Chicken wing, sauteed": 24164200,
    "Chicken wing, fried, coated, from raw": 24167200,
    "Chicken wing, fried, coated, from pre-cooked": 24167210,
    "Chicken wing, fried, coated, from fast food": 24167220,
    "Chicken wing, fried, coated, from restaurant": 24167230,
    "Chicken wing, baked, coated": 24167300,
    "Chicken \"wings\" with hot sauce, from fast food / restaurant": 24168000,
    "Chicken \"wings\" with other sauces or seasoning, from fast food / restaurant": 24168001,
    "Chicken \"wings\", plain, from fast food / restaurant": 24168002,
    "Chicken \"wings\" with hot sauce, from precooked": 24168010,
    "Chicken \"wings\" with other sauces or seasoning, from precooked": 24168011,
    "Chicken \"wings\", plain, from precooked": 24168012,
    "Chicken \"wings\" with hot sauce, from other sources": 24168020,
    "Chicken \"wings\" with other sauces or seasoning, from other sources": 24168021,
    "Chicken \"wings\", plain, from other sources": 24168022,
    "Chicken \"wings\", boneless, with hot sauce, from fast food / restaurant": 24168030,
    "Chicken \"wings\", boneless, with hot sauce, from other sources": 24168031,
    "Chicken, back": 24170200,
    "Chicken, neck or ribs": 24180200,
    "Chicken, tail": 24198340,
    "Chicken skin": 24198440,
    "Chicken feet": 24198500,
    "Chicken, canned, meat only": 24198570,
    "Chicken, chicken roll, roasted": 24198670,
    "Chicken patty, breaded": 24198671,
    "Chicken fillet, breaded": 24198677,
    "Chicken fillet, grilled": 24198683,
    "Chicken, ground": 24198720,
    "Chicken nuggets, NFS": 24198729,
    "Chicken nuggets, from fast food": 24198731,
    "Chicken nuggets, from restaurant": 24198732,
    "Chicken nuggets, from school lunch": 24198735,
    "Chicken nuggets, from frozen": 24198736,
    "Chicken nuggets, from other sources": 24198737,
    "Chicken tenders or strips, NFS": 24198739,
    "Chicken tenders or strips, breaded, from fast food": 24198741,
    "Chicken tenders or strips, breaded, from restaurant": 24198742,
    "Chicken tenders or strips, breaded, from school lunch": 24198745,
    "Chicken tenders or strips, breaded, from frozen": 24198746,
    "Chicken tenders or strips, breaded, from other sources": 24198747,
    "Fried chicken chunks, Puerto Rican style": 24198840,
    "Turkey, NFS": 24201000,
    "Turkey, light meat, skin not eaten": 24201020,
    "Turkey, light meat, skin eaten": 24201030,
    "Turkey, light meat, breaded, baked or fried, skin not eaten": 24201060,
    "Turkey, light meat, breaded, baked or fried, skin eaten": 24201070,
    "Turkey, light meat, roasted, skin not eaten": 24201120,
    "Turkey, light meat, roasted, skin eaten": 24201130,
    "Turkey, dark meat, roasted, skin not eaten": 24201220,
    "Turkey, dark meat, roasted, skin eaten": 24201230,
    "Turkey, light and dark meat, roasted, skin not eaten": 24201320,
    "Turkey, light and dark meat, roasted, skin eaten": 24201330,
    "Turkey, light or dark meat, fried, coated, skin not eaten": 24201360,
    "Turkey, light or dark meat, fried, coated, skin eaten": 24201370,
    "Turkey, light or dark meat, stewed, skin not eaten": 24201410,
    "Turkey light or dark meat, stewed, skin eaten": 24201420,
    "Turkey, light or dark meat, smoked, skin eaten": 24201510,
    "Turkey, light or dark meat, smoked, skin not eaten": 24201520,
    "Turkey, drumstick, cooked, skin not eaten": 24202010,
    "Turkey, drumstick, cooked, skin eaten": 24202020,
    "Turkey, drumstick, roasted, skin not eaten": 24202060,
    "Turkey, drumstick, roasted, skin eaten": 24202070,
    "Turkey, drumstick, smoked, skin eaten": 24202120,
    "Turkey, thigh, cooked, skin eaten": 24202460,
    "Turkey, thigh, cooked, skin not eaten": 24202500,
    "Turkey, neck": 24202600,
    "Turkey, wing, cooked, skin not eaten": 24203010,
    "Turkey, wing, cooked, skin eaten": 24203020,
    "Turkey, wing, smoked, skin eaten": 24203120,
    "Turkey, tail": 24205000,
    "Turkey, back": 24205100,
    "Turkey, canned": 24206000,
    "Turkey, ground": 24207000,
    "Turkey, nuggets": 24208000,
    "Turkey bacon, cooked": 24208500,
    "Turkey bacon, reduced sodium, cooked": 24208510,
    "Duck, cooked, skin eaten": 24300110,
    "Duck, cooked, skin not eaten": 24300120,
    "Duck, roasted, skin eaten": 24301010,
    "Duck, roasted, skin not eaten": 24301020,
    "Duck, pressed, Chinese": 24302010,
    "Goose, wild, roasted": 24311010,
    "Cornish game hen, cooked, skin eaten": 24400010,
    "Cornish game hen, cooked, skin not eaten": 24400020,
    "Cornish game hen, roasted, skin eaten": 24401010,
    "Cornish game hen, roasted, skin not eaten": 24401020,
    "Dove, cooked, NS as to cooking method": 24402100,
    "Dove, fried": 24402110,
    "Quail, cooked": 24403100,
    "Pheasant, cooked": 24404100,
    "Baby Toddler chicken": 24701010,
    "Baby Toddler turkey": 24703010,
    "Liver, beef": 25110140,
    "Liver, chicken": 25110450,
    "Liver, paste or pate": 25112200,
    "Heart": 25120000,
    "Kidney": 25130000,
    "Sweetbreads": 25140110,
    "Brains": 25150000,
    "Tongue": 25160000,
    "Tongue pot roast, Puerto Rican style": 25160130,
    "Tripe": 25170110,
    "Chitterlings": 25170210,
    "Hog maws": 25170310,
    "Gizzard": 25170420,
    "Hot dog, NFS": 25210110,
    "Hot dog, beef": 25210210,
    "Hot dog, meat and poultry": 25210280,
    "Hot dog, reduced fat": 25210290,
    "Hot dog, turkey": 25210410,
    "Beef sausage": 25220105,
    "Beef sausage, reduced fat": 25220106,
    "Beef sausage, reduced sodium": 25220108,
    "Beef sausage with cheese": 25220150,
    "Blood sausage": 25220210,
    "Bratwurst": 25220350,
    "Bratwurst, with cheese": 25220360,
    "Bologna": 25220410,
    "Bologna, reduced fat": 25220425,
    "Bologna, reduced sodium": 25220435,
    "Turkey or chicken and beef sausage": 25220650,
    "Chorizo": 25220710,
    "Head cheese": 25220910,
    "Knockwurst": 25221110,
    "Mortadella": 25221210,
    "Pastrami, NFS": 25221215,
    "Pastrami, made from any kind of meat, reduced fat": 25221220,
    "Pepperoni, NFS": 25221250,
    "Pepperoni, reduced fat": 25221255,
    "Pepperoni, reduced sodium": 25221260,
    "Polish sausage": 25221310,
    "Italian sausage": 25221350,
    "Sausage, NFS": 25221400,
    "Pork sausage": 25221405,
    "Pork sausage, reduced fat": 25221406,
    "Pork sausage, reduced sodium": 25221408,
    "Pork sausage rice links": 25221450,
    "Pork and beef sausage": 25221460,
    "Salami, NFS": 25221500,
    "Salami, made from any type of meat, reduced fat": 25221505,
    "Salami, made from any type of meat, reduced sodium": 25221515,
    "Scrapple, cooked": 25221610,
    "Thuringer": 25221810,
    "Turkey or chicken sausage": 25221830,
    "Turkey or chicken sausage, reduced sodium": 25221855,
    "Turkey or chicken sausage, reduced fat": 25221860,
    "Turkey or chicken and pork sausage": 25221870,
    "Turkey or chicken, pork, and beef sausage, reduced sodium": 25221875,
    "Vienna sausage, canned": 25221910,
    "Pickled sausage": 25221950,
    "Luncheon meat, NFS": 25230110,
    "Ham, prepackaged or deli, luncheon meat": 25230210,
    "Ham, prepackaged or deli, luncheon meat, reduced sodium": 25230220,
    "Chicken, prepackaged or deli, luncheon meat": 25230320,
    "Chicken, prepackaged or deli, luncheon meat, reduced sodium": 25230340,
    "Ham luncheon meat, loaf type": 25230420,
    "Spam": 25230530,
    "Spam, reduced sodium": 25230550,
    "Liverwurst": 25230560,
    "Luncheon meat, loaf type": 25230610,
    "Turkey, prepackaged or deli, luncheon meat": 25230780,
    "Turkey, prepackaged or deli, luncheon meat, reduced sodium": 25230785,
    "Turkey ham, prepackaged or deli, luncheon meat": 25230800,
    "Beef, prepackaged or deli, luncheon meat": 25231110,
    "Beef, prepackaged or deli, luncheon meat, reduced sodium": 25231120,
    "Meat spread or potted meat, NFS": 25240000,
    "Chicken salad spread": 25240110,
    "Ham salad spread": 25240220,
    "Fish, raw": 26100100,
    "Fish, NFS": 26100110,
    "Fish, NS as to type, baked or broiled": 26100120,
    "Fish, NS as to type, baked or broiled, coated": 26100130,
    "Fish, NS as to type, fried": 26100140,
    "Fish, NS as to type, steamed": 26100160,
    "Fish, canned": 26100180,
    "Fish, smoked": 26100190,
    "Fish, stick": 26100270,
    "Fish, anchovy": 26101110,
    "Fish, carp": 26105110,
    "Fish, catfish, NFS": 26107110,
    "Fish, catfish, baked or broiled": 26107120,
    "Fish, catfish, grilled": 26107123,
    "Fish, catfish, baked or broiled, coated": 26107130,
    "Fish, catfish, fried": 26107140,
    "Fish, catfish, steamed": 26107160,
    "Fish, cod, NFS": 26109110,
    "Fish, cod, baked or broiled": 26109120,
    "Fish, cod, grilled": 26109123,
    "Fish, cod, baked or broiled, coated": 26109130,
    "Fish, cod, fried": 26109140,
    "Fish, cod, steamed": 26109160,
    "Fish, croaker": 26111110,
    "Fish, eel": 26113110,
    "Fish, flounder, NFS": 26115110,
    "Fish, flounder, baked or broiled": 26115120,
    "Fish, flounder, grilled": 26115123,
    "Fish, flounder, baked or broiled, coated": 26115130,
    "Fish, flounder, fried": 26115140,
    "Fish, flounder, steamed": 26115160,
    "Fish, haddock, NFS": 26117110,
    "Fish, haddock, baked or broiled": 26117120,
    "Fish, haddock, grilled": 26117121,
    "Fish, haddock, baked or broiled, coated": 26117130,
    "Fish, haddock, fried": 26117140,
    "Fish, haddock, steamed": 26117160,
    "Fish, halibut": 26118010,
    "Fish, herring": 26119110,
    "Fish, pickled": 26119180,
    "Fish, mackerel, NFS": 26121110,
    "Fish, mackerel, baked or broiled": 26121120,
    "Fish, mackerel, grilled": 26121121,
    "Fish, mackerel, baked or broiled, coated": 26121131,
    "Fish, mackerel, fried": 26121140,
    "Fish, mackerel, canned": 26121180,
    "Fish, mullet": 26123110,
    "Fish, perch, NFS": 26127110,
    "Fish, perch, baked or broiled": 26127120,
    "Fish, perch, grilled": 26127123,
    "Fish, perch, baked or broiled, coated": 26127130,
    "Fish, perch, fried": 26127140,
    "Fish, perch, steamed": 26127160,
    "Fish, pike": 26129110,
    "Fish, pompano, NFS": 26131110,
    "Fish, pompano, baked or broiled": 26131120,
    "Fish, pompano, grilled": 26131121,
    "Fish, pompano, baked or broiled, coated": 26131130,
    "Fish, pompano, fried": 26131140,
    "Fish, pompano, steamed": 26131160,
    "Fish, snapper": 26133110,
    "Fish, salmon, raw": 26137100,
    "Fish, salmon, NFS": 26137110,
    "Fish, salmon, baked or broiled": 26137120,
    "Fish, salmon, grilled": 26137123,
    "Fish, salmon, baked or broiled, coated": 26137130,
    "Fish, salmon, fried": 26137140,
    "Fish, salmon, steamed": 26137160,
    "Fish, salmon, canned": 26137180,
    "Fish, salmon, smoked": 26137190,
    "Fish, sardines, canned": 26139180,
    "Fish, bass, NFS": 26141110,
    "Fish, bass, baked or broiled": 26141120,
    "Fish, bass, grilled": 26141121,
    "Fish, bass, baked or broiled, coated": 26141130,
    "Fish, bass, fried": 26141140,
    "Fish, bass, steamed": 26141160,
    "Fish, shark": 26143110,
    "Fish, swordfish": 26149110,
    "Fish, trout, NFS": 26151110,
    "Fish, trout, baked or broiled": 26151120,
    "Fish, trout, grilled": 26151123,
    "Fish, trout, baked or broiled, coated": 26151130,
    "Fish, trout, fried": 26151140,
    "Fish, trout, steamed": 26151160,
    "Fish, tuna, raw": 26153100,
    "Fish, tuna, NFS": 26153110,
    "Fish, tuna, cooked": 26153120,
    "Fish, tuna, canned": 26155110,
    "Fish, whiting, NFS": 26157110,
    "Fish, whiting, baked or broiled": 26157120,
    "Fish, whiting, grilled": 26157123,
    "Fish, whiting, baked or broiled, coated": 26157130,
    "Fish, whiting, fried": 26157140,
    "Fish, whiting, steamed": 26157160,
    "Fish, tilapia, NFS": 26158000,
    "Fish, tilapia, baked or broiled": 26158010,
    "Fish, tilapia, grilled": 26158013,
    "Fish, tilapia, baked or broiled, coated": 26158020,
    "Fish, tilapia, fried": 26158030,
    "Fish, tilapia, steamed": 26158050,
    "Fish, white, mixed species, NFS": 26158100,
    "Fish, white, mixed species, baked or broiled": 26158110,
    "Fish, white, mixed species, baked or broiled, coated": 26158120,
    "Fish, white, mixed species, fried": 26158130,
    "Fish, white, mixed species, steamed": 26158140,
    "Fish, white, mixed species, grilled": 26158150,
    "Frog legs": 26203110,
    "Octopus": 26205110,
    "Caviar": 26211100,
    "Calamari, cooked": 26213120,
    "Calamari, fried": 26213140,
    "Turtle": 26215120,
    "Shellfish, NFS": 26300000,
    "Abalone": 26301110,
    "Clams, raw": 26303100,
    "Clams, NFS": 26303110,
    "Clams, baked or broiled": 26303120,
    "Clams, fried": 26303140,
    "Clams, steamed or boiled": 26303160,
    "Clams, canned": 26303180,
    "Crab": 26305160,
    "Crab, canned": 26305180,
    "Crab, soft shell": 26307140,
    "Crayfish, fried": 26309140,
    "Crayfish, cooked": 26309160,
    "Lobster": 26311110,
    "Mussels": 26313110,
    "Oysters, raw": 26315100,
    "Oysters, baked or broiled": 26315120,
    "Oysters, steamed": 26315130,
    "Oysters, fried": 26315140,
    "Oysters, canned": 26315180,
    "Scallops, baked or broiled": 26317120,
    "Scallops, grilled": 26317121,
    "Scallops, steamed or boiled": 26317130,
    "Scallops, fried": 26317140,
    "Shrimp, NFS": 26319110,
    "Shrimp, baked or broiled": 26319120,
    "Shrimp, grilled": 26319123,
    "Shrimp, steamed or boiled": 26319130,
    "Shrimp, fried": 26319140,
    "Shrimp, baked or broiled, coated": 26319160,
    "Shrimp, dried": 26319170,
    "Shrimp, canned": 26319180,
    "Escargot": 26321110,
    "Barbecue meat, NFS": 27100100,
    "Beef with tomato-based sauce": 27111000,
    "Beef goulash": 27111100,
    "Beef burgundy": 27111200,
    "Beef stew, no potatoes, tomato-based sauce, Mexican style": 27111300,
    "Chili con carne, NS as to beans": 27111400,
    "Chili con carne with beans, from restaurant": 27111405,
    "Chili con carne with beans, home recipe": 27111406,
    "Chili con carne with beans, canned": 27111407,
    "Chili con carne with beans": 27111410,
    "Chili con carne without beans": 27111420,
    "Chili con carne, NS as to beans, with cheese": 27111430,
    "Chili con carne with beans and cheese": 27111440,
    "Sloppy joe, no bun": 27111500,
    "Beef with gravy": 27112000,
    "Salisbury steak with gravy": 27112010,
    "Beef with cream or white sauce": 27113000,
    "Beef stroganoff": 27113100,
    "Creamed chipped or dried beef": 27113200,
    "Swedish meatballs with cream or white sauce": 27113300,
    "Beef with mushroom sauce": 27114000,
    "Beef with soy-based sauce": 27115000,
    "Steak teriyaki": 27115100,
    "Beef curry": 27116100,
    "Beef curry with rice": 27116110,
    "Barbecue beef, with sauce": 27116200,
    "Barbecue beef, no sauce": 27116210,
    "Beef with sweet and sour sauce": 27116300,
    "Stewed seasoned ground beef, Mexican style": 27116350,
    "Steak tartare": 27116400,
    "Meatballs, Puerto Rican style": 27118110,
    "Stewed seasoned ground beef, Puerto Rican style": 27118120,
    "Stewed dried beef, Puerto Rican style": 27118130,
    "Stuffed pot roast, Puerto Rican style, NFS": 27118140,
    "Beef stew, meat with gravy, no potatoes, Puerto Rican style": 27118180,
    "Ham or pork with gravy": 27120020,
    "Barbecue pork, with sauce": 27120030,
    "Barbecue pork, no sauce": 27120040,
    "Sweet and sour pork": 27120060,
    "Ham stroganoff": 27120080,
    "Ham or pork with mushroom sauce": 27120090,
    "Ham or pork with tomato-based sauce": 27120100,
    "Sausage with tomato-based sauce": 27120110,
    "Sausage gravy": 27120120,
    "Pork stew, no potatoes, tomato-based sauce, Mexican style": 27120130,
    "Pork or ham with soy-based sauce": 27120150,
    "Chili hot dog, no bun": 27120210,
    "Frankfurters or hot dogs with tomato-based sauce": 27120250,
    "Pork with chili and tomatoes": 27121000,
    "Stewed pork, Puerto Rican style": 27121010,
    "Chili con carne with beans, made with pork": 27121410,
    "Lamb or mutton with gravy": 27130010,
    "Stewed goat, Puerto Rican style": 27133010,
    "Veal with gravy": 27135010,
    "Veal scallopini": 27135020,
    "Veal with cream sauce": 27135030,
    "Veal with butter sauce": 27135040,
    "Veal Marsala": 27135050,
    "Veal parmigiana": 27135110,
    "Veal cordon bleu": 27135150,
    "Venison or deer with tomato-based sauce": 27136050,
    "Venison or deer with gravy": 27136080,
    "Chili con carne with venison/deer and beans": 27136100,
    "Chicken or turkey cacciatore": 27141000,
    "Spaghetti sauce with poultry": 27141030,
    "Spaghetti sauce with poultry and added vegetables": 27141035,
    "Stewed chicken with tomato-based sauce, Mexican style": 27141050,
    "Chili con carne with chicken or turkey and beans": 27141500,
    "Chicken with gravy": 27142000,
    "Chicken or turkey fricassee": 27142100,
    "Turkey with gravy": 27142200,
    "Chicken or turkey with cream sauce": 27143000,
    "Chicken or turkey with mushroom sauce": 27144000,
    "Chicken or turkey with teriyaki": 27145000,
    "Barbecue chicken": 27146011,
    "Sweet and sour chicken or turkey": 27146100,
    "Sweet and sour chicken or turkey, without vegetables": 27146110,
    "Chicken curry": 27146150,
    "Chicken curry with rice": 27146155,
    "Chicken with mole sauce": 27146160,
    "Chicken or turkey with cheese sauce": 27146200,
    "Chicken or turkey cordon bleu": 27146250,
    "Chicken or turkey parmigiana": 27146300,
    "Orange chicken": 27146350,
    "Sesame chicken": 27146360,
    "Chicken kiev": 27146400,
    "Stuffed chicken, drumstick or breast, Puerto Rican style": 27148010,
    "Fish with cream or white sauce, not tuna or lobster": 27150010,
    "Fish timbale or mousse": 27150050,
    "Shrimp cocktail": 27150110,
    "Tuna with cream or white sauce": 27150120,
    "Seafood thermidor": 27150130,
    "Seafood sauce": 27150140,
    "Spaghetti sauce with seafood": 27150151,
    "Spaghetti sauce with seafood and added vegetables": 27150155,
    "Shrimp with lobster sauce": 27150160,
    "Sweet and sour shrimp": 27150170,
    "Fish sauce": 27150210,
    "Shrimp scampi": 27150230,
    "Fish with tomato-based sauce": 27150310,
    "Fish curry": 27150320,
    "Fish curry with rice": 27150325,
    "Shrimp teriyaki": 27150410,
    "Ceviche": 27151030,
    "Crabs in tomato-based sauce, Puerto Rican style": 27151040,
    "Shrimp in garlic sauce, Puerto Rican style": 27151050,
    "Bacalao guisado": 27151070,
    "Meatballs, NS as to type of meat, with sauce": 27160100,
    "Meat loaf, Puerto Rican style": 27161010,
    "Meat with tomato-based sauce": 27162010,
    "Spaghetti sauce with meat": 27162040,
    "Spaghetti sauce with meat and added vegetables": 27162060,
    "Stewed, seasoned, ground beef and pork, Mexican style": 27162500,
    "Meat with gravy, NS as to type of meat,": 27163010,
    "Beef and potatoes, no sauce": 27211000,
    "Beef stew with potatoes, tomato-based sauce, Mexican style": 27211110,
    "Beef and potatoes with cream sauce, white sauce or mushroom sauce": 27211190,
    "Beef, roast, hash": 27211300,
    "Corned beef hash": 27211400,
    "Beef and potatoes with cheese sauce": 27211500,
    "Stewed, seasoned, ground beef with potatoes, Mexican style": 27211550,
    "Beef and noodles, no sauce": 27212000,
    "Beef and macaroni with cheese sauce": 27212050,
    "Beef and noodles with tomato-based sauce": 27212100,
    "Chili con carne with beans and macaroni": 27212120,
    "Beef goulash with noodles": 27212150,
    "Beef and noodles with gravy": 27212200,
    "Beef and noodles with cream or white sauce": 27212300,
    "Beef stroganoff with noodles": 27212350,
    "Beef and noodles with mushroom sauce": 27212400,
    "Beef and noodles with soy-based sauce": 27212500,
    "Beef and rice, no sauce": 27213000,
    "Biryani with meat": 27213010,
    "Beef and rice with tomato-based sauce": 27213100,
    "Porcupine balls with tomato-based sauce": 27213120,
    "Beef and rice with gravy": 27213200,
    "Beef and rice with cream sauce": 27213300,
    "Beef and rice with mushroom sauce": 27213400,
    "Porcupine balls with mushroom sauce": 27213420,
    "Beef and rice with soy-based sauce": 27213500,
    "Beef and rice with cheese sauce": 27213600,
    "Meat loaf made with beef": 27214100,
    "Meat loaf made with beef, with tomato-based sauce": 27214110,
    "Beef wellington": 27214300,
    "Corned beef patty": 27214500,
    "Stuffed pot roast, with potatoes, Puerto Rican style": 27218110,
    "Beef stew with potatoes, Puerto Rican style": 27218210,
    "Stewed corned beef, Puerto Rican style": 27218310,
    "Meat loaf made with ham": 27220010,
    "Ham and noodles with cream or white sauce": 27220020,
    "Ham and rice with mushroom sauce": 27220030,
    "Ham or pork with stuffing": 27220050,
    "Ham croquette": 27220080,
    "Pork and rice with tomato-based sauce": 27220110,
    "Sausage and rice with tomato-based sauce": 27220120,
    "Sausage and rice with mushroom sauce": 27220150,
    "Sausage and rice with cheese sauce": 27220170,
    "Sausage and noodles with cream or white sauce": 27220190,
    "Ham and noodles, no sauce": 27220210,
    "Ham or pork and rice, no sauce": 27220310,
    "Ham or pork and potatoes with gravy": 27220510,
    "Ham or pork and potatoes with cheese sauce": 27220520,
    "Stewed pig's feet, Puerto Rican style": 27221100,
    "Stuffed pork roast, Puerto Rican style": 27221110,
    "Pork stew, with potatoes, tomato-based sauce, Mexican style": 27221150,
    "Lamb or mutton loaf": 27230010,
    "Lamb or mutton and potatoes with gravy": 27231000,
    "Lamb or mutton and potatoes with tomato-based sauce": 27232000,
    "Lamb or mutton and noodles with gravy": 27233000,
    "Meat loaf made with venison/deer": 27235000,
    "Veal and noodles with cream or white sauce": 27235750,
    "Venison or deer and noodles with cream or white sauce": 27236000,
    "Chicken or turkey hash": 27241000,
    "Chicken or turkey and potatoes with gravy": 27241010,
    "Chicken or turkey and noodles, no sauce": 27242000,
    "Chicken or turkey and noodles with gravy": 27242200,
    "Chicken or turkey and noodles with mushroom sauce": 27242250,
    "Chicken or turkey and noodles with cream or white sauce": 27242300,
    "Chicken or turkey and noodles with cheese sauce": 27242310,
    "Chicken or turkey tetrazzini": 27242350,
    "Chicken or turkey and noodles with tomato-based sauce": 27242400,
    "Chicken or turkey and noodles with soy-based sauce": 27242500,
    "Chicken or turkey and rice, no sauce": 27243000,
    "Biryani with chicken": 27243100,
    "Chicken or turkey and rice with cream sauce": 27243300,
    "Chicken or turkey and rice with mushroom sauce": 27243400,
    "Chicken or turkey and rice with tomato-based sauce": 27243500,
    "Chicken or turkey and rice with soy-based sauce": 27243600,
    "Chicken or turkey with dumplings": 27246100,
    "Chicken or turkey with stuffing": 27246200,
    "Chicken or turkey cake, patty, or croquette": 27246300,
    "Chicken or turkey souffle": 27246400,
    "Meat loaf made with chicken or turkey": 27246500,
    "Meat loaf made with chicken or turkey, with tomato-based sauce": 27246505,
    "Crab, cake": 27250040,
    "Fish, cake or patty": 27250050,
    "Gefilte fish": 27250060,
    "Fish, salmon cake or patty": 27250070,
    "Scallops and noodles with cheese sauce": 27250110,
    "Shrimp and noodles, no sauce": 27250120,
    "Shrimp and noodles with gravy": 27250122,
    "Shrimp and noodles with mushroom sauce": 27250124,
    "Shrimp and noodles with cream or white sauce": 27250126,
    "Shrimp and noodles with soy-based sauce": 27250128,
    "Shrimp and noodles with cheese sauce": 27250130,
    "Shrimp and noodles with tomato sauce": 27250132,
    "Tuna loaf": 27250150,
    "Tuna cake or patty": 27250160,
    "Clam cake or patty": 27250210,
    "Flounder with crab stuffing": 27250250,
    "Lobster with bread stuffing, baked": 27250260,
    "Clams Casino": 27250270,
    "Shrimp, cake or patty": 27250400,
    "Shrimp with crab stuffing": 27250410,
    "Shrimp toast": 27250450,
    "Imitation crab meat": 27250520,
    "Seafood souffle": 27250550,
    "Tuna noodle casserole with cream or white sauce": 27250610,
    "Tuna noodle casserole with mushroom sauce": 27250630,
    "Tuna and rice with mushroom sauce": 27250710,
    "Fish and rice with tomato-based sauce": 27250810,
    "Fish and rice with cream sauce": 27250820,
    "Fish and rice with mushroom sauce": 27250830,
    "Fish and noodles with mushroom sauce": 27250900,
    "Shellfish and noodles with tomato-based sauce": 27250950,
    "Stewed salmon, Puerto Rican style": 27251010,
    "Meat loaf, NS as to type of meat": 27260010,
    "Meatballs, with breading, NS as to type of meat, with gravy": 27260050,
    "Meat loaf made with beef and pork": 27260080,
    "Meat loaf made with beef, veal and pork": 27260090,
    "Meat loaf made with beef and pork, with tomato-based sauce": 27260100,
    "Hash, NS as to type of meat": 27260110,
    "Vienna sausages stewed with potatoes, Puerto Rican style": 27260500,
    "Stewed, seasoned, ground beef and pork with potatoes, Mexican style": 27261500,
    "Beef, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27311110,
    "Beef, potatoes, and vegetables, excluding carrots, broccoli, and dark-green leafy; no sauce": 27311120,
    "Corned beef, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27311210,
    "Corned beef, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; no sauce": 27311220,
    "Beef stew with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27311310,
    "Beef stew with potatoes and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27311320,
    "Beef stew with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27311410,
    "Beef stew with potatoes and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27311420,
    "Shepherd's pie": 27311510,
    "Beef, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27311600,
    "Beef, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27311605,
    "Beef, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; cream sauce, white sauce, or mushroom sauce": 27311610,
    "Beef, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; cream sauce, white sauce, or mushroom sauce": 27311620,
    "Beef, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27311625,
    "Beef, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27311630,
    "Beef, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; cheese sauce": 27311635,
    "Beef, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; cheese sauce": 27311640,
    "Beef, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; soy-based sauce": 27311645,
    "Beef, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; soy-based sauce": 27311650,
    "Beef, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27313010,
    "Beef, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; no sauce": 27313020,
    "Beef chow mein or chop suey with noodles": 27313110,
    "Beef, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; soy-based sauce": 27313150,
    "Beef, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; soy-based sauce": 27313160,
    "Beef, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27313210,
    "Beef, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27313220,
    "Beef, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; mushroom sauce": 27313310,
    "Beef, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; mushroom sauce": 27313320,
    "Beef, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27313410,
    "Beef, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27313420,
    "Beef, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27315010,
    "Beef, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; no sauce": 27315020,
    "Beef, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27315210,
    "Beef, rice, and vegetables excluding carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27315220,
    "Stuffed cabbage rolls with beef and rice": 27315250,
    "Stuffed grape leaves with beef and rice": 27315270,
    "Beef, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; mushroom sauce": 27315310,
    "Beef, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; mushroom sauce": 27315320,
    "Beef, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; cheese sauce": 27315330,
    "Beef, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; cheese sauce": 27315340,
    "Beef, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27315410,
    "Beef, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27315420,
    "Beef, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; soy-based sauce": 27315510,
    "Beef, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; soy-based sauce": 27315520,
    "Pot pie, beef": 27317010,
    "Beef, dumplings, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27317100,
    "Beef, dumplings, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27317110,
    "Stuffed green pepper, Puerto Rican style": 27319010,
    "Ham or pork, noodles and vegetables excluding carrots, broccoli, and dark-green leafy; no sauce": 27320025,
    "Ham or pork, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27320027,
    "Ham or pork, noodles and vegetables excluding carrots, broccoli, and dark-green leafy; cheese sauce": 27320030,
    "Pork, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27320040,
    "Ham or pork, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27320070,
    "Sausage, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27320080,
    "Sausage, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27320090,
    "Pork, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27320100,
    "Pork, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27320110,
    "Sausage, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27320120,
    "Sausage, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27320130,
    "Pork, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27320140,
    "Pork, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27320150,
    "Pork, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; no sauce": 27320210,
    "Pork chow mein or chop suey with noodles": 27320310,
    "Pork, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; soy-based sauce": 27320320,
    "Pork, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; soy-based sauce": 27320330,
    "Pork, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27320340,
    "Pork, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27320350,
    "Ham, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; no sauce": 27320410,
    "Ham, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27320450,
    "Sweet and sour pork with rice": 27320500,
    "Lamb or mutton, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27330050,
    "Lamb or mutton, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27330060,
    "Lamb or mutton, rice, and vegetables  including carrots, broccoli, and/or dark-green leafy; gravy": 27330080,
    "Stuffed grape leaves with lamb and rice": 27330170,
    "Lamb or mutton stew with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27330210,
    "Veal fricassee, Puerto Rican style": 27331150,
    "Stewed rabbit, Puerto Rican style,": 27335500,
    "Venison or deer stew with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27336100,
    "Venison or deer, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27336200,
    "Venison or deer, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27336250,
    "Venison or deer, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27336300,
    "Venison or deer, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27336310,
    "Chicken or turkey, potatoes, corn, and cheese, with gravy": 27341000,
    "Chicken or turkey, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27341010,
    "Chicken or turkey, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; no sauce": 27341020,
    "Chicken or turkey, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27341025,
    "Chicken or turkey, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27341030,
    "Chicken or turkey, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; cream sauce, white sauce, or mushroom sauce": 27341035,
    "Chicken or turkey, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; cream sauce, white sauce, or mushroom sauce": 27341040,
    "Chicken or turkey, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; cheese sauce": 27341045,
    "Chicken or turkey, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; cheese sauce": 27341050,
    "Chicken or turkey, potatoes, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27341055,
    "Chicken or turkey, potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27341060,
    "Chicken or turkey stew with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27341310,
    "Chicken or turkey stew with potatoes and vegetables excluding carrots, broccoli, and dark-green leafy; tomato- based sauce": 27341520,
    "Chicken or turkey, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27343010,
    "Chicken or turkey, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; no sauce": 27343020,
    "Chicken or turkey, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27343410,
    "Chicken or turkey, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27343420,
    "Chicken or turkey, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; cream sauce, white sauce, or mushroom sauce": 27343470,
    "Chicken or turkey, noodles, and vegetables excluding carrots, broccoli, and/or dark-green leafy; cream sauce, white sauce, or mushroom sauce": 27343480,
    "Chicken or turkey, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27343510,
    "Chicken or turkey, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27343520,
    "Chicken or turkey chow mein or chop suey with noodles": 27343910,
    "Chicken or turkey, noodles, and vegetables including carrots, broccoli, and/or dark-green leafy; cheese sauce": 27343950,
    "Chicken or turkey, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; cheese sauce": 27343960,
    "Chicken or turkey, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27345010,
    "Chicken or turkey, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; no sauce": 27345020,
    "Chicken or turkey, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27345210,
    "Chicken or turkey, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27345220,
    "Chicken or turkey, rice, corn, and cheese, with gravy": 27345230,
    "Chicken or turkey, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; soy-based sauce": 27345310,
    "Chicken or turkey, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; soy-based sauce": 27345320,
    "Chicken or turkey, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; cream sauce, white sauce, or mushroom sauce": 27345410,
    "Chicken or turkey, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; cream sauce, white sauce, or mushroom sauce": 27345420,
    "Chicken or turkey, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; cheese sauce": 27345440,
    "Chicken or turkey, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; cheese sauce": 27345450,
    "Chicken or turkey, rice, and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27345510,
    "Chicken or turkey, rice, and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27345520,
    "Pot pie, chicken": 27347100,
    "Chicken or turkey, stuffing, and vegetables including carrots, broccoli, and/or dark-green leafy; no sauce": 27347200,
    "Chicken or turkey,stuffing, and vegetables excluding carrots, broccoli, and dark green leafy; no sauce": 27347210,
    "Chicken or turkey, stuffing, and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27347220,
    "Chicken or turkey, stuffing, and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27347230,
    "Chicken or turkey, dumplings, and vegetables including carrots, broccoli, and/or dark green leafy; gravy": 27347240,
    "Chicken or turkey, dumplings, and vegetables excluding carrots, broccoli, and dark green leafy; gravy": 27347250,
    "Chicken fricassee, Puerto Rican style": 27348100,
    "Paella with seafood": 27350020,
    "Shrimp chow mein or chop suey with noodles": 27350050,
    "Shrimp creole, with rice": 27350060,
    "Tuna noodle casserole with vegetables, cream or white sauce": 27350080,
    "Fish, noodles, and vegetables including carrots, broccoli, and/or dark green leafy; cheese sauce": 27350090,
    "Fish, noodles, and vegetables excluding carrots, broccoli, and dark-green leafy; cheese sauce": 27350100,
    "Bouillabaisse": 27350110,
    "Seafood stew with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27350310,
    "Tuna noodle casserole with vegetables and mushroom sauce": 27350410,
    "Codfish with starchy vegetables, Puerto Rican style": 27351010,
    "Biscayne codfish, Puerto Rican style": 27351040,
    "Stew, NFS": 27360000,
    "Chow mein or chop suey, NS as to type of meat, with noodles": 27360080,
    "Paella, NFS": 27360090,
    "Chow mein or chop suey, various types of meat, with noodles": 27360120,
    "Stewed variety meats, mostly liver, Puerto Rican style": 27361010,
    "Stewed tripe, with potatoes, Puerto Rican style": 27362000,
    "Gumbo with rice": 27363000,
    "Jambalaya with meat and rice": 27363100,
    "Beef and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, no sauce": 27410210,
    "Beef and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, no sauce": 27410220,
    "Beef shish kabob with vegetables, excluding potatoes": 27410250,
    "Beef with vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, tomato-based sauce": 27411100,
    "Swiss steak": 27411120,
    "Beef rolls, stuffed with vegetables or meat mixture, tomato-based sauce": 27411150,
    "Beef with vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, tomato-based sauce": 27411200,
    "Beef with vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, mushroom sauce": 27414100,
    "Beef with vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, mushroom sauce": 27414200,
    "Beef and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, soy-based sauce": 27415100,
    "Beef and broccoli": 27415110,
    "Beef, tofu, and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, soy-based sauce": 27415120,
    "Szechuan beef": 27415130,
    "Hunan beef": 27415140,
    "Beef chow mein or chop suey, no noodles": 27415150,
    "Kung Pao beef": 27415170,
    "Beef and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, soy-based sauce": 27415200,
    "Beef, tofu, and vegetables excluding carrots, broccoli,  and dark-green leafy; no potatoes, soy-based sauce": 27415220,
    "Beef and vegetables, Hawaiian style": 27416100,
    "Pepper steak": 27416150,
    "Beef, ground, with egg and onion": 27416200,
    "Beef salad": 27416250,
    "Beef taco filling: beef, cheese, tomato, taco sauce": 27416300,
    "Stir fried beef and vegetables in soy sauce": 27416400,
    "Beef and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, gravy": 27416450,
    "Beef and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, gravy": 27416500,
    "Seasoned shredded soup meat": 27418110,
    "Beef stew with vegetables excluding potatoes, Puerto Rican style": 27418210,
    "Corned beef with tomato sauce and onion, Puerto Rican style": 27418310,
    "Beef steak with onions, Puerto Rican style": 27418410,
    "Cabbage with ham hocks": 27420010,
    "Ham or pork salad": 27420020,
    "Frankfurters or hot dogs and sauerkraut": 27420040,
    "Pork and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, no sauce": 27420060,
    "Greens with ham or pork": 27420080,
    "Pork, tofu, and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, soy-base sauce": 27420100,
    "Pork and vegetables, Hawaiian style": 27420110,
    "Pork and watercress with soy-based sauce": 27420120,
    "Kung Pao pork": 27420150,
    "Moo Shu pork, without Chinese pancake": 27420160,
    "Pork and onions with soy-based sauce": 27420170,
    "Pork hash": 27420200,
    "Ham and vegetables including carrots broccoli, and/or dark- green leafy; no potatoes, no sauce": 27420250,
    "Ham and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, no sauce": 27420270,
    "Pork and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, no sauce": 27420350,
    "Pork, tofu, and vegetables, excluding carrots, broccoli, and dark-green leafy; no potatoes, soy-based sauce": 27420370,
    "Pork chow mein or chop suey, no noodles": 27420390,
    "Pork and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, tomato-based sauce": 27420400,
    "Pork and vegetables excluding  carrots, broccoli, and dark-green leafy; no potatoes, tomato-based sauce": 27420410,
    "Sausage and vegetables including  carrots, broccoli, and/or dark-green leafy; no potatoes, tomato-based sauce": 27420450,
    "Sausage and vegetables, excluding carrots, broccoli, and dark-green leafy; no potatoes, tomato-based sauce": 27420460,
    "Sausage and peppers, no sauce": 27420470,
    "Pork and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, soy-based sauce": 27420500,
    "Pork and vegetables excluding carrots, broccoli, and dark- green leafy; no potatoes, soy-based sauce": 27420510,
    "Pork shish kabob with vegetables, excluding potatoes": 27420520,
    "Stuffed christophine, Puerto Rican style": 27421010,
    "Pork chop stewed with vegetables, Puerto Rican style": 27422010,
    "Lamb or mutton stew with vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, gravy": 27430400,
    "Veal with vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, cream or white sauce": 27430580,
    "Veal with vegetables excluding carrots, broccoli, and dark-green leafy; and potatoes, cream or white sauce": 27430590,
    "Lamb shish kabob with vegetables, excluding potatoes": 27430610,
    "Chicken or turkey and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, no sauce": 27440110,
    "Chicken or turkey and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, no sauce": 27440120,
    "Chicken or turkey shish kabob with vegetables, excluding potatoes": 27440130,
    "Chicken or turkey creole, without rice": 27441120,
    "Chicken or turkey and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, gravy": 27442110,
    "Chicken or turkey and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, gravy": 27442120,
    "Chicken or turkey a la king with vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, cream, white, or soup-based sauce": 27443110,
    "Chicken or turkey a la king with vegetables excluding carrorts, broccoli, and dark-green leafy; no potatoes, cream, white, or soup-based sauce": 27443120,
    "Chicken or turkey divan": 27443150,
    "Chicken or turkey and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, soy-based sauce": 27445110,
    "Chicken or turkey and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, soy-based sauce": 27445120,
    "Chicken or turkey and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, tomato-based sauce": 27445125,
    "Chicken or turkey and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, tomato-based sauce": 27445130,
    "General Tso chicken": 27445150,
    "Moo Goo Gai Pan": 27445180,
    "Kung pao chicken": 27445220,
    "Almond chicken": 27445250,
    "Chicken or turkey chow mein or chop suey, no noodles": 27446100,
    "Chicken or turkey salad, made with mayonnaise": 27446200,
    "Chicken or turkey salad with nuts and/or fruits": 27446205,
    "Chicken or turkey salad with egg": 27446220,
    "Chicken or turkey salad, made with light mayonnaise": 27446225,
    "Chicken or turkey salad, made with mayonnaise-type salad dressing": 27446230,
    "Chicken or turkey salad, made with light mayonnaise-type salad dressing": 27446235,
    "Chicken or turkey salad, made with creamy dressing": 27446240,
    "Chicken or turkey salad, made with light creamy dressing": 27446245,
    "Chicken or turkey salad, made with Italian dressing": 27446250,
    "Chicken or turkey salad, made with light Italian dressing": 27446255,
    "Chicken or turkey salad, made with any type of fat free dressing": 27446260,
    "Chicken or turkey garden salad, chicken and/or turkey, tomato and/or carrots, other vegetables, no dressing": 27446300,
    "Chicken or turkey garden salad, chicken and/or turkey, other vegetables excluding tomato and carrots, no dressing": 27446310,
    "Chicken or turkey garden salad with bacon and cheese, chicken and/or turkey, bacon, cheese, lettuce and/or greens, tomato and/or carrots, other vegetables, no dressing": 27446315,
    "Chicken or turkey, breaded, fried, garden salad with bacon and cheese, chicken and/or turkey, bacon, cheese, lettuce and/or greens, tomato and/or carrots, other vegetables, no dressing": 27446320,
    "Chicken or turkey garden salad with cheese, chicken and/or turkey, cheese, lettuce and/or greens, tomato and/or carrots, other vegetables, no dressing": 27446330,
    "Chicken or turkey, breaded, fried, garden salad with cheese, chicken and/or turkey, cheese, lettuce and/or greens, tomato and/or carrots, other vegetables, no dressing": 27446332,
    "Asian chicken or turkey garden salad, chicken and/or turkey, lettuce, fruit, nuts, no dressing": 27446350,
    "Asian chicken or turkey garden salad with crispy noodles, chicken and/or turkey, lettuce, fruit, nuts, crispy noodles, no dressing": 27446355,
    "Chicken or turkey caesar garden salad, chicken and/or turkey, lettuce, tomato, cheese, no dressing": 27446360,
    "Chicken or turkey, breaded, fried, caesar garden salad, chicken and/or turkey, lettuce, tomatoes, cheese, no dressing": 27446362,
    "Chicken or turkey and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, cheese sauce": 27446400,
    "Chicken or turkey and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, cheese sauce": 27446410,
    "Chicken or turkey fricassee, with sauce, no potatoes, potatoes reported separately, Puerto Rican style": 27448020,
    "Chicken or turkey fricassee, no sauce, no potatoes, Puerto Rican style": 27448030,
    "Crab salad": 27450010,
    "Lobster salad": 27450020,
    "Salmon salad": 27450030,
    "Shrimp chow mein or chop suey, no noodles": 27450040,
    "Tuna salad, made with mayonnaise": 27450060,
    "Tuna salad, made with light mayonnaise": 27450061,
    "Tuna salad, made with mayonnaise-type salad dressing": 27450062,
    "Tuna salad, made with light mayonnaise-type salad dressing": 27450063,
    "Tuna salad, made with creamy dressing": 27450064,
    "Tuna salad, made with light creamy dressing": 27450065,
    "Tuna salad, made with Italian dressing": 27450066,
    "Tuna salad, made with light Italian dressing": 27450067,
    "Tuna salad, made with any type of fat free dressing": 27450068,
    "Shrimp salad": 27450070,
    "Seafood salad": 27450080,
    "Tuna salad with cheese": 27450090,
    "Tuna salad with egg": 27450100,
    "Shrimp garden salad, shrimp, lettuce, eggs, tomato and/or carrots, other vegetables, no dressing": 27450110,
    "Shrimp garden salad, shrimp, lettuce, eggs, vegetables excluding tomato and carrots, no dressing": 27450120,
    "Crab salad made with imitation crab": 27450130,
    "Fish, tofu, and vegetables, tempura": 27450150,
    "Seafood garden salad with seafood, lettuce, vegetables excluding tomato and carrots, no dressing": 27450180,
    "Seafood garden salad with seafood, lettuce, tomato and/or carrots, other vegetables, no dressing": 27450190,
    "Seafood garden salad with seafood, lettuce, eggs, vegetables excluding tomato and carrots, no dressing": 27450200,
    "Seafood garden salad with seafood, lettuce, eggs, tomato and/or carrots, other vegetables, no dressing": 27450210,
    "Oysters Rockefeller": 27450250,
    "Lomi salmon": 27450310,
    "Shrimp and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, no sauce": 27450400,
    "Shrimp and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, no sauce": 27450405,
    "Shrimp and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, soy-based sauce": 27450410,
    "Shrimp and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, soy-based sauce": 27450420,
    "Shrimp shish kabob with vegetables, excluding potatoes": 27450430,
    "Shrimp creole, no rice": 27450450,
    "Kung Pao shrimp": 27450470,
    "Tuna casserole with vegetables and mushroom sauce, no noodles": 27450510,
    "Shellfish mixture and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, soy-based sauce": 27450600,
    "Shellfish mixture and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, soy-based sauce": 27450610,
    "Shellfish mixture and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, mushroom sauce": 27450650,
    "Shellfish mixture and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, mushroom sauce": 27450660,
    "Fish and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, tomato-based sauce": 27450700,
    "Fish and vegetables excluding carrots, broccoli, and dark- green leafy; no potatoes, tomato-based sauce": 27450710,
    "Fish and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, soy-based sauce": 27450740,
    "Fish and vegetables excluding  carrots, broccoli, and dark-green leafy; no potatoes, soy-based sauce": 27450750,
    "Fish shish kabob with vegetables, excluding potatoes": 27450760,
    "Fried fish with sauce, Puerto Rican style": 27451010,
    "Lobster with sauce, Puerto Rican style": 27451030,
    "Octopus salad, Puerto Rican style": 27451060,
    "Codfish salad, Puerto Rican style, Serenata": 27451070,
    "Chow mein or chop suey, NS as to type of meat, no noodles": 27460010,
    "Lau lau": 27460100,
    "Julienne salad, meat, cheese, eggs, vegetables, no dressing": 27460490,
    "Antipasto with ham, fish, cheese, vegetables": 27460510,
    "Livers, chicken, chopped, with eggs and onion": 27460710,
    "Liver, beef or calves, and onions": 27460750,
    "Stewed chitterlings, Puerto Rican style": 27462000,
    "Stewed gizzards, Puerto Rican style": 27463000,
    "Gumbo, no rice": 27464000,
    "Sandwich, NFS": 27500050,
    "Sandwich wrap, NFS": 27500300,
    "Barbecue sandwich, NFS": 27500400,
    "Sloppy joe sandwich, on white bun": 27510100,
    "Sloppy joe sandwich, on wheat bun": 27510105,
    "Barbecue beef sandwich, on white bun": 27510130,
    "Barbecue beef sandwich, on wheat bun": 27510135,
    "Cheeseburger slider, from fast food": 27510140,
    "Cheeseburger, NFS": 27510155,
    "Cheeseburger, from fast food, 1 small patty": 27510160,
    "Cheeseburger (Burger King)": 27510170,
    "Whopper Jr with cheese (Burger King)": 27510171,
    "Cheeseburger (McDonalds)": 27510172,
    "Cheeseburger, from school cafeteria": 27510190,
    "Cheeseburger slider": 27510191,
    "Cheeseburger, on white bun, 1 small patty": 27510195,
    "Cheeseburger, on wheat bun, 1 small patty": 27510196,
    "Cheeseburger, from fast food, 1 medium patty": 27510215,
    "Quarter Pounder (McDonalds)": 27510229,
    "Whopper with cheese (Burger King)": 27510231,
    "Quarter Pounder with cheese (McDonalds)": 27510232,
    "Cheeseburger, on white bun, 1 medium patty": 27510241,
    "Cheeseburger, on wheat bun, 1 medium patty": 27510242,
    "Cheeseburger, on white bun, 1 large patty": 27510245,
    "Cheeseburger, on wheat bun, 1 large patty": 27510246,
    "Double cheeseburger, on white bun, 2 small patties": 27510254,
    "Double cheeseburger, on wheat bun, 2 small patties": 27510255,
    "Double cheeseburger, on white bun, 2 medium patties": 27510257,
    "Double cheeseburger, on wheat bun, 2 medium patties": 27510258,
    "Cheeseburger, from fast food, 1 large patty": 27510261,
    "Double cheeseburger, on white bun, 2 large patties": 27510262,
    "Double cheeseburger, on wheat bun, 2 large patties": 27510263,
    "Double cheeseburger, from fast food, 2 small patties": 27510371,
    "Double cheeseburger (Burger King)": 27510386,
    "Double cheeseburger (McDonalds)": 27510387,
    "McDouble (McDonalds)": 27510388,
    "Big Mac (McDonalds)": 27510389,
    "Double cheeseburger, from fast food, 2 medium patties": 27510401,
    "Double cheeseburger, from fast food, 2 large patties": 27510405,
    "Hamburger slider, from fast food": 27510501,
    "Hamburger, NFS": 27510521,
    "Hamburger, from fast food, 1 small patty": 27510531,
    "Hamburger (Burger King)": 27510551,
    "Whopper Jr (Burger King)": 27510552,
    "Hamburger (McDonalds)": 27510553,
    "Hamburger, from school cafeteria": 27510565,
    "Hamburger slider": 27510573,
    "Hamburger, on white bun, 1 small patty": 27510575,
    "Hamburger, on wheat bun, 1 small patty": 27510576,
    "Hamburger, from fast food, 1 medium patty": 27510601,
    "Hamburger, from fast food, 1 large patty": 27510605,
    "Whopper (Burger King)": 27510615,
    "Hamburger, on white bun, 1 medium patty": 27510631,
    "Hamburger, on wheat bun, 1 medium patty": 27510632,
    "Hamburger, on white bun, 1 large patty": 27510635,
    "Hamburger, on wheat bun, 1 large patty": 27510636,
    "Double hamburger, on white bun, 2 small patties": 27510649,
    "Double hamburger, on wheat bun, 2 small patties": 27510652,
    "Double hamburger, on white bun, 2 medium patties": 27510655,
    "Double hamburger, on wheat bun, 2 medium patties": 27510657,
    "Double hamburger, on white bun, 2 large patties": 27510658,
    "Double hamburger, on wheat bun, 2 large patties": 27510659,
    "Double hamburger, from fast food, 2 small patties": 27510661,
    "Double hamburger, from fast food, 2 medium patties": 27510671,
    "Double hamburger, from fast food, 2 large patties": 27510675,
    "Meatball sandwich or sub": 27510700,
    "Meatloaf sandwich": 27510702,
    "Chiliburger, with or without cheese, on bun": 27510705,
    "Corned beef sandwich on white": 27510910,
    "Corned beef sandwich on white, with cheese": 27510920,
    "Corned beef sandwich on wheat": 27510930,
    "Corned beef sandwich on wheat, with cheese": 27510940,
    "Reuben sandwich": 27510950,
    "Roast beef sandwich on white": 27513010,
    "Roast beef sandwich on white, with cheese": 27513050,
    "Roast beef sandwich on wheat": 27513055,
    "Roast beef sandwich on wheat, with cheese": 27513065,
    "French dip sandwich": 27513070,
    "Steak sandwich or sub on white": 27514010,
    "Steak sandwich or sub on wheat": 27514020,
    "Cheese steak sandwich or sub on white": 27514030,
    "Cheese steak sandwich or sub on wheat": 27514040,
    "Gyro sandwich": 27516010,
    "Hamburger wrap sandwich, from fast food": 27517000,
    "Ham sandwich on white": 27520210,
    "Ham sandwich on white, with cheese": 27520220,
    "Ham sandwich on wheat": 27520230,
    "Ham sandwich on wheat, with cheese": 27520240,
    "Ham sandwich or sub, restaurant": 27520255,
    "Ham sandwich or sub, with cheese, restaurant": 27520260,
    "Ham sandwich wrap": 27520270,
    "Ham salad sandwich on white": 27520340,
    "Ham salad sandwich on wheat": 27520345,
    "Cuban sandwich": 27520410,
    "Barbecue rib sandwich": 27520500,
    "Barbecue pork sandwich, on white bun": 27520510,
    "Barbecue pork sandwich, on wheat bun": 27520515,
    "Pork sandwich": 27520520,
    "Pork sandwich, with cheese": 27520525,
    "Bacon, lettuce, tomato sandwich on white": 27520610,
    "Bacon, lettuce, tomato sandwich on wheat": 27520620,
    "Turkey sandwich on white": 27540010,
    "Turkey sandwich on white, with cheese": 27540020,
    "Turkey sandwich on wheat": 27540030,
    "Turkey sandwich on wheat, with cheese": 27540040,
    "Turkey sandwich or sub, restaurant": 27540050,
    "Turkey sandwich or sub, with cheese, restaurant": 27540060,
    "Turkey sandwich wrap": 27540070,
    "Chicken deli sandwich or sub, restaurant": 27540080,
    "Chicken deli sandwich or sub, with cheese, restaurant": 27540090,
    "Chicken salad sandwich on white": 27540120,
    "Chicken salad sandwich on wheat": 27540121,
    "Chicken salad sandwich wrap": 27540122,
    "Club sandwich on white": 27540123,
    "Club sandwich on white, with cheese": 27540124,
    "Club sandwich on wheat": 27540125,
    "Club sandwich on wheat, with cheese": 27540126,
    "Club sandwich or sub, restaurant": 27540127,
    "Barbecue chicken sandwich, on white bun": 27540130,
    "Barbecue chicken sandwich, on wheat bun": 27540131,
    "Chicken fillet sandwich, NFS": 27540132,
    "Chicken fillet sandwich, from school cafeteria": 27540139,
    "Chicken fillet biscuit, from fast food": 27540145,
    "Chicken fillet sandwich, fried, from fast food": 27540146,
    "Chicken fillet sandwich, fried, from fast food, with cheese": 27540147,
    "Chicken fillet sandwich, grilled, from fast food": 27540152,
    "Chicken fillet sandwich, grilled, from fast food, with cheese": 27540153,
    "Chicken fillet sandwich, NS as to fried or grilled, from fast food": 27540160,
    "Chicken fillet sandwich, fried, on white bun": 27540175,
    "Chicken fillet sandwich, fried, on white bun; with cheese": 27540176,
    "Chicken fillet sandwich, fried, on wheat bun": 27540185,
    "Chicken fillet sandwich, fried, on wheat bun, with cheese": 27540186,
    "Chicken fillet sandwich, grilled, on white bun": 27540195,
    "Chicken fillet sandwich, grilled, on white bun, with cheese": 27540196,
    "Chicken fillet sandwich, grilled, on wheat bun": 27540205,
    "Chicken fillet sandwich, grilled, on wheat bun, with cheese": 27540206,
    "Chicken fillet wrap sandwich, fried, from fast food": 27540210,
    "Chicken fillet wrap sandwich, grilled, from fast food": 27540300,
    "Turkey or chicken burger, on white bun": 27545100,
    "Turkey or chicken burger, on wheat bun": 27545110,
    "Fish sandwich, fried, from fast food": 27550000,
    "Fish sandwich, fried, from fast food, with cheese": 27550100,
    "Crab cake sandwich": 27550110,
    "Salmon cake sandwich": 27550120,
    "Fried seafood sandwich": 27550150,
    "Fish sandwich, from school cafeteria": 27550200,
    "Fish sandwich, NFS": 27550300,
    "Fish sandwich, fried, on white bun": 27550400,
    "Fish sandwich, fried, on white bun, with cheese": 27550405,
    "Fish sandwich, fried, on wheat bun": 27550410,
    "Fish sandwich, fried, on wheat bun, with cheese": 27550415,
    "Fish sandwich, grilled": 27550420,
    "Fish wrap sandwich": 27550425,
    "Sardine sandwich": 27550510,
    "Tuna salad sandwich on white": 27550720,
    "Tuna salad sandwich on white, with cheese": 27550730,
    "Tuna salad sandwich on wheat": 27550735,
    "Tuna salad sandwich on wheat, with cheese": 27550737,
    "Tuna salad sandwich wrap": 27550755,
    "Seafood salad sandwich": 27550800,
    "Bologna sandwich on white": 27560130,
    "Bologna sandwich on white, with cheese": 27560140,
    "Bologna sandwich on wheat": 27560150,
    "Bologna sandwich on wheat, with cheese": 27560160,
    "Corn dog": 27560300,
    "Pig in a blanket": 27560350,
    "Salami sandwich on white": 27560520,
    "Salami sandwich on white, with cheese": 27560530,
    "Salami sandwich on wheat": 27560540,
    "Salami sandwich on wheat, with cheese": 27560550,
    "Italian sandwich on white": 27560610,
    "Italian sandwich on wheat": 27560620,
    "Italian sandwich or sub, restaurant": 27560630,
    "Italian sausage sandwich on white": 27560710,
    "Italian sausage sandwich on wheat": 27560715,
    "Spam sandwich": 27560920,
    "Hot dog sandwich, NFS, on white bun": 27564000,
    "Hot dog sandwich, NFS, on wheat bun": 27564001,
    "Hot dog sandwich, NFS, on white bread": 27564010,
    "Hot dog sandwich, NFS, on wheat bread": 27564020,
    "Hot dog sandwich, beef, on white bun": 27564060,
    "Hot dog sandwich, beef, on wheat bun": 27564061,
    "Hot dog sandwich, beef, on white bread": 27564070,
    "Hot dog sandwich, beef, on wheat bread": 27564080,
    "Hot dog sandwich, meat and poultry, on white bun": 27564180,
    "Hot dog sandwich, meat and poultry, on wheat bun": 27564181,
    "Hot dog sandwich, meat and poultry, on white bread": 27564190,
    "Hot dog sandwich, meat and poultry, on wheat bread": 27564200,
    "Hot dog sandwich, turkey, on white bun": 27564240,
    "Hot dog sandwich, turkey, on wheat bun": 27564241,
    "Hot dog sandwich, turkey, on white bread": 27564250,
    "Hot dog sandwich, turkey, on wheat bread": 27564260,
    "Hot dog sandwich, reduced fat, on white bun": 27564300,
    "Hot dog sandwich, reduced fat, on wheat bun": 27564301,
    "Hot dog sandwich, reduced fat, on white bread": 27564310,
    "Hot dog sandwich, reduced fat, on wheat bread": 27564320,
    "Hot dog sandwich, vegetarian, on bun": 27564420,
    "Hot dog sandwich, vegetarian, on bread": 27564430,
    "Chili hot dog sandwich, on white bun": 27564440,
    "Chili hot dog sandwich, on wheat bun": 27564441,
    "Chili hot dog sandwich, on white bread": 27564450,
    "Chili hot dog sandwich, on wheat bread": 27564460,
    "Multiple meat sandwich on white": 27580010,
    "Multiple meat sandwich on white, with cheese": 27580020,
    "Multiple meat sandwich on wheat": 27580030,
    "Multiple meat sandwich on wheat, with cheese": 27580040,
    "Multiple meat sandwich or sub, restaurant": 27580050,
    "Multiple meat sandwich or sub, with cheese, restaurant": 27580060,
    "Multiple meat sandwich wrap": 27580070,
    "Turkey and ham sandwich on white": 27580110,
    "Turkey and ham sandwich on white, with cheese": 27580120,
    "Turkey and ham sandwich on wheat": 27580130,
    "Turkey and ham sandwich on wheat, with cheese": 27580140,
    "Turkey and ham sandwich or sub, restaurant": 27580150,
    "Turkey and ham sandwich or sub, with cheese, restaurant": 27580160,
    "Frozen dinner, NFS": 28101000,
    "Beef dinner, NFS, frozen meal": 28110000,
    "Beef with vegetable, diet frozen meal": 28110150,
    "Salisbury steak dinner, NFS, frozen meal": 28110300,
    "Salisbury steak with gravy, potatoes, vegetable, frozen meal": 28110310,
    "Salisbury steak with gravy, whipped potatoes, vegetable, dessert, frozen meal": 28110330,
    "Salisbury steak with gravy, macaroni and cheese, frozen meal": 28110380,
    "Beef, sliced, with gravy, potatoes, vegetable, frozen meal": 28110510,
    "Meatballs, Swedish, in gravy, with noodles, diet frozen meal": 28110660,
    "Beef with spaetzle or rice, vegetable, frozen meal": 28113140,
    "Chicken dinner, NFS, frozen meal": 28140100,
    "Chicken, fried, with potatoes, vegetable, frozen meal": 28140710,
    "Chicken patty, or nuggets, boneless, breaded, potatoes, vegetable, frozen meal": 28140720,
    "Chicken, fried, with potatoes, vegetable, dessert, frozen meal, large meat portion": 28141010,
    "Teriyaki chicken with rice and vegetable, diet frozen meal": 28141201,
    "Chicken with rice and vegetable, diet frozen meal": 28141250,
    "Chicken and vegetables au gratin with rice, diet frozen entree": 28141650,
    "Chicken and vegetable entree with rice, diet frozen meal": 28143020,
    "Chicken with noodles and cheese sauce, diet frozen meal": 28143080,
    "Chicken and vegetable entree with noodles, diet frozen meal": 28143150,
    "Chicken in soy-based sauce, rice and vegetables, frozen meal": 28143200,
    "Chicken and vegetable entree with noodles and cream sauce, frozen meal": 28144100,
    "Turkey with vegetable, stuffing, diet frozen meal": 28145110,
    "Turkey with gravy, dressing, potatoes, vegetable, frozen meal": 28145210,
    "Fish in lemon-butter sauce with starch item, vegetable, frozen meal": 28150510,
    "Shrimp and vegetables in sauce with noodles, diet frozen meal": 28154010,
    "Meat loaf dinner, NFS, frozen meal": 28160300,
    "Meat loaf with potatoes, vegetable, frozen meal": 28160310,
    "Beef, broth, bouillon, or consomme": 28310110,
    "Oxtail soup": 28310150,
    "Beef broth, without tomato, home recipe": 28310170,
    "Meatball soup, home recipe, Mexican style": 28310230,
    "Pho": 28310330,
    "Pepperpot soup": 28311010,
    "Menudo soup, home recipe": 28311020,
    "Beef vegetable soup with potato, pasta, or rice, chunky style, canned, or ready-to-serve": 28315050,
    "Beef vegetable soup, home recipe, Mexican style": 28315140,
    "Meat and corn hominy soup, home recipe, Mexican style": 28315150,
    "Italian Wedding Soup": 28315160,
    "Pork vegetable soup with potato, pasta, or rice, stew type, chunky style": 28320160,
    "Pork with vegetable excluding carrots, broccoli and/or dark-green leafy; soup, Asian Style": 28320300,
    "Chicken or turkey broth, bouillon, or consomme": 28340110,
    "Chicken or turkey broth, without tomato, home recipe": 28340120,
    "Chicken or turkey broth, less or reduced sodium, canned or ready-to-serve": 28340180,
    "Chicken or turkey gumbo soup, home recipe, canned or ready-to-serve": 28340310,
    "Chicken or turkey noodle soup, chunky style, canned or ready-to-serve": 28340510,
    "Sweet and sour soup": 28340550,
    "Chicken or turkey soup with vegetables, broccoli, carrots, celery, potatoes and onions, Asian style": 28340580,
    "Chicken or turkey corn soup with noodles, home recipe": 28340590,
    "Chicken or turkey vegetable soup, canned, prepared with water or ready-to-serve": 28340600,
    "Chicken or turkey vegetable soup, stew type": 28340610,
    "Chicken or turkey vegetable soup with rice, stew type, chunky style": 28340630,
    "Chicken or turkey vegetable soup with noodles, stew type, chunky style, canned or ready-to-serve": 28340640,
    "Chicken or turkey vegetable soup, home recipe": 28340660,
    "Chicken or turkey vegetable soup with rice, home recipe, Mexican style": 28340670,
    "Chicken or turkey and corn hominy soup,  home recipe, Mexican style": 28340680,
    "Hot and sour soup": 28340750,
    "Chicken or turkey soup with vegetables and fruit, Asian Style": 28340800,
    "Chicken or turkey soup, cream of, NS as to prepared with milk or water": 28345110,
    "Chicken or turkey soup, cream of, prepared with milk": 28345120,
    "Fish chowder": 28350050,
    "Clam chowder, NS as to Manhattan or New England style": 28350210,
    "Clam chowder, Manhattan": 28350220,
    "Fish and vegetable soup, no potatoes, Mexican style": 28351110,
    "Codfish, rice, and vegetable soup, Puerto Rican style": 28351160,
    "Clam chowder, New England, NS as to prepared with water or milk": 28355110,
    "Clam chowder, New England, prepared with milk": 28355120,
    "Clam chowder, New England, prepared with water": 28355130,
    "Clam chowder, New England, reduced sodium, canned or ready-to-serve": 28355140,
    "Lobster bisque": 28355250,
    "Lobster gumbo": 28355260,
    "Shrimp soup, cream of, NS as to prepared with milk or water": 28355410,
    "Shrimp soup, cream of, prepared with milk": 28355420,
    "Shrimp gumbo": 28355440,
    "Seafood soup with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy": 28355450,
    "Seafood soup with potatoes, and vegetables excluding carrots, broccoli, and dark-green leafy": 28355460,
    "Seafood soup with vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes": 28355470,
    "Seafood soup with vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes": 28355480,
    "Meat broth, Puerto Rican style": 28360100,
    "Gravy, poultry": 28500000,
    "Gravy, beef": 28500040,
    "Gravy, beef, fat free": 28501010,
    "Gravy, poultry, fat free": 28501110,
    "Gravy, made with soy sauce": 28520000,
    "Gravy, NFS": 28520010,
    "Mole sauce": 28522000,
    "Egg, whole, raw": 31101010,
    "Egg, whole, cooked, NS as to cooking method": 31102000,
    "Egg, whole, boiled or poached": 31103010,
    "Egg, whole, fried, NS as to fat": 31105005,
    "Egg, whole, fried no added fat": 31105010,
    "Egg, whole, fried with margarine": 31105020,
    "Egg, whole, fried with oil": 31105030,
    "Egg, whole, fried with butter": 31105040,
    "Egg, whole, fried with animal fat or meat drippings": 31105060,
    "Egg, whole, fried with cooking spray": 31105080,
    "Egg, whole, fried, NS as to fat type": 31105085,
    "Egg, whole, fried, from fast food / restaurant": 31105090,
    "Egg, whole, baked, NS as to fat": 31106000,
    "Egg, whole, baked, no added fat": 31106010,
    "Egg, whole, baked, fat added": 31106020,
    "Egg, whole, pickled": 31107000,
    "Egg, white only, raw": 31108010,
    "Egg, white, cooked, NS as to fat": 31108100,
    "Egg, white, cooked, no added fat": 31108110,
    "Egg, white, cooked, fat added": 31108120,
    "Egg, yolk only, raw": 31110010,
    "Egg, yolk only, cooked, NS as to fat": 31111000,
    "Egg, yolk only, cooked, no added fat": 31111010,
    "Egg, yolk only, cooked, fat added": 31111020,
    "Duck egg, cooked": 31201000,
    "Goose egg, cooked": 31202000,
    "Quail egg, canned": 31203000,
    "Egg, creamed": 32101000,
    "Egg, Benedict": 32101500,
    "Egg, deviled": 32102000,
    "Egg salad, made with mayonnaise": 32103000,
    "Egg salad, made with light mayonnaise": 32103015,
    "Egg salad, made with mayonnaise-type salad dressing": 32103020,
    "Egg salad, made with light mayonnaise-type salad dressing": 32103025,
    "Egg salad, made with creamy dressing": 32103030,
    "Egg salad, made with light creamy dressing": 32103035,
    "Egg salad, made with Italian dressing": 32103040,
    "Egg salad, made with light Italian dressing": 32103045,
    "Egg Salad, made with any type of fat free dressing": 32103050,
    "Huevos rancheros": 32105180,
    "Egg casserole with bread, cheese, milk and meat": 32105190,
    "Egg foo yung, NFS": 32105200,
    "Chicken egg foo yung": 32105210,
    "Pork egg foo yung": 32105220,
    "Shrimp egg foo yung": 32105230,
    "Beef egg foo yung": 32105240,
    "Egg omelet or scrambled egg, NS as to fat": 32129990,
    "Egg omelet or scrambled egg, made with margarine": 32130000,
    "Egg omelet or scrambled egg, made with oil": 32130010,
    "Egg omelet or scrambled egg, made with butter": 32130020,
    "Egg omelet or scrambled egg, made with animal fat or meat drippings": 32130040,
    "Egg omelet or scrambled egg, made with cooking spray": 32130060,
    "Egg omelet or scrambled egg, NS as to fat type": 32130065,
    "Egg omelet or scrambled egg, no added fat": 32130070,
    "Egg omelet or scrambled egg, from fast food / restaurant": 32130080,
    "Egg omelet or scrambled egg, with cheese, made with margarine": 32130100,
    "Egg omelet or scrambled egg, with cheese, made with oil": 32130110,
    "Egg omelet or scrambled egg, with cheese, made with butter": 32130120,
    "Egg omelet or scrambled egg, with cheese, made with animal fat or meat drippings": 32130140,
    "Egg omelet or scrambled egg, with cheese, made with cooking spray": 32130160,
    "Egg omelet or scrambled egg, with cheese, no added fat": 32130170,
    "Egg omelet or scrambled egg, with meat, NS as to fat": 32130190,
    "Egg omelet or scrambled egg, with meat, made with margarine": 32130200,
    "Egg omelet or scrambled egg, with meat, made with oil": 32130210,
    "Egg omelet or scrambled egg, with meat, made with butter": 32130220,
    "Egg omelet or scrambled egg, with meat, made with animal fat or meat drippings": 32130240,
    "Egg omelet or scrambled egg, with meat, made with cooking spray": 32130260,
    "Egg omelet or scrambled egg, with meat, NS as to fat type": 32130265,
    "Egg omelet or scrambled egg, with meat, no added fat": 32130270,
    "Egg omelet or scrambled egg, with cheese and meat, NS as to fat": 32130290,
    "Egg omelet or scrambled egg, with cheese and meat, made with margarine": 32130300,
    "Egg omelet or scrambled egg, with cheese and meat, made with oil": 32130310,
    "Egg omelet or scrambled egg, with cheese and meat, made with butter": 32130320,
    "Egg omelet or scrambled egg, with cheese and meat, made with animal fat or meat drippings": 32130340,
    "Egg omelet or scrambled egg, with cheese and meat, made with cooking spray": 32130360,
    "Egg omelet or scrambled egg, with cheese and meat, NS as to fat type": 32130365,
    "Egg omelet or scrambled egg, with cheese and meat, no added fat": 32130370,
    "Egg omelet or scrambled egg, with tomatoes, fat added": 32130400,
    "Egg omelet or scrambled egg, with tomatoes, no added fat": 32130410,
    "Egg omelet or scrambled egg, with tomatoes, NS as to fat": 32130420,
    "Egg omelet or scrambled egg, with dark-green vegetables, fat added": 32130430,
    "Egg omelet or scrambled egg, with dark-green vegetables, no added fat": 32130440,
    "Egg omelet or scrambled egg, with dark-green vegetables, NS as to fat": 32130450,
    "Egg omelet or scrambled egg, with tomatoes and dark-green vegetables, fat added": 32130460,
    "Egg omelet or scrambled egg, with tomatoes and dark-green vegetables, no fat added": 32130470,
    "Egg omelet or scrambled egg, with tomatoes and dark-green vegetables, NS as to fat": 32130480,
    "Egg omelet or scrambled egg, with vegetables other than dark green and/or tomatoes, fat added": 32130490,
    "Egg omelet or scrambled egg, with vegetables other than dark green and/or tomatoes, no added fat": 32130500,
    "Egg omelet or scrambled egg, with vegetables other than dark green and/or tomatoes, NS as to fat": 32130510,
    "Egg omelet or scrambled egg, with cheese and tomatoes, fat added": 32130600,
    "Egg omelet or scrambled egg, with cheese and tomatoes, no added fat": 32130610,
    "Egg omelet or scrambled egg, with cheese and tomatoes, NS as to fat": 32130620,
    "Egg omelet or scrambled egg, with cheese and dark-green vegetables, fat added": 32130630,
    "Egg omelet or scrambled egg, with cheese and dark-green vegetables, no added fat": 32130640,
    "Egg omelet or scrambled egg, with cheese and dark-green vegetables, NS as to fat": 32130650,
    "Egg omelet or scrambled egg, with cheese, tomatoes, and dark-green vegetables, fat added": 32130660,
    "Egg omelet or scrambled egg, with cheese, tomatoes, and dark-green vegetables, no added fat": 32130670,
    "Egg omelet or scrambled egg, with cheese, tomatoes, and dark-green vegetables, NS as to fat": 32130680,
    "Egg omelet or scrambled egg, with cheese and vegetables other than dark green and/or tomatoes, fat added": 32130690,
    "Egg omelet or scrambled egg, with cheese and vegetables other than dark green and/or tomatoes, no added fat": 32130700,
    "Egg omelet or scrambled egg, with cheese and vegetables other than dark green and/or tomatoes, NS as to fat": 32130710,
    "Egg omelet or scrambled egg, with meat and tomatoes, fat added": 32130800,
    "Egg omelet or scrambled egg, with meat and tomatoes, no added fat": 32130810,
    "Egg omelet or scrambled egg, with meat and tomatoes, NS as to fat": 32130820,
    "Egg omelet or scrambled egg, with meat and dark-green vegetables, fat added": 32130830,
    "Egg omelet or scrambled egg, with meat and dark-green vegetables, no added fat": 32130840,
    "Egg omelet or scrambled egg, with meat and dark-green vegetables, NS as to fat": 32130850,
    "Egg omelet or scrambled egg, with meat, tomatoes, and dark-green vegetables, fat added": 32130860,
    "Egg omelet or scrambled egg, with meat, tomatoes, and dark-green vegetables, no added fat": 32130870,
    "Egg omelet or scrambled egg, with meat, tomatoes, and dark-green vegetables, NS as to fat": 32130880,
    "Egg omelet or scrambled egg, with meat and vegetables other than dark-green and/or tomatoes, fat added": 32130890,
    "Egg omelet or scrambled egg, with meat and vegetables other than dark-green and/or tomatoes, no added fat": 32130900,
    "Egg omelet or scrambled egg, with meat and vegetables other than dark-green and/or tomatoes, NS as to fat": 32130910,
    "Egg omelet or scrambled egg, with cheese, meat, and tomatoes, fat added": 32131000,
    "Egg omelet or scrambled egg, with cheese, meat, and tomatoes, no added fat": 32131010,
    "Egg omelet or scrambled egg, with cheese, meat, and tomatoes, NS as to fat": 32131020,
    "Egg omelet or scrambled egg, with cheese, meat, and dark-green vegetables, fat added": 32131030,
    "Egg omelet or scrambled egg, with cheese, meat, and dark-green vegetables, no added fat": 32131040,
    "Egg omelet or scrambled egg, with cheese, meat, and dark-green vegetables, NS as to fat": 32131050,
    "Egg omelet or scrambled egg, with cheese, meat, tomatoes, and dark-green vegetables, fat added": 32131060,
    "Egg omelet or scrambled egg, with cheese, meat, tomatoes, and dark-green vegetables, no added fat": 32131070,
    "Egg omelet or scrambled egg, with cheese, meat, tomatoes, and dark-green vegetables, NS as to fat": 32131080,
    "Egg omelet or scrambled egg, with cheese, meat, and vegetables other than dark-green and/or tomatoes, fat added": 32131090,
    "Egg omelet or scrambled egg, with cheese, meat, and vegetables other than dark-green and/or tomatoes, no added fat": 32131100,
    "Egg omelet or scrambled egg, with cheese, meat, and vegetables other than dark-green and/or tomatoes, NS as to fat": 32131110,
    "Egg omelet or scrambled egg, with potatoes and/or onions, fat added": 32131200,
    "Egg omelet or scrambled egg, with potatoes and/or onions, no added fat": 32131210,
    "Egg omelet or scrambled egg, with potatoes and/or onions, NS as to fat": 32131220,
    "Egg salad sandwich on white": 32203010,
    "Egg salad sandwich on wheat": 32203020,
    "Egg drop soup": 32300100,
    "Egg white omelet, scrambled, or fried, NS as to fat": 32400055,
    "Egg white omelet, scrambled, or fried, made with margarine": 32400060,
    "Egg white omelet, scrambled, or fried, made with oil": 32400065,
    "Egg white omelet, scrambled, or fried, made with butter": 32400070,
    "Egg white omelet, scrambled, or fried, made with cooking spray": 32400075,
    "Egg white omelet, scrambled, or fried, NS as to fat type": 32400078,
    "Egg white omelet, scrambled, or fried, no added fat": 32400080,
    "Egg white, omelet, scrambled, or fried, with cheese": 32400100,
    "Egg white, omelet, scrambled, or fried, with meat": 32400200,
    "Egg white, omelet, scrambled, or fried, with vegetables": 32400300,
    "Egg white, omelet, scrambled, or fried, with cheese and meat": 32400400,
    "Egg white, omelet, scrambled, or fried, with cheese and vegetables": 32400500,
    "Egg white, omelet, scrambled, or fried, with meat and vegetables": 32400600,
    "Egg white, omelet, scrambled, or fried, with cheese, meat, and vegetables": 32400700,
    "Meringues": 32401000,
    "Egg substitute, omelet, scrambled, or fried, fat added": 33001010,
    "Egg substitute, omelet, scrambled, or fried, no added fat": 33001050,
    "Egg substitute, omelet, scrambled, or fried, with cheese": 33401000,
    "Egg substitute, omelet, scrambled, or fried, with meat": 33401100,
    "Egg substitute, omelet, scrambled, or fried, with vegetables": 33401200,
    "Egg substitute, omelet, scrambled, or fried, with cheese and meat": 33401300,
    "Egg substitute, omelet, scrambled, or fried, with cheese and vegetables": 33401400,
    "Egg substitute, omelet, scrambled, or fried, with meat and vegetables": 33401500,
    "Egg substitute, omelet, scrambled, or fried, with cheese, meat, and vegetables": 33401600,
    "Egg sandwich on white bread": 34001100,
    "Egg sandwich on white bread, with cheese": 34001110,
    "Egg sandwich on white bread, with meat": 34001120,
    "Egg sandwich on white bread, with meat and cheese": 34001130,
    "Egg sandwich on wheat bread": 34001200,
    "Egg sandwich on wheat bread, with cheese": 34001210,
    "Egg sandwich on wheat bread, with meat": 34001220,
    "Egg sandwich on wheat bread, with meat and cheese": 34001230,
    "Egg sandwich on English muffin": 34001300,
    "Egg sandwich on English muffin, with sausage": 34001310,
    "Egg sandwich on English muffin, with bacon": 34001320,
    "Egg sandwich on English muffin, with ham": 34001330,
    "Egg sandwich on croissant": 34001400,
    "Egg sandwich on croissant, with sausage": 34001410,
    "Egg sandwich on croissant, with bacon": 34001420,
    "Egg sandwich on croissant, with ham": 34001430,
    "Egg sandwich on biscuit": 34001500,
    "Egg sandwich on biscuit, with sausage": 34001510,
    "Egg sandwich on biscuit, with sausage and cheese": 34001520,
    "Egg sandwich on biscuit, with bacon": 34001530,
    "Egg sandwich on biscuit, with bacon and cheese": 34001540,
    "Egg sandwich on bagel": 34001600,
    "Egg sandwich on bagel, with sausage": 34001610,
    "Egg sandwich on bagel, with bacon": 34001620,
    "Egg sandwich on bagel, with ham": 34001630,
    "Egg sandwich on griddle/pancake": 34001700,
    "Egg sandwich on griddle/pancake, with meat": 34001710,
    "Egg white sandwich": 34002000,
    "Egg white sandwich, with cheese": 34002010,
    "Egg white sandwich, with meat": 34002020,
    "Bacon biscuit sandwich": 34002110,
    "Ham biscuit sandwich": 34002120,
    "Sausage biscuit sandwich": 34002130,
    "Sausage biscuit sandwich, with cheese": 34002140,
    "Sausage English muffin sandwich": 34002150,
    "Sausage griddle/pancake sandwich": 34002160,
    "Egg burrito": 34003100,
    "Egg burrito, with sausage": 34003120,
    "Egg burrito, with bacon": 34003130,
    "Egg burrito, with ham": 34003140,
    "Beans, NFS": 41100990,
    "Beans, from dried, NS as to type, fat added": 41101010,
    "Beans, from dried, NS as to type, no added fat": 41101020,
    "Beans, from canned, NS as to type, fat added": 41101060,
    "Beans, from canned, NS as to type, no added fat": 41101070,
    "Beans, from fast food / restaurant, NS as to type": 41101080,
    "White beans, NFS": 41101090,
    "White beans, from dried, fat added": 41101110,
    "White beans, from dried, no added fat": 41101120,
    "White beans, from canned, fat added": 41101140,
    "White beans, from canned, no added fat": 41101180,
    "White beans, from canned, reduced sodium": 41101210,
    "Black beans, NFS": 41101990,
    "Black beans, from dried, fat added": 41102010,
    "Black beans, from dried, no added fat": 41102020,
    "Black beans, from canned, fat added": 41102040,
    "Black beans, from canned, no added fat": 41102080,
    "Black beans, from canned, reduced sodium": 41102110,
    "Black beans, from fast food / restaurant": 41102150,
    "Black beans with meat": 41102170,
    "Fava beans, cooked": 41102210,
    "Lima beans, NFS": 41102990,
    "Lima beans, from dried": 41103010,
    "Pink beans, cooked": 41103070,
    "Pinto beans, NFS": 41103990,
    "Pinto beans, from dried, fat added": 41104010,
    "Pinto beans, from dried, no added fat": 41104020,
    "Pinto beans, from canned, fat added": 41104040,
    "Pinto beans, from canned, no added fat": 41104080,
    "Pinto beans, from canned, reduced sodium": 41104110,
    "Pinto beans, from fast food / restaurant": 41104200,
    "Pinto beans with meat": 41104250,
    "Kidney beans, NFS": 41105990,
    "Kidney beans, from dried, fat added": 41106010,
    "Kidney beans, from dried, no added fat": 41106020,
    "Kidney beans, from canned, fat added": 41106040,
    "Kidney beans, from canned, no added fat": 41106080,
    "Kidney beans, from canned, reduced sodium": 41106110,
    "Kidney beans, from fast food / restaurant": 41106150,
    "Kidney beans with meat": 41106170,
    "Peruvian beans, from dried": 41106510,
    "Soybeans, cooked": 41107010,
    "Mung beans, cooked": 41108010,
    "Baked beans": 41201010,
    "Baked beans, vegetarian": 41201020,
    "Baked beans from fast food / restaurant": 41201050,
    "Beans and tomatoes, no added fat": 41202505,
    "Beans and tomatoes, fat added": 41202510,
    "Black bean salad": 41203030,
    "Refried beans": 41205010,
    "Refried beans, from fast food / restaurant": 41205017,
    "Refried beans with meat": 41205030,
    "Refried beans, from canned, reduced sodium": 41205040,
    "Bean dip, made with refried beans": 41205050,
    "Layer dip": 41205055,
    "Hummus, plain": 41205070,
    "Hummus, flavored": 41205075,
    "Black bean sauce": 41205100,
    "Beans and franks": 41206030,
    "Pork and beans": 41208030,
    "Beans with meat, NS as to type": 41208100,
    "Falafel": 41209000,
    "Bean cake": 41210000,
    "Baked beans, reduced sodium": 41221000,
    "Chili with beans, without meat": 41221020,
    "Blackeyed peas, NFS": 41300990,
    "Blackeyed peas, from dried": 41301010,
    "Chickpeas, NFS": 41301990,
    "Chickpeas, from dried, fat added": 41302010,
    "Chickpeas, from dried, no added fat": 41302020,
    "Chickpeas, from canned, fat added": 41302040,
    "Chickpeas, from canned, no added fat": 41302080,
    "Chickpeas, from canned, reduced sodium": 41302110,
    "Split peas, from dried, no added fat": 41303000,
    "Split peas, from dried, fat added": 41303010,
    "Wasabi peas": 41304000,
    "Lentils, NFS": 41304970,
    "Lentils, from dried, fat added": 41304990,
    "Lentils, from dried, no added fat": 41305000,
    "Lentils, from canned": 41305020,
    "Dal": 41305050,
    "Bean chips": 41310900,
    "Papad, grilled or broiled": 41311000,
    "Sambar, vegetable stew": 41311020,
    "Lentil curry": 41311030,
    "Lentil curry with rice": 41311040,
    "Soy nuts": 41410010,
    "Soy chips": 41410015,
    "Soybean curd": 41420010,
    "Edamame, cooked": 41420020,
    "Soybean curd cheese": 41420050,
    "Miso sauce": 41420100,
    "Miso": 41420110,
    "Natto": 41420200,
    "Hoisin sauce": 41420250,
    "Soy sauce": 41420300,
    "Soy sauce, reduced sodium": 41420350,
    "Yogurt, soy": 41420380,
    "Teriyaki sauce": 41420400,
    "Teriyaki sauce, reduced sodium": 41420410,
    "Worcestershire sauce": 41420450,
    "Soybean curd, deep fried": 41421010,
    "Soybean curd, breaded, fried": 41421020,
    "Vermicelli, made from soybeans": 41425010,
    "Textured vegetable protein, dry": 41440000,
    "Frozen dessert, non-dairy": 41480020,
    "Bean soup, NFS": 41601010,
    "Bean with bacon or ham soup, canned or ready-to-serve": 41601020,
    "Black bean soup, home recipe, canned or ready-to-serve": 41601030,
    "Soybean soup, miso broth": 41601070,
    "Bean soup, home recipe": 41601140,
    "Bean and ham soup, canned, reduced sodium, prepared with water or ready-to-serve": 41601160,
    "Bean and ham soup, home recipe": 41601180,
    "Liquid from stewed kidney beans, Puerto Rican style": 41601200,
    "Garbanzo bean or chickpea soup, home recipe, canned or ready-to-serve": 41602020,
    "Split pea and ham soup": 41602030,
    "Split pea soup": 41602050,
    "Lentil soup, home recipe, canned, or ready-to-serve": 41603010,
    "Bacon strip, meatless": 41810200,
    "Bacon bits": 41810250,
    "Breakfast link, pattie, or slice, meatless": 41810400,
    "Chicken, meatless, NFS": 41810600,
    "Chicken, meatless, breaded, fried": 41810610,
    "Hot dog, vegetarian": 41811400,
    "Luncheon slice, meatless-beef, chicken, salami or turkey": 41811600,
    "Meatball, meatless": 41811800,
    "Veggie burger patty, no bun": 41811890,
    "Swiss steak, with gravy, meatless": 41811950,
    "Sandwich spread, meat substitute type": 41812000,
    "Pot pie, no meat": 41812400,
    "Vegetarian chili, made with meat substitute": 41812450,
    "Tofu and vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, with soy-based sauce": 41812500,
    "Tofu and vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, with soy-based sauce": 41812510,
    "Vegetarian, fillet": 41812600,
    "Vegetarian stew": 41812800,
    "Vegetarian stroganoff": 41812850,
    "Veggie burger, on bun": 41901010,
    "Veggie burger, on bun, with cheese": 41901020,
    "Falafel sandwich": 41901030,
    "Nuts, NFS": 42100050,
    "Almonds, NFS": 42100100,
    "Almonds, unroasted": 42101000,
    "Almonds, salted": 42101110,
    "Almonds, lightly salted": 42101120,
    "Almonds, unsalted": 42101130,
    "Almonds, flavored": 42101300,
    "Almonds, honey roasted": 42101350,
    "Brazil nuts": 42102000,
    "Cashews, NFS": 42104000,
    "Cashews, unroasted": 42104050,
    "Cashews, salted": 42104100,
    "Cashews, lightly salted": 42104105,
    "Cashews, unsalted": 42104110,
    "Cashews, honey roasted": 42104500,
    "Chestnuts": 42105000,
    "Coconut, fresh": 42106000,
    "Coconut, packaged": 42106020,
    "Hazelnuts": 42107000,
    "Macadamia nuts": 42109100,
    "Mixed nuts, NFS": 42110000,
    "Mixed nuts, unroasted": 42110050,
    "Mixed nuts, with peanuts, salted": 42110100,
    "Mixed nuts, with peanuts, lightly salted": 42110110,
    "Mixed nuts, with peanuts, unsalted": 42110120,
    "Mixed nuts, without peanuts, salted": 42110150,
    "Mixed nuts, without peanuts, unsalted": 42110160,
    "Mixed nuts, honey roasted": 42110300,
    "Peanuts, NFS": 42111000,
    "Peanuts, boiled": 42111030,
    "Peanuts, unroasted": 42111040,
    "Peanuts, roasted, salted": 42111100,
    "Peanuts, roasted, unsalted": 42111110,
    "Peanuts, dry roasted, salted": 42111200,
    "Peanuts, dry roasted, lightly salted": 42111205,
    "Peanuts, dry roasted, unsalted": 42111210,
    "Peanuts, honey roasted": 42111500,
    "Pecans, NFS": 42112000,
    "Pecans, unroasted": 42112100,
    "Pecans, salted": 42112200,
    "Pecans, unsalted": 42112210,
    "Pecans, honey roasted": 42112300,
    "Pine nuts": 42113000,
    "Pistachio nuts, NFS": 42114130,
    "Pistachio nuts, salted": 42114140,
    "Pistachio nuts, lightly salted": 42114142,
    "Pistachio nuts, unsalted": 42114145,
    "Walnuts, excluding honey roasted": 42116000,
    "Walnuts, honey roasted": 42116100,
    "Almond butter": 42200500,
    "Almond butter, lower sodium": 42200510,
    "Almond paste": 42200600,
    "Cashew butter": 42201000,
    "Peanut butter": 42202000,
    "Peanut butter, lower sodium": 42202010,
    "Peanut butter, lower sodium and lower sugar": 42202100,
    "Peanut butter, lower sugar": 42202130,
    "Peanut butter, reduced fat": 42202150,
    "Peanut butter, vitamin and mineral fortified": 42202200,
    "Peanut butter and jelly": 42203000,
    "Peanut butter and chocolate spread": 42203100,
    "Soy nut butter": 42203200,
    "Peanut sauce": 42204050,
    "Gravy, vegetarian": 42204100,
    "Peanut butter sandwich, NFS": 42301010,
    "Peanut butter sandwich, with regular peanut butter, on white bread": 42301015,
    "Peanut butter sandwich, with regular peanut butter, on wheat bread": 42301020,
    "Peanut butter sandwich, with reduced fat peanut butter, on white bread": 42301115,
    "Peanut butter sandwich, with reduced fat peanut butter, on wheat bread": 42301120,
    "Peanut butter and jelly sandwich, NFS": 42302010,
    "Peanut butter and jelly sandwich, with regular peanut butter, regular jelly, on white bread": 42302015,
    "Peanut butter and jelly sandwich, with regular peanut butter, regular jelly, on wheat bread": 42302020,
    "Peanut butter and jelly sandwich, with reduced fat peanut butter, on white bread": 42302055,
    "Peanut butter and jelly sandwich, with reduced fat peanut butter, on wheat bread": 42302060,
    "Peanut butter and jelly sandwich, with regular peanut butter, reduced sugar jelly, on white bread": 42302105,
    "Peanut butter and jelly sandwich, with regular peanut butter, reduced sugar jelly, on wheat bread": 42302110,
    "Peanut butter and jelly sandwich, frozen commercial product without crusts": 42303100,
    "Almond butter sandwich, on white bread": 42304010,
    "Almond butter sandwich, on wheat bread": 42304020,
    "Almond butter and jelly sandwich, on white bread": 42304030,
    "Almond butter and jelly sandwich, on wheat bread": 42304040,
    "Nutella sandwich on white bread": 42305010,
    "Nutella sandwich on wheat bread": 42305020,
    "Coconut milk, used in cooking": 42401010,
    "Yogurt, coconut milk": 42401100,
    "Coconut cream, canned, sweetened": 42402010,
    "Coconut water, unsweetened": 42403010,
    "Coconut water, sweetened": 42404010,
    "Trail mix, NFS": 42500000,
    "Trail mix with nuts": 42500100,
    "Trail mix with nuts and fruit": 42501000,
    "Trail mix with chocolate": 42501500,
    "Trail mix with pretzels, cereal, or granola": 42502100,
    "Pumpkin seeds, NFS": 43101050,
    "Pumpkin seeds, salted": 43101100,
    "Pumpkin seeds, unsalted": 43101150,
    "Sunflower seeds, plain, unsalted": 43102000,
    "Sunflower seeds, plain, salted": 43102100,
    "Sunflower seeds, flavored": 43102300,
    "Sunflower seeds, NFS": 43102400,
    "Sesame seeds": 43103000,
    "Tahini": 43103300,
    "Flax seeds": 43104000,
    "Mixed seeds": 43107000,
    "Chia seeds": 43108010,
    "Bread, NS as to major flour": 51000100,
    "Bread, NS as to major flour, toasted": 51000110,
    "Bread, made from home recipe or purchased at a bakery, NS as to major flour": 51000180,
    "Bread, made from home recipe or purchased at a bakery, toasted, NS as to major flour": 51000190,
    "Roll, NS as to major flour": 51000200,
    "Roll, hard, NS as to major flour": 51000300,
    "Roll, bran, NS as to type of bran": 51000400,
    "Bread, white": 51101000,
    "Bread, white, toasted": 51101010,
    "Bread, white, made from home recipe or purchased at a bakery": 51101050,
    "Bread, white, made from home recipe or purchased at a bakery, toasted": 51101060,
    "Bread, white with whole wheat swirl": 51102010,
    "Bread, white with whole wheat swirl, toasted": 51102020,
    "Bread, Cuban": 51105010,
    "Bread, Cuban, toasted": 51105040,
    "Bread, native, water, Puerto Rican style": 51106010,
    "Bread, native, water, toasted, Puerto Rican style": 51106020,
    "Bread, lard, Puerto Rican style": 51106200,
    "Bread, lard, toasted, Puerto Rican style": 51106210,
    "Bread, caressed, Puerto Rican style": 51106300,
    "Bread, caressed, toasted, Puerto Rican style": 51106310,
    "Bread, French or Vienna": 51107010,
    "Bread, French or Vienna, toasted": 51107040,
    "Focaccia, Italian, plain": 51108010,
    "Bread, naan": 51108100,
    "Bread, Italian, Grecian, Armenian": 51109010,
    "Bread, Italian, Grecian, Armenian, toasted": 51109040,
    "Bread, pita": 51109100,
    "Bread, pita with fruit": 51109150,
    "Bread, cheese": 51111010,
    "Bread, cheese, toasted": 51111040,
    "Bread, cinnamon": 51113010,
    "Bread, cinnamon, toasted": 51113100,
    "Bread, cornmeal and molasses": 51115010,
    "Bread, cornmeal and molasses, toasted": 51115020,
    "Bread, egg, Challah": 51119010,
    "Bread, egg, Challah, toasted": 51119040,
    "Garlic bread, NFS": 51121015,
    "Garlic bread, from fast food / restaurant": 51121025,
    "Garlic bread, from frozen": 51121035,
    "Garlic bread, with parmesan cheese, from fast food / restaurant": 51121045,
    "Garlic bread, with parmesan cheese, from frozen": 51121055,
    "Garlic bread, with melted cheese, from fast food / restaurant": 51121065,
    "Garlic bread, with melted cheese, from frozen": 51121075,
    "Bread, onion": 51121110,
    "Bread, onion, toasted": 51121120,
    "Bread, reduced calorie and/or high fiber, white or NFS": 51122000,
    "Bread, reduced calorie and/or high fiber, white or NFS, toasted": 51122010,
    "Bread, reduced calorie and/or high fiber, white or NFS, with fruit and/or nuts": 51122100,
    "Bread, reduced calorie and/or high fiber, white or NFS, with fruit and/or nuts, toasted": 51122110,
    "Bread, white, special formula, added fiber": 51122300,
    "Bread, white, special formula, added fiber, toasted": 51122310,
    "Bread, high protein": 51123010,
    "Bread, high protein, toasted": 51123020,
    "Bread, potato": 51127010,
    "Bread, potato, toasted": 51127020,
    "Bread, raisin": 51129010,
    "Bread, raisin, toasted": 51129020,
    "Bread, white, low sodium or no salt": 51130510,
    "Bread, white, low sodium or no salt, toasted": 51130520,
    "Bread, sour dough": 51133010,
    "Bread, sour dough, toasted": 51133020,
    "Bread, sweet potato": 51134000,
    "Bread, sweet potato, toasted": 51134010,
    "Bread, vegetable": 51135000,
    "Bread, vegetable, toasted": 51135010,
    "Bruschetta": 51136000,
    "Bread, dough, fried": 51140100,
    "Roll, white, soft": 51150000,
    "Roll, white, hard": 51153000,
    "Roll, white, hot dog bun": 51154010,
    "Roll, white, hamburger bun": 51154100,
    "Roll, diet": 51154510,
    "Roll, egg bread": 51154550,
    "Roll, cheese": 51154600,
    "Roll, French or Vienna": 51155000,
    "Roll, garlic": 51156500,
    "Roll, white, hoagie, submarine": 51157000,
    "Roll, Mexican, bolillo": 51158100,
    "Roll, sour dough": 51159000,
    "Roll, sweet, no frosting": 51160000,
    "Roll, sweet, cinnamon bun, no frosting": 51160100,
    "Roll, sweet, cinnamon bun, frosted": 51160110,
    "Pan dulce, NFS": 51160200,
    "Pan Dulce, with fruit, no frosting": 51161000,
    "Roll, sweet, with fruit, frosted": 51161020,
    "Roll, sweet, with fruit, frosted, diet": 51161030,
    "Roll, sweet, frosted": 51161050,
    "Pan Dulce, no topping": 51161250,
    "Pan Dulce, with sugar topping": 51161270,
    "Pan Dulce, with raisins and icing": 51161280,
    "Coffee cake, yeast type": 51165000,
    "Croissant": 51166000,
    "Croissant, cheese": 51166100,
    "Croissant, chocolate": 51166200,
    "Croissant, fruit": 51166500,
    "Brioche": 51167000,
    "Bread, Spanish coffee": 51168000,
    "Bagel": 51180010,
    "Bagel, with raisins": 51180030,
    "Bagel, with fruit other than raisins": 51180080,
    "Bread stuffing": 51182010,
    "Bread stuffing made with egg": 51182020,
    "Breadsticks, NFS": 51183990,
    "Breadsticks, hard, NFS": 51184000,
    "Breadsticks, hard, reduced sodium": 51184100,
    "Breadsticks, soft, NFS": 51184200,
    "Breadsticks, soft, fast food / restaurant": 51184210,
    "Breadsticks, soft, frozen": 51184220,
    "Breadsticks, soft, with parmesan cheese, fast food / restaurant": 51184230,
    "Breadsticks, soft, stuffed or topped with melted cheese": 51184260,
    "Croutons": 51185000,
    "Muffin, English": 51186010,
    "Muffin, English, with raisins": 51186100,
    "Muffin, English, cheese": 51186130,
    "Muffin, English, with fruit other than raisins": 51186160,
    "Melba toast": 51187000,
    "Anisette toast": 51187020,
    "Pannetone": 51188100,
    "Zwieback toast": 51188500,
    "Bread, whole grain white": 51300050,
    "Bread, whole grain white, toasted": 51300060,
    "Bagel, whole grain white": 51300100,
    "Bread, whole wheat": 51300110,
    "Bread, whole wheat, toasted": 51300120,
    "Bread, whole wheat, made from home recipe or purchased at bakery": 51300140,
    "Bread, whole wheat, made from home recipe or purchased at bakery, toasted": 51300150,
    "Bread, chappatti or roti": 51300175,
    "Bread, puri": 51300180,
    "Bread, paratha": 51300185,
    "Bread, whole wheat, with raisins": 51300210,
    "Bread, whole wheat, with raisins, toasted": 51300220,
    "Bread, sprouted wheat": 51300300,
    "Bread, sprouted wheat, toasted": 51300310,
    "Bread, wheat or cracked wheat": 51301010,
    "Bread, wheat or cracked wheat, toasted": 51301020,
    "Bread, wheat or cracked wheat, made from home recipe or purchased at bakery": 51301040,
    "Bread, wheat or cracked wheat, made from home recipe or purchased at bakery, toasted": 51301050,
    "Bread, wheat or cracked wheat, with raisins": 51301120,
    "Bread, wheat or cracked wheat, with raisins, toasted": 51301130,
    "Bread, wheat or cracked wheat, reduced calorie and/or high fiber": 51301510,
    "Bread, wheat or cracked wheat, reduced calorie and/or high fiber, toasted": 51301520,
    "Bread, French or Vienna, whole wheat": 51301540,
    "Bread, French or Vienna, whole wheat, toasted": 51301550,
    "Bread, pita, whole wheat": 51301600,
    "Bread, pita, wheat or cracked wheat": 51301620,
    "Bagel, wheat": 51301700,
    "Bagel, whole wheat": 51301750,
    "Bagel, wheat, with raisins": 51301800,
    "Bagel, whole wheat, with raisins": 51301805,
    "Bagel, wheat, with fruit and nuts": 51301820,
    "Bagel, wheat bran": 51301900,
    "Muffin, English, wheat bran": 51302500,
    "Muffin, English, wheat bran, with raisins": 51302520,
    "Muffin, English, wheat or cracked wheat": 51303010,
    "Muffin, English, whole wheat": 51303030,
    "Muffin, English, wheat or cracked wheat, with raisins": 51303050,
    "Muffin, English, whole wheat, with raisins": 51303070,
    "Muffin, English, whole grain white": 51303100,
    "Breadsticks, hard, whole wheat": 51306000,
    "Roll, wheat or cracked wheat": 51320010,
    "Roll, wheat or cracked wheat, hot dog bun": 51320060,
    "Roll, wheat or cracked wheat, hamburger bun": 51320070,
    "Roll, whole wheat": 51320500,
    "Roll, whole wheat, hot dog bun": 51320550,
    "Roll, whole wheat, hamburger bun": 51320560,
    "Roll, whole grain white": 51320700,
    "Roll, whole grain white, hot dog bun": 51320710,
    "Roll, whole grain white, hamburger bun": 51320720,
    "Bread, rye": 51401010,
    "Bread, rye, toasted": 51401020,
    "Bread, marble rye and pumpernickel": 51401030,
    "Bread, marble rye and pumpernickel, toasted": 51401040,
    "Muffin, English, rye": 51401200,
    "Bread, pumpernickel": 51404010,
    "Bread, pumpernickel, toasted": 51404020,
    "Bagel, pumpernickel": 51404500,
    "Muffin, English, pumpernickel": 51404550,
    "Bread, black": 51407010,
    "Bread, black, toasted": 51407020,
    "Roll, rye": 51420000,
    "Roll, pumpernickel": 51421000,
    "Bread, oatmeal": 51501010,
    "Bread, oatmeal, toasted": 51501020,
    "Bread, oat bran": 51501040,
    "Bread, oat bran, toasted": 51501050,
    "Bagel, oat bran": 51501080,
    "Roll, oatmeal": 51502010,
    "Muffin, English, oat bran": 51503000,
    "Muffin, English, oat bran, with raisins": 51503040,
    "Bread, multigrain, toasted": 51601010,
    "Bread, multigrain": 51601020,
    "Bread, multigrain, with raisins": 51601210,
    "Bread, multigrain, with raisins, toasted": 51601220,
    "Bread, multigrain, reduced calorie and/or high fiber": 51602010,
    "Bread, multigrain, reduced calorie and/or high fiber, toasted": 51602020,
    "Roll, multigrain": 51620000,
    "Roll, multigrain, hot dog bun": 51620020,
    "Roll, multigrain, hamburger bun": 51620030,
    "Bagel, multigrain": 51630000,
    "Bagel, multigrain, with raisins": 51630100,
    "Muffin, English, multigrain": 51630200,
    "Bread, barley": 51801010,
    "Bread, barley, toasted": 51801020,
    "Bread, soy": 51804010,
    "Bread, soy, toasted": 51804020,
    "Bread, sunflower meal": 51805010,
    "Bread, sunflower meal, toasted": 51805020,
    "Bread, rice": 51806010,
    "Bread, rice, toasted": 51806020,
    "Injera, Ethiopian bread": 51807000,
    "Bread, gluten free": 51808000,
    "Bread, gluten free, toasted": 51808010,
    "Breadsticks, hard, gluten free": 51808050,
    "Roll, gluten free": 51808100,
    "Biscuit, NFS": 52101000,
    "Biscuit, from refrigerated dough": 52102040,
    "Biscuit, from fast food / restaurant": 52103000,
    "Biscuit, home recipe": 52104010,
    "Biscuit, wheat": 52104040,
    "Biscuit, cheese": 52104100,
    "Biscuit with fruit": 52104200,
    "Scone": 52105100,
    "Scone, with fruit": 52105200,
    "Cornbread, prepared from mix": 52201000,
    "Cornbread, made from home recipe": 52202060,
    "Cornbread stuffing": 52204000,
    "Cornbread muffin, stick, round": 52206010,
    "Cornbread muffin, stick, round, made from home recipe": 52206060,
    "Corn flour patty or tart, fried": 52207010,
    "Corn pone, baked": 52208010,
    "Corn pone, fried": 52208020,
    "Gordita/sope shell, plain, no filling": 52208760,
    "Hush puppy": 52209010,
    "Johnnycake": 52211010,
    "Spoonbread": 52213010,
    "Tortilla, NFS": 52215000,
    "Tortilla, corn": 52215100,
    "Tortilla, flour": 52215200,
    "Tortilla, whole wheat": 52215260,
    "Taco shell, corn": 52215300,
    "Taco shell, flour": 52215350,
    "Arepa Dominicana": 52220110,
    "Muffin, NFS": 52301000,
    "Muffin, fruit": 52302010,
    "Muffin, fruit, low fat": 52302020,
    "Muffin, chocolate chip": 52302500,
    "Muffin, chocolate": 52302600,
    "Muffin, whole wheat": 52303010,
    "Muffin, wheat": 52303500,
    "Muffin, whole grain": 52304000,
    "Muffin, wheat bran": 52304010,
    "Muffin, bran with fruit, lowfat": 52304040,
    "Muffin, oatmeal": 52304100,
    "Muffin, oat bran": 52304150,
    "Muffin, plain": 52306010,
    "Muffin, cheese": 52306300,
    "Muffin, pumpkin": 52306500,
    "Muffin, zucchini": 52306550,
    "Muffin, carrot": 52306700,
    "Popover": 52311010,
    "Bread, Boston Brown": 52401000,
    "Bread, nut": 52403000,
    "Bread, pumpkin": 52404060,
    "Bread, fruit": 52405010,
    "Bread, zucchini": 52407000,
    "Bread, Irish soda": 52408000,
    "Cake or cupcake, NFS": 53100100,
    "Cake, angel food": 53101100,
    "Cake or cupcake, apple": 53102200,
    "Cake or cupcake, banana": 53102700,
    "Cake or cupcake, Black Forest": 53102800,
    "Cake, Boston cream pie": 53103000,
    "Cake or cupcake, carrot": 53104260,
    "Cake or cupcake, coconut": 53104400,
    "Cheesecake, plain": 53104500,
    "Cheesecake, fruit": 53104550,
    "Cheesecake, chocolate": 53104600,
    "Cake or cupcake, chocolate with white icing, bakery": 53105262,
    "Cake or cupcake, chocolate with white icing, from mix": 53105264,
    "Cake or cupcake, chocolate with chocolate icing, bakery": 53105270,
    "Cake or cupcake, chocolate with chocolate icing, from mix": 53105272,
    "Cake or cupcake, chocolate, no icing": 53105275,
    "Cake or cupcake, German chocolate": 53105300,
    "Cake or cupcake, gluten free": 53105310,
    "Cake, chocolate, flourless": 53105396,
    "Cake, cream": 53106500,
    "Snack cake, chocolate": 53108200,
    "Snack cake, white": 53109200,
    "Cake, fruit cake": 53110000,
    "Cake or cupcake, gingerbread": 53111000,
    "Ice cream cake": 53112100,
    "Cake, jelly roll": 53113000,
    "Cake or cupcake, lemon": 53114100,
    "Cake or cupcake, marble": 53115200,
    "Cake or cupcake, peanut butter": 53115450,
    "Cake, pound": 53116000,
    "Cake or cupcake, pumpkin": 53116510,
    "Cake or cupcake, red velvet": 53116520,
    "Cake or cupcake, spice": 53117200,
    "Cake, sponge": 53118100,
    "Cake or cupcake, strawberry": 53118110,
    "Cake, torte": 53118500,
    "Cake, tres leche": 53118550,
    "Cake, pineapple, upside down": 53119000,
    "Cake or cupcake, white with white icing, bakery": 53120270,
    "Cake or cupcake, white with white icing, from mix": 53120272,
    "Cake or cupcake, white with chocolate icing, bakery": 53121270,
    "Cake or cupcake, white with chocolate icing, from mix": 53121272,
    "Cake or cupcake, white, no icing": 53121275,
    "Cake, strawberry shortcake": 53123070,
    "Cake or cupcake, zucchini": 53124110,
    "Cookie, batter or dough, raw": 53200100,
    "Cookie, NFS": 53201000,
    "Cookie, almond": 53202000,
    "Cookie, applesauce": 53203000,
    "Cookie, biscotti": 53203500,
    "Cookie, brownie, NS as to icing": 53204000,
    "Cookie, brownie, without icing": 53204010,
    "Cookie, brownie, with icing or filling": 53204100,
    "Cookie, brownie, reduced fat, NS as to icing": 53204840,
    "Cookie, butterscotch, brownie": 53205250,
    "Cookie, bar, with chocolate": 53205260,
    "Cookie, chocolate chip": 53206000,
    "Cookie, chocolate chip, made from home recipe or purchased at a bakery": 53206020,
    "Cookie, chocolate chip, reduced fat": 53206030,
    "Cookie, chocolate chip sandwich": 53206100,
    "Cookie, chocolate, made with rice cereal": 53206500,
    "Cookie, chocolate, made with oatmeal and coconut, no bake": 53206550,
    "Cookie, chocolate or fudge": 53207000,
    "Cookie, chocolate or fudge, reduced fat": 53207020,
    "Cookie, chocolate, with chocolate filling or coating, fat free": 53207050,
    "Cookie, marshmallow, chocolate-covered": 53208000,
    "Cookie, marshmallow pie, chocolate covered": 53208200,
    "Cookie, chocolate, with icing or coating": 53209005,
    "Cookie, sugar wafer, chocolate-covered": 53209010,
    "Cookie, chocolate sandwich": 53209015,
    "Cookie, chocolate sandwich, reduced fat": 53209020,
    "Cookie, chocolate, sandwich, with extra filling": 53209100,
    "Cookie, chocolate and vanilla sandwich": 53209500,
    "Cookie, chocolate wafer": 53210000,
    "Cookie, graham cracker with chocolate and marshmallow": 53210900,
    "Cookie bar, with chocolate, nuts, and graham crackers": 53211000,
    "Cookie, coconut": 53215500,
    "Cookie, fruit-filled bar": 53220000,
    "Cookie, fig bar": 53220030,
    "Cookie, fortune": 53222010,
    "Cookie, cone shell, ice cream type, wafer or cake": 53222020,
    "Cookie, gingersnaps": 53223000,
    "Cookie, granola": 53223100,
    "Cookie, ladyfinger": 53224000,
    "Cookie, lemon bar": 53224250,
    "Cookie, macaroon": 53225000,
    "Cookie, marshmallow, with coconut": 53226000,
    "Cookie, marshmallow, with rice cereal, no bake": 53226500,
    "Cookie, marshmallow, with rice cereal and chocolate chips": 53226550,
    "Cookie, marshmallow and peanut butter, with oat cereal, no bake": 53226600,
    "Cookie, meringue": 53228000,
    "Cookie, molasses": 53230000,
    "Cookie, Lebkuchen": 53231000,
    "Cookie, multigrain, high fiber": 53231400,
    "Cookie, oatmeal": 53233000,
    "Cookie, oatmeal, with raisins": 53233010,
    "Cookie, oatmeal, reduced fat, NS as to raisins": 53233040,
    "Cookie, oatmeal sandwich, with creme filling": 53233050,
    "Cookie, oatmeal, with chocolate chips": 53233060,
    "Cookie, oatmeal sandwich, with peanut butter and jelly filling": 53233080,
    "Cookie, oatmeal, with chocolate and peanut butter, no bake": 53233100,
    "Cookie, peanut butter": 53234000,
    "Cookie, peanut butter, with chocolate": 53234100,
    "Cookie, peanut butter with rice cereal, no bake": 53234250,
    "Cookie, peanut butter sandwich": 53235000,
    "Cookie, with peanut butter filling, chocolate-coated": 53235500,
    "Cookie, Pfeffernusse": 53235600,
    "Cookie, Pizzelle": 53236000,
    "Cookie, pumpkin": 53236100,
    "Cookie, raisin": 53237000,
    "Cookie, raisin sandwich, cream-filled": 53237010,
    "Cookie, rum ball, no bake": 53237500,
    "Cookie, sandwich-type, not chocolate or vanilla": 53238000,
    "Cookie, shortbread": 53239000,
    "Cookie, shortbread, reduced fat": 53239010,
    "Cookie, shortbread, with icing or filling": 53239050,
    "Pocky": 53239100,
    "Cookie, animal": 53240000,
    "Cookie, animal, with frosting or icing": 53240010,
    "Cookie, butter or sugar": 53241500,
    "Marie biscuit": 53241510,
    "Cookie, butter or sugar, with fruit and/or nuts": 53241600,
    "Cookie, sugar wafer": 53242000,
    "Cookie, toffee bar": 53242500,
    "Cookie, vanilla sandwich": 53243000,
    "Cookie, vanilla sandwich, extra filling": 53243010,
    "Cookie, vanilla sandwich, reduced fat": 53243050,
    "Cookie, butter or sugar, with chocolate icing or filling": 53244010,
    "Cookie, butter or sugar, with icing or filling other than chocolate": 53244020,
    "Cookie, tea, Japanese": 53246000,
    "Cookie, vanilla wafer": 53247000,
    "Cookie, vanilla wafer, reduced fat": 53247050,
    "Cookie, vanilla with caramel, coconut, and chocolate coating": 53247500,
    "Cookie, rugelach": 53251100,
    "Cookie, chocolate chip, sugar free": 53260030,
    "Cookie, oatmeal, sugar free": 53260200,
    "Cookie, sandwich, sugar free": 53260300,
    "Cookie, sugar or plain, sugar free": 53260400,
    "Cookie, sugar wafer, sugar free": 53260500,
    "Cookie, peanut butter, sugar free": 53260600,
    "Cookie, gluten free": 53261000,
    "Cookies, Puerto Rican style": 53270100,
    "Pie, NFS": 53300100,
    "Tart, all types": 53300200,
    "Pie, apple": 53301000,
    "Pie, apple, fast food": 53301070,
    "Pie, berry": 53303500,
    "Pie, blueberry": 53304000,
    "Pie, cherry": 53305000,
    "Pie, lemon": 53305700,
    "Pie, key lime": 53305800,
    "Pie, peach": 53307000,
    "Pie, strawberry": 53312000,
    "Pie, banana cream": 53341000,
    "Pie, chocolate cream": 53342000,
    "Pie, coconut cream": 53343000,
    "Pie, custard": 53344000,
    "Dessert pizza": 53344300,
    "Pie, peanut butter cream": 53346000,
    "Pie, pineapple cream": 53346500,
    "Pie, pumpkin": 53347000,
    "Pie, sweet potato": 53360000,
    "Pie, vanilla cream": 53365000,
    "Pie, lemon meringue": 53381000,
    "Pie, pecan": 53385000,
    "Pie, oatmeal": 53385500,
    "Pie shell": 53391000,
    "Pie shell, graham cracker": 53391100,
    "Pie shell, chocolate": 53391150,
    "Cobbler, apple": 53410100,
    "Cobbler, berry": 53410300,
    "Cobbler, cherry": 53410500,
    "Cobbler, peach": 53410800,
    "Crisp, apple": 53415100,
    "Fritter, plain": 53415110,
    "Fritter, fruit": 53415120,
    "Crisp, berry": 53415300,
    "Crisp, cherry": 53415400,
    "Crisp, peach": 53415500,
    "Cream puff, eclair, custard or cream filled, NS as to icing": 53420000,
    "Cream puff, eclair, custard or cream filled, not iced": 53420100,
    "Cream puff, eclair, custard or cream filled, iced": 53420200,
    "Cream puff, eclair, custard or cream filled, iced, reduced fat": 53420210,
    "Cream puff, no filling or icing": 53420250,
    "Sopaipilla, without syrup or honey": 53420400,
    "Sopaipilla with syrup or honey": 53420410,
    "Crepe, chocolate filled": 53430100,
    "Crepe, fruit filled": 53430200,
    "Tamale, sweet": 53430700,
    "Strudel, apple": 53440000,
    "Strudel, berry": 53440300,
    "Strudel, cherry": 53440500,
    "Strudel, cheese": 53440600,
    "Strudel, peach": 53440700,
    "Baklava": 53441110,
    "Basbousa": 53441210,
    "Turnover, fruit": 53450000,
    "Pastry, fruit-filled": 53452100,
    "Pastry, made with bean or lotus seed paste filling, baked": 53452120,
    "Pastry, made with bean paste and salted egg yolk filling, baked": 53452130,
    "Pastry, Chinese, made with rice flour": 53452150,
    "Pastry, cookie type, fried": 53452170,
    "Pastry, Italian, with cheese": 53452200,
    "Pastry, puff": 53452400,
    "Pastry, puff, custard or cream filled, iced or not iced": 53452420,
    "Cheese pastry puffs": 53452450,
    "Pastry, mainly flour and water, fried": 53452500,
    "Empanada, fruit": 53453150,
    "Breakfast pastry, NFS": 53500100,
    "Danish pastry, plain or spice": 53510000,
    "Danish pastry, with fruit": 53510100,
    "Danish pastry, with cheese": 53511000,
    "Doughnut, NFS": 53520000,
    "Doughnut, cake type, plain": 53520100,
    "Doughnut, chocolate": 53520120,
    "Doughnut, cake type, powdered sugar": 53520130,
    "Doughnut, cake type, with icing": 53520135,
    "Doughnut, cake type, chocolate icing": 53520140,
    "Doughnut, chocolate, with chocolate icing": 53520160,
    "Doughnut holes": 53520170,
    "Churros": 53520200,
    "Beignet": 53520510,
    "Doughnut, yeast type": 53521110,
    "Doughnut, yeast type, with chocolate icing": 53521130,
    "Doughnut, jelly": 53521140,
    "Doughnut, custard-filled": 53521210,
    "Doughnut, custard-filled, with icing": 53521230,
    "Breakfast tart": 53530000,
    "Breakfast tart, lowfat": 53530010,
    "Coffee cake, crumb or quick-bread type": 53610100,
    "Coffee cake, crumb or quick-bread type, with fruit": 53610170,
    "Coffee cake, crumb or quick-bread type, cheese-filled": 53610200,
    "Cereal or granola bar (General Mills Fiber One Chewy Bar)": 53710400,
    "Cereal or granola bar (Kellogg's Nutri-Grain Cereal Bar)": 53710500,
    "Cereal or granola bar (Kellogg's Nutri-Grain Yogurt Bar)": 53710502,
    "Cereal or granola bar (Kellogg's Nutri-Grain Fruit and Nut Bar)": 53710504,
    "Milk 'n Cereal bar": 53710600,
    "Cereal or granola bar (Kellogg's Special K bar)": 53710700,
    "Cereal or granola bar (Kashi Chewy)": 53710800,
    "Cereal or granola bar (Kashi Crunchy)": 53710802,
    "Cereal or granola bar (KIND Fruit and Nut Bar)": 53710810,
    "Cereal or granola bar (General Mills Nature Valley Chewy Trail Mix)": 53710900,
    "Cereal or granola bar, with yogurt coating (General Mills Nature Valley Chewy Granola Bar)": 53710902,
    "Cereal or granola bar (General Mills Nature Valley Sweet and Salty Granola Bar)": 53710904,
    "Cereal or granola bar (General Mills Nature Valley Crunchy Granola Bar)": 53710906,
    "Cereal or granola bar (Quaker Chewy Granola Bar)": 53711000,
    "Cereal or granola bar (Quaker Chewy 90 Calorie Granola Bar)": 53711002,
    "Cereal or granola bar (Quaker Chewy 25% Less Sugar Granola Bar)": 53711004,
    "Cereal or granola bar (Quaker Chewy Dipps Granola Bar)": 53711006,
    "Cereal or granola bar (Quaker Granola Bites)": 53711100,
    "Snack bar, oatmeal": 53712000,
    "Cereal or Granola bar, NFS": 53712100,
    "Cereal or granola bar, lowfat, NFS": 53712200,
    "Cereal or granola bar, nonfat": 53712210,
    "Cereal or granola bar, reduced sugar, NFS": 53713000,
    "Cereal or granola bar, fruit and nut": 53713010,
    "Cereal or granola bar, peanuts , oats, sugar, wheat germ": 53713100,
    "Cereal or granola bar, chocolate coated, NFS": 53714200,
    "Cereal or granola bar, with coconut, chocolate coated": 53714210,
    "Cereal or granola bar with nuts, chocolate coated": 53714220,
    "Cereal or granola bar, oats, nuts, coated with non-chocolate coating": 53714230,
    "Cereal or granola bar, coated with non-chocolate coating": 53714250,
    "Cereal or granola bar, high fiber, coated with non-chocolate yogurt coating": 53714300,
    "Cereal or granola bar, with rice cereal": 53714400,
    "Breakfast bar, NFS": 53714500,
    "Breakfast bar, date, with yogurt coating": 53714510,
    "Breakfast bar, cereal crust with fruit filling, lowfat": 53714520,
    "Nutrition bar (Balance Original Bar)": 53720100,
    "Nutrition bar (Clif Bar)": 53720200,
    "Nutrition bar (Clif Kids Organic Zbar)": 53720210,
    "Nutrition bar (PowerBar)": 53720300,
    "Nutrition bar (Slim Fast Original Meal Bar)": 53720400,
    "Nutrition bar (Snickers Marathon Protein Bar)": 53720500,
    "Nutrition bar (South Beach Living Meal Bar)": 53720600,
    "Nutrition bar (South Beach Living High Protein Bar)": 53720610,
    "Nutrition bar (Tiger's Milk)": 53720700,
    "Nutrition bar (Zone Perfect Classic Crunch)": 53720800,
    "Nutrition bar or meal replacement bar, NFS": 53729000,
    "Baby Toddler snack, NFS": 53800000,
    "Baby Toddler bar": 53801000,
    "Baby Toddler cookie": 53803100,
    "Baby Toddler biscuit": 53803300,
    "Crackers, NFS": 54001000,
    "Graham crackers": 54102010,
    "Graham crackers (Teddy Grahams)": 54102015,
    "Graham crackers, chocolate covered": 54102020,
    "Crackers, oatmeal": 54102050,
    "Crackers, Cuban": 54102060,
    "Graham crackers, reduced fat": 54102100,
    "Graham crackers, sandwich, with filling": 54102200,
    "Crackers, breakfast biscuit": 54103000,
    "Crackers, butter, reduced sodium": 54200100,
    "Crackers, matzo, reduced sodium": 54201010,
    "Crackers, saltine, reduced sodium": 54202020,
    "Crackers, wheat, reduced sodium": 54204020,
    "Crackers, woven wheat, reduced sodium": 54204030,
    "Crackers, butter, plain": 54301010,
    "Crackers, butter, flavored": 54301020,
    "Crackers, butter (Ritz)": 54301030,
    "Crackers, butter, reduced fat": 54301100,
    "Crackers, cheese": 54304000,
    "Crackers, cheese (Cheez-It)": 54304005,
    "Crackers, cheese (Goldfish)": 54304020,
    "Crackers, cheese, reduced fat": 54304100,
    "Crackers, cheese, reduced sodium": 54304110,
    "Crackers, cheese, whole grain": 54304150,
    "Crackers, crispbread": 54305010,
    "Crackers, flatbread": 54305020,
    "Crackers, matzo": 54307000,
    "Crackers, milk": 54308000,
    "Crackers, oyster": 54313000,
    "Chips, rice": 54318000,
    "Rice cake": 54318500,
    "Crackers, rice": 54319000,
    "Crackers, rice and nuts": 54319005,
    "Popcorn cake": 54319020,
    "Rice paper": 54319500,
    "Crackers, saltine": 54325000,
    "Crackers, saltine, reduced fat": 54325010,
    "Crackers, saltine, multigrain": 54325060,
    "Crackers, multigrain": 54326000,
    "Crackers, sandwich": 54328000,
    "Crackers, sandwich, peanut butter filled": 54328100,
    "Crackers, sandwich, peanut butter filled (Ritz)": 54328105,
    "Crackers, sandwich, reduced fat, peanut butter filled": 54328110,
    "Crackers, whole grain, sandwich, peanut butter filled": 54328120,
    "Crackers, sandwich, cheese filled": 54328200,
    "Crackers, sandwich, cheese filled (Ritz)": 54328210,
    "Crackers, water": 54336000,
    "Crackers, wonton": 54336100,
    "Crackers, woven wheat": 54337010,
    "Crackers, woven wheat, plain (Triscuit)": 54337020,
    "Crackers, woven wheat, flavored (Triscuit)": 54337030,
    "Crackers, woven wheat, reduced fat": 54337060,
    "Crackers, wheat": 54338000,
    "Crackers, wheat, plain (Wheat Thins)": 54338010,
    "Crackers, wheat, flavored (Wheat Thins)": 54338020,
    "Crackers, wheat, reduced fat": 54338100,
    "Crackers, gluten free, plain": 54340100,
    "Crackers, gluten free, flavored": 54340110,
    "Baby Toddler crackers": 54350000,
    "Baby Toddler puffs, fruit": 54350100,
    "Baby Toddler puffs, vegetable": 54350200,
    "Baby Toddler crunchies": 54360000,
    "Baby Toddler wheels": 54360100,
    "Corn nuts": 54401011,
    "Corn chips, plain": 54401021,
    "Corn chips, flavored": 54401026,
    "Corn chips, plain (Fritos)": 54401031,
    "Corn chips, flavored (Fritos)": 54401035,
    "Cheese flavored corn snacks": 54401055,
    "Cheese flavored corn snacks, reduced fat": 54401065,
    "Tortilla chips, plain": 54401075,
    "Cheese flavored corn snacks (Cheetos)": 54401081,
    "Tortilla chips, flavored": 54401085,
    "Corn chips, reduced sodium": 54401090,
    "Tortilla chips, nacho cheese flavor (Doritos)": 54401110,
    "Tortilla chips, cool ranch flavor (Doritos)": 54401111,
    "Tortilla chips, other flavors (Doritos)": 54401112,
    "Tortilla chips, reduced fat, plain": 54401121,
    "Tortilla chips, reduced fat, flavored": 54401122,
    "Tortilla chips, low fat, unsalted": 54401170,
    "Tortilla chips, reduced sodium": 54402080,
    "Snack mix": 54402200,
    "Potato chips, restructured, multigrain": 54402610,
    "Pita chips": 54402700,
    "Popcorn, NFS": 54403001,
    "Popcorn, movie theater, with added butter": 54403005,
    "Popcorn, movie theater, no butter added": 54403006,
    "Popcorn, air-popped, no butter added": 54403010,
    "Popcorn, air-popped, with added butter": 54403040,
    "Popcorn, popped in oil, no butter added": 54403045,
    "Popcorn, popped in oil, with added butter": 54403046,
    "Popcorn, microwave, NFS": 54403051,
    "Popcorn, microwave, plain": 54403052,
    "Popcorn, microwave, plain, light": 54403053,
    "Popcorn, microwave, low sodium": 54403054,
    "Popcorn, microwave, butter flavored": 54403056,
    "Popcorn, microwave, flavored, light": 54403057,
    "Popcorn, microwave, cheese flavored": 54403058,
    "Popcorn, microwave, kettle": 54403059,
    "Popcorn, ready-to-eat, NFS": 54403080,
    "Popcorn, ready-to-eat, plain": 54403081,
    "Popcorn, ready-to-eat, plain, light": 54403082,
    "Popcorn, ready-to-eat, low sodium": 54403083,
    "Popcorn, ready-to-eat, butter flavored": 54403085,
    "Popcorn, ready-to-eat, cheese flavored": 54403087,
    "Popcorn, ready-to-eat, flavored, light": 54403088,
    "Popcorn, ready-to-eat, kettle": 54403089,
    "Popcorn, caramel coated": 54403110,
    "Popcorn, caramel coated, with nuts": 54403120,
    "Popcorn, chocolate coated": 54403160,
    "Popcorn chips, plain": 54404000,
    "Popcorn chips, other flavors": 54404010,
    "Popcorn chips, sweet flavors": 54404020,
    "Onion flavored rings": 54406010,
    "Shrimp chips": 54406200,
    "Pretzels, NFS": 54408000,
    "Pretzels, hard, NFS": 54408015,
    "Pretzels, hard, plain, salted": 54408016,
    "Pretzels, hard, plain, lightly salted": 54408017,
    "Pretzels, hard, plain, unsalted": 54408030,
    "Pretzels, hard, flavored": 54408035,
    "Pretzels, hard, multigrain": 54408070,
    "Pretzels, hard, plain, gluten free": 54408081,
    "Pretzels, hard, flavored, gluten free": 54408082,
    "Pretzel chips, hard, plain": 54408105,
    "Pretzel chips, hard, flavored": 54408110,
    "Pretzel chips, hard, gluten free": 54408115,
    "Pretzels, hard, coated, NFS": 54408190,
    "Pretzels, hard, chocolate coated": 54408200,
    "Pretzels, hard, white chocolate coated": 54408210,
    "Pretzels, hard, yogurt coated": 54408250,
    "Pretzels, hard, coated, gluten free": 54408260,
    "Pretzels, hard, filled, NFS": 54408290,
    "Pretzels, hard, cheese filled": 54408300,
    "Pretzels, hard, peanut butter filled": 54408310,
    "Pretzels, soft, NFS": 54408400,
    "Pretzels, soft, ready-to-eat, NFS": 54408405,
    "Pretzels, soft, ready-to-eat, salted, buttered": 54408410,
    "Pretzels, soft, ready-to-eat, unsalted, buttered": 54408411,
    "Pretzels, soft, ready-to-eat, salted, no butter": 54408415,
    "Pretzels, soft, ready-to-eat, unsalted, no butter": 54408416,
    "Pretzels, soft, ready-to-eat, cinnamon sugar coated": 54408420,
    "Pretzels, soft, ready-to-eat, coated or flavored": 54408422,
    "Pretzels, soft, ready-to-eat, topped with meat": 54408430,
    "Pretzels, soft, ready-to-eat, topped with cheese": 54408432,
    "Pretzels, soft, from frozen, NFS": 54408450,
    "Pretzels, soft, from frozen, salted": 54408455,
    "Pretzels, soft, from frozen, unsalted": 54408456,
    "Pretzels, soft, from frozen, cinnamon sugar coated": 54408460,
    "Pretzels, soft, from frozen, coated or flavored": 54408462,
    "Pretzels, soft, from frozen, topped with meat": 54408465,
    "Pretzels, soft, from frozen, topped with cheese": 54408466,
    "Pretzels, soft, filled with cheese": 54408470,
    "Pretzels, soft, from school lunch": 54408475,
    "Pretzels, soft, multigrain": 54408480,
    "Pretzels, soft, gluten free": 54408485,
    "Pretzels, soft, gluten free, cinnamon sugar coated": 54408486,
    "Pretzels, soft, gluten free, coated or flavored": 54408487,
    "Multigrain chips (Sun Chips)": 54420210,
    "Snack mix, plain (Chex Mix)": 54420220,
    "Bagel chips": 54440010,
    "Cracker chips": 54440020,
    "Pancakes, NFS": 55100005,
    "Pancakes, plain, frozen": 55100010,
    "Pancakes, fruit, frozen": 55100020,
    "Pancakes, chocolate, frozen": 55100025,
    "Pancakes, whole grain, frozen": 55100030,
    "Pancakes, plain, fast food / restaurant": 55100050,
    "Pancakes, fruit, fast food / restaurant": 55100055,
    "Pancakes, chocolate, fast food / restaurant": 55100060,
    "Pancakes, whole grain, fast food / restaurant": 55100065,
    "Pancakes, school": 55100080,
    "Pancakes, plain": 55101000,
    "Pancakes, plain, reduced fat": 55101015,
    "Pancakes, fruit": 55103000,
    "Pancakes, pumpkin": 55103020,
    "Pancakes, chocolate": 55103100,
    "Pancakes, whole grain": 55105200,
    "Pancakes, whole grain, reduced fat": 55105205,
    "Pancakes, gluten free": 55106000,
    "Waffle, NFS": 55200010,
    "Waffle, plain, frozen": 55200020,
    "Waffle, plain, reduced fat": 55200030,
    "Waffle, fruit, frozen": 55200040,
    "Waffle, chocolate, frozen": 55200050,
    "Waffle, whole grain, frozen": 55200060,
    "Waffle, whole grain, reduced fat": 55200070,
    "Waffle, whole grain, fruit, frozen": 55200080,
    "Waffle, plain, fast food / restaurant": 55200100,
    "Waffle, chocolate, fast food / restaurant": 55200110,
    "Waffle, fruit, fast food / restaurant": 55200120,
    "Waffle, whole grain, fast food / restaurant": 55200130,
    "Waffle, school": 55200200,
    "Waffle, plain": 55201000,
    "Waffle, fruit": 55203000,
    "Waffle, chocolate": 55203600,
    "Waffle, cinnamon": 55203700,
    "Waffle, whole grain": 55205000,
    "Waffle, gluten free": 55208000,
    "French toast, NFS": 55300010,
    "French toast, frozen": 55300020,
    "French toast, fast food / restaurant": 55300050,
    "French toast, school": 55300060,
    "French toast, plain": 55301000,
    "French toast, whole grain": 55301015,
    "French toast, gluten free": 55301025,
    "French toast sticks": 55301031,
    "French toast sticks, fast food / restaurant": 55301040,
    "French toast sticks, school": 55301048,
    "Crepe, NFS": 55400010,
    "Crepe, plain": 55401000,
    "Chinese pancake": 55501000,
    "Dumpling, no meat": 55610300,
    "Cake made with glutinous rice": 55701000,
    "Idli": 55702000,
    "Dosa, plain": 55702100,
    "Cake made with glutinous rice and dried beans": 55703000,
    "Funnel cake with sugar": 55801000,
    "Funnel cake with sugar and fruit": 55801010,
    "Pasta, vegetable, cooked": 56104000,
    "Noodles, cooked": 56112000,
    "Noodles, whole grain, cooked": 56113000,
    "Noodles, vegetable, cooked": 56113990,
    "Noodles, chow mein": 56116000,
    "Long rice noodles, made from mung beans, cooked": 56116990,
    "Rice noodles, cooked": 56117090,
    "Pasta, cooked": 56130000,
    "Pasta, whole grain, cooked": 56132990,
    "Pasta, gluten free": 56140100,
    "Cereal, cooked, NFS": 56200300,
    "Barley": 56200410,
    "Buckwheat groats": 56200510,
    "Grits, NFS": 56200990,
    "Grits, regular or quick, made with water, NS as to fat": 56201050,
    "Grits, regular or quick, made with water, no added fat": 56201051,
    "Grits, regular or quick, made with water, fat added": 56201052,
    "Grits, with cheese, NS as to fat": 56201090,
    "Grits, with cheese, no added fat": 56201091,
    "Grits, with cheese, fat added": 56201092,
    "Grits, instant, made with water, no added fat": 56201210,
    "Grits, instant, made with water, fat added": 56201220,
    "Grits, instant, made with water, NS as to fat": 56201230,
    "Cornmeal mush, NS as to fat": 56201515,
    "Cornmeal mush, no added fat": 56201516,
    "Cornmeal mush, fat added": 56201517,
    "Masa harina, cooked": 56201600,
    "Millet": 56202000,
    "Oatmeal, fast food, plain": 56202900,
    "Oatmeal, fast food, flavored": 56202905,
    "Oatmeal, NFS": 56202960,
    "Oatmeal, regular or quick, made with water, no added fat": 56203056,
    "Oatmeal, regular or quick, made with water, fat added": 56203057,
    "Oatmeal, regular or quick, made with milk, no added fat": 56203066,
    "Oatmeal, regular or quick, made with milk, fat added": 56203067,
    "Oatmeal, regular or quick, made with non-dairy milk, no added fat": 56203076,
    "Oatmeal, regular or quick, made with non-dairy milk, fat added": 56203077,
    "Oatmeal, instant, plain, made with water, no added fat": 56203086,
    "Oatmeal, instant, plain, made with water, fat added": 56203087,
    "Oatmeal, instant, plain, made with milk, no added fat": 56203096,
    "Oatmeal, instant, plain, made with milk, fat added": 56203097,
    "Oatmeal, instant, plain, made with non-dairy milk, no added fat": 56203106,
    "Oatmeal, instant, plain, made with non-dairy milk, fat added": 56203107,
    "Oatmeal, instant, maple flavored, no added fat": 56203130,
    "Oatmeal, instant, maple flavored, fat added": 56203135,
    "Oatmeal, instant, fruit flavored, no added fat": 56203155,
    "Oatmeal, instant, fruit flavored, fat added": 56203160,
    "Oatmeal, reduced sugar": 56203555,
    "Oatmeal, multigrain": 56203610,
    "Quinoa, NS as to fat": 56204000,
    "Quinoa, no added fat": 56204005,
    "Quinoa, fat added": 56204010,
    "Rice, cooked, NFS": 56205000,
    "Rice, white, cooked, NS as to fat": 56205001,
    "Rice, white, cooked, made with oil": 56205002,
    "Rice, white, cooked, made with butter": 56205004,
    "Rice, white, cooked, made with margarine": 56205006,
    "Rice, white, cooked, fat added, NS as to fat type": 56205007,
    "Rice, white, cooked, no added fat": 56205008,
    "Rice, brown, cooked, NS as to fat": 56205011,
    "Rice, brown, cooked, fat added, made with oil": 56205012,
    "Rice, brown, cooked, made with butter": 56205014,
    "Rice, brown, cooked, made with margarine": 56205016,
    "Rice, brown, cooked, fat added, NS as to fat type": 56205017,
    "Rice, brown, cooked, no added fat": 56205018,
    "Cream of rice": 56205050,
    "Rice, cooked, with milk": 56205060,
    "Rice, sweet, cooked with honey": 56205070,
    "Congee": 56205101,
    "Yellow rice, cooked, NS as to fat": 56205130,
    "Yellow rice, cooked, no added fat": 56205150,
    "Yellow rice, cooked, fat added": 56205170,
    "Rice, white, cooked, glutinous": 56205190,
    "Rice, wild, 100%, cooked, NS as to fat": 56205205,
    "Rice, wild, 100%, cooked, no added fat": 56205210,
    "Rice, wild, 100%, cooked, fat added": 56205215,
    "Rice, white and wild, cooked, no added fat": 56205300,
    "Rice, brown and wild, cooked, no added fat": 56205310,
    "Rice, white and wild, cooked, fat added": 56205320,
    "Rice, white and wild, cooked, NS as to fat": 56205330,
    "Rice, brown and wild, cooked, fat added": 56205340,
    "Rice, brown and wild, cooked, NS as to fat": 56205350,
    "Rice, white, cooked with fat, Puerto Rican style": 56205410,
    "Cream of wheat, NFS": 56206990,
    "Cream of wheat, regular or quick, made with water, no added fat": 56207016,
    "Cream of wheat, regular or quick, made with water, fat added": 56207017,
    "Cream of wheat, instant, made with water, no added fat": 56207030,
    "Cream of wheat, instant, made with water, fat added": 56207060,
    "Bulgur, no added fat": 56207110,
    "Bulgur, fat added": 56207120,
    "Bulgur, NS as to fat": 56207130,
    "Couscous, plain, cooked": 56207160,
    "Whole wheat cereal, cooked": 56207200,
    "Wheat cereal, chocolate flavored, cooked": 56207370,
    "Oat bran cereal, cooked": 56208500,
    "Cereal, ready-to-eat, NFS": 57100100,
    "Cereal, O's, flavored": 57103100,
    "Cereal, crunch": 57119000,
    "Cereal, O's, plain": 57123000,
    "Cereal, chocolate puffs": 57124200,
    "Cereal, cinnamon toast": 57125000,
    "Cereal, chocolate crispy": 57126000,
    "Cereal, corn squares": 57132000,
    "Cereal, corn flakes, plain": 57134000,
    "Cereal, corn puffs": 57137000,
    "Cereal, rice crispy, plain": 57151000,
    "Cereal, bran flakes, plain": 57207000,
    "Cereal, shredded wheat, flavored": 57214000,
    "Cereal, rice crispy, flavored": 57216000,
    "Cereal, fruit rings": 57221700,
    "Cereal, fruit crispy": 57223000,
    "Cereal, granola": 57227000,
    "Cereal, oat bunches": 57237100,
    "Cereal, corn squares, flavored": 57240100,
    "Cereal, O's, honey nut": 57241000,
    "Cereal, multigrain": 57301600,
    "Cereal, oat squares": 57304100,
    "Cereal, frosted oats with marshmallows": 57305150,
    "Cereal, O's, multigrain": 57308400,
    "Cereal, bran flakes, flavored": 57329000,
    "Cereal, rice squares": 57336000,
    "Cereal, K's, plain": 57344000,
    "Cereal, K's, flavored": 57344010,
    "Cereal, flavored puffs": 57347000,
    "Cereal, corn flakes, flavored": 57348000,
    "Cereal, O's, NFS": 57401100,
    "Cereal, wheat squares": 57411000,
    "Wheat germ": 57412000,
    "Cereal, plain puffs": 57416000,
    "Cereal, shredded wheat, plain": 57417000,
    "Cereal, wheat flakes": 57418000,
    "Cereal, other, NFS": 57420100,
    "Cereal, other, plain": 57420110,
    "Cereal, other, fruit flavored": 57420120,
    "Cereal, other, chocolate": 57420130,
    "Cereal, other, peanut butter": 57420140,
    "Cereal, other, honey": 57420150,
    "Cereal, reduced sugar": 57420160,
    "Wheat bran": 57601100,
    "Oats, raw": 57602100,
    "Cereal, oat bran, ready-to-eat": 57602500,
    "Baby Toddler cereal, barley, dry": 57801000,
    "Baby Toddler cereal, oatmeal, dry": 57804000,
    "Baby Toddler cereal, rice, dry": 57805000,
    "Baby Toddler cereal, rice with fruit, dry": 57805090,
    "Baby Toddler cereal, multigrain with fruit, dry": 57806000,
    "Baby Toddler cereal, multigrain, dry": 57806050,
    "Baby Toddler cereal, oatmeal with fruit, dry": 57806100,
    "Baby Toddler cereal, NFS": 57820000,
    "Baby Toddler cereal, rice, ready-to-eat": 57820100,
    "Baby Toddler cereal, oatmeal, ready-to-eat": 57820200,
    "Baby Toddler cereal, multigrain, ready-to-eat": 57820300,
    "Baby Toddler cereal, multigrain with fruit, ready-to-eat": 57822000,
    "Baby Toddler cereal, oatmeal with fruit, ready-to-eat": 57823000,
    "Baby Toddler cereal, rice with fruit, ready-to-eat": 57824000,
    "Chilaquiles": 58100360,
    "Frito pie": 58101830,
    "Mexican pizza": 58101835,
    "Taco or tostada salad with meat": 58101930,
    "Taco or tostada salad with chicken": 58101935,
    "Taco or tostada salad, meatless": 58101940,
    "Taco or tostada salad with meat and sour cream": 58101945,
    "Taco or tostada salad with chicken and sour cream": 58101950,
    "Taco or tostada salad, meatless with sour cream": 58101955,
    "Taco, NFS": 58102000,
    "Taco, corn tortilla, beef, cheese": 58102010,
    "Taco, corn tortilla, beef, with beans, cheese": 58102020,
    "Taco, corn tortilla, pork, cheese": 58102030,
    "Taco, corn tortilla, pork, with beans, cheese": 58102040,
    "Taco, corn tortilla, chicken, cheese": 58102050,
    "Taco, corn tortilla, chicken, with beans, cheese": 58102060,
    "Taco, corn tortilla, with beans, cheese": 58102070,
    "Taco, flour tortilla, beef, cheese": 58102110,
    "Taco, flour tortilla, beef, with beans, cheese": 58102120,
    "Taco, flour tortilla, pork, cheese": 58102130,
    "Taco, flour tortilla, pork, with beans, cheese": 58102140,
    "Taco, flour tortilla, chicken, cheese": 58102150,
    "Taco, flour tortilla, chicken, with beans, cheese": 58102160,
    "Taco, flour tortilla, with beans, cheese": 58102170,
    "Taco, fish": 58102210,
    "Taco, meat, no cheese": 58102220,
    "Taco, meat, with beans, no cheese": 58102230,
    "Taco, with beans, no cheese": 58102240,
    "Taco, cheese only": 58102250,
    "Burrito, NFS": 58102300,
    "Burrito, beef, cheese": 58102310,
    "Burrito, beef, with rice, cheese": 58102320,
    "Burrito, beef, with beans, cheese": 58102330,
    "Burrito, beef, with beans and rice, cheese": 58102340,
    "Burrito, pork, cheese": 58102410,
    "Burrito, pork, with rice, cheese": 58102420,
    "Burrito, pork, with beans, cheese": 58102430,
    "Burrito, pork, with beans and rice, cheese": 58102440,
    "Burrito, chicken, cheese": 58102510,
    "Burrito, chicken, with rice, cheese": 58102520,
    "Burrito, chicken, with beans, cheese": 58102530,
    "Burrito, chicken, with beans and rice, cheese": 58102540,
    "Burrito, with beans, no cheese": 58102605,
    "Burrito, with beans, cheese": 58102610,
    "Burrito, with beans and rice, cheese": 58102620,
    "Burrito, meat, no cheese": 58102630,
    "Burrito, meat, with rice, no cheese": 58102640,
    "Burrito, meat, with beans, no cheese": 58102650,
    "Burrito, meat, with beans and rice, no cheese": 58102660,
    "Burrito, cheese only": 58102680,
    "Burrito bowl, NFS": 58102700,
    "Burrito bowl, beef or pork": 58102710,
    "Burrito bowl, beef or pork, with rice": 58102720,
    "Burrito bowl, beef or pork, with beans": 58102730,
    "Burrito bowl, beef or pork, with beans and rice": 58102740,
    "Burrito bowl, chicken": 58102750,
    "Burrito bowl, chicken, with rice": 58102760,
    "Burrito bowl, chicken, with beans": 58102770,
    "Burrito bowl, chicken, with beans and rice": 58102780,
    "Burrito bowl, with beans": 58102790,
    "Enchilada, NFS": 58102800,
    "Enchilada, beef": 58102810,
    "Enchilada, pork": 58102820,
    "Enchilada, chicken": 58102830,
    "Enchilada, no meat": 58102840,
    "Tamale, NFS": 58103100,
    "Tamale, beef": 58103120,
    "Tamale, pork": 58103125,
    "Tamale, chicken": 58103130,
    "Tamale, no meat": 58103250,
    "Tamale casserole with meat": 58103310,
    "Nachos, NFS": 58104105,
    "Nachos, cheese only": 58104120,
    "Nachos, beef or pork": 58104130,
    "Nachos, beef or pork, with beans": 58104135,
    "Nachos, chicken": 58104150,
    "Nachos, chicken, with beans": 58104165,
    "Nachos, with beans": 58104170,
    "Gordita, cheese only": 58104255,
    "Gordita, with beans": 58104260,
    "Gordita, meat": 58104290,
    "Gordita, meat, with beans": 58104295,
    "Chimichanga, meat": 58104500,
    "Chimichanga, with beans": 58104520,
    "Chimichanga, chicken": 58104530,
    "Quesadilla, NFS": 58104700,
    "Quesadilla, cheese only": 58104710,
    "Quesadilla, beef or pork": 58104730,
    "Quesadilla, chicken": 58104740,
    "Quesadilla, with vegetables": 58104750,
    "Quesadilla, beef or pork, with vegetables": 58104760,
    "Quesadilla, chicken, with vegetables": 58104770,
    "Quesadilla, egg": 58104780,
    "Quesadilla, egg, with meat": 58104790,
    "Taquito, cheese only": 58104800,
    "Taquito, beef or pork": 58104825,
    "Taquito, chicken": 58104835,
    "Taquito, egg": 58104900,
    "Fajita, NFS": 58104990,
    "Fajita, chicken": 58105000,
    "Fajita, beef or pork": 58105050,
    "Fajita, shrimp": 58105060,
    "Fajita, vegetable": 58105075,
    "Pupusa, cheese only": 58105100,
    "Pupusa, with beans": 58105105,
    "Pupusa, meat": 58105110,
    "Pupusa, meat, with beans": 58105120,
    "Pizza, cheese, from frozen, thin crust": 58106200,
    "Pizza, cheese, from frozen, thick crust": 58106205,
    "Pizza, cheese, from restaurant or fast food, NS as to type of crust": 58106210,
    "Pizza, cheese, from restaurant or fast food, thin crust": 58106220,
    "Pizza, cheese, from restaurant or fast food, medium crust": 58106225,
    "Pizza, cheese, from restaurant or fast food, thick crust": 58106230,
    "Pizza, cheese, stuffed crust": 58106233,
    "Pizza, cheese, from school lunch, medium crust": 58106234,
    "Pizza, cheese, from school lunch, thin crust": 58106235,
    "Pizza, cheese, from school lunch, thick crust": 58106236,
    "Pizza, extra cheese, thin crust": 58106250,
    "Pizza, extra cheese, thick crust": 58106260,
    "Pizza, cheese, with vegetables, from frozen, thin crust": 58106300,
    "Pizza, cheese with vegetables, from frozen, thick crust": 58106305,
    "Pizza, cheese, with vegetables, from restaurant or fast food, thin crust": 58106320,
    "Pizza, cheese, with vegetables, from restaurant or fast food, medium crust": 58106325,
    "Pizza, cheese, with vegetables, from restaurant or fast food, thick crust": 58106330,
    "Pizza with cheese and extra vegetables, thin crust": 58106345,
    "Pizza with cheese and extra vegetables, medium crust": 58106347,
    "Pizza with cheese and extra vegetables, thick crust": 58106350,
    "Pizza, cheese, with fruit, thin crust": 58106358,
    "Pizza, cheese, with fruit, medium crust": 58106359,
    "Pizza, cheese, with fruit, thick crust": 58106360,
    "Pizza with pepperoni, from frozen, thin crust": 58106512,
    "Pizza with pepperoni, from frozen, medium crust": 58106514,
    "Pizza with pepperoni, from frozen, thick crust": 58106516,
    "Pizza with pepperoni, from restaurant or fast food, NS as to type of crust": 58106540,
    "Pizza with pepperoni, from restaurant or fast food, thin crust": 58106550,
    "Pizza with pepperoni, from restaurant or fast food,  medium crust": 58106555,
    "Pizza with pepperoni, from restaurant or fast food, thick crust": 58106560,
    "Pizza with pepperoni, stuffed crust": 58106565,
    "Pizza with pepperoni, from school lunch, thin crust": 58106570,
    "Pizza, with pepperoni, from school lunch, medium crust": 58106578,
    "Pizza with pepperoni, from school lunch, thick crust": 58106580,
    "Pizza with meat other than pepperoni, from frozen, thin crust": 58106602,
    "Pizza with meat other than pepperoni, from frozen, medium crust": 58106604,
    "Pizza with meat other than pepperoni, from frozen, thick crust": 58106606,
    "Pizza with meat other than pepperoni, from restaurant or fast food, NS as to type of crust": 58106610,
    "Pizza with meat other than pepperoni, from restaurant or fast food, thin crust": 58106620,
    "Pizza with meat other than pepperoni, from restaurant or fast food, medium crust": 58106625,
    "Pizza with meat other than pepperoni, from restaurant or fast food, thick crust": 58106630,
    "Pizza, with meat other than pepperoni, stuffed crust": 58106633,
    "Pizza, with meat other than pepperoni, from school lunch, medium crust": 58106634,
    "Pizza, with meat other than pepperoni, from school lunch, thin crust": 58106635,
    "Pizza, with meat other than pepperoni, from school lunch, thick crust": 58106636,
    "Pizza with extra meat, thin crust": 58106650,
    "Pizza with extra meat, medium crust": 58106655,
    "Pizza with extra meat, thick crust": 58106660,
    "Pizza with meat and vegetables, from frozen, thin crust": 58106700,
    "Pizza with meat and vegetables, from frozen, medium crust": 58106702,
    "Pizza with meat and vegetables, from frozen, thick crust": 58106705,
    "Pizza with meat and vegetables, from restaurant or fast food, thin crust": 58106720,
    "Pizza with meat and vegetables, from restaurant or fast food, medium crust": 58106725,
    "Pizza with meat and vegetables, from restaurant or fast food, thick crust": 58106730,
    "Pizza with extra meat and extra vegetables, thin crust": 58106736,
    "Pizza with extra meat and extra vegetables, thick crust": 58106737,
    "Pizza with extra meat and extra vegetables, medium crust": 58106738,
    "Pizza with meat and fruit, thin crust": 58106750,
    "Pizza with meat and fruit, medium crust": 58106755,
    "Pizza with meat and fruit, thick crust": 58106760,
    "Pizza with beans and vegetables, thin crust": 58106820,
    "Pizza with beans and vegetables, thick crust": 58106830,
    "Pizza, no cheese, thin crust": 58107050,
    "Pizza, no cheese, thick crust": 58107100,
    "White pizza, cheese, thin crust": 58107205,
    "White pizza, cheese, thick crust": 58107207,
    "White pizza, cheese, with vegetables, thin crust": 58107212,
    "White pizza, cheese, with vegetables, thick crust": 58107214,
    "White pizza, cheese, with meat, thin crust": 58107222,
    "White pizza, cheese, with meat, thick crust": 58107224,
    "White pizza, cheese, with meat and vegetables, thin crust": 58107232,
    "White pizza, cheese, with meat and vegetables, thick crust": 58107234,
    "Calzone, with cheese, meatless": 58108000,
    "Calzone, with meat and cheese": 58108010,
    "Pizza rolls": 58108050,
    "Pizza, cheese, whole wheat thin crust": 58109015,
    "Pizza, cheese, whole wheat thick crust": 58109020,
    "Pizza, with meat, whole wheat thin crust": 58109030,
    "Pizza, with meat, whole wheat thick crust": 58109040,
    "Pizza, cheese and vegetables, whole wheat thin crust": 58109050,
    "Pizza, cheese and vegetables, whole wheat thick crust": 58109060,
    "Pizza, cheese, gluten-free thin crust": 58109100,
    "Pizza, cheese, gluten-free thick crust": 58109110,
    "Pizza, with meat, gluten-free thin crust": 58109120,
    "Pizza, with meat, gluten-free thick crust": 58109130,
    "Pizza, cheese and vegetables, gluten-free thin crust": 58109140,
    "Pizza, cheese and vegetables, gluten-free thick crust": 58109150,
    "Breakfast pizza with egg": 58109210,
    "Egg roll, meatless": 58110110,
    "Egg roll, with shrimp": 58110120,
    "Egg roll, with beef and/or pork": 58110130,
    "Egg roll, with chicken or turkey": 58110170,
    "Roll with meat and/or shrimp, vegetables and rice paper, not fried": 58110200,
    "Wonton, dumpling or pot sticker, fried": 58111110,
    "Wonton, dumpling or pot sticker, fried, no meat": 58111120,
    "Puffs, fried, crab meat and cream cheese filled": 58111200,
    "Wonton, dumpling or pot sticker, steamed": 58112510,
    "Empanada, NFS": 58116100,
    "Empanada, no meat": 58116115,
    "Empanada, beef": 58116117,
    "Empanada, beef, with vegetables": 58116120,
    "Empanada, chicken": 58116125,
    "Empanada, chicken, with vegetables": 58116130,
    "Cornmeal stick, Puerto Rican style": 58117210,
    "Kibby, Puerto Rican style": 58117310,
    "Bacalaitos fritos": 58117410,
    "Hayacas, Puerto Rican style": 58117510,
    "Crepe, with meat": 58120110,
    "Pierogi": 58121610,
    "Gnocchi, cheese": 58122210,
    "Gnocchi, potato": 58122220,
    "Knish": 58122320,
    "Bao bun": 58123110,
    "Bao bun, no meat": 58123120,
    "Pastry, cheese-filled": 58124210,
    "Pastry, egg and cheese filled": 58124220,
    "Pastry, meat / poultry-filled": 58124230,
    "Spanakopita": 58124250,
    "Samosa": 58124500,
    "Quiche with meat, poultry or fish": 58125110,
    "Spinach quiche, meatless": 58125120,
    "Cheese quiche, meatless": 58125180,
    "Turnover or hot pocket, NFS": 58126100,
    "Turnover or hot pocket, meatless": 58126105,
    "Turnover or hot pocket, beef": 58126125,
    "Turnover or hot pocket, ham": 58126130,
    "Turnover or hot pocket, pizza pocket, meat": 58126150,
    "Turnover or hot pocket, pizza pocket": 58126160,
    "Turnover or hot pocket, chicken": 58126270,
    "Turnover, seafood": 58126275,
    "Turnover or lean pocket": 58126290,
    "Turnover or breakfast pocket, egg": 58126400,
    "Biscuit with gravy": 58128000,
    "Chicken cornbread": 58128110,
    "Cornmeal dressing with chicken or turkey and vegetables": 58128120,
    "Dressing with oysters": 58128210,
    "Dressing with chicken or turkey and vegetables": 58128220,
    "Dressing with meat and vegetables": 58128250,
    "Lasagna with meat": 58130011,
    "Lasagna with meat, canned": 58130013,
    "Lasagna with meat, from restaurant": 58130014,
    "Lasagna with meat, home recipe": 58130015,
    "Lasagna with meat, frozen": 58130016,
    "Lasagna with meat and spinach": 58130020,
    "Lasagna with chicken or turkey": 58130140,
    "Lasagna, with chicken or turkey, and spinach": 58130150,
    "Lasagna, meatless": 58130310,
    "Lasagna, meatless, with vegetables": 58130320,
    "Ravioli, NS as to filling, no sauce": 58131100,
    "Ravioli, NS as to filling, with tomato sauce": 58131110,
    "Ravioli, NS as to filling, with cream sauce": 58131120,
    "Ravioli, meat-filled, no sauce": 58131310,
    "Ravioli, meat-filled, with tomato sauce or meat sauce": 58131320,
    "Ravioli, meat-filled, with tomato sauce or meat sauce, canned": 58131323,
    "Ravioli, meat-filled, with cream sauce": 58131330,
    "Ravioli, cheese-filled, no sauce": 58131510,
    "Ravioli, cheese-filled, with tomato sauce": 58131520,
    "Ravioli, cheese-filled, with tomato sauce, canned": 58131523,
    "Ravioli, cheese-filled, with meat sauce": 58131530,
    "Ravioli, cheese-filled, with cream sauce": 58131535,
    "Ravioli, cheese and spinach-filled, no sauce": 58131590,
    "Ravioli, cheese and spinach-filled, with cream sauce": 58131600,
    "Ravioli, cheese and spinach filled, with tomato sauce": 58131610,
    "Manicotti, cheese-filled, no sauce": 58133110,
    "Manicotti, cheese-filled, with tomato sauce, meatless": 58133120,
    "Manicotti, cheese-filled, with meat sauce": 58133130,
    "Manicotti, vegetable- and cheese-filled, with tomato sauce, meatless": 58133140,
    "Stuffed shells, cheese-filled, no sauce": 58134110,
    "Stuffed shells, cheese-filled, with tomato sauce, meatless": 58134120,
    "Stuffed shells, cheese-filled, with meat sauce": 58134130,
    "Stuffed shells, cheese- and spinach- filled, no sauce": 58134160,
    "Stuffed shells, with chicken, with tomato sauce": 58134210,
    "Stuffed shells, with fish and/or shellfish, with tomato sauce": 58134310,
    "Tortellini, meat-filled, with tomato sauce": 58134610,
    "Tortellini, meat-filled, with tomato sauce, canned": 58134613,
    "Tortellini, cheese-filled, meatless, with tomato sauce": 58134620,
    "Tortellini, cheese-filled, meatless, with tomato sauce, canned": 58134623,
    "Tortellini, cheese-filled, meatless, with vinaigrette dressing": 58134640,
    "Tortellini, meat-filled, no sauce": 58134650,
    "Tortellini, cheese-filled, with cream sauce": 58134660,
    "Tortellini, cheese-filled, no sauce": 58134680,
    "Tortellini, spinach-filled, with tomato sauce": 58134710,
    "Tortellini, spinach-filled, no sauce": 58134720,
    "Cannelloni, cheese- and spinach-filled, no sauce": 58134810,
    "Chow fun noodles with meat and vegetables": 58135110,
    "Chow fun noodles with vegetables, meatless": 58135120,
    "Lo mein, NFS": 58136110,
    "Lo mein, meatless": 58136120,
    "Lo mein, with shrimp": 58136130,
    "Lo mein, with pork": 58136140,
    "Lo mein, with beef": 58136150,
    "Lo mein, with chicken": 58136160,
    "Pad Thai, NFS": 58137210,
    "Pad Thai, meatless": 58137220,
    "Pad Thai with chicken": 58137230,
    "Pad Thai with seafood": 58137240,
    "Pad Thai with meat": 58137250,
    "Adobo, with noodles": 58137300,
    "Spaghetti with corned beef, Puerto Rican style": 58140110,
    "Macaroni with tuna, Puerto Rican style": 58140310,
    "Macaroni or noodles with cheese": 58145110,
    "Macaroni or noodles with cheese, from restaurant": 58145111,
    "Macaroni or noodles with cheese, made from packaged mix": 58145112,
    "Macaroni or noodles with cheese, canned": 58145113,
    "Macaroni or noodles with cheese, Easy Mac type": 58145117,
    "Macaroni or noodles with cheese, made from reduced fat packaged mix": 58145119,
    "Macaroni or noodles with cheese and tuna": 58145120,
    "Macaroni or noodles with cheese and meat": 58145135,
    "Macaroni or noodles with cheese and meat, prepared from Hamburger Helper mix": 58145136,
    "Macaroni or noodles with cheese and tomato": 58145140,
    "Macaroni or noodles with cheese and frankfurters or hot dogs": 58145160,
    "Macaroni or noodles with cheese and egg": 58145170,
    "Macaroni or noodles with cheese and chicken or turkey": 58145190,
    "Macaroni or noodles with cheese, whole grain": 58145300,
    "Pasta with tomato-based sauce, cheese and meat": 58146120,
    "Pasta with tomato-based sauce and cheese": 58146150,
    "Pasta with vegetables, no sauce or dressing": 58146160,
    "Pasta with sauce, NFS": 58146210,
    "Pasta with sauce, meatless, school lunch": 58146215,
    "Pasta with tomato-based sauce, restaurant": 58146221,
    "Pasta with tomato-based sauce, home recipe": 58146222,
    "Pasta with tomato-based sauce, ready-to-heat": 58146223,
    "Pasta with tomato-based sauce, and added vegetables, restaurant": 58146301,
    "Pasta with tomato-based sauce, and added vegetables, home recipe": 58146302,
    "Pasta with tomato-based sauce, and added vegetables, ready-to-heat": 58146303,
    "Pasta with sauce and meat, from school lunch": 58146315,
    "Pasta with tomato-based sauce and meat, restaurant": 58146321,
    "Pasta with tomato-based sauce and meat, home recipe": 58146322,
    "Pasta with tomato-based sauce and meat, ready-to-heat": 58146323,
    "Pasta with tomato-based sauce, meat, and added vegetables, restaurant": 58146331,
    "Pasta with tomato-based sauce, meat, and added vegetables, home recipe": 58146332,
    "Pasta with tomato-based sauce, meat, and added vegetables, ready-to-heat": 58146333,
    "Pasta with tomato-based sauce and poultry, restaurant": 58146341,
    "Pasta with tomato-based sauce and poultry, home recipe": 58146342,
    "Pasta with tomato-based sauce and poultry, ready-to-heat": 58146343,
    "Pasta with tomato-based sauce, poultry, and added vegetables, restaurant": 58146351,
    "Pasta with tomato-based sauce, poultry, and added vegetables, home recipe": 58146352,
    "Pasta with tomato-based sauce, poultry, and added vegetables, ready-to-heat": 58146353,
    "Pasta with tomato-based sauce and seafood, restaurant": 58146361,
    "Pasta with tomato-based sauce and seafood, home recipe": 58146362,
    "Pasta with tomato-based sauce and seafood, ready-to-heat": 58146363,
    "Pasta with tomato-based sauce, seafood, and added vegetables, restaurant": 58146371,
    "Pasta with tomato-based sauce, seafood, and added vegetables, home recipe": 58146372,
    "Pasta with tomato-based sauce, seafood, and added vegetables, ready-to-heat": 58146373,
    "Pasta with cream sauce, restaurant": 58146381,
    "Pasta with cream sauce, home recipe": 58146382,
    "Pasta with cream sauce, ready-to-heat": 58146383,
    "Pasta with cream sauce and added vegetables, restaurant": 58146391,
    "Pasta with cream sauce and added vegetables, from home recipe": 58146392,
    "Pasta with cream sauce and added vegetables, ready-to-heat": 58146393,
    "Pasta with cream sauce and meat, restaurant": 58146401,
    "Pasta with cream sauce and meat, home recipe": 58146402,
    "Pasta with cream sauce and meat, ready-to-heat": 58146403,
    "Pasta with cream sauce, meat, and added vegetables, restaurant": 58146411,
    "Pasta with cream sauce, meat, and added vegetables, home recipe": 58146412,
    "Pasta with cream sauce, meat, and added vegetables, ready-to-heat": 58146413,
    "Pasta with cream sauce and poultry, restaurant": 58146421,
    "Pasta with cream sauce and poultry, home recipe": 58146422,
    "Pasta with cream sauce and poultry, ready-to-heat": 58146423,
    "Pasta with cream sauce, poultry, and added vegetables, restaurant": 58146431,
    "Pasta with cream sauce, poultry, and added vegetables, home recipe": 58146432,
    "Pasta with cream sauce, poultry, and added vegetables, ready-to-heat": 58146433,
    "Pasta with cream sauce and seafood, restaurant": 58146441,
    "Pasta with cream sauce and seafood, home recipe": 58146442,
    "Pasta with cream sauce and seafood, ready-to-heat": 58146443,
    "Pasta with cream sauce, seafood, and added vegetables, restaurant": 58146451,
    "Pasta with cream sauce, seafood, and added vegetables, home recipe": 58146452,
    "Pasta with cream sauce, seafood, and added vegetables, ready-to-heat": 58146453,
    "Pasta, whole grain, with tomato-based sauce, restaurant": 58146601,
    "Pasta, whole grain, with tomato-based sauce, home recipe": 58146602,
    "Pasta, whole grain, with tomato-based sauce, ready-to-heat": 58146603,
    "Pasta, whole grain, with tomato-based sauce and added vegetables, restaurant": 58146611,
    "Pasta, whole grain, with tomato-based sauce and added vegetables, home recipe": 58146612,
    "Pasta, whole grain, with tomato-based sauce and added vegetables, ready-to-heat": 58146613,
    "Pasta, whole grain, with tomato-based sauce and meat, restaurant": 58146621,
    "Pasta, whole grain, with tomato-based sauce and meat, home recipe": 58146622,
    "Pasta, whole grain, with tomato-based sauce and meat, ready-to-heat": 58146623,
    "Pasta, whole grain, with tomato-based sauce, meat, and added vegetables, restaurant": 58146631,
    "Pasta, whole grain, with tomato-based sauce, meat, and added vegetables, home recipe": 58146632,
    "Pasta, whole grain, with tomato-based sauce, meat, and added vegetables, ready-to-heat": 58146633,
    "Pasta, whole grain, with tomato-based sauce and poultry, restaurant": 58146641,
    "Pasta, whole grain, with tomato-based sauce and poultry, home recipe": 58146642,
    "Pasta, whole grain, with tomato-based sauce and poultry, ready-to-heat": 58146643,
    "Pasta, whole grain, with tomato-based sauce, poultry, and added vegetables, restaurant": 58146651,
    "Pasta, whole grain, with tomato-based sauce, poultry, and added vegetables, home recipe": 58146652,
    "Pasta, whole grain, with tomato-based sauce, poultry, and added vegetables, ready-to-heat": 58146653,
    "Pasta, whole grain, with tomato-based sauce and seafood, restaurant": 58146661,
    "Pasta, whole grain, with tomato-based sauce and seafood, home recipe": 58146662,
    "Pasta, whole grain, with tomato-based sauce and seafood, ready-to-heat": 58146663,
    "Pasta, whole grain, with tomato-based sauce, seafood, and added vegetables, restaurant": 58146671,
    "Pasta, whole grain, with tomato-based sauce, seafood, and added vegetables, home recipe": 58146672,
    "Pasta, whole grain, with tomato-based sauce, seafood, and added vegetables, ready-to-heat": 58146673,
    "Pasta, whole grain, with cream sauce, restaurant": 58146681,
    "Pasta, whole grain, with cream sauce, home recipe": 58146682,
    "Pasta, whole grain, with cream sauce, ready-to-heat": 58146683,
    "Pasta, whole grain, with cream sauce, and added vegetables, restaurant": 58146691,
    "Pasta, whole grain, with cream sauce, and added vegetables, home recipe": 58146692,
    "Pasta, whole grain, with cream sauce, and added vegetables, ready-to-heat": 58146693,
    "Pasta, whole grain, with cream sauce and meat, restaurant": 58146701,
    "Pasta, whole grain, with cream sauce and meat, home recipe": 58146702,
    "Pasta, whole grain, with cream sauce and meat, ready-to-heat": 58146703,
    "Pasta, whole grain, with cream sauce, meat, and added vegetables, restaurant": 58146711,
    "Pasta, whole grain, with cream sauce, meat, and added vegetables, home recipe": 58146712,
    "Pasta, whole grain, with cream sauce, meat, and added vegetables, ready-to-heat": 58146713,
    "Pasta, whole grain, with cream sauce and poultry, restaurant": 58146721,
    "Pasta, whole grain, with cream sauce and poultry, home recipe": 58146722,
    "Pasta, whole grain, with cream sauce and poultry, ready-to-heat": 58146723,
    "Pasta, whole grain, with cream sauce, poultry, and added vegetables, restaurant": 58146731,
    "Pasta, whole grain, with cream sauce, poultry, and added vegetables, home recipe": 58146732,
    "Pasta, whole grain, with cream sauce, poultry, and added vegetables, ready-to-heat": 58146733,
    "Pasta, whole grain, with cream sauce and seafood, restaurant": 58146741,
    "Pasta, whole grain, with cream sauce and seafood, home recipe": 58146742,
    "Pasta, whole grain, with cream sauce and seafood, ready-to-heat": 58146743,
    "Pasta, whole grain, with cream sauce, seafood, and added vegetables, restaurant": 58146751,
    "Pasta, whole grain, with cream sauce, seafood, and added vegetables, home recipe": 58146752,
    "Pasta, whole grain, with cream sauce, seafood, and added vegetables, ready-to-heat": 58146753,
    "Pasta with tomato-based sauce and beans or lentils": 58147110,
    "Macaroni or noodles, creamed, with cheese": 58147330,
    "Macaroni or noodles, creamed, with cheese and tuna": 58147340,
    "Flavored pasta": 58147510,
    "Yat Ga Mein with meat, fish, or poultry": 58147520,
    "Macaroni or pasta salad, made with mayonnaise": 58148110,
    "Macaroni or pasta salad, made with light mayonnaise": 58148111,
    "Macaroni or pasta salad, made with mayonnaise-type salad dressing": 58148112,
    "Macaroni or pasta salad, made with light mayonnaise-type salad dressing": 58148113,
    "Macaroni or pasta salad, made with Italian dressing": 58148114,
    "Macaroni or pasta salad, made with light Italian dressing": 58148115,
    "Macaroni or pasta salad, made with creamy dressing": 58148116,
    "Macaroni or pasta salad, made with light creamy dressing": 58148117,
    "Macaroni or pasta salad, made with any type of fat free dressing": 58148118,
    "Macaroni or pasta salad with egg": 58148120,
    "Macaroni or pasta salad with tuna": 58148130,
    "Macaroni or pasta salad with crab meat": 58148140,
    "Macaroni or pasta salad with shrimp": 58148150,
    "Macaroni or pasta salad with tuna and egg": 58148160,
    "Macaroni or pasta salad with chicken": 58148170,
    "Macaroni or pasta salad with cheese": 58148180,
    "Macaroni or pasta salad with meat": 58148550,
    "Noodle pudding": 58149110,
    "Bibimbap, Korean": 58150100,
    "Rice, fried, meatless": 58150110,
    "Rice, fried, NFS": 58150310,
    "Rice, fried, with chicken": 58150320,
    "Rice, fried, with pork": 58150330,
    "Rice, fried, with beef": 58150340,
    "Rice, fried, with shrimp": 58150510,
    "Dukboki or Tteokbokki, Korean": 58150520,
    "Adobo, with rice": 58150530,
    "Sushi, NFS": 58151100,
    "Sushi roll, avocado": 58151170,
    "Sushi roll, California": 58151180,
    "Sushi roll, eel": 58151190,
    "Sushi roll, salmon": 58151200,
    "Sushi roll, shrimp": 58151210,
    "Sushi roll tuna": 58151220,
    "Sushi roll, vegetable": 58151230,
    "Sushi, topped with crab": 58151400,
    "Sushi, topped with eel": 58151410,
    "Sushi, topped with salmon": 58151420,
    "Sushi, topped with shrimp": 58151430,
    "Sushi, topped with tuna": 58151440,
    "Sushi, topped with egg": 58151450,
    "Rice with chicken, Puerto Rican style": 58155110,
    "Paella with meat, Valenciana style": 58155310,
    "Seafood paella, Puerto Rican style": 58155320,
    "Soupy rice with chicken, Puerto Rican style": 58155410,
    "Soupy rice mixture with chicken and potatoes, Puerto Rican style": 58155510,
    "Stewed rice, Puerto Rican style": 58155810,
    "Rice with squid, Puerto Rican style": 58155910,
    "Fried rice, Puerto Rican style": 58156110,
    "Rice with vienna sausage, Puerto Rican style": 58156210,
    "Rice with Spanish sausage, Puerto Rican style": 58156310,
    "Rice with onions, Puerto Rican style": 58156410,
    "Rice with stewed beans, Puerto Rican style": 58156710,
    "Congee, with meat, poultry, and/or seafood": 58157300,
    "Congee, with meat, poultry, and/or seafood, and vegetables": 58157310,
    "Congee, with vegetables": 58157320,
    "Biryani with vegetables": 58160000,
    "Beans and rice, from fast food / restaurant": 58160100,
    "Kidney beans and rice, from fast food / restaurant": 58160102,
    "Black beans and rice, from fast food / restaurant": 58160104,
    "Pinto beans and rice, from fast food / restaurant": 58160106,
    "Beans and white rice": 58160110,
    "Beans and rice, with tomatoes": 58160120,
    "Beans and rice, with meat": 58160132,
    "Kidney beans and white rice": 58160150,
    "Black beans and white rice": 58160154,
    "Pinto beans and white rice": 58160156,
    "Rice, white, with corn, NS as to fat": 58160400,
    "Rice, white, with corn, no added fat": 58160410,
    "Rice, white, with corn, fat added": 58160420,
    "Rice, white, with peas, NS as to fat": 58160430,
    "Rice, white, with peas, no added fat": 58160440,
    "Rice, white, with peas, fat added": 58160450,
    "Rice, white, with carrots, NS as to fat": 58160460,
    "Rice, white, with carrots, no added fat": 58160470,
    "Rice, white, with carrots, fat added": 58160480,
    "Rice, white, with peas and carrots, NS as to fat": 58160490,
    "Rice, white, with peas and carrots, no added fat": 58160500,
    "Rice, white, with peas and carrots, fat added": 58160510,
    "Rice, white, with tomatoes and/or tomato-based sauce, NS as to fat": 58160520,
    "Rice, white, with tomatoes and/or tomato-based sauce, no added fat": 58160530,
    "Rice, white, with tomatoes and/or tomato-based sauce, fat added": 58160540,
    "Rice, white, with dark green vegetables, NS as to fat": 58160550,
    "Rice, white, with dark green vegetables, no added fat": 58160560,
    "Rice, white, with dark green vegetables, fat added": 58160570,
    "Rice, white, with carrots and tomatoes and/or tomato-based sauce, NS as to fat": 58160580,
    "Rice, white, with carrots and tomatoes and/or tomato-based sauce, no added fat": 58160590,
    "Rice, white, with carrots and tomatoes and/or tomato-based sauce, fat added": 58160600,
    "Rice, white, with dark green vegetables and tomatoes and/or tomato-based sauce, NS as to fat": 58160610,
    "Rice, white, with dark green vegetables and tomatoes and/or tomato-based sauce, no added fat": 58160620,
    "Rice, white, with dark green vegetables and tomatoes and/or tomato-based sauce, fat added": 58160630,
    "Rice, white, with carrots and dark green vegetables, NS as to fat": 58160640,
    "Rice, white, with carrots and dark green vegetables, no added fat": 58160650,
    "Rice, white, with carrots and dark green vegetables, fat added": 58160660,
    "Rice, white, with carrots, dark green vegetables, and tomatoes and/or tomato-based sauce, NS as to fat": 58160670,
    "Rice, white, with carrots, dark green vegetables, and tomatoes and/or tomato-based sauce, no added fat": 58160680,
    "Rice, white, with carrots, dark green vegetables, and tomatoes and/or tomato-based sauce, fat added": 58160690,
    "Rice, white, with other vegetables, NS as to fat": 58160700,
    "Rice, white, with other vegetables, no added fat": 58160710,
    "Rice, white, with other vegetables, fat added": 58160720,
    "Rice, white, with lentils, NS as to fat": 58160800,
    "Rice, white, with lentils, fat added": 58160805,
    "Rice, white, with lentils, no added fat": 58160810,
    "Rice, cooked with coconut milk": 58161200,
    "Beans and brown rice": 58161320,
    "Kidney beans and brown rice": 58161321,
    "Black beans and brown rice": 58161322,
    "Pinto beans and brown rice": 58161323,
    "Rice, brown, with corn, NS as to fat": 58161420,
    "Rice, brown, with corn, no added fat": 58161422,
    "Rice, brown, with corn, fat added": 58161424,
    "Rice, brown, with peas, NS as to fat": 58161430,
    "Rice, brown, with peas, no added fat": 58161432,
    "Rice, brown, with peas, fat added": 58161434,
    "Rice, brown, with carrots, NS as to fat": 58161435,
    "Rice, brown, with carrots, no added fat": 58161437,
    "Rice, brown, with carrots, fat added": 58161439,
    "Rice, brown, with peas and carrots, NS as to fat": 58161440,
    "Rice, brown, with peas and carrots, no added fat": 58161442,
    "Rice, brown, with peas and carrots, fat added": 58161444,
    "Rice, brown, with tomatoes and/or tomato based sauce, NS as to fat": 58161460,
    "Rice, brown, with tomatoes and/or tomato based sauce, no added fat": 58161462,
    "Rice, brown, with tomatoes and/or tomato based sauce, fat added": 58161464,
    "Rice, brown, with dark green vegetables, NS as to fat": 58161470,
    "Rice, brown, with dark green vegetables, no added fat": 58161472,
    "Rice, brown, with dark green vegetables, fat added": 58161474,
    "Rice, brown, with carrots and tomatoes and/or tomato-based sauce, NS as to fat": 58161480,
    "Rice, brown, with carrots and tomatoes and/or tomato-based sauce, no added fat": 58161482,
    "Rice, brown, with carrots and tomatoes and/or tomato-based sauce, fat added": 58161484,
    "Rice, brown, with dark green vegetables and tomatoes and/or tomato-based sauce, NS as to fat": 58161490,
    "Rice, brown, with dark green vegetables and tomatoes and/or tomato-based sauce, no added fat": 58161492,
    "Rice, brown, with dark green vegetables and tomatoes and/or tomato-based sauce, fat added": 58161494,
    "Rice, brown, with carrots and dark green vegetables, NS as to fat": 58161500,
    "Rice, brown, with carrots and dark green vegetables, no added fat": 58161502,
    "Rice, brown, with carrots and dark green vegetables, fat added": 58161504,
    "Grape leaves stuffed with rice": 58161510,
    "Rice, brown, with carrots, dark green vegetables, and tomatoes and/or tomato-based sauce, NS as to fat": 58161520,
    "Rice, brown, with carrots, dark green vegetables, and tomatoes and/or tomato-based sauce, no added fat": 58161522,
    "Rice, brown, with carrots, dark green vegetables, and tomatoes and/or tomato-based sauce, fat added": 58161524,
    "Rice, brown, with other vegetables, NS as to fat": 58161530,
    "Rice, brown, with other vegetables, no added fat": 58161532,
    "Rice, brown, with other vegetables, fat added": 58161534,
    "Rice croquette": 58161710,
    "Stuffed pepper, with meat": 58162090,
    "Stuffed pepper, with rice and meat": 58162110,
    "Stuffed pepper, with rice, meatless": 58162120,
    "Stuffed tomato, with rice and meat": 58162130,
    "Stuffed tomato, with rice, meatless": 58162140,
    "Rice pilaf": 58162310,
    "Dirty rice": 58163130,
    "Flavored rice mixture": 58163310,
    "Flavored rice mixture with cheese": 58163330,
    "Flavored rice, brown and wild": 58163360,
    "Flavored rice and pasta mixture": 58163380,
    "Flavored rice and pasta mixture, reduced sodium": 58163400,
    "Spanish rice, from restaurant": 58163405,
    "Spanish rice, fat added": 58163410,
    "Spanish rice, no added fat": 58163420,
    "Spanish rice, NS as to fat": 58163430,
    "Spanish rice with ground beef": 58163450,
    "Rice dressing": 58163510,
    "Rice with raisins": 58164110,
    "Rice dessert or salad with fruit": 58164210,
    "Rice, white, with cheese and/or cream based sauce, NS as to fat": 58164500,
    "Rice, white, with cheese and/or cream based sauce, no added fat": 58164510,
    "Rice, white, with cheese and/or cream based sauce, fat added": 58164520,
    "Rice, white, with gravy, NS as to fat": 58164530,
    "Rice, white, with gravy, no added fat": 58164540,
    "Rice, white, with gravy, fat added": 58164550,
    "Rice, white, with soy-based sauce, NS as to fat": 58164560,
    "Rice, white, with soy-based sauce, no added fat": 58164570,
    "Rice, white, with soy-based sauce, fat added": 58164580,
    "Rice, brown, with cheese and/or cream based sauce, NS as to fat": 58164800,
    "Rice, brown, with cheese and/or cream based sauce, no added fat": 58164810,
    "Rice, brown, with cheese and/or cream based sauce, fat added": 58164820,
    "Rice, brown, with gravy, NS as to fat": 58164830,
    "Rice, brown, with gravy, no added fat": 58164840,
    "Rice, brown, with gravy, fat added": 58164850,
    "Rice, brown, with soy-based sauce, NS as to fat": 58164860,
    "Rice, brown, with soy-based sauce, no added fat": 58164870,
    "Rice, brown, with soy-based sauce, fat added": 58164880,
    "Rice, white, with vegetables, cheese and/or cream based sauce, NS as to fat": 58165000,
    "Rice, white, with vegetables, cheese and/or cream based sauce, no added fat": 58165010,
    "Rice, white, with vegetables, cheese and/or cream based sauce, fat added": 58165020,
    "Rice, white, with vegetables and gravy, NS as to fat": 58165030,
    "Rice, white, with vegetables and gravy, no added fat": 58165040,
    "Rice, white, with vegetables and gravy, fat added": 58165050,
    "Rice, white, with vegetables, soy-based sauce, NS as to fat": 58165060,
    "Rice, white, with vegetables, soy-based sauce, no added fat": 58165070,
    "Rice, white, with vegetables, soy-based sauce, fat added": 58165080,
    "Rice, brown, with vegetables, cheese and/or cream based sauce, NS as to fat": 58165400,
    "Rice, brown, with vegetables, cheese and/or cream based sauce, no added fat": 58165410,
    "Rice, brown, with vegetables, cheese and/or cream based sauce, fat added": 58165420,
    "Rice, brown, with vegetables and gravy, NS as to fat": 58165430,
    "Rice, brown, with vegetables and gravy, no added fat": 58165440,
    "Rice, brown, with vegetables and gravy, fat added": 58165450,
    "Rice, brown, with vegetables, soy-based sauce, NS as to fat": 58165460,
    "Rice, brown, with vegetables, soy-based sauce, no added fat": 58165470,
    "Rice, brown, with vegetables, soy-based sauce, fat added": 58165480,
    "Upma": 58174000,
    "Dosa, with filling": 58174100,
    "Vada": 58175000,
    "Tabbouleh": 58175110,
    "Vegetable sandwich on white": 58200210,
    "Vegetable sandwich on white, with cheese": 58200220,
    "Vegetable sandwich on wheat": 58200230,
    "Vegetable sandwich on wheat, with cheese": 58200240,
    "Vegetable sandwich wrap": 58200250,
    "Jelly sandwich, NFS": 58201000,
    "Jelly sandwich, on white bread": 58201005,
    "Jelly sandwich, on wheat bread": 58201015,
    "Noodles with vegetables in tomato-based sauce, diet frozen meal": 58302080,
    "Spaghetti and meatballs dinner, NFS, frozen meal": 58304010,
    "Spaghetti with meat sauce, diet frozen meal": 58304060,
    "Pancakes and sausage, frozen meal": 58310310,
    "Soup, NFS": 58400000,
    "Noodle soup, NFS": 58400100,
    "Rice soup, NFS": 58400200,
    "Barley soup, home recipe, canned, or ready-to-serve": 58401010,
    "Beef noodle soup, canned or ready-to-serve": 58402010,
    "Beef noodle soup, home recipe": 58402100,
    "Chicken or turkey noodle soup, canned or ready-to-serve": 58403010,
    "Chicken or turkey noodle soup, home recipe": 58403040,
    "Chicken or turkey noodle soup, cream of, home recipe, canned, or ready-to-serve": 58403050,
    "Chicken or turkey noodle soup, reduced sodium, canned or ready-to-serve": 58403060,
    "Chicken or turkey rice soup, canned, or ready-to-serve": 58404010,
    "Chicken or turkey rice soup, home recipe": 58404030,
    "Chicken or turkey rice soup, reduced sodium, canned, prepared with water or ready-to-serve": 58404040,
    "Matzo ball soup": 58404500,
    "Chicken or turkey soup with dumplings and potatoes, home recipe, canned, or ready-to-serve": 58404510,
    "Chicken or turkey soup with dumplings, home recipe, canned or ready-to-serve": 58404520,
    "Instant soup, noodle": 58407010,
    "Soup, mostly noodles": 58407030,
    "Soup, mostly noodles, reduced sodium": 58407035,
    "Wonton soup": 58408010,
    "Noodle soup with vegetables, Asian style": 58408500,
    "Sopa Seca de Fideo, Mexican style, made with dry noodles, home recipe": 58421010,
    "Sopa de Fideo Aguada, Mexican style noodle soup, home recipe": 58421020,
    "Sopa seca de arroz, home recipe, Mexican style": 58421060,
    "Sopa de tortilla, Mexican style tortilla soup, home recipe": 58421080,
    "Meat substitute, cereal- and vegetable protein-based, fried": 59003000,
    "Clementine, raw": 61100600,
    "Grapefruit, raw": 61101010,
    "Grapefruit, canned": 61101200,
    "Kumquat, raw": 61110010,
    "Lemon, raw": 61113010,
    "Lemon pie filling": 61113500,
    "Lime, raw": 61116010,
    "Orange, raw": 61119010,
    "Orange, canned, NFS": 61122300,
    "Orange, canned, juice pack": 61122320,
    "Orange, canned, in syrup": 61122330,
    "Tangerine, raw": 61125010,
    "Grapefruit juice, 100%, freshly squeezed": 61201010,
    "Grapefruit juice, 100%, NS as to form": 61201020,
    "Grapefruit juice, 100%, canned, bottled or in a carton": 61201220,
    "Grapefruit juice, 100%, with calcium added": 61201225,
    "Grapefruit juice,100%, frozen, reconstituted": 61201620,
    "Lemon juice, 100%, NS as to form": 61204000,
    "Lemon juice, 100%, freshly squeezed": 61204010,
    "Lemon juice, 100%, canned or bottled": 61204200,
    "Lime juice, 100%, NS as to form": 61207000,
    "Lime juice, 100%, freshly squeezed": 61207010,
    "Lime juice, 100%, canned or bottled": 61207200,
    "Orange juice, 100%, NFS": 61210000,
    "Orange juice, 100%,  freshly squeezed": 61210010,
    "Orange juice, 100%, canned, bottled or in a carton": 61210220,
    "Orange juice, 100%, with calcium added, canned, bottled or in a carton": 61210250,
    "Orange juice, 100%, frozen, reconstituted": 61210620,
    "Orange juice, 100%, frozen, not reconstituted": 61210720,
    "Orange juice, 100%, with calcium added, frozen, reconstituted": 61210820,
    "Tangerine juice, 100%": 61213220,
    "Fruit juice blend, citrus, 100% juice": 61213800,
    "Fruit mixture, dried": 62101050,
    "Apple, dried": 62101100,
    "Apricot, dried": 62104100,
    "Blueberries, dried": 62105000,
    "Cherries, dried": 62106000,
    "Banana chips": 62107200,
    "Currants, dried": 62108100,
    "Cranberries, dried": 62109100,
    "Date": 62110100,
    "Fig, dried": 62113100,
    "Mango, dried": 62114050,
    "Papaya, dried": 62114110,
    "Peach, dried": 62116100,
    "Pear, dried": 62119100,
    "Persimmon, dried": 62120000,
    "Pineapple, dried": 62120100,
    "Prune, dried": 62122100,
    "Raisins": 62125100,
    "Tamarind, dried": 62126000,
    "Fruit, NFS": 63100100,
    "Fruit, pickled": 63100110,
    "Apple, raw": 63101000,
    "Applesauce, regular": 63101110,
    "Applesauce, unsweetened": 63101120,
    "Applesauce, flavored": 63101150,
    "Apple pie filling": 63101210,
    "Apple, baked": 63101310,
    "Apricot, raw": 63103010,
    "Apricot, canned": 63103110,
    "Avocado, raw": 63105010,
    "Banana, raw": 63107010,
    "Banana, fried": 63107410,
    "Cantaloupe, raw": 63109010,
    "Starfruit, raw": 63109700,
    "Cherries, maraschino": 63111010,
    "Cherry pie filling": 63113030,
    "Cherries, raw": 63115010,
    "Cherries, canned": 63115110,
    "Cherries, frozen": 63115200,
    "Dragon fruit": 63116010,
    "Fig, raw": 63119010,
    "Fig, canned": 63119110,
    "Grapes, raw": 63123000,
    "Guava, raw": 63125010,
    "Kiwi fruit, raw": 63126500,
    "Lychee": 63126510,
    "Honeydew melon, raw": 63127010,
    "Mango, raw": 63129010,
    "Mango, canned": 63129030,
    "Mango, frozen": 63129050,
    "Nectarine, raw": 63131010,
    "Papaya, raw": 63133010,
    "Papaya, canned": 63133100,
    "Passion fruit, raw": 63134010,
    "Peach, raw": 63135010,
    "Peach, canned, NFS": 63135110,
    "Peach, canned, in syrup": 63135140,
    "Peach, canned, juice pack": 63135170,
    "Peach, frozen": 63135620,
    "Pear, raw": 63137010,
    "Pear, Asian, raw": 63137050,
    "Pear, canned, NFS": 63137110,
    "Pear, canned, in syrup": 63137140,
    "Pear, canned, juice pack": 63137170,
    "Persimmon, raw": 63139010,
    "Pineapple, raw": 63141010,
    "Pineapple, canned, NFS": 63141110,
    "Pineapple, canned, in syrup": 63141140,
    "Pineapple, canned, juice pack": 63141170,
    "Pineapple, frozen": 63141200,
    "Plum, raw": 63143010,
    "Plum, canned": 63143110,
    "Pomegranate, raw": 63145010,
    "Rhubarb": 63147110,
    "Tamarind": 63148750,
    "Watermelon, raw": 63149010,
    "Berries, NFS": 63200100,
    "Blackberries, raw": 63201010,
    "Blackberries, frozen": 63201600,
    "Blueberries, raw": 63203010,
    "Bluberries, canned": 63203110,
    "Blueberries, frozen": 63203600,
    "Blueberry pie filling": 63203700,
    "Cranberries, raw": 63207010,
    "Cranberry sauce": 63207110,
    "Raspberries, raw": 63219000,
    "Raspberries, frozen": 63219610,
    "Strawberries, raw": 63223020,
    "Strawberries, canned": 63223110,
    "Strawberries, frozen": 63223610,
    "Ambrosia": 63301010,
    "Fruit salad, fresh or raw,  excluding citrus fruits, no dressing": 63311000,
    "Fruit salad, fresh or raw, including citrus fruits, no dressing": 63311050,
    "Fruit cocktail, canned, NFS": 63311110,
    "Fruit cocktail, canned, in syrup": 63311140,
    "Fruit cocktail, canned, juice pack": 63311170,
    "Fruit mixture, frozen": 63311180,
    "Apple salad with dressing": 63401010,
    "Apple, candied": 63401060,
    "Fruit, chocolate covered": 63401070,
    "Fruit salad, excluding citrus fruits, with salad dressing or mayonnaise": 63402950,
    "Fruit salad, excluding citrus fruits, with whipped cream": 63402960,
    "Fruit salad, excluding citrus fruits, with nondairy whipped topping": 63402970,
    "Fruit salad, excluding citrus fruits, with marshmallows": 63402980,
    "Fruit salad, including citrus fruits, with pudding": 63402990,
    "Fruit salad, excluding citrus fruits, with pudding": 63403000,
    "Fruit salad, including citrus fruits, with salad dressing or mayonnaise": 63403010,
    "Fruit salad, including citrus fruit, with whipped cream": 63403020,
    "Fruit salad, including citrus fruits, with nondairy whipped topping": 63403030,
    "Fruit salad, including citrus fruits, with marshmallows": 63403040,
    "Lime souffle": 63403150,
    "Guacamole, NFS": 63409010,
    "Guacamole with tomatoes": 63409015,
    "Chutney": 63409020,
    "Pineapple salad with dressing": 63413010,
    "Soup, fruit": 63415100,
    "Frozen fruit juice bar": 63420105,
    "Frozen fruit juice bar, no sugar added": 63420205,
    "Sorbet": 63430150,
    "Fruit juice, NFS": 64100100,
    "Fruit juice blend, 100% juice": 64100110,
    "Cranberry juice blend, 100% juice": 64100200,
    "Cranberry juice blend, 100% juice, with calcium added": 64100220,
    "Apple cider": 64101010,
    "Apple juice, 100%": 64104010,
    "Apple juice, 100%, with calcium added": 64104030,
    "Blackberry juice, 100%": 64104600,
    "Blueberry juice": 64104610,
    "Cranberry juice, 100%, not a blend": 64105400,
    "Grape juice, 100%": 64116020,
    "Grape juice, 100%, with calcium added": 64116060,
    "Papaya juice, 100%": 64120010,
    "Passion fruit juice, 100%": 64121000,
    "Pineapple juice, 100%": 64124020,
    "Pomegranate juice, 100%": 64126000,
    "Prune juice, 100%": 64132010,
    "Strawberry juice, 100%": 64132500,
    "Watermelon juice, 100%": 64133100,
    "Fruit smoothie, with whole fruit, no dairy": 64134015,
    "Fruit smoothie, with whole fruit, no dairy, added protein": 64134020,
    "Fruit smoothie, with whole fruit, non-dairy": 64134025,
    "Fruit smoothie juice drink, no dairy": 64134030,
    "Fruit smoothie, light": 64134100,
    "Fruit smoothie, bottled": 64134200,
    "Fruit nectar, NFS": 64200100,
    "Apricot nectar": 64201010,
    "Banana nectar": 64201500,
    "Cantaloupe nectar": 64202010,
    "Guava nectar": 64203020,
    "Mango nectar": 64204010,
    "Peach nectar": 64205010,
    "Papaya nectar": 64210010,
    "Passion fruit nectar": 64213010,
    "Pear nectar": 64215010,
    "Soursop, nectar": 64221010,
    "Vinegar": 64401000,
    "Baby Toddler fruit, NFS": 67100000,
    "Baby Toddler multiple fruit, Stage 2": 67100100,
    "Baby Toddler multiple fruit, Stage 3": 67100105,
    "Baby Toddler fruit, with grain": 67100250,
    "Baby Toddler fruit, with yogurt": 67100260,
    "Baby Toddler fruit and vegetables, Stage 2": 67100350,
    "Baby Toddler fruit and vegetables, Stage 3": 67100360,
    "Baby Toddler fruit and vegetables, with grain": 67100450,
    "Baby Toddler fruit and vegetables, with yogurt": 67100460,
    "Baby Toddler fruit, vegetables, and meat": 67100470,
    "Baby Toddler fruit and meat": 67100480,
    "Baby Toddler apples, Stage 1": 67102010,
    "Baby Toddler apples, Stage 2": 67102030,
    "Baby Toddler bananas, Stage 1": 67105030,
    "Baby Toddler bananas, Stage 2": 67105040,
    "Baby Toddler peaches, Stage 1": 67108010,
    "Baby Toddler peaches, Stage 2": 67108040,
    "Baby Toddler pears, Stage 1": 67109010,
    "Baby Toddler pears, Stage 2": 67109040,
    "Baby Toddler prunes": 67110000,
    "Baby Toddler mangoes": 67110100,
    "Baby Toddler juice, NFS": 67201000,
    "Baby Toddler juice, apple": 67202000,
    "Baby Toddler juice, grape": 67203800,
    "Baby Toddler juice, mixed fruit": 67204000,
    "Baby Toddler juice, pear": 67212000,
    "Baby Toddler juice, fruit and vegetable": 67230600,
    "Baby Toddler juice, fruit and yogurt blend": 67250150,
    "Baby Toddler pudding": 67408010,
    "Baby Toddler yogurt melts": 67430500,
    "Potato, NFS": 71000100,
    "Potato, baked, NFS": 71100100,
    "Potato, baked, peel not eaten": 71101000,
    "Potato, boiled, NFS": 71102980,
    "Potato, boiled, ready-to-heat": 71102990,
    "Potato, boiled, from fresh, peel not eaten, NS as to fat": 71103000,
    "Potato, boiled, from fresh, peel not eaten, no added fat": 71103010,
    "Potato, boiled, from fresh, peel not eaten, fat added, NS as to fat type": 71103020,
    "Potato, boiled, from fresh, peel not eaten, made with oil": 71103030,
    "Potato, boiled, from fresh, peel not eaten, made with butter": 71103040,
    "Potato, boiled, from fresh, peel not eaten, made with margarine": 71103050,
    "Potato, boiled, from fresh, peel eaten, NS as to fat": 71103105,
    "Potato, boiled, from fresh, peel eaten, fat added, NS as to fat type": 71103115,
    "Potato, boiled, from fresh,  peel eaten, no added fat": 71103125,
    "Potato, boiled, from fresh, peel eaten, made with oil": 71103135,
    "Potato, boiled, from fresh, peel eaten, made with butter": 71103140,
    "Potato, boiled, from fresh, peel eaten, made with margarine": 71103150,
    "Potato, canned, NS as to fat": 71103300,
    "Potato, canned, fat added, NS as to fat type": 71103310,
    "Potato, canned, no added fat": 71103320,
    "Potato, roasted, NFS": 71104030,
    "Potato, roasted, from fresh, peel eaten, NS as to fat": 71104040,
    "Potato, roasted, from fresh, peel eaten, no added fat": 71104050,
    "Potato, roasted, from fresh, peel eaten, fat added, NS as to fat type": 71104060,
    "Potato, roasted, from fresh, peel eaten, made with oil": 71104070,
    "Potato, roasted, from fresh, peel eaten, made with butter": 71104080,
    "Potato, roasted, from fresh, peel eaten, made with margarine": 71104090,
    "Potato, roasted, from fresh, peel not eaten, NS as to fat": 71104100,
    "Potato, roasted, from fresh, peel not eaten, fat added, NS as to fat type": 71104110,
    "Potato, roasted, from fresh, peel not eaten, no added fat": 71104120,
    "Potato, roasted, from fresh, peel not eaten, made with oil": 71104130,
    "Potato, roasted, from fresh, peel not eaten, made with butter": 71104140,
    "Potato, roasted, from fresh, peel not eaten, made with margarine": 71104150,
    "Potato, roasted, ready-to-heat": 71104200,
    "Stewed potatoes, Puerto Rican style": 71106000,
    "Potato only from Puerto Rican mixed dishes, gravy and other components reported separately": 71106010,
    "Potato from Puerto Rican style stuffed pot roast, with gravy": 71106020,
    "Potato from Puerto Rican beef stew, with gravy": 71106050,
    "Potato from Puerto Rican chicken fricassee, with sauce": 71106070,
    "Potato chips, NFS": 71200010,
    "Potato chips, plain": 71200100,
    "Potato chips, barbecue flavored": 71200110,
    "Potato chips, sour cream and onion flavored": 71200120,
    "Potato chips, cheese flavored": 71200130,
    "Potato chips, other flavored": 71200140,
    "Potato chips, ruffled, plain": 71200200,
    "Potato chips, ruffled, barbecue flavored": 71200210,
    "Potato chips, ruffled, sour cream and onion flavored": 71200220,
    "Potato chips, ruffled, cheese flavored": 71200230,
    "Potato chips, ruffled, other flavored": 71200240,
    "Potato chips, restructured, plain": 71200300,
    "Potato chips, restructured, flavored": 71200310,
    "Potato chips, baked, plain": 71200400,
    "Potato chips, baked, flavored": 71200410,
    "Potato chips, reduced fat": 71201050,
    "Potato chips, restructured, reduced fat, lightly salted": 71201200,
    "Potato chips, unsalted": 71202000,
    "Potato chips, lightly salted": 71202500,
    "Potato chips, restructured, lightly salted": 71202510,
    "Potato chips, popped, plain": 71203010,
    "Potato chips, popped, flavored": 71203020,
    "Potato chips, popped, NFS": 71203030,
    "Potato sticks, plain": 71205020,
    "Potato sticks, flavored": 71205030,
    "Potato sticks, fry shaped": 71205040,
    "Vegetable chips": 71220000,
    "Potato, scalloped, NFS": 71305015,
    "Potato, scalloped, from fast food or restaurant": 71305020,
    "Potato, scalloped, from fresh": 71305030,
    "Potato, scalloped, from fresh, with meat": 71305040,
    "Potato, scalloped, from dry mix": 71305050,
    "Potato, scalloped, from dry mix, with meat": 71305060,
    "Potato, scalloped, ready-to-heat": 71305070,
    "Potato, scalloped, ready-to-heat, with meat": 71305080,
    "Potato, french fries, NFS": 71400990,
    "Potato, french fries, NS as to fresh or frozen": 71401000,
    "Potato, french fries, from fresh, fried": 71401010,
    "Potato, french fries, from fresh, baked": 71401015,
    "Potato, french fries, from frozen, baked": 71401020,
    "Potato, french fries, fast food": 71401030,
    "Potato, french fries, restaurant": 71401031,
    "Potato, french fries, from frozen, fried": 71401032,
    "Potato, french fries, school": 71401033,
    "Potato, french fries, with cheese, fast food / restaurant": 71401039,
    "Potato, french fries, with cheese, school": 71401041,
    "Potato, french fries, with chili, fast food / restaurant": 71401045,
    "Potato, french fries, with chili and cheese, fast food / restaurant": 71401050,
    "Potato, french fries, with cheese": 71402500,
    "Potato, french fries, with chili and cheese": 71402510,
    "Potato, french fries, with chili": 71402520,
    "Potato, home fries, NFS": 71403020,
    "Potato, home fries, from restaurant / fast food": 71403030,
    "Potato, home fries, from fresh": 71403040,
    "Potato, home fries, ready-to-heat": 71403050,
    "Potato, home fries, with vegetables": 71403500,
    "Potato, hash brown, NFS": 71404000,
    "Potato, hash brown, from fast food": 71404010,
    "Potato, hash brown, from fast food, with cheese": 71404020,
    "Potato, hash brown, from restaurant": 71404030,
    "Potato, hash brown, from restaurant, with cheese": 71404040,
    "Potato, hash brown, from school lunch": 71404050,
    "Potato, hash brown, from fresh": 71405010,
    "Potato, hash brown, from fresh, with cheese": 71405019,
    "Potato, hash brown, from dry mix": 71405030,
    "Potato, hash brown, ready-to-heat": 71405040,
    "Potato, hash brown, ready-to-heat, with cheese": 71405050,
    "Potato skins without topping": 71410000,
    "Potato skins, with cheese": 71410500,
    "Potato skins, with cheese and bacon": 71411000,
    "Potato skins, NFS": 71411100,
    "Potato, mashed, NFS": 71501000,
    "Potato, mashed, from fast food": 71501005,
    "Potato, mashed, from fast food, with gravy": 71501006,
    "Potato, mashed, ready-to-heat": 71501007,
    "Potato, mashed, from fresh, made with milk": 71501010,
    "Potato, mashed, from fresh, made with milk, with cheese": 71501011,
    "Potato, mashed, from fresh, made with milk, with gravy": 71501012,
    "Potato, mashed, from fresh, NFS": 71501013,
    "Potato, mashed, from restaurant": 71501016,
    "Potato, mashed, from restaurant, with gravy": 71501017,
    "Potato, mashed, from school lunch": 71501018,
    "Potato, mashed, from dry mix, NFS": 71501035,
    "Potato, mashed, from dry mix, made with milk": 71501040,
    "Potato, mashed, from dry mix, made with milk, with cheese": 71501045,
    "Potato, mashed, from dry mix, made with milk, with gravy": 71501054,
    "Potato, mashed, ready-to-heat, NFS": 71501061,
    "Potato, mashed, ready-to-heat, with cheese": 71501071,
    "Potato, mashed, ready-to-heat, with gravy": 71501075,
    "Potato patty": 71503010,
    "Potato tots, NFS": 71505000,
    "Potato tots, fast food / restaurant": 71505010,
    "Potato tots, school": 71505020,
    "Potato tots, from fresh, fried or baked": 71505030,
    "Potato tots, frozen, baked": 71505040,
    "Potato tots, frozen, fried": 71505050,
    "Potato tots, frozen, NS as to fried or baked": 71505060,
    "Potato, baked, peel not eaten, with butter": 71507005,
    "Potato, baked, peel not eaten, with sour cream": 71507010,
    "Potato, baked, peel not eaten, with cheese": 71507020,
    "Potato, baked, peel not eaten, with meat": 71507025,
    "Potato, baked, peel not eaten, with chili": 71507030,
    "Potato, baked, peel not eaten, with vegetables": 71507035,
    "Potato, baked, peel eaten": 71508001,
    "Potato, baked, peel eaten, with butter": 71508005,
    "Potato, baked, peel eaten, with sour cream": 71508010,
    "Potato, baked, peel eaten, with cheese": 71508020,
    "Potato, baked, peel eaten, with meat": 71508025,
    "Potato, baked, peel eaten, with chili": 71508030,
    "Potato, baked, peel eaten, with vegetables": 71508035,
    "Potato salad with egg, from restaurant": 71600950,
    "Potato salad with egg, made with mayonnaise": 71601010,
    "Potato salad with egg, made with light mayonnaise": 71601015,
    "Potato salad with egg, made with mayonnaise-type salad dressing": 71601020,
    "Potato salad with egg, made with light mayonnaise-type salad dressing": 71601025,
    "Potato salad with egg, made with creamy dressing": 71601030,
    "Potato salad with egg, made with light creamy dressing": 71601035,
    "Potato salad with egg, made with Italian dressing": 71601040,
    "Potato salad with egg, made with light Italian dressing": 71601045,
    "Potato salad with egg, made with any type of fat free dressing": 71601050,
    "Potato salad, German style": 71602010,
    "Potato salad, from restaurant": 71602950,
    "Potato salad, made with mayonnaise": 71603010,
    "Potato salad, made with light mayonnaise": 71603015,
    "Potato salad, made with mayonnaise-type salad dressing": 71603020,
    "Potato salad, made with light mayonnaise-type salad dressing": 71603025,
    "Potato salad, made with creamy dressing": 71603030,
    "Potato salad, made with light creamy dressing": 71603035,
    "Potato salad, made with Italian dressing": 71603040,
    "Potato salad, made with light Italian dressing": 71603045,
    "Potato salad, made with any type of fat free dressing": 71603050,
    "Potato pancake": 71701000,
    "Lefse": 71701500,
    "Stewed potatoes": 71703990,
    "Stewed potatoes with tomatoes": 71704000,
    "Potato soup, NS as to made with milk or water": 71801000,
    "Potato soup, cream of, prepared with milk": 71801010,
    "Potato soup, prepared with water": 71801020,
    "Potato and cheese soup": 71801100,
    "Potato chowder": 71803010,
    "Plantain soup, Puerto Rican style": 71851010,
    "Plantain, cooked, no added fat": 71900100,
    "Plantain, cooked with oil": 71900200,
    "Ripe plantain, raw": 71905000,
    "Plantain, cooked, fat added, NS as to fat type": 71905008,
    "Plantain, cooked with butter or margarine": 71905100,
    "Plantain chips": 71905410,
    "Green banana, cooked in salt water": 71910110,
    "Green banana, fried": 71910210,
    "Cassava, cooked": 71930120,
    "Yuca fries": 71930190,
    "Casabe, cassava bread": 71930200,
    "Dasheen, cooked": 71962020,
    "Taro, cooked": 71962040,
    "Fufu": 71970200,
    "Taro chips": 71980200,
    "Beet greens, raw": 72101100,
    "Beet greens, cooked": 72101220,
    "Broccoli raab, raw": 72103000,
    "Broccoli raab, cooked": 72103030,
    "Chard, raw": 72104100,
    "Chard, cooked": 72104220,
    "Collards, raw": 72107100,
    "Collards, fresh, cooked, no added fat": 72107211,
    "Collards, frozen, cooked, no added fat": 72107212,
    "Collards, canned, cooked, no added fat": 72107213,
    "Collards, NS as to form, cooked": 72107220,
    "Collards, fresh, cooked, fat added, NS as to fat type": 72107221,
    "Collards, frozen, cooked, fat added, NS as to fat type": 72107222,
    "Collards, fresh, cooked with oil": 72107227,
    "Collards, fresh, cooked with butter or margarine": 72107228,
    "Collards, frozen, cooked with oil": 72107230,
    "Collards, frozen, cooked with butter or margarine": 72107231,
    "Collards, canned, cooked with oil": 72107233,
    "Collards, canned, cooked with butter or margarine": 72107234,
    "Cress, raw": 72110100,
    "Cress, cooked": 72110221,
    "Dandelion greens, raw": 72113100,
    "Dandelion greens, cooked": 72113220,
    "Romaine lettuce, raw": 72116000,
    "Caesar salad, with romaine, no dressing": 72116150,
    "Escarole, cooked": 72116220,
    "Greens, fresh, cooked, no added fat": 72118211,
    "Greens, frozen, cooked, no added fat": 72118212,
    "Greens, NS as to form, cooked": 72118220,
    "Greens, fresh, cooked, fat added": 72118221,
    "Greens, frozen, cooked, fat added": 72118222,
    "Greens, canned, cooked, fat added": 72118223,
    "Kale, raw": 72119190,
    "Kale, fresh, cooked, no added fat": 72119211,
    "Kale, frozen, cooked, no added fat": 72119212,
    "Kale, canned, cooked, no added fat": 72119213,
    "Kale, NS as to form, cooked": 72119220,
    "Kale, fresh, cooked, fat added": 72119221,
    "Kale, frozen, cooked, fat added": 72119222,
    "Lambsquarter, cooked": 72120220,
    "Mustard greens, raw": 72122100,
    "Mustard greens, fresh, cooked, no added fat": 72122211,
    "Mustard greens, frozen, cooked, no added fat": 72122212,
    "Mustard greens, canned, cooked, no added fat": 72122213,
    "Mustard greens, NS as to form, cooked": 72122220,
    "Mustard greens, fresh, cooked, fat added": 72122221,
    "Mustard greens, frozen, cooked, fat added": 72122222,
    "Poke greens, cooked": 72123020,
    "Radicchio, raw": 72124100,
    "Spinach, raw": 72125100,
    "Spinach, fresh, cooked, no added fat": 72125211,
    "Spinach, frozen, cooked, no added fat": 72125212,
    "Spinach, canned, cooked, no added fat": 72125213,
    "Spinach, fresh, cooked with oil": 72125217,
    "Spinach, fresh, cooked with butter or margarine": 72125218,
    "Spinach, NS as to form, cooked": 72125220,
    "Spinach, fresh, cooked, fat added, NS as to fat type": 72125221,
    "Spinach, frozen, cooked, fat added, NS as to fat type": 72125222,
    "Spinach, canned, cooked, fat added, NS as to fat type": 72125223,
    "Spinach, frozen, cooked with oil": 72125224,
    "Spinach, frozen, cooked with butter or margarine": 72125225,
    "Spinach, canned, cooked with oil": 72125227,
    "Spinach, canned, cooked with butter or margarine": 72125228,
    "Spinach, creamed": 72125230,
    "Spinach souffle": 72125240,
    "Spinach and cheese casserole": 72125260,
    "Palak Paneer": 72125310,
    "Channa Saag": 72125500,
    "Taro leaves, cooked": 72126001,
    "Turnip greens, fresh, cooked, no added fat": 72128211,
    "Turnip greens, frozen, cooked, no added fat": 72128212,
    "Turnip greens, canned, cooked, no added fat": 72128213,
    "Turrnip greens, NS as to form, cooked": 72128220,
    "Turnip greens, fresh, cooked, fat added": 72128221,
    "Turnip greens, frozen, cooked, fat added": 72128222,
    "Turnip greens, canned, cooked, fat added": 72128223,
    "Watercress, raw": 72130100,
    "Watercress, cooked": 72130201,
    "Bitter melon, horseradish, jute, or radish leaves, cooked": 72132201,
    "Sweet potato, squash, pumpkin, chrysanthemum, or bean leaves, cooked": 72133201,
    "Broccoli, raw": 72201100,
    "Broccoli, cooked, from restaurant": 72201190,
    "Broccoli, fresh, cooked, no added fat": 72201211,
    "Broccoli, frozen, cooked, no added fat": 72201212,
    "Broccoli, NS as to form, cooked": 72201220,
    "Broccoli, fresh, cooked, fat added, NS as to fat type": 72201221,
    "Broccoli, frozen, cooked, fat added, NS as to fat type": 72201222,
    "Broccoli, fresh, cooked with oil": 72201223,
    "Broccoli, fresh, cooked with butter or margarine": 72201224,
    "Broccoli, frozen, cooked with oil": 72201226,
    "Broccoli, frozen, cooked with butter or margarine": 72201227,
    "Broccoli casserole with noodles": 72202010,
    "Broccoli casserole with rice": 72202020,
    "Fried broccoli": 72202030,
    "Broccoli, chinese, raw": 72203000,
    "Broccoli, Chinese, cooked": 72203070,
    "Broccoli soup, prepared with milk, home recipe, canned or ready-to-serve": 72302000,
    "Broccoli soup, prepared with water, home recipe, canned, or ready-to-serve": 72302020,
    "Broccoli cheese soup, prepared with milk, home recipe, canned, or ready-to-serve": 72302100,
    "Spinach soup": 72307000,
    "Dark-green leafy vegetable soup with meat, Asian style": 72308000,
    "Dark-green leafy vegetable soup, meatless, Asian style": 72308500,
    "Carrots, raw": 73101010,
    "Carrots, raw, salad": 73101110,
    "Carrots, raw, salad with apples": 73101210,
    "Carrots, cooked, from restaurant": 73102190,
    "Carrots, fresh, cooked, no added fat": 73102211,
    "Carrots, frozen, cooked, no added fat": 73102212,
    "Carrots, canned, cooked, no added fat": 73102213,
    "Carrots, fresh, cooked with oil": 73102217,
    "Carrots, fresh, cooked with butter or margarine": 73102218,
    "Carrots, NS as to form, cooked": 73102220,
    "Carrots, fresh, cooked, fat added, NS as to fat type": 73102221,
    "Carrots, frozen, cooked, fat added, NS as to fat type": 73102222,
    "Carrots, canned, cooked, fat added, NS as to fat type": 73102223,
    "Carrots, frozen, cooked with oil": 73102224,
    "Carrots, frozen, cooked with butter or margarine": 73102225,
    "Carrots, canned, cooked with oil": 73102227,
    "Carrots, canned, cooked with butter or margarine": 73102228,
    "Carrots, glazed, cooked": 73102241,
    "Carrots, canned, reduced sodium, cooked, no added fat": 73103010,
    "Carrots, canned, reduced sodium, cooked, fat added, NS as to fat type": 73103020,
    "Carrots, canned, reduced sodium, cooked with oil": 73103021,
    "Carrots, canned, reduced sodium, cooked with butter or margarine": 73103022,
    "Beet juice": 73105000,
    "Carrot juice, 100%": 73105010,
    "Peas and carrots, fresh, cooked, no added fat": 73111211,
    "Peas and carrots, frozen, cooked, no added fat": 73111212,
    "Peas and carrots, canned, cooked, no added fat": 73111213,
    "Peas and carrots, cooked, NS as to form": 73111220,
    "Peas and carrots, fresh, cooked, fat added": 73111221,
    "Peas and carrots, frozen, cooked, fat added": 73111222,
    "Peas and carrots, canned, cooked, fat added": 73111223,
    "Pumpkin, canned, cooked": 73201013,
    "Pumpkin, cooked": 73201020,
    "Winter squash, raw": 73302010,
    "Winter squash, cooked, no added fat": 73303010,
    "Winter squash, cooked, fat added": 73303020,
    "Squash, winter, souffle": 73305020,
    "Sweet potato, NFS": 73401000,
    "Sweet potato, baked, NS as to fat": 73403000,
    "Sweet potato, baked, no added fat": 73403010,
    "Sweet potato, baked, fat added": 73403020,
    "Sweet potato, boiled, NS as to fat": 73405000,
    "Sweet potato, boiled, no added fat": 73405010,
    "Sweet potato, boiled, fat added": 73405020,
    "Sweet potato, candied": 73406000,
    "Sweet potato, canned, NS as to fat": 73407000,
    "Sweet potato, canned, no added fat": 73407050,
    "Sweet potato, canned, fat added": 73407060,
    "Sweet potato, casserole or mashed": 73409000,
    "Sweet potato fries, NFS": 73410200,
    "Sweet potato chips": 73410210,
    "Sweet potato fries, frozen": 73410320,
    "Sweet potato fries, from fresh": 73410340,
    "Sweet potato fries, fast food / restaurant": 73410400,
    "Sweet potato fries, school": 73410500,
    "Sweet potato tots": 73420020,
    "Sweet potato tots, fast food / restaurant": 73420100,
    "Sweet potato tots, school": 73420200,
    "Squash, winter type, soup, home recipe, canned, or ready-to-serve": 73502000,
    "Tomatoes, raw": 74101000,
    "Tomatoes, NS as to form, cooked": 74201000,
    "Tomatoes, fresh, cooked": 74201001,
    "Tomatoes, canned, cooked": 74201003,
    "Tomatoes, scalloped": 74203010,
    "Tomatoes, canned, reduced sodium, cooked": 74204500,
    "Fried green tomatoes": 74205010,
    "Tomato, green, pickled": 74205020,
    "Sun-dried tomatoes": 74206000,
    "Tomato juice, 100%": 74301100,
    "Tomato juice, 100%, low sodium": 74301150,
    "Tomato juice cocktail": 74302000,
    "Tomato and vegetable juice, 100%": 74303000,
    "Tomato and vegetable juice, 100%, low sodium": 74303100,
    "Ketchup": 74401010,
    "Ketchup, reduced sodium": 74401110,
    "Tomato chili sauce": 74402010,
    "Salsa, NFS": 74402100,
    "Salsa, pico de gallo": 74402110,
    "Salsa, red": 74402150,
    "Salsa, red, homemade": 74402200,
    "Taco sauce": 74402210,
    "Enchilada sauce, red": 74402250,
    "Enchilada sauce, green": 74402260,
    "Salsa verde or salsa, green": 74402350,
    "Hot Thai sauce": 74403000,
    "Spaghetti sauce": 74404010,
    "Spaghetti sauce with added vegetables": 74404020,
    "Spaghetti sauce, reduced sodium": 74404050,
    "Spaghetti sauce, fat free": 74404060,
    "Vodka sauce with tomatoes and cream": 74404090,
    "Barbecue sauce": 74406010,
    "Buffalo sauce": 74406060,
    "Steak sauce": 74406100,
    "Cocktail sauce": 74406500,
    "Puerto Rican seasoning with ham": 74410110,
    "Puerto Rican seasoning with ham and tomato sauce": 74415110,
    "Puerto Rican seasoning without ham and tomato sauce": 74420110,
    "Tomato aspic": 74501010,
    "Tomato and cucumber salad made with tomato, cucumber, oil, and vinegar": 74506000,
    "Tomato soup, NFS": 74601000,
    "Tomato soup, cream of, prepared with milk": 74601010,
    "Tomato soup, prepared with water, or ready-to-serve": 74602010,
    "Tomato soup, instant type, prepared with water": 74602050,
    "Tomato soup, canned, reduced sodium, prepared with water, or ready-to-serve": 74602200,
    "Tomato beef soup, prepared with water": 74603010,
    "Tomato noodle soup, canned, prepared with water or ready-to-serve": 74604500,
    "Tomato vegetable soup, prepared with water": 74606010,
    "Tomato vegetable soup with noodles, prepared with water": 74606020,
    "Tomato sandwich on white": 74701000,
    "Tomato sandwich on wheat": 74701010,
    "Raw vegetable, NFS": 75100250,
    "Sprouts, NFS": 75100300,
    "Alfalfa sprouts, raw": 75100500,
    "Artichoke, raw": 75100750,
    "Asparagus, raw": 75100800,
    "Bean sprouts, raw": 75101000,
    "Green beans, raw": 75101800,
    "Beets, raw": 75102500,
    "Broccoflower, raw": 75102600,
    "Brussels sprouts, raw": 75102750,
    "Cabbage, green, raw": 75103000,
    "Cabbage, Chinese, raw": 75104000,
    "Cabbage, red, raw": 75105000,
    "Cactus, raw": 75105500,
    "Cauliflower, raw": 75107000,
    "Celery, raw": 75109000,
    "Fennel bulb, raw": 75109010,
    "Basil, raw": 75109400,
    "Chives, raw": 75109500,
    "Cilantro, raw": 75109550,
    "Corn, raw": 75109600,
    "Cucumber, raw": 75111000,
    "Eggplant, raw": 75111200,
    "Garlic, raw": 75111500,
    "Jicama, raw": 75111800,
    "Kohlrabi, raw": 75112000,
    "Lettuce, raw": 75113000,
    "Lettuce, Boston, raw": 75113060,
    "Lettuce, arugula, raw": 75113080,
    "Mixed salad greens, raw": 75114000,
    "Mushrooms, raw": 75115000,
    "Onions, green, raw": 75117010,
    "Onions, raw": 75117020,
    "Parsley, raw": 75119000,
    "Green peas, raw": 75120000,
    "Pepper, hot chili, raw": 75121000,
    "Pepper, raw, NFS": 75122000,
    "Pepper, sweet, green, raw": 75122100,
    "Pepper, sweet, red, raw": 75122200,
    "Pepper, banana, raw": 75124000,
    "Radish, raw": 75125000,
    "Rutabaga, raw": 75127000,
    "Seaweed, raw": 75127500,
    "Snowpeas, raw": 75127750,
    "Summer squash, yellow, raw": 75128000,
    "Summer squash, green, raw": 75128010,
    "Turnip, raw": 75129000,
    "Mixed vegetable juice": 75132000,
    "Celery juice": 75132100,
    "Broccoli salad with cauliflower, cheese, bacon bits, and dressing": 75140500,
    "Broccoli slaw salad": 75140510,
    "Coleslaw, fast food / restaurant": 75140990,
    "Coleslaw": 75141000,
    "Cabbage salad, NFS": 75141040,
    "Coleslaw, with fruit": 75141100,
    "Cucumber and vegetable namasu": 75142000,
    "Cucumber salad, made with sour cream dressing": 75142500,
    "Cucumber salad, made with Italian dressing": 75142550,
    "Cucumber salad made with cucumber and vinegar": 75142600,
    "Lettuce, salad with assorted vegetables including tomatoes and/or carrots, no dressing": 75143000,
    "Lettuce, salad with assorted vegetables excluding tomatoes and carrots, no dressing": 75143050,
    "Lettuce, salad with avocado, tomato, and/or carrots, with or without other vegetables, no dressing": 75143100,
    "Lettuce, salad with cheese, tomato and/or carrots, with or without other vegetables, no dressing": 75143200,
    "Lettuce, salad with egg, tomato, and/or carrots, with or without other vegetables, no dressing": 75143300,
    "Lettuce, salad with egg, cheese, tomato, and/or carrots, with or without other vegetables, no dressing": 75143350,
    "Lettuce, wilted, with bacon dressing": 75144100,
    "Seven-layer salad, lettuce salad made with a combination of onion, celery, green pepper, peas, mayonnaise, cheese, eggs, and/or bacon": 75145000,
    "Greek Salad, no dressing": 75146000,
    "Spinach salad, no dressing": 75147000,
    "Cobb salad, no dressing": 75148010,
    "Aloe vera juice drink": 75200700,
    "Artichoke, fresh, cooked, no added fat": 75201011,
    "Artichoke, canned, cooked, no added fat": 75201013,
    "Artichokes, NS as to form, cooked": 75201020,
    "Asparagus, fresh, cooked, no added fat": 75202011,
    "Asparagus, frozen, cooked, no added fat": 75202012,
    "Asparagus, canned, cooked, no added fat": 75202013,
    "Asparagus, NS as to form, cooked": 75202020,
    "Asparagus, fresh, cooked, fat added, NS as to fat type": 75202021,
    "Asparagus, frozen, cooked, fat added, NS as to fat type": 75202022,
    "Asparagus, canned, cooked, fat added, NS as to fat type": 75202023,
    "Asparagus, fresh, cooked with oil": 75202027,
    "Asparagus, fresh, cooked with butter or margarine": 75202028,
    "Asparagus, frozen, cooked with oil": 75202031,
    "Asparagus, frozen, cooked with butter or margarine": 75202032,
    "Asparagus, canned, cooked with oil": 75202034,
    "Asparagus, canned, cooked with butter or margarine": 75202035,
    "Bamboo shoots, cooked": 75203028,
    "Lima beans, from frozen, no added fat": 75204012,
    "Lima beans, from frozen, fat added": 75204022,
    "Lima beans, from canned": 75204023,
    "Green beans, cooked, from restaurant": 75205005,
    "Green beans, fresh, cooked, no added fat": 75205021,
    "Green beans, frozen, cooked, no added fat": 75205022,
    "Green beans, canned, cooked, no added fat": 75205023,
    "Green beans, NS as to form, cooked": 75205030,
    "Green beans, fresh, cooked, fat added, NS as to fat type": 75205031,
    "Green beans, frozen, cooked, fat added, NS as to fat type": 75205032,
    "Green beans, canned, cooked, fat added, NS as to fat type": 75205033,
    "Green beans, fresh, cooked with oil": 75205044,
    "Green beans, fresh, cooked with butter or margarine": 75205045,
    "Green beans, frozen, cooked with oil": 75205047,
    "Green beans, frozen, cooked with butter or margarine": 75205048,
    "Green beans, canned, cooked with oil": 75205050,
    "Green beans, canned, cooked with butter or margarine": 75205051,
    "Green beans, canned, reduced sodium, cooked, no added fat": 75205120,
    "Green beans, canned, reduced sodium, cooked, fat added, NS as to fat type": 75205130,
    "Green beans, canned, reduced sodium, cooked with oil": 75205131,
    "Green beans, canned, reduced sodium, cooked with butter or margarine": 75205132,
    "Fried green beans": 75205200,
    "Yellow string beans, cooked": 75206020,
    "Bean sprouts, cooked": 75207021,
    "Beets, fresh, cooked, no added fat": 75208011,
    "Beets, canned, cooked, no added fat": 75208013,
    "Beets, NS as to form, cooked": 75208020,
    "Beets, fresh, cooked, fat added": 75208021,
    "Beets, canned, cooked, fat added": 75208023,
    "Beets, canned, reduced sodium, cooked": 75208110,
    "Bitter melon, cooked": 75208310,
    "Breadfruit, cooked": 75208501,
    "Broccoflower, cooked": 75208720,
    "Brussels sprouts, fresh, cooked, no added fat": 75209011,
    "Brussels sprouts, frozen, cooked, no added fat": 75209012,
    "Brussels sprouts, NS as to form, cooked": 75209020,
    "Brussels sprouts, fresh, cooked, fat added": 75209021,
    "Brussels sprouts, frozen, cooked, fat added": 75209022,
    "Burdock, cooked": 75209501,
    "Cabbage, Chinese, cooked, no added fat": 75210010,
    "Cabbage, Chinese, cooked, fat added": 75210020,
    "Cabbage, green, cooked, no added fat": 75211020,
    "Cabbage, green, cooked, fat added, NS as to fat type": 75211030,
    "Cabbage, green, cooked with oil": 75211031,
    "Cabbage, green, cooked with butter or margarine": 75211032,
    "Cabbage, red, cooked": 75212020,
    "Cabbage, savoy, cooked": 75213020,
    "Cactus, cooked, no added fat": 75213110,
    "Cactus, cooked, fat added": 75213120,
    "Cauliflower, fresh, cooked, no added fat": 75214011,
    "Cauliflower, frozen, cooked, no added fat": 75214012,
    "Cauliflower, NS as to form, cooked": 75214020,
    "Cauliflower, fresh, cooked, fat added, NS as to fat type": 75214021,
    "Cauliflower, frozen, cooked, fat added, NS as to fat type": 75214022,
    "Cauliflower, fresh, cooked with oil": 75214027,
    "Cauliflower, fresh, cooked with butter or margarine": 75214028,
    "Cauliflower, frozen, cooked with oil": 75214030,
    "Cauliflower, frozen, cooked with butter or margarine": 75214031,
    "Celery, cooked": 75215020,
    "Fennel bulb, cooked": 75215120,
    "Christophine, cooked": 75215511,
    "Corn, cooked, from restaurant": 75215990,
    "Corn, fresh, cooked, no added fat": 75216111,
    "Corn, frozen, cooked, no added fat": 75216112,
    "Corn, canned, cooked, no added fat": 75216113,
    "Corn, NS as to form, cooked": 75216120,
    "Corn, fresh, cooked, fat added, NS as to fat type": 75216121,
    "Corn, frozen, cooked, fat added, NS as to fat type": 75216122,
    "Corn, canned, cooked, fat added, NS as to fat type": 75216123,
    "Corn, fresh, cooked with oil": 75216134,
    "Corn, fresh, cooked with butter or margarine": 75216135,
    "Corn, frozen, cooked with oil": 75216137,
    "Corn, frozen, cooked with butter or margarine": 75216138,
    "Corn, canned, cooked with oil": 75216141,
    "Corn, canned, cooked with butter or margarine": 75216142,
    "Corn, creamed": 75216153,
    "Corn, canned, reduced sodium, cooked, no added fat": 75216310,
    "Corn, canned, reduced sodium, cooked, fat added, NS as to fat type": 75216320,
    "Corn, canned, reduced sodium, cooked with oil": 75216321,
    "Corn, canned, reduced sodium, cooked with butter or margarine": 75216322,
    "Cucumber, cooked": 75216720,
    "Eggplant, cooked, no added fat": 75217010,
    "Eggplant, cooked, fat added": 75217020,
    "Flowers or blossoms of sesbania, squash, or lily, cooked": 75217301,
    "Garlic, cooked": 75217400,
    "Hominy, cooked": 75217520,
    "Kohlrabi, cooked": 75218011,
    "Leek, cooked": 75218400,
    "Lotus root, cooked": 75218501,
    "Mushrooms, fresh, cooked, no added fat": 75219011,
    "Mushrooms, NS as to form, cooked": 75219020,
    "Mushrooms, fresh, cooked, fat added, NS as to fat type": 75219021,
    "Mushrooms, canned, cooked": 75219023,
    "Mushrooms, fresh, cooked with oil": 75219033,
    "Mushrooms, fresh, cooked with butter or margarine": 75219034,
    "Mushroom, Asian, cooked, from dried": 75219100,
    "Okra, fresh, cooked, no added fat": 75220011,
    "Okra, frozen, cooked, no added fat": 75220012,
    "Okra, NS as to form, cooked": 75220020,
    "Okra, fresh, cooked, fat added": 75220021,
    "Okra, frozen, cooked, fat added": 75220022,
    "Lettuce, cooked": 75220051,
    "Onions, cooked, no added fat": 75221011,
    "Onions, cooked, fat added": 75221021,
    "Onions, pearl, cooked": 75221030,
    "Onions, green, cooked": 75221061,
    "Palm hearts, cooked": 75221160,
    "Parsnips, cooked": 75222020,
    "Blackeyed peas, from frozen": 75223022,
    "Blackeyed peas, from canned": 75223023,
    "Green peas, cooked, from restaurant": 75224000,
    "Green peas, fresh, cooked, no added fat": 75224021,
    "Green peas, frozen, cooked, no added fat": 75224022,
    "Green peas, canned, cooked, no added fat": 75224023,
    "Green peas, NS as to form, cooked": 75224030,
    "Green peas, fresh, cooked, fat added, NS as to fat type": 75224031,
    "Green peas, frozen, cooked, fat added, NS as to fat type": 75224032,
    "Green peas, canned, cooked, fat added, NS as to fat type": 75224033,
    "Green peas, fresh, cooked with oil": 75224043,
    "Green peas, fresh, cooked with butter or margarine": 75224044,
    "Green peas, frozen, cooked with oil": 75224046,
    "Green peas, frozen, cooked with butter or margarine": 75224047,
    "Green peas, canned, cooked with oil": 75224049,
    "Green peas, canned, cooked with butter or margarine": 75224050,
    "Green peas, canned, reduced sodium, cooked, no added fat": 75224120,
    "Green peas, canned, reduced sodium, cooked, fat added, NS as to fat type": 75224130,
    "Green peas, canned, reduced sodium, cooked with oil": 75224131,
    "Green peas, canned, reduced sodium, cooked with butter or margarine": 75224132,
    "Peppers, green, cooked": 75226020,
    "Peppers, red, cooked": 75226060,
    "Hot peppers, cooked": 75226111,
    "Pimiento": 75226700,
    "Daikon radish, cooked": 75227110,
    "Rutabaga, cooked": 75228020,
    "Salsify, cooked": 75229011,
    "Sauerkraut": 75230000,
    "Snowpea, fresh, cooked, no added fat": 75231011,
    "Snowpea, frozen, cooked, no added fat": 75231012,
    "Snowpea, NS as to form, cooked": 75231020,
    "Snowpea, fresh, cooked, fat added": 75231021,
    "Snowpea, frozen, cooked, fat added": 75231022,
    "Seaweed, dried": 75232000,
    "Seaweed, cooked, no added fat": 75232110,
    "Seaweed, cooked, fat added": 75232120,
    "Summer squash, yellow or green, fresh, cooked, no added fat": 75233011,
    "Summer squash, yellow or green, frozen, cooked, no added fat": 75233012,
    "Summer squash, yellow or green, canned, cooked, no added fat": 75233013,
    "Summer squash, yellow or green, NS as to form, cooked": 75233020,
    "Summer squash, yellow or green, fresh, cooked, fat added, NS as to fat type": 75233021,
    "Summer squash, yellow or green, frozen, cooked, fat added, NS as to fat type": 75233022,
    "Summer squash, yellow or green, canned, cooked, fat added, NS as to fat type": 75233023,
    "Summer squash, yellow or green, fresh, cooked with oil": 75233027,
    "Summer squash, yellow or green, fresh, cooked with butter or margarine": 75233028,
    "Summer squash, yellow or green, frozen, cooked with oil": 75233030,
    "Summer squash, yellow or green, frozen, cooked with butter or margarine": 75233031,
    "Summer squash, yellow or green, canned, cooked with oil": 75233033,
    "Summer squash, yellow or green, canned, cooked with butter or margarine": 75233034,
    "Spaghetti squash, cooked": 75233220,
    "Turnip, cooked": 75234021,
    "Water Chesnut": 75235000,
    "Winter melon, cooked": 75235750,
    "Yeast": 75236000,
    "Yeast extract spread": 75236500,
    "Lima beans and corn, cooked, no added fat": 75301110,
    "Lima beans and corn, cooked, fat added": 75301120,
    "Bean salad, yellow and/or green string beans": 75302080,
    "Peppers and onions, cooked, no added fat": 75306998,
    "Peppers and onions, cooked, fat added": 75307000,
    "Classic mixed vegetables, cooked, from restaurant": 75310990,
    "Classic mixed vegetables, frozen, cooked, no added fat": 75311012,
    "Classic mixed vegetables, canned, cooked, no added fat": 75311013,
    "Classic mixed vegetables, NS as to form, cooked": 75311020,
    "Classic mixed vegetables, frozen, cooked, fat added, NS as to fat type": 75311022,
    "Classic mixed vegetables, canned, cooked, fat added, NS as to fat type": 75311023,
    "Classic mixed vegetables, frozen, cooked with oil": 75311027,
    "Classic mixed vegetables, frozen, cooked with butter or margarine": 75311028,
    "Classic mixed vegetables, canned, cooked with oil": 75311030,
    "Classic mixed vegetables, canned, cooked with butter or margarine": 75311031,
    "Classic mixed vegetables, canned, reduced sodium, cooked, no added fat": 75311110,
    "Classic mixed vegetables, canned, reduced sodium, cooked, fat added, NS as to fat type": 75311120,
    "Classic mixed vegetables, canned, reduced sodium, cooked with oil": 75311121,
    "Classic mixed vegetables, canned, reduced sodium, cooked with butter or margarine": 75311122,
    "Peas and corn, cooked, no added fat": 75315010,
    "Peas and corn, cooked, fat added": 75315020,
    "Ratatouille": 75316050,
    "Vegetables, stew type, cooked, fat added": 75317010,
    "Vegetables, stew type, cooked, no added fat": 75317020,
    "Broccoli and cauliflower, cooked, no added fat": 75330050,
    "Broccoli and cauliflower, cooked, fat added": 75330060,
    "Broccoli, cauliflower and carrots, cooked, no added fat": 75330080,
    "Broccoli, cauliflower and carrots, cooked, fat added": 75330090,
    "Asian stir fry vegetables, cooked, no added fat": 75340010,
    "Asian stir fry vegetables, cooked, fat added": 75340020,
    "Jai, Monk's Food": 75340200,
    "Vegetable mixture, dried": 75365000,
    "Artichokes, stuffed": 75400500,
    "Green bean casserole": 75403020,
    "Green beans, cooked, Szechuan-style": 75403200,
    "Fried cauliflower": 75409020,
    "Chiles rellenos, cheese-filled": 75410500,
    "Chiles rellenos, filled with meat and cheese": 75410530,
    "Stuffed jalapeno pepper": 75410550,
    "Corn, scalloped or pudding": 75411010,
    "Fritter, corn": 75411020,
    "Fried eggplant": 75412010,
    "Eggplant dip": 75412030,
    "Eggplant parmesan casserole, regular": 75412060,
    "Eggplant with cheese and tomato sauce": 75412070,
    "Mushrooms, stuffed": 75414020,
    "Fried mushrooms": 75414030,
    "Fried okra": 75414500,
    "Fried onion rings": 75415022,
    "Pea salad": 75416500,
    "Pea salad with cheese": 75416600,
    "Fried summer squash, yellow or green": 75418010,
    "Squash, summer, casserole with tomato and cheese": 75418020,
    "Squash, summer, casserole, with rice and tomato sauce": 75418030,
    "Squash, summer, casserole, with cheese sauce": 75418040,
    "Squash, summer, souffle": 75418060,
    "Vegetable stew without meat": 75439010,
    "Chow mein or chop suey, meatless, no noodles": 75439500,
    "Vegetable tempura": 75440200,
    "Pakora": 75440400,
    "Vegetable curry": 75440600,
    "Vegetable curry with rice": 75440610,
    "Chow mein or chop suey, meatless, with noodles": 75460900,
    "Green beans, pickled": 75500110,
    "Beets, pickled": 75500210,
    "Celery, pickled": 75500510,
    "Relish, corn": 75501010,
    "Cauliflower, pickled": 75502010,
    "Cabbage, fresh, pickled, Japanese style": 75502500,
    "Cabbage, red, pickled": 75502510,
    "Kimchi": 75502520,
    "Pickles, dill": 75503010,
    "Relish, pickle": 75503020,
    "Pickles, sweet": 75503040,
    "Eggplant, pickled": 75503080,
    "Ginger root, pickled": 75503085,
    "Horseradish": 75503090,
    "Mushrooms, pickled": 75505000,
    "Mustard": 75506010,
    "Honey mustard dip": 75506100,
    "Okra, pickled": 75507000,
    "Olives, NFS": 75510000,
    "Olives, green": 75510010,
    "Olives, black": 75510020,
    "Olives, stuffed": 75510030,
    "Olive tapenade": 75510050,
    "Hot pepper sauce": 75511010,
    "Peppers, pickled": 75511020,
    "Pepper, hot, pickled": 75511040,
    "Jalapenos, NFS": 75511050,
    "Pickles, NFS": 75511100,
    "Pickles, fried": 75511300,
    "Radishes, pickled, Hawaiian style": 75512010,
    "Seaweed, pickled": 75513010,
    "Vegetables, pickled": 75515100,
    "Turnip, pickled": 75534030,
    "Wasabi paste": 75534550,
    "Zucchini, pickled": 75535000,
    "Asparagus soup, cream of, NS as to made with milk or water": 75601000,
    "Asparagus soup, cream of, prepared with milk": 75601010,
    "Borscht": 75601100,
    "Cabbage soup, home recipe, canned or ready-to-serve": 75601200,
    "Cabbage with meat soup, home recipe, canned or ready-to-serve": 75601210,
    "Kimchi soup": 75601250,
    "Celery soup, cream of, prepared with water, home recipe, canned or ready-to-serve": 75603020,
    "Gazpacho": 75604600,
    "Mushroom soup, cream of, prepared with milk": 75607010,
    "Mushroom soup, cream of, prepared with water": 75607020,
    "Mushroom soup, with meat broth, prepared with water": 75607040,
    "Mushroom soup, cream of, NS as to made with milk or water": 75607060,
    "Mushroom soup, cream of, canned, reduced sodium, prepared with water": 75607140,
    "Onion soup, French": 75608100,
    "Pea soup, prepared with milk": 75609010,
    "Vegetable soup, cream of, prepared with milk": 75611010,
    "Seaweed soup": 75647000,
    "Vegetable soup, canned, prepared with water or ready-to-serve": 75649010,
    "Vegetable soup, reduced sodium, canned, ready to serve": 75649040,
    "Vegetable soup, made from dry mix": 75649050,
    "Vegetable soup, home recipe": 75649110,
    "Vegetable noodle soup, home recipe": 75649150,
    "Minestrone soup, reduced sodium, canned or ready-to-serve": 75650990,
    "Minestrone soup, home recipe": 75651000,
    "Minestrone soup, canned, prepared with water, or ready-to-serve": 75651010,
    "Vegetable beef soup, canned, prepared with water, or ready-to-serve": 75651020,
    "Vegetable noodle soup, canned, prepared with water, or ready-to-serve": 75651040,
    "Vegetable rice soup, canned, prepared with water or ready-to-serve": 75651070,
    "Vegetable beef soup, home recipe": 75652010,
    "Vegetable beef soup with noodles or pasta, home recipe": 75652040,
    "Vegetable beef soup with rice, home recipe": 75652050,
    "Vegetable beef soup, chunky style": 75656060,
    "Vegetable broth, bouillon": 75657000,
    "Baby Toddler vegetable, NFS": 76000000,
    "Baby Toddler carrots, Stage 1": 76201010,
    "Baby Toddler carrots, Stage 2": 76201040,
    "Baby Toddler squash, Stage 1": 76205010,
    "Baby Toddler squash, Stage 2": 76205040,
    "Baby Toddler sweet potatoes, Stage 1": 76209010,
    "Baby Toddler sweet potatoes, Stage 2": 76209040,
    "Baby Toddler green beans, Stage 1": 76401010,
    "Baby Toddler green beans, Stage 2": 76401040,
    "Baby Toddler beets": 76403010,
    "Baby Toddler multiple vegetables, Stage 2": 76407010,
    "Baby Toddler multiple vegetables, Stage 3": 76407020,
    "Baby Toddler vegetables, with grain": 76407050,
    "Baby Toddler vegetables and meat": 76407080,
    "Baby Toddler peas, Stage 1": 76409010,
    "Baby Toddler peas, Stage 2": 76409040,
    "Toddler meal, NFS": 76700000,
    "Toddler meal, meat and vegetables": 76702100,
    "Toddler meal, rice and vegetables": 76702200,
    "Toddler meal, pasta": 76702300,
    "Toddler meal, pasta and vegetables": 76702400,
    "Fried stuffed potatoes, Puerto Rican style": 77121010,
    "Green plantain with cracklings, Puerto Rican style": 77201210,
    "Ripe plantain meat pie, Puerto Rican style": 77205610,
    "Cassava Pasteles, Puerto Rican style": 77230210,
    "Puerto Rican pasteles": 77272010,
    "Stuffed cabbage, with meat, Puerto Rican style": 77316010,
    "Stuffed cabbage, with meat and rice, Syrian dish, Puerto Rican style": 77316510,
    "Eggplant and meat casserole": 77316600,
    "Vegetable and fruit juice, 100% juice, with high vitamin C": 78101000,
    "Fruit and vegetable smoothie, with dairy": 78101100,
    "Fruit and vegetable smoothie, added protein": 78101110,
    "Fruit and vegetable smoothie, non-dairy": 78101115,
    "Fruit and vegetable smoothie, non-dairy, added protein": 78101118,
    "Fruit and vegetable smoothie, bottled": 78101120,
    "Fruit and vegetable smoothie, no dairy": 78101125,
    "Vegetable smoothie": 78101130,
    "Table fat, NFS": 81100000,
    "Butter, NFS": 81100500,
    "Butter, stick": 81101000,
    "Butter, tub": 81101010,
    "Butter, light": 81101520,
    "Margarine, NFS": 81102000,
    "Margarine, stick": 81102010,
    "Margarine, tub": 81102020,
    "Margarine-oil blend, NFS": 81103035,
    "Margarine-oil blend, stick": 81103040,
    "Margarine-oil blend, tub": 81103080,
    "Butter replacement, liquid": 81103090,
    "Margarine-oil blend, tub, light": 81104010,
    "Margarine-oil blend, stick, light": 81104020,
    "Butter-oil blend, NFS": 81104490,
    "Butter-oil blend, stick": 81104500,
    "Butter-oil blend, tub": 81104510,
    "Butter-oil blend, light": 81104550,
    "Butter replacement, powder": 81106010,
    "Oil or table fat, NFS": 81200100,
    "Animal fat or drippings": 81201000,
    "Lard": 81202000,
    "Shortening, NS as to vegetable or animal": 81203000,
    "Ghee, clarified butter": 81204000,
    "Garlic sauce": 81301000,
    "Lemon-butter sauce": 81301020,
    "Hollandaise sauce": 81302010,
    "Sandwich spread": 81302040,
    "Tartar sauce": 81302050,
    "Horseradish sauce": 81302060,
    "Pesto sauce": 81302070,
    "Fry sauce": 81308100,
    "Sauce, NFS": 81308200,
    "Curry sauce": 81312100,
    "Honey butter": 81322000,
    "Vegetable oil, NFS": 82101000,
    "Almond oil": 82101300,
    "Coconut oil": 82101500,
    "Corn oil": 82102000,
    "Cottonseed oil": 82103000,
    "Flaxseed oil": 82103500,
    "Olive oil": 82104000,
    "Peanut oil": 82105000,
    "Canola oil": 82105500,
    "Safflower oil": 82106000,
    "Sesame oil": 82107000,
    "Soybean oil": 82108000,
    "Sunflower oil": 82108500,
    "Walnut oil": 82108700,
    "Wheat germ oil": 82109000,
    "Salad dressing, NFS, for salads": 83100100,
    "Salad dressing, NFS, for sandwiches": 83100200,
    "Blue or roquefort cheese dressing": 83101000,
    "Bacon and tomato dressing": 83101600,
    "Caesar dressing": 83102000,
    "Coleslaw dressing": 83103000,
    "French or Catalina dressing": 83104000,
    "Honey mustard dressing": 83105500,
    "Italian dressing, made with vinegar and oil": 83106000,
    "Mayonnaise, regular": 83107000,
    "Vegan mayonnaise": 83108000,
    "Russian dressing": 83109000,
    "Mayonnaise-type salad dressing": 83110000,
    "Avocado dressing": 83112000,
    "Creamy Italian dressing": 83112400,
    "Poppy seed dressing": 83112950,
    "Sesame dressing": 83112990,
    "Ranch dressing": 83113500,
    "Thousand Island dressing": 83114000,
    "Yogurt dressing": 83115000,
    "Salad dressing, light, NFS": 83200100,
    "Blue or roquefort cheese dressing, light": 83201000,
    "Coleslaw dressing, light": 83201400,
    "Creamy Italian dressing, light": 83201500,
    "French or Catalina dressing, light": 83202020,
    "Caesar dressing, light": 83203000,
    "Mayonnaise, light": 83204000,
    "Mayonnaise, reduced fat,  with olive oil": 83204030,
    "Mayonnaise-type salad dressing, light": 83204050,
    "Honey mustard dressing, light": 83204500,
    "Italian dressing, light": 83205450,
    "Ranch dressing, light": 83205560,
    "Russian dressing, light": 83206000,
    "Sesame dressing, light": 83206500,
    "Thousand Island dressing, light": 83207000,
    "Korean dressing or marinade": 83208500,
    "Blue or roquefort cheese dressing, fat free": 83300100,
    "Caesar dressing, fat free": 83300200,
    "Creamy Italian dressing, fat free": 83300250,
    "French or Catalina dressing, fat free": 83300400,
    "Honey mustard dressing, fat free": 83300500,
    "Italian dressing, fat free": 83300600,
    "Mayonnaise, fat free": 83300700,
    "Ranch dressing, fat free": 83300750,
    "Russian dressing, fat free": 83300800,
    "Salad dressing, fat free, NFS": 83300900,
    "Thousand Island dressing, fat free": 83301000,
    "Bacon, for use with vegetables": 89901000,
    "Ham, for use with vegetables": 89901002,
    "Beef, for use with vegetables": 89901004,
    "Chicken, for use with vegetables": 89901006,
    "Cream sauce, for use with vegetables": 89901010,
    "Cheese sauce, for use with vegetables": 89901020,
    "Gravy, for use with vegetables": 89901030,
    "Soy based sauce, for use with vegetables": 89901040,
    "Tomato sauce, for use with vegetables": 89901050,
    "Avocado, for use on a sandwich": 89902000,
    "Cucumber, for use on a sandwich": 89902010,
    "Lettuce, for use on a sandwich": 89902020,
    "Mushrooms, for use on a sandwich": 89902030,
    "Onions, for use on a sandwich": 89902040,
    "Pepper, for use on a sandwich": 89902050,
    "Spinach, for use on a sandwich": 89902060,
    "Tomatoes, for use on a sandwich": 89902070,
    "Bacon, for use on a sandwich": 89902100,
    "Sugar, NFS": 91101000,
    "Sugar, white, granulated or lump": 91101010,
    "Sugar, white, confectioner's, powdered": 91101020,
    "Sugar, brown": 91102010,
    "Sugar, cinnamon": 91104100,
    "Sugar substitute and sugar blend": 91106010,
    "Sugar substitute, sucralose, powder": 91107000,
    "Sugar substitute, stevia, powder": 91108000,
    "Sugar substitute, stevia, liquid": 91108010,
    "Sugar substitute, monk fruit, powder": 91108020,
    "Sugar substitute, powder, NFS": 91200000,
    "Sugar substitute, liquid, NFS": 91200005,
    "Sugar substitute, saccharin, powder": 91200040,
    "Sugar substitute, saccharin, liquid": 91200110,
    "Sugar substitute, aspartame, powder": 91201010,
    "Syrup, NFS": 91300010,
    "Pancake syrup": 91300100,
    "Corn syrup": 91301030,
    "Blueberry syrup": 91301050,
    "Chocolate syrup": 91301080,
    "Chocolate syrup, light": 91301081,
    "Simple syrup": 91301100,
    "Strawberry drink syrup": 91301130,
    "Pancake syrup, light": 91301510,
    "Honey": 91302010,
    "Agave liquid sweetener": 91302020,
    "Molasses": 91303000,
    "Topping, butterscotch or caramel": 91304010,
    "Topping, chocolate": 91304020,
    "Topping, fruit": 91304030,
    "Topping, marshmallow": 91304040,
    "Topping, nuts and syrup": 91304060,
    "Chocolate hazelnut spread": 91304090,
    "Icing, chocolate": 91305010,
    "Icing, white": 91305020,
    "Caramel dip, regular": 91306020,
    "Caramel dip, light": 91306025,
    "Chocolate dip": 91306030,
    "Dessert dip": 91306040,
    "Sweet and sour sauce": 91361010,
    "Dessert sauce": 91361040,
    "Duck sauce": 91361050,
    "Jam or jelly, NFS": 91400000,
    "Jelly": 91401000,
    "Jam": 91402000,
    "Fruit butter": 91403000,
    "Marmalade": 91404000,
    "Jam or jelly, sugar free": 91405000,
    "Jam or jelly, reduced sugar": 91405500,
    "Jam or jelly, fruit juice sweetened": 91406500,
    "Guava paste": 91407100,
    "Sweet potato paste": 91407120,
    "Bean paste, sweetened": 91407150,
    "Gelatin dessert": 91501010,
    "Gelatin dessert with fruit": 91501020,
    "Gelatin salad with vegetables": 91501100,
    "Gelatin dessert, sugar free": 91511010,
    "Gelatin dessert, sugar free, with fruit": 91511020,
    "Yokan": 91520100,
    "Haupia": 91560100,
    "Italian Ice": 91601000,
    "Italian Ice, no sugar added": 91601010,
    "Popsicle, NFS": 91610900,
    "Popsicle": 91611000,
    "Popsicle, no sugar added": 91611100,
    "Freezer pop": 91612000,
    "Snow cone": 91621000,
    "Snow cone, no sugar added": 91621050,
    "Candy, NFS": 91700010,
    "Almonds, chocolate covered candy": 91701010,
    "Chocolate candy, other, NFS": 91705005,
    "Chocolate candy": 91705010,
    "Chocolate candy with nuts, other, NFS": 91705012,
    "Chocolate candy with nuts": 91705015,
    "Chocolate candy with cereal": 91705020,
    "Chocolate candy, cookie filled": 91705080,
    "Chocolate chips": 91705200,
    "Candy, sprinkles": 91705250,
    "Dark chocolate candy, other, NFS": 91705290,
    "Dark chocolate candy": 91705300,
    "Dark chocolate candy with nuts, other, NFS": 91705312,
    "Dark chocolate candy with nuts": 91705315,
    "White chocolate candy": 91705400,
    "Chocolate candy, fudge": 91705440,
    "Chocolate candy, caramel filled": 91705450,
    "Chocolate candy, caramel filled with nuts": 91705460,
    "Chocolate candy, coconut filled": 91705470,
    "Chocolate candy, cream filled": 91705480,
    "Chocolate candy, nougat filled": 91705510,
    "Chocolate candy, nougat filled with nuts": 91705520,
    "Chocolate candy, peanut butter filled": 91705530,
    "Chocolate candy with dried fruit": 91705550,
    "Chocolate candy, sugar free": 91706010,
    "Candy, non chocolate, other, NFS": 91706020,
    "Ladoo, round ball": 91718300,
    "Candy, licorice": 91721000,
    "Candy, marshmallow": 91723000,
    "Candy, caramel": 91723030,
    "Candy, nougat with nuts": 91728000,
    "Peanuts, chocolate covered candy": 91731000,
    "Candy, peanut brittle": 91733000,
    "Candy, mint": 91745000,
    "Candy, gummy": 91745010,
    "Candy, hard": 91745025,
    "Candy, lollipop": 91745030,
    "Cough drops": 91745035,
    "Candy, cotton": 91745050,
    "Candy, fruit flavored pieces": 91745110,
    "Chocolate candy, candy shell": 91746100,
    "Chocolate candy, candy shell with nuts": 91746110,
    "Candy, fruit snacks": 91746300,
    "Candy, fruit leather": 91746350,
    "Candy, taffy": 91750000,
    "Candy, non chocolate, sugar free": 91770060,
    "Chewing gum": 91801000,
    "Chewing gum, sugar free": 91802000,
    "Coffee, NS as to type": 92100000,
    "Coffee, NS as to brewed or instant": 92100500,
    "Coffee, brewed": 92101000,
    "Coffee, brewed, blend of regular and decaffeinated": 92101500,
    "Coffee, Turkish": 92101600,
    "Coffee, espresso": 92101610,
    "Coffee, espresso, decaffeinated": 92101630,
    "Coffee, brewed, flavored": 92101700,
    "Coffee, Cuban": 92101800,
    "Coffee, macchiato": 92101810,
    "Coffee, macchiato, sweetened": 92101820,
    "Coffee, cafe con leche": 92101850,
    "Coffee, cafe con leche, decaffeinated": 92101851,
    "Coffee, Latte": 92101900,
    "Coffee, Latte, nonfat": 92101901,
    "Coffee, Latte, with non-dairy milk": 92101903,
    "Coffee, Latte, flavored": 92101904,
    "Coffee, Latte, nonfat, flavored": 92101905,
    "Coffee, Latte, with non-dairy milk, flavored": 92101906,
    "Coffee, Latte, decaffeinated": 92101910,
    "Coffee, Latte, decaffeinated, nonfat": 92101911,
    "Coffee, Latte, decaffeinated, with non-dairy milk": 92101913,
    "Coffee, Latte, decaffeinated, flavored": 92101917,
    "Coffee, Latte, decaffeinated, nonfat, flavored": 92101918,
    "Coffee, Latte, decaffeinated, with non-dairy milk, flavored": 92101919,
    "Frozen coffee drink": 92101920,
    "Frozen coffee drink, nonfat": 92101921,
    "Frozen coffee drink, with non-dairy milk": 92101923,
    "Frozen coffee drink, with whipped cream": 92101925,
    "Frozen coffee drink, nonfat, with whipped cream": 92101926,
    "Frozen coffee drink, with non-dairy milk and whipped cream": 92101928,
    "Frozen coffee drink, decaffeinated": 92101930,
    "Frozen coffee drink, decaffeinated, nonfat": 92101931,
    "Frozen coffee drink, decaffeinated, with non-dairy milk": 92101933,
    "Frozen coffee drink, decaffeinated, with whipped cream": 92101935,
    "Frozen coffee drink, decaffeinated, nonfat, with whipped cream": 92101936,
    "Frozen coffee drink, decaffeinated, with non-dairy milk and whipped cream": 92101938,
    "Coffee, Cafe Mocha": 92101950,
    "Coffee, Cafe Mocha, nonfat": 92101955,
    "Coffee, Cafe Mocha, with non-dairy milk": 92101960,
    "Coffee, Cafe Mocha, decaffeinated": 92101965,
    "Coffee, Cafe Mocha, decaffeinated, nonfat": 92101970,
    "Coffee, Cafe Mocha, decaffeinated, with non-dairy milk": 92101975,
    "Frozen mocha coffee drink": 92102000,
    "Frozen mocha coffee drink, nonfat": 92102010,
    "Frozen mocha coffee drink, with non-dairy milk": 92102020,
    "Frozen mocha coffee drink, with whipped cream": 92102030,
    "Frozen mocha coffee drink, nonfat, with whipped cream": 92102040,
    "Frozen mocha coffee drink, with non-dairy milk and whipped cream": 92102050,
    "Frozen mocha coffee drink, decaffeinated": 92102060,
    "Frozen mocha coffee drink, decaffeinated, nonfat": 92102070,
    "Frozen mocha coffee drink, decaffeinated, with non-dairy milk": 92102080,
    "Frozen mocha coffee drink, decaffeinated, with whipped cream": 92102090,
    "Frozen mocha coffee drink, decaffeinated, nonfat, with whipped cream": 92102100,
    "Frozen mocha coffee drink, decaffeinated, with non-dairy milk and whipped cream": 92102110,
    "Iced Coffee, brewed": 92102400,
    "Iced Coffee, brewed, decaffeinated": 92102401,
    "Iced Coffee, pre-lightened and pre-sweetened": 92102450,
    "Coffee, Iced Latte": 92102500,
    "Coffee, Iced Latte, nonfat": 92102501,
    "Coffee, Iced Latte, with non-dairy milk": 92102502,
    "Coffee, Iced Latte, flavored": 92102503,
    "Coffee, Iced Latte, nonfat, flavored": 92102504,
    "Coffee, Iced Latte, with non-dairy milk, flavored": 92102505,
    "Coffee, Iced Latte, decaffeinated": 92102510,
    "Coffee, Iced Latte, decaffeinated, nonfat": 92102511,
    "Coffee, Iced Latte, decaffeinated, with non-dairy milk": 92102512,
    "Coffee, Iced Latte, decaffeinated, flavored": 92102513,
    "Coffee, Iced Latte, decaffeinated, nonfat, flavored": 92102514,
    "Coffee, Iced Latte, decaffeinated, with non-dairy milk, flavored": 92102515,
    "Coffee, Iced Cafe Mocha": 92102600,
    "Coffee, Iced Cafe Mocha, nonfat": 92102601,
    "Coffee, Iced Cafe Mocha, with non-dairy milk": 92102602,
    "Coffee, Iced Cafe Mocha, decaffeinated": 92102610,
    "Coffee, Iced Cafe Mocha, decaffeinated, nonfat": 92102611,
    "Coffee, Iced Cafe Mocha, decaffeinated, with non-dairy milk": 92102612,
    "Coffee, instant, reconstituted": 92103000,
    "Coffee, instant, 50% less caffeine, reconstituted": 92104000,
    "Coffee, NS as to brewed or instant, decaffeinated": 92111000,
    "Coffee, brewed, decaffeinated": 92111010,
    "Coffee, instant, decaffeinated, reconstituted": 92114000,
    "Coffee, instant, pre-lightened and pre-sweetened with sugar, reconstituted": 92121000,
    "Coffee, instant, decaffeinated, pre-lightened and pre-sweetened with sugar, reconsitituted": 92121001,
    "Coffee, instant, pre-sweetened with sugar, reconstituted": 92121010,
    "Coffee, mocha, instant, pre-lightened and pre-sweetened with sugar, reconstituted": 92121020,
    "Coffee, mocha, instant, pre-lightened and pre-sweetened with low calorie sweetener, reconstituted": 92121030,
    "Coffee, instant, pre-lightened and pre-sweetened with low calorie sweetener, reconstituted": 92121040,
    "Coffee, instant, decaffeinated, pre-lightened and pre-sweetened with low calorie sweetener, reconstituted": 92121041,
    "Coffee, mocha, instant, decaffeinated, pre-lightened and pre-sweetened with low calorie sweetener, reconstituted": 92121050,
    "Coffee, pre-lightened and pre-sweetened with sugar": 92130000,
    "Coffee, decaffeinated, pre-lightened and pre-sweetened with sugar": 92130001,
    "Coffee, pre-lightened and pre-sweetened with low calorie sweetener": 92130005,
    "Coffee, decaffeinated, pre-lightened and pre-sweetened with low calorie sweetener": 92130006,
    "Coffee, pre-lightened": 92130010,
    "Coffee, decaffeinated, pre-lightened": 92130011,
    "Coffee, pre-sweetened with sugar": 92130020,
    "Coffee, decaffeinated, pre-sweetened with sugar": 92130021,
    "Coffee, pre-sweetened with low calorie sweetener": 92130030,
    "Coffee, decaffeinated, pre-sweetened with low calorie sweetener": 92130031,
    "Coffee and chicory, brewed": 92152000,
    "Coffee and chicory, brewed, decaffeinated": 92152010,
    "Coffee, Cappuccino": 92161000,
    "Coffee, Cappuccino, nonfat": 92161001,
    "Coffee, Cappuccino, with non-dairy milk": 92161002,
    "Coffee, Cappuccino, decaffeinated": 92162000,
    "Coffee, Cappuccino, decaffeinated, nonfat": 92162001,
    "Coffee, Cappuccino, decaffeinated, with non-dairy milk": 92162002,
    "Coffee, bottled/canned": 92171000,
    "Coffee, bottled/canned, light": 92171010,
    "Coffee, instant, not reconstituted": 92191100,
    "Coffee, instant, 50% less caffeine, not reconstituted": 92191105,
    "Coffee, instant, decaffeinated, not reconstituted": 92191200,
    "Coffee, instant, pre-sweetened with sugar, not reconstituted": 92191400,
    "Coffee, mocha, instant, pre-lightened and pre-sweetened with sugar, not reconstituted": 92192000,
    "Coffee, mocha, instant, pre-lightened and pre-sweetened with low calorie sweetener, not reconstituted": 92192030,
    "Coffee, mocha, instant, decaffeinated, pre-lightened and pre-sweetend with low calorie sweetener, not reconstituted": 92192040,
    "Coffee, instant, pre-lightened and pre-sweetened with sugar, not reconstituted": 92193000,
    "Coffee, instant, decaffeinated, pre-lightened and pre-sweetened with sugar, not reconstituted": 92193005,
    "Coffee, instant, pre-lightened and pre-sweetened with low calorie sweetener, not reconstituted": 92193020,
    "Coffee, instant, decaffeinated, pre-lightened and pre-sweetened with low calorie sweetener, not reconstituted": 92193025,
    "Coffee substitute": 92201010,
    "Chicory beverage": 92202010,
    "Cereal beverage": 92203000,
    "Cereal beverage with beet roots, from powdered instant": 92203110,
    "Coffee substitute, dry powder": 92291300,
    "Tea, hot, leaf, black": 92302000,
    "Tea, hot, leaf, black, decaffeinated": 92302500,
    "Tea, hot, leaf, green": 92303010,
    "Tea, hot, leaf, green, decaffeinated": 92303100,
    "Tea, hot, leaf, oolong": 92304100,
    "Tea, iced, instant, black, unsweetened": 92305010,
    "Tea, iced, instant, black, pre-sweetened with sugar": 92305040,
    "Tea, iced, instant, black, decaffeinated, pre-sweetened with sugar": 92305050,
    "Tea, iced, instant, black, pre-sweetened with low calorie sweetener": 92305090,
    "Tea, iced, instant, black, decaffeinated, pre-sweetened with low calorie sweetener": 92305110,
    "Tea, iced, instant, black, decaffeinated, unsweetened": 92305180,
    "Tea, iced, instant, green, unsweetened": 92305900,
    "Tea, iced, instant, green, pre-sweetened with sugar": 92305910,
    "Tea, iced, instant, green, pre-sweetened with low calorie sweetener": 92305920,
    "Tea, hot, herbal": 92306000,
    "Tea, hot, hibiscus": 92306090,
    "Corn beverage": 92306100,
    "Tea, hot, chamomile": 92306700,
    "Tea, hot, with milk": 92306800,
    "Tea, ginger": 92306850,
    "Tea, bubble": 92306910,
    "Tea, kombucha": 92306920,
    "Tea, iced, instant, black, unsweetened, dry": 92307000,
    "Tea, iced, instant, black, pre-sweetened, dry": 92307400,
    "Iced Tea / Lemonade juice drink": 92307500,
    "Iced Tea / Lemonade juice drink, light": 92307510,
    "Iced Tea / Lemonade juice drink, diet": 92307520,
    "Tea, iced, brewed, black, pre-sweetened with sugar": 92308000,
    "Tea, iced, brewed, black, pre-sweetened with low calorie sweetener": 92308010,
    "Tea, iced, brewed, black, unsweetened": 92308020,
    "Tea, iced, brewed, black, decaffeinated, pre-sweetened with sugar": 92308030,
    "Tea, iced, brewed, black, decaffeinated, pre-sweetened with low calorie sweetener": 92308040,
    "Tea, iced, brewed, black, decaffeinated, unsweetened": 92308050,
    "Tea, iced, brewed, green, pre-sweetened with sugar": 92308500,
    "Tea, iced, brewed, green, pre-sweetened with low calorie sweetener": 92308510,
    "Tea, iced, brewed, green, unsweetened": 92308520,
    "Tea, iced, brewed, green, decaffeinated, pre-sweetened with sugar": 92308530,
    "Tea, iced, brewed, green, decaffeinated, pre-sweetened with low calorie sweetener": 92308540,
    "Tea, iced, brewed, green, decaffeinated, unsweetened": 92308550,
    "Tea, iced, bottled, black": 92309000,
    "Tea, iced, bottled, black, decaffeinated": 92309010,
    "Tea, iced, bottled, black, diet": 92309020,
    "Tea, iced, bottled, black, decaffeinated, diet": 92309030,
    "Tea, iced, bottled, black, unsweetened": 92309040,
    "Tea, iced, bottled, black, decaffeinated, unsweetened": 92309050,
    "Tea, iced, bottled, green": 92309500,
    "Tea, iced, bottled, green, diet": 92309510,
    "Tea, iced, bottled, green, unsweetened": 92309520,
    "Soft drink, NFS": 92400000,
    "Soft drink, NFS, diet": 92400100,
    "Water, tonic": 92410110,
    "Water, carbonated, plain": 92410210,
    "Water, carbonated, flavored": 92410250,
    "Soft drink, cola": 92410310,
    "Soft drink, cola, reduced sugar": 92410315,
    "Soft drink, cola, diet": 92410320,
    "Soft drink, cola, decaffeinated": 92410340,
    "Soft drink, cola, decaffeinated, diet": 92410350,
    "Soft drink, pepper type": 92410360,
    "Soft drink, pepper type, diet": 92410370,
    "Soft drink, pepper type, decaffeinated": 92410390,
    "Soft drink, pepper type, decaffeinated, diet": 92410400,
    "Soft drink, cream soda": 92410410,
    "Soft drink, cream soda, diet": 92410420,
    "Soft drink, fruit flavored, caffeine free": 92410510,
    "Soft drink, fruit flavored, diet, caffeine free": 92410520,
    "Soft drink, fruit flavored, caffeine containing": 92410550,
    "Soft drink, fruit flavored, caffeine containing, diet": 92410560,
    "Soft drink, ginger ale": 92410610,
    "Soft drink, ginger ale, diet": 92410620,
    "Soft drink, root beer": 92410710,
    "Soft drink, root beer, diet": 92410720,
    "Soft drink, chocolate flavored": 92410810,
    "Soft drink, chocolate flavored, diet": 92410820,
    "Soft drink, cola, fruit or vanilla flavored": 92411510,
    "Soft drink, cola, chocolate flavored": 92411520,
    "Soft drink, cola, fruit or vanilla flavored, diet": 92411610,
    "Soft drink, cola, chocolate flavored, diet": 92411620,
    "Fruit juice drink, citrus, carbonated": 92432000,
    "Fruit juice drink, noncitrus, carbonated": 92433000,
    "Fruit juice drink": 92510610,
    "Tamarind drink": 92510650,
    "Fruit punch, made with fruit juice and soda": 92510720,
    "Fruit punch, made with soda, fruit juice, and sherbet or ice cream": 92510730,
    "Lemonade, fruit juice drink": 92510955,
    "Lemonade, fruit flavored drink": 92510960,
    "Lemonade, frozen concentrate, not reconstituted": 92511000,
    "Fruit flavored drink": 92511015,
    "Fruit juice beverage, 40-50% juice, citrus": 92511250,
    "Frozen daiquiri mix, from frozen concentrate, reconstituted": 92512050,
    "Pina Colada, nonalcoholic": 92512090,
    "Margarita mix, nonalcoholic": 92512110,
    "Slush frozen drink": 92513000,
    "Slush frozen drink, no sugar added": 92513010,
    "Fruit flavored drink, with high vitamin C": 92530410,
    "Cranberry juice drink, with high vitamin C": 92530510,
    "Fruit juice drink, with high vitamin C": 92530610,
    "Vegetable and fruit juice drink, with high vitamin C": 92530950,
    "Fruit juice drink (Sunny D)": 92531030,
    "Fruit flavored drink, powdered, reconstituted": 92541010,
    "Fruit flavored drink, with high vitamin C, powdered, reconstituted": 92542000,
    "Fruit juice drink, with high vitamin C, light": 92550030,
    "Fruit juice drink, light": 92550035,
    "Fruit juice drink, diet": 92550040,
    "Cranberry juice drink, with high vitamin C, light": 92550110,
    "Grape juice drink, light": 92550200,
    "Orange juice beverage, 40-50% juice, light": 92550350,
    "Apple juice beverage, 40-50% juice, light": 92550360,
    "Lemonade, fruit juice drink, light": 92550370,
    "Pomegranate juice beverage, 40-50% juice, light": 92550380,
    "Vegetable and fruit juice drink, with high vitamin C, diet": 92550400,
    "Vegetable and fruit juice drink, with high vitamin C, light": 92550405,
    "Fruit flavored drink, with high vitamin C, diet": 92550610,
    "Fruit flavored drink, diet": 92550620,
    "Fruit flavored drink, with high vitamin C, powdered, reconstituted, diet": 92552000,
    "Fruit flavored drink, powdered, reconstituted, diet": 92552010,
    "Fruit juice drink, reduced sugar (Sunny D)": 92552020,
    "Fruit juice drink (Capri Sun)": 92552030,
    "Fruit juice drink, with high vitamin C, plus added calcium": 92582100,
    "Fruit juice drink, added calcium (Sunny D)": 92582110,
    "Horchata beverage, made with water": 92610020,
    "Horchata beverage, made with milk": 92610030,
    "Oatmeal beverage with water": 92611010,
    "Oatmeal beverage with milk": 92611100,
    "Sugar cane beverage": 92612010,
    "Cornmeal beverage": 92613010,
    "Cornmeal beverage with chocolate milk": 92613510,
    "Wine, nonalcoholic": 92801000,
    "Nonalcoholic malt beverage": 92803000,
    "Shirley Temple": 92804000,
    "Fruit flavored drink, with high vitamin C, powdered, not reconstituted": 92900100,
    "Fruit flavored drink, powdered, not reconstituted": 92900110,
    "Fruit flavored drink, powdered, not reconstituted, diet": 92900200,
    "Sports drink, dry concentrate, not reconstituted": 92900300,
    "Beer": 93101000,
    "Beer, light": 93102000,
    "Beer, low carb": 93102100,
    "Beer, higher alcohol": 93102300,
    "Alcoholic malt beverage, sweetened": 93106000,
    "Alcoholic malt beverage": 93106100,
    "Hard cider": 93106500,
    "Cordial or liqueur": 93201000,
    "Cordial or liqueur, coffee flavored": 93202000,
    "Cocktail, NFS": 93301000,
    "Alexander": 93301010,
    "Bloody Mary": 93301030,
    "Canadian Club and soda": 93301031,
    "Cape Cod": 93301032,
    "Daiquiri": 93301040,
    "Gelatin shot, alcoholic": 93301045,
    "Gimlet": 93301050,
    "Gin and Tonic": 93301060,
    "Greyhound": 93301075,
    "Jagerbomb": 93301083,
    "Kamikaze": 93301085,
    "Manhattan": 93301090,
    "Margarita": 93301100,
    "Martini": 93301110,
    "Martini, flavored": 93301111,
    "Mimosa": 93301115,
    "Mint julep": 93301120,
    "Mojito": 93301125,
    "Old fashioned": 93301130,
    "Orange Blossom": 93301132,
    "Rob Roy": 93301135,
    "Rusty Nail": 93301136,
    "Screwdriver": 93301140,
    "Seabreeze": 93301141,
    "Seven and Seven": 93301142,
    "Tom Collins": 93301150,
    "Whiskey sour": 93301160,
    "Whiskey and soda": 93301170,
    "Whiskey and water": 93301181,
    "Whiskey and cola": 93301182,
    "Whiskey and diet cola": 93301183,
    "Whiskey and ginger ale": 93301184,
    "Rum and cola": 93301190,
    "Rum and diet cola": 93301191,
    "Pina Colada": 93301200,
    "Brandy and cola": 93301205,
    "Vodka and soda": 93301211,
    "Vodka and lemonade": 93301213,
    "Vodka and cola": 93301214,
    "Vodka and diet cola": 93301215,
    "Vodka and energy drink": 93301216,
    "Vodka and water": 93301217,
    "Vodka and tonic": 93301218,
    "Sloe gin fizz": 93301230,
    "Black Russian": 93301240,
    "White Russian": 93301250,
    "Fruit punch, alcoholic": 93301270,
    "Champagne punch": 93301275,
    "Singapore Sling": 93301280,
    "Mai Tai": 93301310,
    "Tequila Sunrise": 93301320,
    "Gin Rickey": 93301330,
    "Long Island iced tea": 93301360,
    "Fuzzy Navel": 93301370,
    "Irish Coffee": 93301400,
    "Liqueur with cream": 93301450,
    "Frozen daiquiri": 93301500,
    "Frozen margarita": 93301510,
    "Eggnog, alcoholic": 93301550,
    "Gin fizz": 93301600,
    "Rum, hot buttered": 93302000,
    "Wine, table, red": 93401010,
    "Wine, table, white": 93401020,
    "Wine, table, rose": 93401030,
    "Wine, rice": 93401100,
    "Wine, dessert, sweet": 93402000,
    "Wine, light": 93403000,
    "Wine cooler": 93404000,
    "Sangria, red": 93404550,
    "Sangria, white": 93404560,
    "Wine spritzer": 93405000,
    "Glug": 93406000,
    "Brandy": 93501000,
    "Whiskey": 93502000,
    "Scotch": 93502100,
    "Gin": 93503000,
    "Rum": 93504000,
    "Rum cooler": 93504100,
    "Vodka": 93505000,
    "Tequila": 93505100,
    "Water, NFS": 94000010,
    "Water, tap": 94000100,
    "Water, bottled, plain": 94100100,
    "Water, non-carbonated, flavored": 94100200,
    "Water beverage, fruit flavored": 94100300,
    "Water, enhanced, regular": 94200100,
    "Water, enhanced, diet": 94200200,
    "Water, baby": 94300100,
    "Nutritional drink or shake, ready-to-drink (Boost)": 95101000,
    "Nutritional drink or shake, ready-to-drink (Boost Plus)": 95101010,
    "Nutritional drink or shake, ready-to-drink (Carnation Instant Breakfast)": 95102000,
    "Nutritional drink or shake, ready-to-drink (Ensure)": 95103000,
    "Nutritional drink or shake, ready-to-drink (Ensure Plus)": 95103010,
    "Nutritional drink or shake, ready-to-drink, sugar free (Glucerna)": 95104000,
    "Nutritional drink or shake, ready-to-drink (Kellogg's Special K Protein)": 95105000,
    "Nutritional drink or shake, ready-to-drink (Muscle Milk)": 95106000,
    "Nutritional drink or shake, ready-to-drink, light (Muscle Milk)": 95106010,
    "Nutritional drink or shake, ready-to-drink (Slim Fast)": 95110000,
    "Nutritional drink or shake, ready-to-drink, sugar free (Slim Fast)": 95110010,
    "Nutritional drink or shake, high protein, ready-to-drink (Slim Fast)": 95110020,
    "Nutritional drink or shake, ready-to-drink, NFS": 95120000,
    "Nutritional drink or shake, high protein, ready-to-drink, NFS": 95120010,
    "Nutritional drink or shake, high protein, light, ready-to-drink, NFS": 95120020,
    "Nutritional drink or shake, liquid, soy-based": 95120050,
    "Nutritional powder mix (Carnation Instant Breakfast)": 95201000,
    "Nutritional powder mix, sugar free (Carnation Instant Breakfast)": 95201010,
    "Nutritional powder mix (EAS Whey Protein Powder)": 95201200,
    "Nutritional powder mix (EAS Soy Protein Powder)": 95201300,
    "Nutritional powder mix, high protein (Herbalife)": 95201500,
    "Nutritional powder mix (Isopure)": 95201600,
    "Nutritional powder mix (Kellogg's Special K20 Protein Water)": 95201700,
    "Nutritional powder mix (Muscle Milk)": 95202000,
    "Nutritional powder mix, light (Muscle Milk)": 95202010,
    "Nutritional powder mix (Slim Fast)": 95210000,
    "Nutritional powder mix, sugar free (Slim Fast)": 95210010,
    "Nutritional powder mix, high protein (Slim Fast)": 95210020,
    "Nutritional powder mix, NFS": 95220000,
    "Nutritional powder mix, high protein, NFS": 95220010,
    "Nutritional powder mix, whey based, NFS": 95230000,
    "Nutritional powder mix, protein, soy based, NFS": 95230010,
    "Nutritional powder mix, protein, light, NFS": 95230020,
    "Nutritional powder mix, protein, NFS": 95230030,
    "Energy drink (Full Throttle)": 95310200,
    "Energy drink (Monster)": 95310400,
    "Energy drink (Mountain Dew AMP)": 95310500,
    "Energy drink (No Fear)": 95310550,
    "Energy drink (No Fear Motherload)": 95310555,
    "Energy drink (NOS)": 95310560,
    "Energy drink (Red Bull)": 95310600,
    "Energy drink (Rockstar)": 95310700,
    "Energy drink (SoBe Energize Energy Juice Drink)": 95310750,
    "Energy drink (Vault)": 95310800,
    "Energy Drink": 95311000,
    "Energy drink, low calorie (Monster)": 95312400,
    "Energy drink, sugar free (Monster)": 95312410,
    "Energy drink, sugar free (Mountain Dew AMP)": 95312500,
    "Energy drink, sugar free (No Fear)": 95312550,
    "Energy drink, sugar-free (NOS)": 95312555,
    "Energy drink (Ocean Spray Cran-Energy Juice Drink)": 95312560,
    "Energy drink, sugar-free (Red Bull)": 95312600,
    "Energy drink, sugar free (Rockstar)": 95312700,
    "Energy drink, sugar free (Vault)": 95312800,
    "Energy drink (XS)": 95312900,
    "Energy drink (XS Gold Plus)": 95312905,
    "Energy drink, sugar free": 95313200,
    "Sports drink (Gatorade G)": 95320200,
    "Sports drink (Powerade)": 95320500,
    "Sports drink, NFS": 95321000,
    "Sports drink, low calorie (Gatorade G2)": 95322200,
    "Sports drink, low calorie (Powerade Zero)": 95322500,
    "Sports drink, low calorie": 95323000,
    "Fluid replacement, electrolyte solution": 95330100,
    "Fluid replacement, 5% glucose in water": 95330500,
    "Fruit juice, acai blend": 95342000,
    "Cheese as ingredient in sandwiches": 99991400,
    "Cheese and Queso as ingredient": 99991410,
    "Beef as ingredient in recipes": 99992100,
    "Breakfast meat as ingredient in omelet": 99992230,
    "Chicken as ingredient in recipes": 99992405,
    "Fish fillet, fried as ingredient in sandwiches": 99992610,
    "Breading or batter as ingredient in food": 99995000,
    "Wheat bread as ingredient in sandwiches": 99995130,
    "Wheat bun as ingredient in sandwiches": 99995135,
    "Spinach, cooked, as ingredient": 99997210,
    "Broccoli, cooked, as ingredient": 99997220,
    "Carrots, cooked, as ingredient": 99997310,
    "Tomatoes, cooked, as ingredient": 99997410,
    "Onions, cooked, as ingredient": 99997510,
    "Mushrooms, cooked, as ingredient": 99997515,
    "Green pepper, cooked, as ingredient": 99997520,
    "Red pepper, cooked, as ingredient": 99997525,
    "Cabbage, cooked, as ingredient": 99997530,
    "Cauliflower, cooked, as ingredient": 99997535,
    "Eggplant, cooked, as ingredient": 99997540,
    "Green beans, cooked, as ingredient": 99997545,
    "Summer squash, cooked, as ingredient": 99997550,
    "Dark green vegetables as ingredient in omelet": 99997800,
    "Tomatoes as ingredient in omelet": 99997802,
    "Other vegetables as ingredient in omelet": 99997804,
    "Vegetables as ingredient in curry": 99997810,
    "Sauce as ingredient in hamburgers": 99998130,
    "Industrial oil as ingredient in food": 99998210,
    "Milk, low sodium, whole": 11111100,
    "Milk, calcium fortified, whole": 11111150,
    "Milk, calcium fortified, low fat (1%)": 11111160,
    "Milk, calcium fortified, fat free (skim)": 11111170,
    "Milk, acidophilus, low fat (1%)": 11112120,
    "Milk, acidophilus, reduced fat (2%)": 11112130,
    "Buttermilk, low fat (1%)": 11115100,
    "Buttermilk, reduced fat (2%)": 11115200,
    "Soy milk, nonfat, chocolate": 11321200,
    "Imitation milk, non-soy, sweetened": 11340000,
    "Dill dip, yogurt based": 11440020,
    "Yogurt, frozen, NS as to flavor, lowfat milk": 11460150,
    "Yogurt, frozen, chocolate, lowfat milk": 11460160,
    "Yogurt, frozen, flavors other than chocolate, lowfat milk": 11460170,
    "Yogurt, frozen, NS as to flavor, nonfat milk": 11460190,
    "Yogurt, frozen, chocolate, nonfat milk": 11460200,
    "Yogurt, frozen, flavors other than chocolate, with sorbet or sorbet-coated": 11460250,
    "Yogurt, frozen, flavors other than chocolate, nonfat milk": 11460300,
    "Yogurt, frozen, chocolate, nonfat milk, with low-calorie sweetener": 11460400,
    "Yogurt, frozen, flavors other than chocolate, nonfat milk, with low-calorie sweetener": 11460410,
    "Yogurt, frozen, NS as to flavor, whole milk": 11460420,
    "Yogurt, frozen, chocolate, whole milk": 11460430,
    "Yogurt, frozen, flavors other than chocolate, whole milk": 11460440,
    "Yogurt, frozen, chocolate-coated": 11461000,
    "Yogurt, frozen, cone, flavors other than chocolate, lowfat milk": 11461270,
    "Yogurt, frozen, cone, chocolate, lowfat milk": 11461280,
    "Yogurt, whole milk, baby food, with fruit and multigrain cereal puree, NFS": 11480020,
    "Yogurt, whole milk, baby food, with fruit and multigrain cereal puree, plus iron": 11480030,
    "Yogurt, whole milk, baby food, with fruit and multigrain cereal puree, plus DHA": 11480040,
    "Chocolate milk, ready to drink, fat free (Nesquik)": 11511610,
    "Hot chocolate / Cocoa, ready to drink, made with nonfat milk and whipped cream": 11512110,
    "Hot chocolate / Cocoa, ready to drink, made with non-dairy milk and whipped cream": 11512120,
    "Chocolate milk, made from dry mix, NS as to type of milk": 11513000,
    "Chocolate milk, made from dry mix with low fat milk": 11513200,
    "Chocolate milk, made from reduced sugar mix, NS as to type of milk": 11513350,
    "Chocolate milk, made from reduced sugar mix with whole milk": 11513355,
    "Chocolate milk, made from reduced sugar mix with reduced fat milk": 11513360,
    "Chocolate milk, made from reduced sugar mix with low fat milk": 11513365,
    "Chocolate milk, made from reduced sugar mix with fat free milk": 11513370,
    "Chocolate milk, made from reduced sugar mix with non-dairy milk": 11513375,
    "Chocolate milk, made from no sugar added dry mix, NS as to type of milk (Nesquik)": 11513390,
    "Chocolate milk, made from no sugar added dry mix with whole milk (Nesquik)": 11513391,
    "Chocolate milk, made from no sugar added dry mix with low fat milk (Nesquik)": 11513393,
    "Chocolate milk, made from no sugar added dry mix with fat free milk  (Nesquik)": 11513394,
    "Chocolate milk, made from light syrup, NS as to type of milk": 11513800,
    "Chocolate milk, made from light syrup with non-dairy milk": 11513805,
    "Chocolate milk, made from sugar free syrup, NS as to type of milk": 11513850,
    "Chocolate milk, made from sugar free syrup with reduced fat milk": 11513852,
    "Chocolate milk, made from sugar free syrup with low fat milk": 11513853,
    "Chocolate milk, made from sugar free syrup with fat free milk": 11513854,
    "Chocolate milk, made from sugar free syrup with non-dairy milk": 11513855,
    "Eggnog, lowfat / light": 11531500,
    "Infant formula, NS as to form (Similac Expert Care Alimentum)": 11710050,
    "Infant formula, powder, made with water, NFS (Similac Expert Care Alimentum)": 11710053,
    "Infant formula, powder, made with tap water (Similac Expert Care Alimentum)": 11710054,
    "Infant formula, powder, made with plain bottled water (Similac Expert Care Alimentum)": 11710055,
    "Infant formula, NS as to form (Similac Advance)": 11710350,
    "Infant formula, liquid concentrate, made with water, NFS (Similac Advance)": 11710352,
    "Infant formula, powder, made with water, NFS (Similac Advance)": 11710353,
    "Infant formula, liquid concentrate, made with tap water (Similac Advance)": 11710354,
    "Infant formula, liquid concentrate, made with plain bottled water (Similac Advance)": 11710355,
    "Infant formula, liquid concentrate, made with baby water (Similac Advance)": 11710356,
    "Infant formula, NS as to form (Similac Advance Organic)": 11710360,
    "Infant formula, ready-to-feed (Similac Advance Organic)": 11710361,
    "Infant formula, powder, made with water, NFS (Similac Advance Organic)": 11710363,
    "Infant formula, powder, made with tap water (Similac Advance Organic)": 11710367,
    "Infant formula, powder, made with plain bottled water (Similac Advance Organic)": 11710368,
    "Infant formula, powder, made with baby water (Similac Advance Organic)": 11710369,
    "Infant formula, NS as to form (Similac Sensitive)": 11710370,
    "Infant formula, liquid concentrate, made with water, NFS (Similac Sensitive)": 11710372,
    "Infant formula, powder, made with water, NFS (Similac Sensitive)": 11710373,
    "Infant formula, liquid concentrate, made with tap water (Similac Sensitive)": 11710374,
    "Infant formula, liquid concentrate, made with plain bottled water (Similac Sensitive)": 11710375,
    "Infant formula, liquid concentrate, made with baby water (Similac Sensitive)": 11710376,
    "Infant formula, NS as to form (Similac for Spit-Up)": 11710380,
    "Infant formula, NS as to form (Similac Expert Care NeoSure)": 11710470,
    "Infant formula, ready-to-feed (Similac Expert Care NeoSure)": 11710471,
    "Infant formula, powder, made with water, NFS (Similac Expert Care NeoSure)": 11710473,
    "Infant formula, powder, made with tap water (Similac Expert Care NeoSure)": 11710477,
    "Infant formula, powder, made with plain bottled water (Similac Expert Care NeoSure)": 11710478,
    "Infant formula, powder, made with baby water (Similac Expert Care NeoSure)": 11710479,
    "Infant formula, NS as to form (Similac Go and Grow)": 11710480,
    "Infant formula, NS as to form (Enfamil Newborn)": 11710620,
    "Infant formula, powder, made with water, NFS (Enfamil Newborn)": 11710626,
    "Infant formula, powder, made with tap water (Enfamil Newborn)": 11710627,
    "Infant formula, powder, made with plain bottled water (Enfamil Newborn)": 11710628,
    "Infant formula, NS as to form (Enfamil Infant)": 11710630,
    "Infant formula, liquid concentrate, made with water, NFS (Enfamil Infant)": 11710632,
    "Infant formula, liquid concentrate, made with tap water (Enfamil Infant)": 11710633,
    "Infant formula, liquid concentrate, made with plain bottled water (Enfamil Infant)": 11710634,
    "Infant formula, liquid concentrate, made with baby water (Enfamil Infant)": 11710635,
    "Infant formula, powder, made with water, NFS (Enfamil Infant)": 11710636,
    "Infant formula, NS as to form (Enfamil A.R.)": 11710660,
    "Infant formula, powder, made with water, NFS (Enfamil A.R.)": 11710663,
    "Infant formula, powder, made with tap water (Enfamil A.R.)": 11710664,
    "Infant formula, NS as to form (Enfamil EnfaCare)": 11710665,
    "Infant formula, ready-to-feed (Enfamil EnfaCare)": 11710666,
    "Infant formula, powder, made with water, NFS (Enfamil EnfaCare)": 11710667,
    "Infant formula, powder, made with plain bottled water (Enfamil A.R.)": 11710668,
    "Infant formula, NS as to form (Enfamil Gentlease)": 11710670,
    "Infant formula, powder, made with water, NFS (Enfamil Gentlease)": 11710673,
    "Infant formula, powder, made with tap water (Enfamil EnfaCare)": 11710674,
    "Infant formula, powder, made with plain bottled water (Enfamil EnfaCare)": 11710675,
    "Infant formula, powder, made with baby water (Enfamil EnfaCare)": 11710676,
    "Infant formula, NS as to form (Enfamil Enfagrow Toddler Transitions)": 11710680,
    "Infant formula, ready-to-feed (Enfamil Enfragrow Toddler Transitions)": 11710681,
    "Infant formula, powder, made with water, NFS (Enfamil Enfragrow Toddler Transitions)": 11710683,
    "Infant formula, powder, made with tap water (Enfamil Enfagrow Toddler Transitions)": 11710687,
    "Infant formula, powder, made with plain bottled water (Enfamil Enfagrow Toddler Transitions)": 11710688,
    "Infant formula, NS as to form (Enfamil Enfagrow Toddler Transitions Gentlease)": 11710690,
    "Infant formula, powder, made with water, NFS (Enfamil Enfagrow Toddler Transitions Gentlease)": 11710693,
    "Infant formula, powder, made with tap water (Enfamil Enfagrow Toddler Transitions Gentlease)": 11710697,
    "Infant formula, powder, made with plain bottled water (Enfamil Enfagrow Toddler Transitions Gentlease)": 11710698,
    "Infant formula, powder, made with baby water (Enfamil Enfagrow Toddler Transitions Gentlease)": 11710699,
    "Infant formula, NS as to form (PediaSure)": 11710800,
    "Infant formula, with fiber, NS as to form (PediaSure Fiber)": 11710805,
    "Infant formula, with fiber, ready-to-feed (PediaSure Fiber)": 11710806,
    "Infant formula, NS as to form (Gerber Good Start Gentle)": 11710910,
    "Infant formula, liquid concentrate, made with water, NFS (Gerber Good Start Gentle)": 11710912,
    "Infant formula, powder, made with water, NFS (Gerber Good Start Gentle)": 11710913,
    "Infant formula, liquid concentrate, made with tap water (Gerber Good Start Gentle)": 11710914,
    "Infant formula, liquid concentrate, made with plain bottled water (Gerber Good Start Gentle)": 11710915,
    "Infant formula, liquid concentrate, made with baby water (Gerber Good Start Gentle)": 11710916,
    "Infant formula, NS as to form (Gerber Good Start Protect)": 11710920,
    "Infant formula, powder, made with water, NFS (Gerber Good Start Protect)": 11710923,
    "Infant formula, powder, made with tap water (Gerber Good Start Protect)": 11710927,
    "Infant formula, powder, made with plain bottled water (Gerber Good Start Protect)": 11710928,
    "Infant formula, powder, made with baby water (Gerber Good Start Protect)": 11710929,
    "Infant formula, NS as to form (Gerber Graduates Protect)": 11710940,
    "Infant formula, NS as to form (Store Brand)": 11710960,
    "Infant formula, liquid concentrate, made with water, NFS (Store Brand)": 11710961,
    "Infant formula, powder, made with water, NFS (Store Brand)": 11710962,
    "Infant formula, ready-to-feed (Store Brand)": 11710963,
    "Infant formula, liquid concentrate, made with tap water (Store Brand)": 11710964,
    "Infant formula, liquid concentrate, made with plain bottled water (Store Brand)": 11710965,
    "Infant formula, liquid concentrate, made with baby water (Store Brand)": 11710966,
    "Infant formula, NS as to form (Enfamil ProSobee)": 11720310,
    "Infant formula, liquid concentrate, made with water, NFS (Enfamil ProSobee)": 11720312,
    "Infant formula, powder, made with water, NFS (Enfamil ProSobee)": 11720313,
    "Infant formula, liquid concentrate, made with tap water (Enfamil ProSobee)": 11720314,
    "Infant formula, liquid concentrate, made with plain bottled water (Enfamil ProSobee)": 11720315,
    "Infant formula, liquid concentrate, made with baby water (Enfamil ProSobee)": 11720316,
    "Infant formula, NS as to form (Enfamil Enfagrow Toddler Transitions Soy)": 11720320,
    "Infant formula, powder, made with water, NFS (Enfamil Enfagrow Toddler Transitions Soy)": 11720323,
    "Infant formula, NS as to form (Similac Isomil Soy)": 11720410,
    "Infant formula, liquid concentrate, made with water, NFS (Similac Isomil Soy)": 11720412,
    "Infant formula, powder, made with water, NFS (Similac Isomil Soy)": 11720413,
    "Infant formula, liquid concentrate, made with tap water (Similac Isomil Soy)": 11720414,
    "Infant formula, liquid concentrate, made with plain bottled water (Similac Isomil Soy)": 11720415,
    "Infant formula, liquid concentrate, made with baby water (Similac Isomil Soy)": 11720416,
    "Infant formula, ready-to-feed (Similac Expert Care for Diarrhea)": 11720431,
    "Infant formula, NS as to form (Gerber Good Start Soy)": 11720610,
    "Infant formula, liquid concentrate, made with water, NFS (Gerber Good Start Soy)": 11720612,
    "Infant formula, powder, made with water, NFS (Gerber Good Start Soy)": 11720613,
    "Infant formula, liquid concentrate, made with tap water (Gerber Good Start Soy)": 11720614,
    "Infant formula, liquid concentrate, made with plain bottled water (Gerber Good Start Soy)": 11720615,
    "Infant formula, liquid concentrate, made with baby water (Gerber Good Start Soy)": 11720616,
    "Infant formula, NS as to form (Gerber Graduates Soy)": 11720620,
    "Infant formula, NS as to form (Store Brand Soy)": 11720800,
    "Infant formula, ready-to-feed (Store brand Soy)": 11720801,
    "Infant formula, liquid concentrate, made with water, NFS (Store Brand Soy)": 11720802,
    "Infant formula, powder, made with water, NFS (Store Brand Soy)": 11720803,
    "Infant formula, powder, made with tap water (Store Brand Soy)": 11720807,
    "Infant formula, powder, made with plain bottled water (Store Brand Soy)": 11720808,
    "Infant formula, NS as to form (Enfamil Nutramigen)": 11740310,
    "Infant formula, liquid concentrate, made with water, NFS (Enfamil Nutramigen)": 11740312,
    "Infant formula, NS as to form (PurAmino)": 11740320,
    "Infant formula, powder, made with water, NFS (PurAmino)": 11740323,
    "Infant formula, NS as to form (Enfamil Pregestimil)": 11740400,
    "Infant formula, ready-to-feed, low iron (Enfamil Premature 20 Cal)": 11740511,
    "Infant formula, ready-to-feed, with iron (Enfamil Premature 20 Cal)": 11740521,
    "Milk, dry, not reconstituted, whole": 11811000,
    "Milk, dry, not reconstituted, low fat (1%)": 11812000,
    "Hot chocolate / Cocoa, dry mix, not reconstituted": 11830100,
    "Hot chocolate / Cocoa, dry mix, no sugar added, not reconstituted": 11830115,
    "Sour cream, half and half": 12310200,
    "Sour cream, reduced fat": 12310300,
    "Dill dip, regular": 12350210,
    "Dill dip, light": 12350215,
    "Ice cream, rich, flavors other than chocolate": 13110120,
    "Ice cream, rich, chocolate": 13110130,
    "Ice cream, rich, NS as to flavor": 13110140,
    "Ice cream, soft serve, NS as to flavor": 13110220,
    "Ice cream, no sugar added, NS as to flavor": 13110310,
    "Ice cream, no sugar added, flavors other than chocolate": 13110320,
    "Ice cream, no sugar added, chocolate": 13110330,
    "Ice cream bar or stick, rich chocolate ice cream, thick chocolate covering": 13120120,
    "Ice cream bar or stick, rich ice cream, thick chocolate covering": 13120121,
    "Ice cream bar or stick, rich ice cream, chocolate covered, with nuts": 13120130,
    "Ice cream bar, cake covered": 13120300,
    "Ice cream bar, stick or nugget, with crunch coating": 13120310,
    "Ice cream bar or stick with fruit": 13120400,
    "Ice cream cone with nuts, flavors other than chocolate": 13120700,
    "Ice cream cone, chocolate covered, with nuts, flavors other than chocolate": 13120710,
    "Ice cream cone, chocolate covered or dipped, flavors other than chocolate": 13120720,
    "Ice cream cone with nuts, chocolate ice cream": 13120750,
    "Ice cream cone, chocolate covered or dipped, chocolate ice cream": 13120760,
    "Ice cream cone, chocolate covered, with nuts, chocolate ice cream": 13120780,
    "Ice cream sundae, prepackaged type, flavors other than chocolate": 13121200,
    "Ice cream sundae, fudge topping, with cake, with whipped cream": 13121500,
    "Ice cream pie, no crust": 13122100,
    "Ice cream pie, with cookie crust, fudge topping, and whipped cream": 13122500,
    "Dippin' Dots, flash frozen ice cream snacks, flavors other than chocolate": 13127000,
    "Dippin' Dots, flash frozen ice cream snacks, chocolate": 13127010,
    "Light ice cream, no sugar added, NS as to flavor": 13130320,
    "Light ice cream, no sugar added, flavors other than chocolate": 13130330,
    "Light ice cream, no sugar added, chocolate": 13130340,
    "Light ice cream, soft serve, NS as to flavor": 13130590,
    "Light ice cream, soft serve, flavors other than chocolate": 13130600,
    "Light ice cream, soft serve, chocolate": 13130610,
    "Light ice cream, soft serve cone, flavors other than chocolate": 13130620,
    "Light ice cream, soft serve cone, chocolate": 13130630,
    "Light ice cream, soft serve cone, NS as to flavor": 13130640,
    "Ice cream sandwich, made with light, no sugar added ice cream": 13136000,
    "Light ice cream, bar or stick, chocolate covered, with nuts": 13140110,
    "Light ice cream, cone, NFS": 13140450,
    "Light ice cream, cone, flavors other than chocolate": 13140500,
    "Light ice cream, cone, chocolate": 13140550,
    "Light ice cream, no sugar added, cone, NS as to flavor": 13140570,
    "Light ice cream, no sugar added, cone, flavors other than chocolate": 13140575,
    "Light ice cream, no sugar added, cone, chocolate": 13140580,
    "Light ice cream, sundae, soft serve, chocolate or fudge topping, with whipped cream": 13140600,
    "Light ice cream, sundae, soft serve, fruit topping, with whipped cream": 13140630,
    "Light ice cream, sundae, soft serve, not fruit or chocolate topping, with whipped cream": 13140650,
    "Light ice cream, sundae, soft serve, chocolate or fudge topping, without whipped cream": 13140660,
    "Light ice cream, sundae, soft serve, fruit topping, without whipped cream": 13140670,
    "Light ice cream, sundae, soft serve, not fruit or chocolate topping, without whipped cream": 13140680,
    "Light ice cream, creamsicle or dreamsicle, no sugar added": 13140710,
    "Milk dessert bar or stick, frozen, with coconut": 13142000,
    "Fat free ice cream, no sugar added, chocolate": 13160150,
    "Fat free ice cream, no sugar added, flavors other than chocolate": 13160160,
    "Fat free ice cream, flavors other than chocolate": 13160400,
    "Fat free ice cream, chocolate": 13160410,
    "Fat free ice cream, NS as to flavor": 13160420,
    "Milk dessert bar, frozen, made from lowfat milk": 13161000,
    "Milk dessert sandwich bar, frozen, made from lowfat milk": 13161500,
    "Milk dessert sandwich bar, frozen, with low-calorie sweetener, made from lowfat milk": 13161520,
    "Light ice cream, bar or stick, with low-calorie sweetener, chocolate coated": 13161630,
    "Baked Alaska": 13170000,
    "Bread pudding made with evaporated milk and rum": 13210150,
    "Diplomat pudding, Puerto Rican style": 13210160,
    "Pudding, Mexican bread": 13210180,
    "Pudding, Mexican bread, low fat": 13210190,
    "Pudding, chocolate, NS as to from dry mix or ready-to-eat": 13210220,
    "Pudding, chocolate, low calorie, containing artificial sweetener, NS as to from dry mix or ready-to-eat": 13210250,
    "Rice flour cream, Puerto Rican style": 13210260,
    "Custard, Puerto Rican style": 13210270,
    "Pudding, flavors other than chocolate, low calorie, containing artificial sweetener, NS as to from dry mix or ready-to-eat": 13210290,
    "Pudding, tapioca, made from home recipe, made with milk": 13210500,
    "Pudding, tapioca, chocolate, made with milk": 13210530,
    "Pudding, coconut": 13210610,
    "Pudding, cornmeal, milk, and molasses": 13210710,
    "Pudding, pumpkin": 13210750,
    "Pumpkin pudding, Puerto Rican style": 13210810,
    "Fresh corn custard, Puerto Rican style": 13210820,
    "Pudding, ready-to-eat, chocolate, reduced fat": 13220230,
    "Pudding, ready-to-eat, chocolate, fat free": 13220235,
    "Pudding, ready-to-eat, flavors other than chocolate, reduced fat": 13220240,
    "Pudding, ready-to-eat, flavors other than chocolate, fat free": 13220245,
    "Pudding, ready-to-eat, chocolate and non-chocolate flavors combined": 13230200,
    "Pudding, ready-to-eat, tapioca, fat free": 13230510,
    "Mousse, not chocolate": 13250100,
    "Mousse, chocolate, lowfat, reduced calorie, prepared from dry mix, water added": 13250200,
    "Coconut custard, Puerto Rican style": 13252100,
    "Custard pudding, flavor other than chocolate, baby food, NS as to strained or junior": 13310000,
    "Custard pudding, baby food, flavor other than chocolate, strained": 13311000,
    "Custard pudding, baby food, flavor other than chocolate, junior": 13312000,
    "Milk gravy, quick gravy": 13412000,
    "Cheese, Mozzarella, whole milk": 14107020,
    "Queso Chihuahua": 14132000,
    "Cheese, cottage, lowfat, with vegetables": 14204030,
    "Cheese sandwich, American cheese, on whole wheat bread, no spread": 14640006,
    "Cheese sandwich, Cheddar cheese, on whole wheat bread, no spread": 14640012,
    "Cheese sandwich, reduced fat American cheese, on whole wheat bread, no spread": 14640018,
    "Cheese sandwich, reduced fat Cheddar cheese, on white bread, no spread": 14640020,
    "Cheese sandwich, reduced fat Cheddar cheese, on wheat bread, no spread": 14640022,
    "Cheese sandwich, reduced fat Cheddar cheese, on whole wheat bread, no spread": 14640024,
    "Cheese sandwich, American cheese, on white bread, with mayonnaise": 14640026,
    "Cheese sandwich, American cheese, on wheat bread, with mayonnaise": 14640028,
    "Cheese sandwich, American cheese, on whole wheat bread, with mayonnaise": 14640030,
    "Cheese sandwich, Cheddar cheese, on white bread, with mayonnaise": 14640032,
    "Cheese sandwich, Cheddar cheese, on wheat bread, with mayonnaise": 14640034,
    "Cheese sandwich, Cheddar cheese, on whole wheat bread, with mayonnaise": 14640036,
    "Cheese sandwich, reduced fat American cheese, on white bread, with mayonnaise": 14640038,
    "Cheese sandwich, reduced fat American cheese,, on wheat bread, with mayonnaise": 14640040,
    "Cheese sandwich, reduced fat American cheese, on whole wheat bread, with mayonnaise": 14640042,
    "Cheese sandwich, reduced fat Cheddar cheese, on white bread, with mayonnaise": 14640044,
    "Cheese sandwich, reduced fat Cheddar cheese, on wheat bread, with mayonnaise": 14640046,
    "Cheese sandwich, reduced fat Cheddar cheese, on whole wheat bread, with mayonnaise": 14640048,
    "Cheese sandwich, American cheese, on white bread, with butter": 14640050,
    "Cheese sandwich, American cheese, on wheat bread, with butter": 14640052,
    "Cheese sandwich, American cheese, on whole wheat bread, with butter": 14640054,
    "Cheese sandwich, Cheddar cheese, on white bread, with butter": 14640056,
    "Cheese sandwich, Cheddar cheese, on wheat bread, with butter": 14640058,
    "Cheese sandwich, Cheddar cheese, on whole wheat bread, with butter": 14640060,
    "Cheese sandwich, reduced fat American cheese, on white bread, with butter": 14640062,
    "Cheese sandwich, reduced fat American cheese, on wheat bread, with butter": 14640064,
    "Cheese sandwich, reduced fat American cheese, on whole wheat bread, with butter": 14640066,
    "Cheese sandwich, reduced fat Cheddar cheese, on white bread, with butter": 14640068,
    "Cheese sandwich, reduced fat Cheddar cheese, on wheat bread, with butter": 14640070,
    "Cheese sandwich, reduced fat Cheddar cheese, on whole wheat bread, with butter": 14640072,
    "Grilled cheese sandwich, American cheese, on whole wheat bread": 14640115,
    "Grilled cheese sandwich, Cheddar cheese, on whole wheat bread": 14640135,
    "Grilled cheese sandwich, reduced fat American cheese, on whole wheat bread": 14640165,
    "Grilled cheese sandwich, reduced fat Cheddar cheese, on white bread": 14640185,
    "Grilled cheese sandwich, reduced fat Cheddar cheese, on wheat bread": 14640190,
    "Grilled cheese sandwich, reduced fat Cheddar cheese, on whole wheat bread": 14640195,
    "Cheese sauce made with lowfat cheese": 14650150,
    "Beer cheese soup, made with milk": 14710200,
    "Meat, baby food, NS as to type, NS as to strained or junior": 20000070,
    "Meat sticks, baby food, NS as to type of meat": 20000090,
    "Beef, NS as to cut, cooked, lean and fat eaten": 21000110,
    "Beef, NS as to cut, cooked, lean only eaten": 21000120,
    "Steak, NS as to type of meat, cooked, lean and fat eaten": 21001010,
    "Steak, NS as to type of meat, cooked, lean only eaten": 21001020,
    "Beef, pickled": 21002000,
    "Beef, NS as to cut, fried, NS to fat eaten": 21003000,
    "Beef steak, NS as to cooking method, lean and fat eaten": 21101010,
    "Beef steak, NS as to cooking method, lean only eaten": 21101020,
    "Beef steak, broiled or baked, NS as to fat eaten": 21101110,
    "Beef steak, broiled or baked, lean and fat eaten": 21101120,
    "Beef steak, broiled or baked, lean only eaten": 21101130,
    "Beef steak, fried, NS as to fat eaten": 21102110,
    "Beef steak, fried, lean and fat eaten": 21102120,
    "Beef steak, fried, lean only eaten": 21102130,
    "Beef steak, breaded or floured, baked or fried, NS as to fat eaten": 21103110,
    "Beef steak, breaded or floured, baked or fried, lean and fat eaten": 21103120,
    "Beef steak, breaded or floured, baked or fried, lean only eaten": 21103130,
    "Beef steak, battered, fried, lean and fat eaten": 21104120,
    "Beef steak, battered, fried, lean only eaten": 21104130,
    "Beef steak, braised, NS as to fat eaten": 21105110,
    "Beef steak, braised, lean and fat eaten": 21105120,
    "Beef steak, braised, lean only eaten": 21105130,
    "Beef, shortribs, cooked, NS as to fat eaten": 21304000,
    "Beef, shortribs, cooked, lean and fat eaten": 21304110,
    "Beef, shortribs, cooked, lean only eaten": 21304120,
    "Beef, shortribs, barbecued, with sauce, lean and fat eaten": 21304210,
    "Beef, shortribs, barbecued, with sauce, lean only eaten": 21304220,
    "Beef, roast, roasted, lean and fat eaten": 21401110,
    "Beef, roast, roasted, lean only eaten": 21401120,
    "Beef, roast, canned": 21401400,
    "Beef, pot roast, braised or boiled, lean and fat eaten": 21407110,
    "Beef, pot roast, braised or boiled, lean only eaten": 21407120,
    "Beef, stew meat, cooked, lean and fat eaten": 21410110,
    "Beef, stew meat, cooked, lean only eaten": 21410120,
    "Corned beef, cooked, lean and fat eaten": 21416110,
    "Corned beef, cooked, lean only eaten": 21416120,
    "Corned beef, canned, ready-to-eat": 21416150,
    "Beef brisket, cooked, lean and fat eaten": 21417110,
    "Beef brisket, cooked, lean only eaten": 21417120,
    "Beef, baby food, NS as to strained or junior": 21701000,
    "Beef, baby food, junior": 21701020,
    "Pork, NS as to cut, cooked, lean and fat eaten": 22000110,
    "Pork, NS as to cut, cooked, lean only eaten": 22000120,
    "Pork, NS as to cut, fried, NS as to fat eaten": 22000200,
    "Pork, NS as to cut, fried, lean and fat eaten": 22000210,
    "Pork, NS as to cut, fried, lean only eaten": 22000220,
    "Pork, NS as to cut, breaded or floured, fried, NS as to fat eaten": 22000300,
    "Pork, NS as to cut, breaded or floured, fried, lean and fat eaten": 22000310,
    "Pork, NS as to cut, breaded or floured, fried, lean only eaten": 22000320,
    "Pork, pickled, NS as to cut": 22001000,
    "Pork, ground or patty, breaded, cooked": 22002100,
    "Pork chop, broiled or baked, NS as to fat eaten": 22101100,
    "Pork chop, broiled or baked, lean and fat eaten": 22101110,
    "Pork chop, broiled or baked, lean only eaten": 22101120,
    "Pork chop, breaded or floured, broiled or baked, NS as to fat eaten": 22101130,
    "Pork chop, breaded or floured, broiled or baked, lean and fat eaten": 22101140,
    "Pork chop, breaded or floured, broiled or baked, lean only eaten": 22101150,
    "Pork chop, fried, NS as to fat eaten": 22101200,
    "Pork chop, fried, lean and fat eaten": 22101210,
    "Pork chop, fried, lean only eaten": 22101220,
    "Pork chop, battered, fried, NS as to fat eaten": 22101400,
    "Pork chop, battered, fried, lean and fat eaten": 22101410,
    "Pork chop, battered, fried, lean only eaten": 22101420,
    "Pork chop, stewed, NS as to fat eaten": 22101500,
    "Pork chop, stewed, lean and fat eaten": 22101510,
    "Pork chop, stewed, lean only eaten": 22101520,
    "Pork chop, smoked or cured, cooked, NS as to fat eaten": 22107000,
    "Pork chop, smoked or cured, cooked, lean and fat eaten": 22107010,
    "Pork chop, smoked or cured, cooked, lean only eaten": 22107020,
    "Pork steak or cutlet, NS as to cooking method, NS as to fat eaten": 22201000,
    "Pork steak or cutlet, NS as to cooking method, lean and fat eaten": 22201010,
    "Pork steak or cutlet, NS as to cooking method, lean only eaten": 22201020,
    "Pork steak or cutlet, battered, fried, NS as to fat eaten": 22201050,
    "Pork steak or cutlet, battered, fried, lean and fat eaten": 22201060,
    "Pork steak or cutlet, battered, fried, lean only eaten": 22201070,
    "Pork steak or cutlet, fried, NS as to fat eaten": 22201200,
    "Pork steak or cutlet, fried, lean and fat eaten": 22201210,
    "Pork steak or cutlet, fried, lean only eaten": 22201220,
    "Pork steak or cutlet, breaded or floured, broiled or baked, NS as to fat eaten": 22201300,
    "Pork steak or cutlet, breaded or floured, broiled or baked, lean and fat eaten": 22201310,
    "Pork steak or cutlet, breaded or floured, broiled or baked, lean only eaten": 22201320,
    "Pork steak or cutlet, breaded or floured, fried, lean and fat eaten": 22201410,
    "Pork steak or cutlet, breaded or floured, fried, lean only eaten": 22201420,
    "Pork, tenderloin, breaded, fried": 22210310,
    "Pork, tenderloin, braised": 22210350,
    "Pork, tenderloin, baked": 22210400,
    "Pork, tenderloin, battered, fried": 22210450,
    "Ham, fried, NS as to fat eaten": 22300120,
    "Ham, fried, lean and fat eaten": 22300130,
    "Ham, fried, lean only eaten": 22300140,
    "Ham, breaded or floured, fried, NS as to fat eaten": 22300150,
    "Ham, breaded or floured, fried, lean and fat eaten": 22300160,
    "Ham, breaded or floured, fried, lean only eaten": 22300170,
    "Ham, fresh, cooked, NS as to fat eaten": 22301000,
    "Ham, fresh, cooked, lean and fat eaten": 22301110,
    "Ham, fresh, cooked, lean only eaten": 22301120,
    "Ham, smoked or cured, cooked, lean and fat eaten": 22311010,
    "Ham, smoked or cured, cooked, lean only eaten": 22311020,
    "Ham, smoked or cured, canned, lean and fat eaten": 22311510,
    "Ham, smoked or cured, canned, lean only eaten": 22311520,
    "Pork roast, NS as to cut, cooked, lean and fat eaten": 22400110,
    "Pork roast, NS as to cut, cooked, lean only eaten": 22400120,
    "Pork roast, loin, cooked, NS as to fat eaten": 22401000,
    "Pork roast, loin, cooked, lean and fat eaten": 22401010,
    "Pork roast, loin, cooked, lean only eaten": 22401020,
    "Pork roast, shoulder, cooked, NS as to fat eaten": 22411000,
    "Pork roast, shoulder, cooked, lean and fat eaten": 22411010,
    "Pork roast, shoulder, cooked, lean only eaten": 22411020,
    "Pork roast, smoked or cured, cooked, NS as to fat eaten": 22421000,
    "Pork roast, smoked or cured, cooked, lean and fat eaten": 22421010,
    "Pork roast, smoked or cured, cooked, lean only eaten": 22421020,
    "Salt pork, cooked": 22621000,
    "Pork, spareribs, cooked, lean and fat eaten": 22701010,
    "Pork, spareribs, cooked, lean only eaten": 22701020,
    "Pork, spareribs, barbecued, with sauce, NS as to fat eaten": 22701030,
    "Pork, spareribs, barbecued, with sauce, lean and fat eaten": 22701040,
    "Pork, spareribs, barbecued, with sauce, lean only eaten": 22701050,
    "Pork skin, boiled": 22709110,
    "Lamb chop, NS as to cut, cooked, lean and fat eaten": 23101010,
    "Lamb chop, NS as to cut, cooked, lean only eaten": 23101020,
    "Lamb, loin chop, cooked, NS as to fat eaten": 23104000,
    "Lamb, loin chop, cooked, lean and fat eaten": 23104010,
    "Lamb, loin chop, cooked, lean only eaten": 23104020,
    "Lamb, shoulder chop, cooked, NS as to fat eaten": 23107000,
    "Lamb, shoulder chop, cooked, lean and fat eaten": 23107010,
    "Lamb, shoulder chop, cooked, lean only eaten": 23107020,
    "Lamb, ribs, cooked, lean only eaten": 23110000,
    "Lamb, ribs, cooked, NS as to fat eaten": 23110010,
    "Lamb, ribs, cooked, lean and fat eaten": 23110050,
    "Lamb hocks, cooked": 23111010,
    "Lamb, roast, cooked, NS as to fat eaten": 23120100,
    "Lamb, roast, cooked, lean and fat eaten": 23120110,
    "Lamb, roast, cooked, lean only eaten": 23120120,
    "Goat, fried": 23150200,
    "Goat, baked": 23150250,
    "Goat head, cooked": 23150270,
    "Goat ribs, cooked": 23150300,
    "Veal, NS as to cut, cooked, NS as to fat eaten": 23200100,
    "Veal, NS as to cut, cooked, lean and fat eaten": 23200110,
    "Veal, NS as to cut, cooked, lean only eaten": 23200120,
    "Veal chop, NS as to cooking method, lean and fat eaten": 23201020,
    "Veal chop, NS as to cooking method, lean only eaten": 23201030,
    "Veal chop, fried, NS as to fat eaten": 23203010,
    "Veal chop, fried, lean and fat eaten": 23203020,
    "Veal chop, fried, lean only eaten": 23203030,
    "Veal chop, broiled, NS as to fat eaten": 23203100,
    "Veal chop, broiled, lean and fat eaten": 23203110,
    "Veal chop, broiled, lean only eaten": 23203120,
    "Veal cutlet or steak, NS as to cooking method, NS as to fat eaten": 23204010,
    "Veal cutlet or steak, NS as to cooking method, lean and fat eaten": 23204020,
    "Veal cutlet or steak, NS as to cooking method, lean only eaten": 23204030,
    "Veal cutlet or steak, broiled, NS as to fat eaten": 23204200,
    "Veal cutlet or steak, broiled, lean and fat eaten": 23204210,
    "Veal cutlet or steak, broiled, lean only eaten": 23204220,
    "Veal cutlet or steak, fried, NS as to fat eaten": 23205010,
    "Veal cutlet or steak, fried, lean and fat eaten": 23205020,
    "Veal cutlet or steak, fried, lean only eaten": 23205030,
    "Veal, roasted, NS as to fat eaten": 23210010,
    "Veal, roasted, lean and fat eaten": 23210020,
    "Veal, roasted, lean only eaten": 23210030,
    "Veal patty, breaded, cooked": 23220030,
    "Rabbit, domestic, NS as to cooking method": 23311100,
    "Rabbit, NS as to domestic or wild, breaded, fried": 23311120,
    "Rabbit, wild, cooked": 23311200,
    "Venison/deer, cured": 23321050,
    "Venison/deer, roasted": 23321100,
    "Venison/deer steak, breaded or floured, cooked, NS as to cooking method": 23321250,
    "Deer chop, cooked": 23322300,
    "Venison/deer ribs, cooked": 23322350,
    "Venison/deer, stewed": 23322400,
    "Lamb, baby food, strained": 23410010,
    "Veal, baby food, strained": 23420010,
    "Turkey with barbecue sauce, skin eaten": 24209000,
    "Turkey with barbecue sauce, skin not eaten": 24209001,
    "Duck, coated, fried": 24301210,
    "Chicken, baby food, NS as to strained or junior": 24701000,
    "Chicken, baby food, junior": 24701020,
    "Turkey, baby food, NS as to strained or junior": 24703000,
    "Turkey, baby food, junior": 24703020,
    "Chicken stick, baby food": 24705010,
    "Turkey stick, baby food": 24706010,
    "Beef liver, braised": 25110120,
    "Chicken liver, braised": 25110420,
    "Tongue, smoked, cured, or pickled, cooked": 25160110,
    "Frankfurter or hot dog, cheese-filled": 25210150,
    "Frankfurter or hot dog, beef and pork": 25210220,
    "Frankfurter or hot dog, beef and pork, reduced fat or light": 25210240,
    "Frankfurter or hot dog, meat and poultry, fat free": 25210250,
    "Frankfurter or hot dog, chicken": 25210310,
    "Frankfurter or hot dog, beef, reduced fat or light": 25210620,
    "Frankfurter or hot dog, reduced fat or light, NFS": 25210750,
    "Bologna, made from any kind of meat, reduced fat and reduced sodium": 25220445,
    "Ham, pork and chicken, canned luncheon meat, chopped, minced, pressed, spiced, reduced fat and reduced sodium": 25230540,
    "Fish, NS as to type, baked or broiled, made with butter": 26100121,
    "Fish, NS as to type, baked or broiled, made with margarine": 26100122,
    "Fish, NS as to type, baked or broiled, made without fat": 26100123,
    "Fish, NS as to type, baked or broiled, made with cooking spray": 26100124,
    "Fish, NS as to type, coated, baked or broiled, made with butter": 26100131,
    "Fish, NS as to type, coated, baked or broiled, made with margarine": 26100132,
    "Fish, NS as to type, coated, baked or broiled, made without fat": 26100133,
    "Fish, NS as to type, coated, baked or broiled, made with cooking spray": 26100134,
    "Fish, NS as to type, coated, fried, made with butter": 26100141,
    "Fish, NS as to type, coated, fried, made with margarine": 26100142,
    "Fish, NS as to type, coated, fried, made without fat": 26100143,
    "Fish, NS as to type, coated, fried, made with cooking spray": 26100144,
    "Fish, NS as to type, dried": 26100170,
    "Fish, NS as to type, from fast food": 26100200,
    "Fish stick, patty or nugget from fast food": 26100260,
    "Anchovy, canned": 26101180,
    "Barracuda, cooked, NS as to cooking method": 26103110,
    "Barracuda, baked or broiled, fat added in cooking": 26103120,
    "Barracuda, baked or broiled, fat not added in cooking": 26103121,
    "Barracuda, coated, baked or broiled, fat added in cooking": 26103130,
    "Barracuda, coated, baked or broiled, fat not added in cooking": 26103131,
    "Barracuda, coated, fried": 26103140,
    "Barracuda, steamed or poached": 26103160,
    "Carp, baked or broiled, fat added in cooking": 26105120,
    "Carp, baked or broiled, fat not added in cooking": 26105121,
    "Carp, coated, baked or broiled, fat added in cooking": 26105130,
    "Carp, coated, baked or broiled, fat not added in cooking": 26105131,
    "Carp, coated, fried": 26105140,
    "Carp, steamed or poached": 26105160,
    "Carp, smoked": 26105190,
    "Catfish, baked or broiled, made with butter": 26107121,
    "Catfish, baked or broiled, made with margarine": 26107122,
    "Catfish, baked or broiled, made with cooking spray": 26107124,
    "Catfish, coated, baked or broiled, made with butter": 26107131,
    "Catfish, coated, baked or broiled, made with margarine": 26107132,
    "Catfish, coated, baked or broiled, made without fat": 26107133,
    "Catfish, coated, baked or broiled, made with cooking spray": 26107134,
    "Catfish, coated, fried, made with butter": 26107141,
    "Catfish, coated, fried, made with margarine": 26107142,
    "Catfish, coated, fried, made without fat": 26107143,
    "Catfish, coated, fried, made with cooking spray": 26107144,
    "Cod, baked or broiled, made with butter": 26109121,
    "Cod, baked or broiled, made with margarine": 26109122,
    "Cod, baked or broiled, made with cooking spray": 26109124,
    "Cod, coated, baked or broiled, made with butter": 26109131,
    "Cod, coated, baked or broiled, made with margarine": 26109132,
    "Cod, coated, baked or broiled, made without fat": 26109133,
    "Cod, coated, baked or broiled, made with cooking spray": 26109134,
    "Cod, coated, fried, made with butter": 26109141,
    "Cod, coated, fried, made with margarine": 26109142,
    "Cod, coated, fried, made without fat": 26109143,
    "Cod, coated, fried, made with cooking spray": 26109144,
    "Cod, dried, salted": 26109170,
    "Cod, dried, salted, salt removed in water": 26109180,
    "Cod, smoked": 26109190,
    "Croaker, baked or broiled, fat added in cooking": 26111120,
    "Croaker, baked or broiled, fat not added in cooking": 26111121,
    "Croaker, coated, baked or broiled, fat added in cooking": 26111130,
    "Croaker, coated, baked or broiled, fat not added in cooking": 26111131,
    "Croaker, coated, fried": 26111140,
    "Croaker, steamed or poached": 26111160,
    "Eel, steamed or poached": 26113160,
    "Eel, smoked": 26113190,
    "Flounder, raw": 26115000,
    "Flounder, baked or broiled, made with butter": 26115121,
    "Flounder, baked or broiled, made with margarine": 26115122,
    "Flounder, baked or broiled, made with cooking spray": 26115124,
    "Flounder, coated, baked or broiled, made with butter": 26115131,
    "Flounder, coated, baked or broiled, made with margarine": 26115132,
    "Flounder, coated, baked or broiled, made without fat": 26115133,
    "Flounder, coated, baked or broiled, made with cooking spray": 26115134,
    "Flounder, coated, fried, made with butter": 26115141,
    "Flounder, coated, fried, made with margarine": 26115142,
    "Flounder, coated, fried, made without fat": 26115143,
    "Flounder, coated, fried, made with cooking spray": 26115144,
    "Flounder, smoked": 26115190,
    "Haddock, coated, baked or broiled, fat not added in cooking": 26117131,
    "Haddock, smoked": 26117190,
    "Halibut, raw": 26118000,
    "Halibut, baked or broiled, made with oil": 26118020,
    "Halibut, baked or broiled, made with butter": 26118021,
    "Halibut, baked or broiled, made with margarine": 26118022,
    "Halibut, baked or broiled, made without fat": 26118023,
    "Halibut, baked or broiled, made with cooking spray": 26118024,
    "Halibut, coated, baked or broiled, made with oil": 26118030,
    "Halibut, coated, baked or broiled, made with butter": 26118031,
    "Halibut, coated, baked or broiled, made with margarine": 26118032,
    "Halibut, coated, baked or broiled, made without fat": 26118033,
    "Halibut, coated, baked or broiled, made with cooking spray": 26118034,
    "Halibut, coated, fried, made with oil": 26118040,
    "Halibut, coated, fried, made with butter": 26118041,
    "Halibut, coated, fried, made with margarine": 26118042,
    "Halibut, coated, fried, made without fat": 26118043,
    "Halibut, coated, fried, made with cooking spray": 26118044,
    "Halibut, steamed or poached": 26118050,
    "Halibut, smoked": 26118060,
    "Herring, raw": 26119100,
    "Herring, baked or broiled, fat added in cooking": 26119120,
    "Herring, baked or broiled, fat not added in cooking": 26119121,
    "Herring, coated, baked or broiled, fat added in cooking": 26119130,
    "Herring, coated, baked or broiled, fat not added in cooking": 26119131,
    "Herring, coated, fried": 26119140,
    "Herring, pickled, in cream sauce": 26119160,
    "Herring, dried, salted": 26119170,
    "Herring, smoked, kippered": 26119190,
    "Mackerel, raw": 26121100,
    "Mackerel, coated, baked or broiled, fat not added in cooking": 26121132,
    "Mackerel, pickled": 26121160,
    "Mackerel, smoked": 26121190,
    "Mullet, raw": 26123100,
    "Mullet, baked or broiled, fat added in cooking": 26123120,
    "Mullet, baked or broiled, fat not added in cooking": 26123121,
    "Mullet, coated, baked or broiled, fat added in cooking": 26123130,
    "Mullet, coated, baked or broiled, fat not added in cooking": 26123131,
    "Mullet, coated, fried": 26123140,
    "Mullet, steamed or poached": 26123160,
    "Ocean perch, raw": 26125100,
    "Ocean perch, cooked, NS as to cooking method": 26125110,
    "Ocean perch, baked or broiled, fat added in cooking": 26125120,
    "Ocean perch, baked or broiled, fat not added in cooking": 26125121,
    "Ocean perch, coated, baked or broiled, fat added in cooking": 26125130,
    "Ocean perch, coated, baked or broiled, fat not added in cooking": 26125131,
    "Ocean perch, coated, fried": 26125140,
    "Ocean perch, steamed or poached": 26125160,
    "Perch, baked or broiled, made with butter": 26127121,
    "Perch, baked or broiled, made with margarine": 26127122,
    "Perch, baked or broiled, made with cooking spray": 26127124,
    "Perch, coated, baked or broiled, made with butter": 26127131,
    "Perch, coated, baked or broiled, made with margarine": 26127132,
    "Perch, coated, baked or broiled, made without fat": 26127133,
    "Perch, coated, baked or broiled, made with cooking spray": 26127134,
    "Perch, coated, fried, made with butter": 26127141,
    "Perch, coated, fried, made with margarine": 26127142,
    "Perch, coated, fried, made without fat": 26127143,
    "Perch, coated, fried, made with cooking spray": 26127144,
    "Pike, baked or broiled, fat added in cooking": 26129120,
    "Pike, baked or broiled, fat not added in cooking": 26129121,
    "Pike, coated, baked or broiled, fat added in cooking": 26129130,
    "Pike, coated, baked or broiled, fat not added in cooking": 26129131,
    "Pike, coated, fried": 26129140,
    "Pike, steamed or poached": 26129160,
    "Pompano, raw": 26131100,
    "Pompano, coated, baked or broiled, fat not added in cooking": 26131131,
    "Pompano, smoked": 26131190,
    "Porgy, raw": 26133100,
    "Porgy, baked or broiled, fat added in cooking": 26133120,
    "Porgy, baked or broiled, fat not added in cooking": 26133121,
    "Porgy, coated, baked or broiled, fat added in cooking": 26133130,
    "Porgy, coated, baked or broiled, fat not added in cooking": 26133131,
    "Porgy, coated, fried": 26133140,
    "Porgy, steamed or poached": 26133160,
    "Ray, cooked, NS as to cooking method": 26135110,
    "Ray, baked or broiled, fat added in cooking": 26135120,
    "Ray, baked or broiled, fat not added in cooking": 26135121,
    "Ray, coated, baked or broiled, fat added in cooking": 26135130,
    "Ray, coated, baked or broiled, fat not added in cooking": 26135131,
    "Ray, coated, fried": 26135140,
    "Ray, steamed or poached": 26135160,
    "Salmon, baked or broiled, made with butter": 26137121,
    "Salmon, baked or broiled, made with margarine": 26137122,
    "Salmon, baked or broiled, made with cooking spray": 26137124,
    "Salmon, coated, baked or broiled, made with butter": 26137131,
    "Salmon, coated, baked or broiled, made with margarine": 26137132,
    "Salmon, coated, baked or broiled, made without fat": 26137133,
    "Salmon, coated, baked or broiled, made with cooking spray": 26137134,
    "Salmon, coated, fried, made with butter": 26137141,
    "Salmon, coated, fried, made with margarine": 26137142,
    "Salmon, coated, fried, made without fat": 26137143,
    "Salmon, coated, fried, made with cooking spray": 26137144,
    "Salmon, dried": 26137170,
    "Sardines, cooked": 26139110,
    "Sardines, dried": 26139170,
    "Sardines, skinless, boneless, packed in water": 26139190,
    "Sea bass, coated, baked or broiled, fat not added in cooking": 26141131,
    "Sea bass, pickled": 26141180,
    "Shark, baked or broiled, fat added in cooking": 26143120,
    "Shark, baked or broiled, fat not added in cooking": 26143121,
    "Shark, coated, baked or broiled, fat added in cooking": 26143130,
    "Shark, coated, baked or broiled, fat not added in cooking": 26143131,
    "Shark, coated, fried": 26143140,
    "Shark, steamed or poached": 26143160,
    "Sturgeon, cooked, NS as to cooking method": 26147110,
    "Sturgeon, baked or broiled, fat added in cooking": 26147120,
    "Sturgeon, steamed": 26147130,
    "Sturgeon, coated, fried": 26147140,
    "Sturgeon, smoked": 26147190,
    "Swordfish, baked or broiled, fat added in cooking": 26149120,
    "Swordfish, baked or broiled, fat not added in cooking": 26149121,
    "Swordfish, coated, baked or broiled, fat added in cooking": 26149130,
    "Swordfish, coated, baked or broiled, fat not added in cooking": 26149131,
    "Swordfish, coated, fried": 26149140,
    "Swordfish, steamed or poached": 26149160,
    "Trout, baked or broiled, made with butter": 26151121,
    "Trout, baked or broiled, made with margarine": 26151122,
    "Trout, baked or broiled, made with cooking spray": 26151124,
    "Trout, coated, baked or broiled, made with butter": 26151131,
    "Trout, coated, baked or broiled, made with margarine": 26151132,
    "Trout, coated, baked or broiled, made without fat": 26151133,
    "Trout, coated, baked or broiled, made with cooking spray": 26151134,
    "Trout, coated, fried, made with butter": 26151141,
    "Trout, coated, fried, made with margarine": 26151142,
    "Trout, coated, fried, made without fat": 26151143,
    "Trout, coated, fried, made with cooking spray": 26151144,
    "Trout, smoked": 26151190,
    "Tuna, fresh, baked or broiled, fat not added in cooking": 26153122,
    "Tuna, fresh, coated, baked or broiled, fat added in cooking": 26153130,
    "Tuna, fresh, coated, baked or broiled, fat not added": 26153131,
    "Tuna, fresh, coated, fried": 26153140,
    "Tuna, fresh, steamed or poached": 26153160,
    "Tuna, fresh, dried": 26153170,
    "Tuna, fresh, smoked": 26153190,
    "Tuna, canned, oil pack": 26155180,
    "Tuna, canned, water pack": 26155190,
    "Whiting, baked or broiled, made with butter": 26157121,
    "Whiting, baked or broiled, made with margarine": 26157122,
    "Whiting, baked or broiled, made with cooking spray": 26157124,
    "Whiting, coated, baked or broiled, made with butter": 26157131,
    "Whiting, coated, baked or broiled, made with margarine": 26157132,
    "Whiting, coated, baked or broiled, made without fat": 26157133,
    "Whiting, coated, baked or broiled, made with cooking spray": 26157134,
    "Whiting, coated, fried, made with butter": 26157141,
    "Whiting, coated, fried, made with margarine": 26157142,
    "Whiting, coated, fried, made without fat": 26157143,
    "Whiting, coated, fried, made with cooking spray": 26157144,
    "Tilapia, baked or broiled, made with butter": 26158011,
    "Tilapia, baked or broiled, made with margarine": 26158012,
    "Tilapia, baked or broiled, made with cooking spray": 26158014,
    "Tilapia, coated, baked or broiled, made with butter": 26158021,
    "Tilapia, coated, baked or broiled, made with margarine": 26158022,
    "Tilapia, coated, baked or broiled, made without fat": 26158023,
    "Tilapia, coated, baked or broiled, made with cooking spray": 26158024,
    "Tilapia, coated, fried, made with butter": 26158031,
    "Tilapia, coated, fried, made with margarine": 26158032,
    "Tilapia, coated, fried, made without fat": 26158033,
    "Tilapia, coated, fried, made with cooking spray": 26158034,
    "Frog legs, steamed": 26203160,
    "Octopus, steamed": 26205160,
    "Octopus, dried": 26205170,
    "Octopus, dried, boiled": 26205180,
    "Octopus, smoked": 26205190,
    "Roe, shad, cooked": 26207110,
    "Roe, herring": 26209100,
    "Squid, raw": 26213100,
    "Squid, baked or broiled, fat not added in cooking": 26213121,
    "Squid, coated, baked or broiled, fat added in cooking": 26213130,
    "Squid, coated, baked or broiled, fat not added in cooking": 26213131,
    "Squid, steamed or boiled": 26213160,
    "Squid, dried": 26213170,
    "Squid, pickled": 26213180,
    "Squid, canned": 26213190,
    "Abalone, floured or breaded, fried": 26301140,
    "Abalone, steamed or poached": 26301160,
    "Clams, baked or broiled, fat not added in cooking": 26303121,
    "Clams, coated, baked or broiled, fat added in cooking": 26303130,
    "Clams, coated, baked or broiled, fat not added in cooking": 26303131,
    "Clams, smoked, in oil": 26303190,
    "Crab, cooked, NS as to cooking method": 26305110,
    "Crab, baked or broiled, fat added in cooking": 26305120,
    "Crab, baked or broiled, fat not added in cooking": 26305121,
    "Crab, coated, baked or broiled, fat added in cooking": 26305130,
    "Crab, coated, baked or broiled, fat not added in cooking": 26305131,
    "Lobster, baked or broiled, fat added in cooking": 26311120,
    "Lobster, baked or broiled, fat not added in cooking": 26311121,
    "Lobster, coated, fried": 26311140,
    "Lobster, steamed or boiled": 26311160,
    "Lobster, coated, baked or broiled, fat added in cooking": 26311170,
    "Lobster, coated, baked or broiled, fat not added in cooking": 26311171,
    "Lobster, canned": 26311180,
    "Mussels, raw": 26313100,
    "Mussels, steamed or poached": 26313160,
    "Oysters, cooked, NS as to cooking method": 26315110,
    "Oysters, baked or broiled, fat not added in cooking": 26315121,
    "Oysters, coated, baked or broiled, fat added in cooking": 26315160,
    "Oysters, coated, baked or broiled, fat not added in cooking": 26315161,
    "Oysters, smoked": 26315190,
    "Scallops, cooked, NS as to cooking method": 26317110,
    "Scallops, coated, baked or broiled, fat added in cooking": 26317160,
    "Scallops, coated, baked or broiled, fat not added in cooking": 26317161,
    "Shrimp, baked or broiled, made with butter": 26319121,
    "Shrimp, baked or broiled, made with margarine": 26319122,
    "Shrimp, baked or broiled, made with cooking spray": 26319124,
    "Shrimp, coated, fried, made with butter": 26319141,
    "Shrimp, coated, fried, made with margarine": 26319142,
    "Shrimp, coated, fried, made without fat": 26319143,
    "Shrimp, coated, fried, made with cooking spray": 26319144,
    "Shrimp, coated, fried, from fast food / restaurant": 26319145,
    "Shrimp, coated, baked or broiled, made with butter": 26319161,
    "Shrimp, coated, baked or broiled, made with margarine": 26319162,
    "Shrimp, coated, baked or broiled, made without fat": 26319163,
    "Shrimp, coated, baked or broiled, made with cooking spray": 26319164,
    "Beef stew, no potatoes, tomato-based sauce, with chili peppers, Mexican style": 27111310,
    "Pork curry": 27120160,
    "Lamb or mutton goulash": 27130050,
    "Lamb or mutton curry": 27130100,
    "Crab, deviled": 27150020,
    "Crab imperial": 27150030,
    "Lobster newburg": 27150060,
    "Lobster with butter sauce": 27150070,
    "Shrimp curry": 27150100,
    "Lobster sauce": 27150190,
    "Oyster sauce": 27150200,
    "Fish moochim": 27150250,
    "Mussels with tomato-based sauce": 27150330,
    "Sardines with tomato-based sauce": 27150350,
    "Sardines with mustard sauce": 27150370,
    "Scallops with cheese sauce": 27150510,
    "Meat with barbecue sauce, NS as to type of meat": 27160010,
    "Beef stew with potatoes, tomato-based sauce": 27211100,
    "Beef goulash with potatoes": 27211150,
    "Beef stew with potatoes, gravy": 27211200,
    "Chili con carne with beans and rice": 27213150,
    "Creamed dried beef on toast": 27214600,
    "Chicken in cheese sauce with Spanish rice": 27243700,
    "Clams, stuffed": 27250020,
    "Codfish ball or cake": 27250030,
    "Salmon loaf": 27250080,
    "Oyster fritter": 27250220,
    "Mackerel cake or patty": 27250300,
    "Haddock cake or patty": 27250310,
    "Liver dumpling": 27260510,
    "Breaded brains, Puerto Rican style": 27261000,
    "Ham pot pie": 27320020,
    "Shepherd's pie with lamb": 27330010,
    "Lamb or mutton stew with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy; gravy": 27330030,
    "Lamb or mutton stew with potatoes and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27330110,
    "Lamb or mutton stew with potatoes and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27330220,
    "Veal stew with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27332100,
    "Veal stew with potatoes and vegetables excluding carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27332110,
    "Rabbit stew with potatoes and vegetables": 27335100,
    "Venison or deer stew with potatoes and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27336150,
    "Chicken or turkey stew with potatoes and vegetables excluding carrots, broccoli, and dark-green leafy; gravy": 27341320,
    "Chicken or turkey stew with potatoes and vegetables including carrots, broccoli, and/or dark-green leafy; tomato-based sauce": 27341510,
    "Seafood stew with potatoes and vegetables excluding carrots, broccoli, and dark-green leafy; tomato-based sauce": 27350030,
    "Tuna pot pie": 27350070,
    "Oyster pie": 27350200,
    "Stewed codfish, Puerto Rican style": 27351030,
    "Goulash, NFS": 27360010,
    "Meat pie, NFS": 27360050,
    "Brunswick stew": 27360100,
    "Lamb or mutton stew with vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, gravy": 27430410,
    "Veal goulash with vegetables excluding carrots, broccoli, and dark-green leafy; no potatoes, tomato-based sauce": 27430500,
    "Veal goulash with vegetables including carrots, broccoli, and/or dark-green leafy; no potatoes, tomato-based sauce": 27430510,
    "Meat sandwich, NFS": 27500100,
    "Wrap sandwich, filled with meat, poultry, or fish, vegetables, and cheese": 27500200,
    "Beef sandwich, NFS": 27510000,
    "Beef barbecue sandwich or Sloppy Joe, on bun": 27510110,
    "Cheeseburger, 1 miniature patty, with condiments, on miniature bun, from fast food / restaurant": 27510145,
    "Cheeseburger, 1 miniature patty, on miniature bun, from school": 27510150,
    "Cheeseburger, 1 small patty, with condiments, on bun, from fast food / restaurant": 27510165,
    "Cheeseburger, 1 small patty, with condiments, on bun, from fast food / restaurant (Wendy's Kid's Cheeseburger)": 27510173,
    "Cheeseburger, 1 small patty, with condiments, on bun, from fast food / restaurant (Wendy's Jr. Cheeseburger)": 27510174,
    "Cheeseburger, 1 small patty, with condiments, on bun, from fast food / restaurant (Wendy's Jr. Cheeseburger Deluxe)": 27510175,
    "Cheeseburger, 1 small patty, with condiments, on white bun": 27510205,
    "Cheeseburger, 1 small patty, with condiments, on wheat bun": 27510206,
    "Cheeseburger, 1 small patty, with condiments, on whole wheat bun": 27510207,
    "Cheeseburger, 1 medium patty, with condiments, on bun, from fast food / restaurant": 27510225,
    "Cheeseburger, 1 medium patty, with condiments, on bun, from fast food / restaurant (Wendy's 1/4 lb Single with cheese)": 27510233,
    "Cheeseburger submarine sandwich with lettuce, tomato and spread": 27510235,
    "Cheeseburger, 1 medium patty, plain, on whole wheat bun": 27510243,
    "Cheeseburger, 1 medium patty, with condiments, on white bun": 27510251,
    "Cheeseburger, 1 medium patty, with condiments, on wheat bun": 27510252,
    "Cheeseburger, 1 medium patty, with condiments, on whole wheat bun": 27510253,
    "Cheeseburger, 1 large patty, with condiments, on bun, from fast food / restaurant": 27510266,
    "Bacon cheeseburger, 1 small patty, with condiments, on bun, from fast food / restaurant": 27510276,
    "Bacon cheeseburger, 1 small patty, with condiments, on bun, from fast food / restaurant (Wendy's Jr. Bacon Cheeseburger)": 27510281,
    "Bacon cheeseburger, 1 medium patty, plain, on bun, from fast food / restaurant": 27510305,
    "Bacon cheeseburger, 1 medium patty, with condiments, on bun, from fast food / restaurant": 27510312,
    "Bacon cheeseburger, 1 medium patty, plain, on white bun": 27510331,
    "Bacon cheeseburger, 1 medium patty, with condiments, on white bun": 27510341,
    "Bacon cheeseburger, 1 medium patty, with condiments, on wheat bun": 27510342,
    "Bacon cheeseburger, 1 medium patty, with condiments, on whole wheat bun": 27510343,
    "Bacon cheeseburger, 1 large patty, with condiments, on bun, from fast food / restaurant": 27510346,
    "Double cheeseburger, 2 small patties, with condiments, on bun, from fast food / restaurant": 27510376,
    "Double cheeseburger, 2 small patties, with condiments, on bun, from fast food / restaurant (Wendy's Double Stack)": 27510391,
    "Double cheeseburger, 2 medium patties, with condiments, on bun, from fast food / restaurant": 27510406,
    "Double cheeseburger, 2 medium patties, with condiments, on bun, from fast food / restaurant (McDonald's Double Quarter Pounder with Cheese)": 27510412,
    "Double cheeseburger, 2 medium patties, with condiments, on bun, from fast food / restaurant (Wendy's 1/2 lb Double with cheese)": 27510413,
    "Double bacon cheeseburger, 2 small patties, with condiments, on bun, from fast food / restaurant (Burger King Bacon Double Cheeseburger)": 27510431,
    "Double bacon cheeseburger, 2 medium patties, plain, on bun, from fast food / restaurant": 27510446,
    "Double bacon cheeseburger, 2 medium patties, with condiments, on bun, from fast food / restaurant": 27510451,
    "Double bacon cheeseburger, 2 medium patties, with condiments, on bun, from fast food / restaurant (Wendy's Baconator)": 27510465,
    "Double bacon cheeseburger, 2 large patties, with condiments, on bun, from fast food / restaurant": 27510475,
    "Triple cheeseburger, 3 medium patties, with condiments, on bun, from fast food / restaurant": 27510486,
    "Hamburger, 1 miniature patty, with condiments, on miniature bun, from fast food / restaurant": 27510506,
    "Hamburger, 1 miniature patty, on miniature bun, from school": 27510511,
    "Hamburger, 1 small patty, with condiments, on bun, from fast food / restaurant": 27510536,
    "Hamburger, 1 small patty, with condiments, on bun, from fast food / restaurant (Wendy's Jr. Hamburger)": 27510555,
    "Hamburger, 1 small patty, plain, on whole wheat bun": 27510577,
    "Hamburger, 1 small patty, with condiments, on white bun": 27510585,
    "Hamburger, 1 small patty, with condiments, on whole wheat bun": 27510587,
    "Hamburger, 1 medium patty, with condiments, on bun, from fast food / restaurant": 27510606,
    "Hamburger, 1 medium patty, with condiments, on bun, from fast food / restaurant (Wendy's 1/4 lb Single )": 27510616,
    "Hamburger, 1 medium patty, plain, on whole wheat bun": 27510633,
    "Hamburger, 1 medium patty, with condiments, on white bun": 27510641,
    "Hamburger, 1 medium patty, with condiments, on wheat bun": 27510642,
    "Hamburger, 1 medium patty, with condiments, on whole wheat bun": 27510643,
    "Double hamburger, 2 small patties, with condiments, on bun, from fast food / restaurant": 27510667,
    "Double hamburger, 2 medium patties, with condiments, on bun, from fast food / restaurant": 27510676,
    "Double hamburger, 2 medium patties, with condiments, on bun, from fast food / restaurant (Burger King Double WHOPPER)": 27510681,
    "Double hamburger, 2 medium patties, with condiments, on bun, from fast food / restaurant (Wendy's 1/2 lb Double)": 27510682,
    "Pastrami sandwich": 27511010,
    "Roast beef sandwich, with gravy": 27513020,
    "Roast beef submarine sandwich, with lettuce, tomato and spread": 27513040,
    "Roast beef submarine sandwich, with cheese, lettuce, tomato and spread": 27513041,
    "Roast beef sandwich with bacon and cheese sauce": 27513060,
    "Steak submarine sandwich with lettuce and tomato": 27515000,
    "Steak sandwich, plain, on roll": 27515010,
    "Steak and cheese submarine sandwich, with lettuce and tomato": 27515020,
    "Steak and cheese sandwich, plain, on roll": 27515030,
    "Steak and cheese submarine sandwich, plain, on roll": 27515040,
    "Fajita-style beef sandwich with cheese, on pita bread, with lettuce and tomato": 27515050,
    "Steak and cheese submarine sandwich, with fried peppers and onions, on roll": 27515070,
    "Steak sandwich, plain, on biscuit": 27515080,
    "Bacon sandwich, with spread": 27520110,
    "Bacon and cheese sandwich, with spread": 27520120,
    "Bacon, chicken, and tomato club sandwich, with cheese, lettuce and spread": 27520135,
    "Bacon and egg sandwich": 27520140,
    "Bacon, lettuce, and tomato sandwich with spread": 27520150,
    "Bacon, lettuce, and tomato submarine sandwich, with spread": 27520155,
    "Bacon, lettuce, tomato, and cheese submarine sandwich, with spread": 27520156,
    "Bacon, chicken, and tomato club sandwich, on multigrain roll with lettuce and spread": 27520160,
    "Bacon, breaded fried chicken fillet, and tomato club with lettuce and spread": 27520165,
    "Bacon, breaded fried chicken fillet, and tomato club sandwich with cheese, lettuce and spread": 27520166,
    "Bacon on biscuit": 27520170,
    "Ham on biscuit": 27520250,
    "Ham sandwich, with spread": 27520300,
    "Ham sandwich with lettuce and spread": 27520310,
    "Ham and cheese sandwich, with lettuce and spread": 27520320,
    "Ham and egg sandwich": 27520330,
    "Ham and cheese sandwich, with spread, grilled": 27520350,
    "Ham and cheese sandwich, on bun, with lettuce and spread": 27520360,
    "Hot ham and cheese sandwich, on bun": 27520370,
    "Ham and cheese on English muffin": 27520380,
    "Ham and cheese submarine sandwich, with lettuce, tomato and spread": 27520390,
    "Midnight sandwich, with spread": 27520420,
    "Pork sandwich, with gravy": 27520530,
    "Ham and tomato club sandwich, with lettuce and spread": 27520540,
    "Chicken sandwich, with spread": 27540110,
    "Chicken sandwich, with cheese and spread": 27540111,
    "Chicken fillet, breaded, fried, sandwich": 27540140,
    "Chicken fillet, breaded, fried, sandwich with lettuce, tomato and spread": 27540150,
    "Chicken fillet, breaded, fried, sandwich with cheese, lettuce, tomato and spread": 27540151,
    "Chicken patty sandwich, miniature, with spread": 27540170,
    "Chicken patty sandwich or biscuit": 27540180,
    "Chicken patty sandwich, with lettuce and spread": 27540190,
    "Fajita-style chicken sandwich with cheese, on pita bread, with lettuce and tomato": 27540200,
    "Chicken patty sandwich with cheese, on wheat bun, with lettuce, tomato and spread": 27540230,
    "Chicken fillet, broiled, sandwich with lettuce, tomato, and spread": 27540235,
    "Chicken fillet, broiled, sandwich, on whole wheat roll, with lettuce, tomato and spread": 27540240,
    "Chicken fillet, broiled, sandwich with cheese, on whole wheat roll, with lettuce, tomato and non-mayonnaise type spread": 27540250,
    "Chicken fillet, broiled, sandwich, on oat bran bun, with lettuce, tomato, spread": 27540260,
    "Chicken fillet, broiled, sandwich, with lettuce, tomato, and non-mayonnaise type spread": 27540270,
    "Chicken fillet, broiled, sandwich with cheese, on bun, with lettuce, tomato and spread": 27540280,
    "Chicken, bacon, and tomato club sandwich, with lettuce and spread": 27540285,
    "Chicken submarine sandwich, with lettuce, tomato and spread": 27540290,
    "Chicken submarine sandwich, with cheese, lettuce, tomato and spread": 27540291,
    "Buffalo chicken submarine sandwich": 27540295,
    "Buffalo chicken submarine sandwich with cheese": 27540296,
    "Turkey sandwich, with spread": 27540310,
    "Turkey salad or turkey spread sandwich": 27540320,
    "Turkey sandwich, with gravy": 27540330,
    "Turkey submarine sandwich, with cheese, lettuce, tomato and spread": 27540350,
    "Turkey and bacon submarine sandwich, with lettuce, tomato and spread": 27540360,
    "Turkey and bacon submarine sandwich, with cheese, lettuce, tomato and spread": 27540361,
    "Turkey, ham, and roast beef club sandwich, with lettuce, tomato and spread": 27541000,
    "Turkey, ham, and roast beef club sandwich with cheese, lettuce, tomato, and spread": 27541001,
    "Turkey or chicken burger, plain, on bun, from fast food / restaurant": 27545000,
    "Turkey or chicken burger, with condiments, on bun, from fast food / restaurant": 27545010,
    "Turkey or chicken burger, with condiments, on white bun": 27545200,
    "Turkey or chicken burger, with condiments, on wheat bun": 27545210,
    "Turkey or chicken burger, with condiments, on whole wheat bun": 27545220,
    "Tuna salad sandwich, with lettuce": 27550710,
    "Tuna salad submarine sandwich, with lettuce and tomato": 27550750,
    "Tuna salad submarine sandwich, with cheese, lettuce and tomato": 27550751,
    "Luncheon meat sandwich, NFS, with spread": 27560000,
    "Bologna sandwich, with spread": 27560110,
    "Bologna and cheese sandwich, with spread": 27560120,
    "Puerto Rican sandwich": 27560410,
    "Pepperoni and salami submarine sandwich, with lettuce, tomato and spread": 27560500,
    "Salami sandwich, with spread": 27560510,
    "Sausage on biscuit": 27560650,
    "Sausage griddle cake sandwich": 27560660,
    "Sausage and cheese on English muffin": 27560670,
    "Sausage balls, made with biscuit mix and cheese": 27560705,
    "Sausage and spaghetti sauce sandwich": 27560720,
    "Cold cut sumarine sandwich, with cheese, lettuce, tomato and spread": 27560910,
    "Meat spread or potted meat sandwich": 27563010,
    "Frankfurter or hot dog sandwich, NFS, plain, on whole wheat bun": 27564002,
    "Frankfurter or hot dog sandwich, NFS, plain, on whole grain white bun": 27564003,
    "Frankfurter or hot dog sandwich, NFS, plain, on multigrain bun": 27564004,
    "Frankfurter or hot dog sandwich, NFS, plain, on whole wheat bread": 27564030,
    "Frankfurter or hot dog sandwich, NFS, plain, on whole grain white bread": 27564040,
    "Frankfurter or hot dog sandwich, NFS, plain, on multigrain bread": 27564050,
    "Frankfurter or hot dog sandwich, beef, plain, on whole wheat bun": 27564062,
    "Frankfurter or hot dog sandwich, beef, plain, on whole grain white bun": 27564063,
    "Frankfurter or hot dog sandwich, beef, plain, on multigrain bun": 27564064,
    "Frankfurter or hot dog sandwich, beef, plain, on whole wheat bread": 27564090,
    "Frankfurter or hot dog sandwich, beef, plain, on whole grain white bread": 27564100,
    "Frankfurter or hot dog sandwich, beef, plain, on multigrain bread": 27564110,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on white bun": 27564120,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on wheat bun": 27564121,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on whole wheat bun": 27564122,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on whole grain white bun": 27564123,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on multigrain bun": 27564124,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on white bread": 27564130,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on wheat bread": 27564140,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on whole wheat bread": 27564150,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on whole grain white bread": 27564160,
    "Frankfurter or hot dog sandwich, beef and pork, plain, on multigrain bread": 27564170,
    "Frankfurter or hot dog sandwich, meat and poultry, plain, on whole wheat bun": 27564182,
    "Frankfurter or hot dog sandwich, meat and poultry, plain, on whole grain white bun": 27564183,
    "Frankfurter or hot dog sandwich, meat and poultry, plain, on multigrain bun": 27564184,
    "Frankfurter or hot dog sandwich, meat and poultry, plain, on whole wheat bread": 27564210,
    "Frankfurter or hot dog sandwich, meat and poultry, plain, on whole grain white bread": 27564220,
    "Frankfurter or hot dog sandwich, meat and poultry, plain, on multigrain bread": 27564230,
    "Frankfurter or hot dog sandwich, chicken and/or turkey, plain, on whole wheat bun": 27564242,
    "Frankfurter or hot dog sandwich, chicken and/or turkey, plain, on whole grain white bun": 27564243,
    "Frankfurter or hot dog sandwich, chicken and/or turkey, plain, on multigrain bun": 27564244,
    "Frankfurter or hot dog sandwich, chicken and/or turkey, plain, on whole wheat bread": 27564270,
    "Frankfurter or hot dog sandwich, chicken and/or turkey, plain, on whole grain white bread": 27564280,
    "Frankfurter or hot dog sandwich, chicken and/or turkey, plain, on multigrain bread": 27564290,
    "Frankfurter or hot dog sandwich, reduced fat or light, plain, on whole wheat bun": 27564302,
    "Frankfurter or hot dog sandwich, reduced fat or light, plain, on whole grain white bun": 27564303,
    "Frankfurter or hot dog sandwich, reduced fat or light, plain, on multigrain bun": 27564304,
    "Frankfurter or hot dog sandwich, reduced fat or light, plain, on whole wheat bread": 27564330,
    "Frankfurter or hot dog sandwich, reduced fat or light, plain, on whole grain white bread": 27564340,
    "Frankfurter or hot dog sandwich, reduced fat or light, plain, on multigrain bread": 27564350,
    "Frankfurter or hot dog sandwich, fat free, plain, on white bun": 27564360,
    "Frankfurter or hot dog sandwich, fat free, plain, on wheat bun": 27564361,
    "Frankfurter or hot dog sandwich, fat free, plain, on whole wheat bun": 27564362,
    "Frankfurter or hot dog sandwich, fat free, plain, on whole grain white bun": 27564363,
    "Frankfurter or hot dog sandwich, fat free, plain, on multigrain bun": 27564364,
    "Frankfurter or hot dog sandwich, fat free, plain, on white bread": 27564370,
    "Frankfurter or hot dog sandwich, fat free, plain, on wheat bread": 27564380,
    "Frankfurter or hot dog sandwich, fat free, plain, on whole wheat bread": 27564390,
    "Frankfurter or hot dog sandwich, fat free, plain, on whole grain white bread": 27564400,
    "Frankfurter or hot dog sandwich, fat free, plain, on multigrain bread": 27564410,
    "Frankfurter or hot dog sandwich, with chili, on whole wheat bun": 27564442,
    "Frankfurter or hot dog sandwich, with chili, on whole grain white bun": 27564443,
    "Frankfurter or hot dog sandwich, with chili, on multigrain bun": 27564444,
    "Frankfurter or hot dog sandwich, with chili, on whole wheat bread": 27564470,
    "Frankfurter or hot dog sandwich, with chili, on whole grain white bread": 27564480,
    "Frankfurter or hot dog sandwich, with chili, on multigrain bread": 27564490,
    "Frankfurter or hot dog sandwich, with meatless chili, on white bun": 27564500,
    "Frankfurter or hot dog sandwich, with meatless chili, on wheat bun": 27564501,
    "Frankfurter or hot dog sandwich, with meatless chili, on whole wheat bun": 27564502,
    "Frankfurter or hot dog sandwich, with meatless chili, on whole grain white bun": 27564503,
    "Frankfurter or hot dog sandwich, with meatless chili, on multigrain bun": 27564504,
    "Frankfurter or hot dog sandwich, with meatless chili, on white bread": 27564510,
    "Frankfurter or hot dog sandwich, with meatless chili, on wheat bread": 27564520,
    "Frankfurter or hot dog sandwich, with meatless chili, on whole wheat bread": 27564530,
    "Frankfurter or hot dog sandwich, with meatless chili, on whole grain white bread": 27564540,
    "Frankfurter or hot dog sandwich, with meatless chili, on multigrain bread": 27564550,
    "Frankfurter or hot dog sandwich, meatless, on bun, with meatless chili": 27564560,
    "Frankfurter or hot dog sandwich, meatless, on bread, with meatless chili": 27564570,
    "Hors d'oeuvres, with spread": 27570310,
    "Beef stew, baby food, toddler": 27601000,
    "Beef and egg noodles, baby food, NS as to strained or junior": 27610100,
    "Beef and egg noodles, baby food, strained": 27610110,
    "Beef and egg noodles, baby food, junior": 27610120,
    "Beef with vegetables, baby food, strained": 27610710,
    "Beef with vegetables, baby food, toddler": 27610730,
    "Chicken and rice dinner, baby food, strained": 27640050,
    "Chicken noodle dinner, baby food, NS as to strained or junior": 27640100,
    "Chicken noodle dinner, baby food, strained": 27640110,
    "Chicken noodle dinner, baby food, junior": 27640120,
    "Chicken, noodles, and vegetables, baby food, toddler": 27640810,
    "Chicken stew, baby food, toddler": 27641000,
    "Turkey, rice and vegetables, baby food, NS as to strained or junior": 27642100,
    "Turkey, rice and vegetables, baby food, strained": 27642110,
    "Turkey, rice and vegetables, baby food, junior": 27642120,
    "Turkey, rice, and vegetables, baby food, toddler": 27642130,
    "Chicken soup, baby food": 27644110,
    "Sirloin, chopped, with gravy, mashed potatoes, vegetable, frozen meal": 28110220,
    "Sirloin tips, with gravy, potatoes, vegetable, frozen meal": 28110250,
    "Sirloin beef, with gravy, potatoes, vegetable, frozen meal": 28110270,
    "Salisbury steak with gravy, potatoes, vegetable, soup or macaroni and cheese, dessert, frozen meal": 28110340,
    "Salisbury steak with gravy, potatoes, vegetable, dessert, frozen meal": 28110350,
    "Salisbury steak with gravy, macaroni and cheese, vegetable, frozen meal": 28110370,
    "Salisbury steak, potatoes, vegetable, dessert, diet frozen meal": 28110390,
    "Beef short ribs, boneless, with barbecue sauce, potatoes, vegetable, frozen meal": 28110620,
    "Meatballs, Swedish, in sauce, with noodles, frozen meal": 28110640,
    "Salisbury steak, baked, with tomato sauce, vegetable, diet frozen meal": 28113110,
    "Veal dinner, NFS, frozen meal": 28130000,
    "Veal, breaded, with spaghetti, in tomato sauce, frozen meal": 28133110,
    "Veal parmigiana with vegetable, fettuccine alfredo, dessert, frozen meal": 28133340,
    "Chicken divan, frozen meal": 28140150,
    "Chicken, boneless, with gravy, dressing, rice, vegetable, dessert, frozen meal": 28140250,
    "Chicken and noodles with vegetable, dessert, frozen meal": 28140320,
    "Chicken patty, breaded, with tomato sauce and cheese, fettuccine alfredo, vegetable, frozen meal": 28140730,
    "Chicken patty or nuggets, boneless, breaded, with pasta and tomato sauce, fruit, dessert, frozen meal": 28140740,
    "Chicken, fried, with potatoes, vegetable, dessert, frozen meal": 28140810,
    "Chicken patty parmigiana, breaded, with vegetable, diet frozen meal": 28141050,
    "Chicken a la king with rice, frozen meal": 28141600,
    "Chicken and vegetables in cream or white sauce, diet frozen meal": 28141610,
    "Chicken chow mein with rice, diet frozen meal": 28143040,
    "Chicken cacciatore with noodles, diet frozen meal": 28143110,
    "Chicken and vegetable entree with noodles, frozen meal": 28143130,
    "Chicken in cream sauce with noodles and vegetable, frozen meal": 28143170,
    "Chicken in butter sauce with potatoes and vegetable, diet frozen meal": 28143180,
    "Chicken in mushroom sauce, white and wild rice, vegetable, frozen meal": 28143190,
    "Chicken in orange sauce with almond rice, diet frozen meal": 28143210,
    "Turkey dinner, NFS, frozen meal": 28145000,
    "Turkey with gravy, dressing, vegetable and fruit, diet frozen meal": 28145100,
    "Turkey with gravy, dressing, potatoes, vegetable, dessert, frozen meal": 28145610,
    "Turkey tetrazzini, frozen meal": 28145710,
    "Haddock with chopped spinach, diet frozen meal": 28150210,
    "Flounder with chopped broccoli, diet frozen meal": 28150220,
    "Shrimp and clams in tomato-based sauce, with noodles, frozen meal": 28153010,
    "Stuffed green pepper, frozen meal": 28160650,
    "Stuffed cabbage, with meat and tomato sauce, diet frozen meal": 28160710,
    "Beef broth, with tomato, home recipe": 28310160,
    "Beef noodle soup, Puerto Rican style": 28310320,
    "Beef and rice soup, Puerto Rican style": 28310420,
    "Menudo soup, canned, prepared with water or ready-to-serve": 28311030,
    "Beef stroganoff soup, chunky style, home recipe, canned or ready-to-serve": 28317010,
    "Ham, rice, and potato soup, Puerto Rican style": 28320130,
    "Ham, noodle, and vegetable soup, Puerto Rican style": 28320140,
    "Bacon soup, cream of, prepared with water": 28321130,
    "Lamb, pasta, and vegetable soup, Puerto Rican style": 28331110,
    "Chicken or turkey broth, with tomato, home recipe": 28340130,
    "Mexican style chicken broth soup stock": 28340150,
    "Beef broth, less or reduced sodium, canned or ready-to-serve": 28340179,
    "Chicken rice soup, Puerto Rican style": 28340210,
    "Chicken soup with noodles and potatoes, Puerto Rican style": 28340220,
    "Chicken or turkey vegetable soup with potato and cheese, chunky style, canned or ready-to-serve": 28340690,
    "Bird's nest soup": 28340700,
    "Chicken or turkey soup, cream of, canned, reduced sodium, NS as to made with milk or water": 28345010,
    "Chicken or turkey soup, cream of, canned, reduced sodium, made with milk": 28345020,
    "Chicken or turkey soup, cream of, canned, reduced sodium, made with water": 28345030,
    "Chicken or turkey soup, cream of, prepared with water": 28345130,
    "Chicken or turkey mushroom soup, cream of, prepared with milk": 28345160,
    "Duck soup": 28345170,
    "Fish stock, home recipe": 28350040,
    "Crab soup, NS as to tomato-base or cream style": 28350110,
    "Crab soup, tomato-base": 28350120,
    "Turtle and vegetable soup": 28350310,
    "Fish soup with potatoes, Mexican style": 28351120,
    "Codfish soup with noodles, Puerto Rican style": 28351170,
    "Crab soup, cream of, prepared with milk": 28355210,
    "Oyster stew": 28355310,
    "Salmon soup, cream style": 28355350,
    "Shrimp soup, cream of, prepared with water": 28355430,
    "Spanish vegetable soup, Puerto Rican style": 28360210,
    "Gravy, meat or poultry, with wine": 28500010,
    "Gravy, meat, with fruit": 28500020,
    "Gravy, poultry, low sodium": 28500030,
    "Gravy, giblet": 28500050,
    "Gravy, beef or meat, low sodium": 28500060,
    "Gravy, beef or meat, home recipe": 28500070,
    "Gravy, poultry, home recipe": 28500080,
    "Gravy, mushroom": 28500100,
    "Gravy, redeye": 28500150,
    "Gravy or sauce, poultry-based from Puerto Rican-style chicken fricasse": 28510010,
    "Gravy, meat-based, from Puerto-Rican style stuffed pot roast": 28510020,
    "Gravy, meat-based, from Puerto-Rican style beef stew": 28510030,
    "Mole verde sauce": 28522050,
    "Egg curry": 32101530,
    "Ripe plantain omelet, Puerto Rican style": 32105310,
    "Scrambled eggs with jerked beef, Puerto Rican style": 32105330,
    "Eggs a la Malaguena, Puerto Rican style": 32110100,
    "Shrimp-egg patty": 32110150,
    "Egg dessert, Puerto Rican style": 32120100,
    "Zabaglione": 32120200,
    "Fried egg sandwich": 32201000,
    "Egg, cheese, ham, and bacon on bun": 32202000,
    "Egg, cheese, and ham on English muffin": 32202010,
    "Egg, cheese, and ham on biscuit": 32202020,
    "Egg, cheese and ham on bagel": 32202025,
    "Egg, cheese, and sausage on English muffin": 32202030,
    "Egg, cheese, and sausage on bun": 32202034,
    "Egg, extra cheese, and extra sausage, on bun": 32202035,
    "Egg, cheese, and beef on English Muffin": 32202040,
    "Egg, cheese, and steak on bagel": 32202045,
    "Egg, cheese, and sausage on biscuit": 32202050,
    "Egg, cheese, and sausage griddle cake sandwich": 32202055,
    "Egg and sausage on biscuit": 32202060,
    "Egg, cheese, and bacon on biscuit": 32202070,
    "Egg, cheese, and bacon griddle cake sandwich": 32202075,
    "Egg, cheese, and bacon on English muffin": 32202080,
    "Egg, cheese and bacon on bagel": 32202085,
    "Egg and bacon on biscuit": 32202090,
    "Egg and ham on biscuit": 32202110,
    "Egg, cheese and sausage on bagel": 32202120,
    "Egg and steak on biscuit": 32202130,
    "Egg and cheese on biscuit": 32202200,
    "Scrambled egg sandwich": 32204010,
    "Garlic egg soup, Puerto Rican style": 32301100,
    "Egg white, omelet, scrambled, or fried, with cheese, fat not added in cooking": 32400110,
    "Egg white, omelet, scrambled, or fried, with cheese, NS as to fat added in cooking": 32400120,
    "Egg white, omelet, scrambled, or fried, with meat, fat not added in cooking": 32400210,
    "Egg white, omelet, scrambled, or fried, with meat, NS as to fat added in cooking": 32400220,
    "Egg white, omelet, scrambled, or fried, with vegetables, fat not added in cooking": 32400310,
    "Egg white, omelet, scrambled, or fried, with vegetables, NS as to fat added in cooking": 32400320,
    "Egg white, omelet, scrambled, or fried, with cheese and meat, fat not added in cooking": 32400410,
    "Egg white, omelet, scrambled, or fried, with cheese and meat, NS as to fat added in cooking": 32400420,
    "Egg white, omelet, scrambled, or fried, with cheese and vegetables, fat not added in cooking": 32400510,
    "Egg white, omelet, scrambled, or fried, with cheese and vegetables, NS as to fat added in cooking": 32400520,
    "Egg white, omelet, scrambled, or fried, with meat and vegetables, fat not added in cooking": 32400610,
    "Egg white, omelet, scrambled, or fried, with meat and vegetables, NS as to fat added in cooking": 32400620,
    "Egg white, omelet, scrambled, or fried, with cheese, meat, and vegetables, fat not added in cooking": 32400710,
    "Egg white, omelet, scrambled, or fried, with cheese, meat, and vegetables, NS as to fat added in cooking": 32400720,
    "Egg substitute, omelet, scrambled, or fried, NS as to fat added in cooking": 33000990,
    "Egg substitute, omelet, scrambled, or fried, made with margarine": 33001000,
    "Egg substitute, omelet, scrambled, or fried, made with butter": 33001020,
    "Egg substitute, omelet, scrambled, or fried, NS as to type of fat": 33001035,
    "Egg substitute, omelet, scrambled, or fried, made with cooking spray": 33001040,
    "Egg substitute, cheese flavored, omelet, scrambled, or fried, fat added in cooking": 33001100,
    "Egg substitute, cheese flavored, omelet, scrambled, or fried, fat not added in cooking": 33001110,
    "Egg substitute, cheese flavored, omelet, scrambled, or fried, NS as to fat added in cooking": 33001120,
    "Egg substitute, vegetable flavored, omelet, scrambled, or fried, fat added in cooking": 33001200,
    "Egg substitute, vegetable flavored, omelet, scrambled, or fried, fat not added in cooking": 33001210,
    "Egg substitute, vegetable flavored, omelet, scrambled, or fried, NS as to fat added in cooking": 33001220,
    "Egg substitute, omelet, scrambled, or fried, with cheese, fat not added in cooking": 33401010,
    "Egg substitute, omelet, scrambled, or fried, with cheese, NS as to fat added in cooking": 33401020,
    "Egg substitute, omelet, scrambled, or fried, with meat, fat not added in cooking": 33401110,
    "Egg substitute, omelet, scrambled, or fried, with meat, NS as to fat added in cooking": 33401120,
    "Egg substitute, omelet, scrambled, or fried, with vegetables, fat not added in cooking": 33401210,
    "Egg substitute, omelet, scrambled, or fried, with vegetables, NS as to fat added in cooking": 33401220,
    "Egg substitute, omelet, scrambled, or fried, with cheese and meat, fat not added in cooking": 33401310,
    "Egg substitute, omelet, scrambled, or fried, with cheese and meat, NS as to fat added in cooking": 33401320,
    "Egg substitute, omelet, scrambled, or fried, with cheese and vegetables, fat not added in cooking": 33401410,
    "Egg substitute, omelet, scrambled, or fried, with cheese and vegetables, NS as to fat added in cooking": 33401420,
    "Egg substitute, omelet, scrambled, or fried, with meat and vegetables, fat not added in cooking": 33401510,
    "Egg substitute, omelet, scrambled, or fried, with meat and vegetables, NS as to fat added in cooking": 33401520,
    "Egg substitute, omelet, scrambled, or fried, with cheese, meat, and vegetables, fat not added in cooking": 33401610,
    "Egg substitute, omelet, scrambled, or fried, with cheese, meat, and vegetables, NS as to fat added in cooking": 33401620,
    "Beans, dry, cooked, NS as to type and as to fat added in cooking": 41101000,
    "Beans, canned, drained, NS as to type and as to fat added in cooking": 41101050,
    "White beans, dry, cooked, NS as to fat added in cooking": 41101100,
    "White beans, dry, cooked, made with oil": 41101111,
    "White beans, dry, cooked, made with animal fat or meat drippings": 41101112,
    "White beans, dry, cooked, made with margarine": 41101113,
    "White beans, canned, drained, made with oil": 41101150,
    "White beans, canned, drained, low sodium, NS as to fat added in cooking": 41101200,
    "White beans, canned, drained, low sodium, fat not added in cooking": 41101220,
    "Black, brown, or Bayo beans, dry, cooked, NS as to fat added in cooking": 41102000,
    "Black, brown, or Bayo beans, dry, cooked, made with oil": 41102011,
    "Black, brown, or Bayo beans, dry, cooked, made with animal fat or meat drippings": 41102012,
    "Black, brown, or Bayo beans, dry, cooked, made with margarine": 41102013,
    "Black, brown, or Bayo beans, canned, drained, NS as to fat added in cooking": 41102030,
    "Black, brown, or Bayo beans, canned, drained, made with oil": 41102050,
    "Black, brown, or Bayo beans, canned, drained, made with animal fat or meat drippings": 41102060,
    "Black, brown, or Bayo beans, canned, drained, made with margarine": 41102070,
    "Black, brown, or Bayo beans, canned, drained, low sodium, NS as to fat added in cooking": 41102100,
    "Black, brown, or Bayo beans, canned, drained, low sodium, fat not added in cooking": 41102120,
    "Fava beans, dry, cooked, NS as to fat added in cooking": 41102200,
    "Fava beans, dry, cooked, fat not added in cooking": 41102220,
    "Fava beans, canned, drained, fat added in cooking": 41102260,
    "Lima beans, dry, cooked, NS as to fat added in cooking": 41103000,
    "Lima beans, dry, cooked, made with oil": 41103011,
    "Lima beans, dry, cooked, made with animal fat or meat drippings": 41103012,
    "Lima beans, dry, cooked, made with margarine": 41103013,
    "Lima beans, dry, cooked, fat not added in cooking": 41103020,
    "Pink beans, dry, cooked, NS as to fat added in cooking": 41103050,
    "Pink beans, dry, cooked, fat not added in cooking": 41103060,
    "Pink beans, canned, drained, fat added in cooking": 41103090,
    "Pink beans, canned, drained, fat not added in cooking": 41103100,
    "Pinto, calico, or red Mexican beans, dry, cooked, NS as to fat added in cooking": 41104000,
    "Pinto, calico, or red Mexican beans, dry, cooked, made with oil": 41104011,
    "Pinto, calico, or red Mexican beans, dry, cooked, made with animal fat or meat drippings": 41104012,
    "Pinto, calico, or red Mexican beans, dry, cooked, made with margarine": 41104013,
    "Pinto, calico, or red Mexican beans, canned, drained, NS as to fat added in cooking": 41104030,
    "Pinto, calico, or red Mexican beans, canned, drained, made with oil": 41104050,
    "Pinto, calico, or red Mexican beans, canned, drained, made with animal fat or meat drippings": 41104060,
    "Pinto, calico, or red Mexican beans, canned, drained, made with margarine": 41104070,
    "Pinto, calico, or red Mexican beans, canned, drained, low sodium, NS as to fat added in cooking": 41104100,
    "Pinto, calico, or red Mexican beans, canned, drained, low sodium, fat not added in cooking": 41104120,
    "Red kidney beans, dry, cooked, NS as to fat added in cooking": 41106000,
    "Red kidney beans, dry, cooked, made with oil": 41106011,
    "Red kidney beans, dry, cooked, made with animal fat or meat drippings": 41106012,
    "Red kidney beans, dry, cooked, made with margarine": 41106013,
    "Red kidney beans, canned, drained, made with oil": 41106050,
    "Red kidney beans, canned, drained, low sodium, NS as to fat added in cooking": 41106100,
    "Red kidney beans, canned, drained, low sodium, fat not added in cooking": 41106120,
    "Yellow, canary, or Peruvian beans, dry, cooked, NS as to fat added in cooking": 41106500,
    "Yellow, canary, or Peruvian beans, dry, cooked, made with oil": 41106511,
    "Yellow, canary, or Peruvian beans, dry, cooked, made with animal fat or meat drippings": 41106512,
    "Yellow, canary, or Peruvian beans, dry, cooked, made with margarine": 41106513,
    "Yellow, canary, or Peruvian beans, dry, cooked, fat not added in cooking": 41106520,
    "Yellow, canary, or Peruvian beans, canned, drained, fat added in cooking, NS as to type of fat": 41106540,
    "Soybeans, dry, cooked, fat not added in cooking": 41107000,
    "Soybeans, dry, cooked, NS as to fat added in cooking": 41107020,
    "Mung beans, dry, cooked, fat not added in cooking": 41108000,
    "Mung beans, dry, cooked, NS as to fat added in cooking": 41108020,
    "Mung beans, canned, drained, NS as to fat added in cooking": 41108030,
    "Chili beans, barbecue beans, ranch style beans or Mexican- style beans": 41202020,
    "Beans and tomatoes, NS as to fat added in cooking": 41202500,
    "Boston baked beans": 41204020,
    "Refried beans, made with oil": 41205011,
    "Refried beans, made with animal fat or meat drippings": 41205012,
    "Refried beans, made with margarine": 41205013,
    "Refried beans, fat not added in cooking": 41205015,
    "Refried beans, NS as to fat added in cooking": 41205016,
    "Refried beans with cheese": 41205020,
    "Beans, dry, cooked with ground beef": 41207030,
    "Stewed beans with pork, tomatoes, and chili peppers, Mexican style": 41210090,
    "Stewed red beans, Puerto Rican style": 41210100,
    "Stewed pink beans with white potatoes and ham, Puerto Rican style": 41210150,
    "Stewed pink beans with pig's feet, Puerto Rican style": 41210160,
    "Stewed red beans with pig's feet, Puerto Rican style": 41210170,
    "Stewed red beans with pig's feet and potatoes, Puerto Rican style": 41210190,
    "Black beans, Cuban style": 41210200,
    "Cowpeas, dry, cooked, NS as to fat added in cooking": 41301000,
    "Cowpeas, dry, cooked, fat not added in cooking": 41301020,
    "Chickpeas, dry, cooked, NS as to fat added in cooking": 41302000,
    "Chickpeas, dry, cooked, made with oil": 41302011,
    "Chickpeas, dry, cooked, made with animal fat or meat drippings": 41302012,
    "Chickpeas, dry, cooked, made with margarine": 41302013,
    "Chickpeas, canned, drained, NS as to fat added in cooking": 41302030,
    "Chickpeas, canned, drained, made with oil": 41302050,
    "Chickpeas, canned, drained, low sodium, NS as to fat added in cooking": 41302100,
    "Chickpeas, canned, drained, low sodium, fat not added in cooking": 41302120,
    "Green or yellow split peas, dry, cooked, made with oil": 41303011,
    "Green or yellow split peas, dry, cooked, made with animal fat or meat drippings": 41303012,
    "Green or yellow split peas, dry, cooked, made with margarine": 41303013,
    "Green or yellow split peas, dry, cooked, NS as to fat added in cooking": 41303020,
    "Peas, dry, cooked with pork": 41304030,
    "Cowpeas, dry, cooked with pork": 41304130,
    "Lentils, dry, cooked, NS as to fat added in cooking": 41304980,
    "Lentils, dry, cooked, made with oil": 41304991,
    "Lentils, dry, cooked, made with animal fat or meat drippings": 41304992,
    "Lentils, dry, cooked, made with margarine": 41304993,
    "Loaf, lentil": 41306000,
    "Stewed chickpeas, Puerto Rican style": 41310150,
    "Stewed chickpeas, with potatoes, Puerto Rican style": 41310160,
    "Chickpeas stewed with pig's feet, Puerto Rican style": 41310200,
    "Stewed chickpeas with Spanish sausages, Puerto Rican style": 41310210,
    "Fried chickpeas with bacon, Puerto Rican style": 41310220,
    "Soybean meal": 41422010,
    "Tofu, frozen dessert, flavors other than chocolate": 41480000,
    "Tofu, frozen dessert, chocolate": 41480010,
    "Lima bean soup, home recipe, canned or ready-to-serve": 41601040,
    "Pinto bean soup, home recipe, canned or ready-to-serve": 41601080,
    "Bean soup, with macaroni, home recipe, canned, or ready-to-serve": 41601090,
    "Portuguese bean soup, home recipe, canned or ready-to-serve": 41601100,
    "Bean and ham soup, chunky style, canned or ready-to-serve": 41601110,
    "Bean soup, mixed beans, home recipe, canned or ready-to-serve": 41601130,
    "Pea and ham soup, chunky style, canned or ready-to-serve": 41602010,
    "Split pea soup, canned, reduced sodium, prepared with water or ready-to-serve": 41602070,
    "Split pea and ham soup, canned, reduced sodium, prepared with water or ready-to-serve": 41602090,
    "Vegetarian meat loaf": 41812900,
    "Walnuts": 42116050,
    "Peanut butter sandwich, with regular peanut butter, on whole wheat bread": 42301025,
    "Peanut butter sandwich, with reduced fat peanut butter, on whole wheat bread": 42301125,
    "Peanut butter and jelly sandwich, with regular peanut butter, regular jelly, on whole wheat bread": 42302025,
    "Peanut butter and jelly sandwich, with reduced fat peanut butter, regular jelly, on whole wheat bread": 42302065,
    "Peanut butter and jelly sandwich, with regular peanut butter, reduced sugar jelly, on whole wheat bread": 42302115,
    "Peanut butter and jelly sandwich, with reduced fat peanut butter, reduced sugar jelly, on white bread": 42302155,
    "Peanut butter and jelly sandwich, with reduced fat peanut butter, reduced sugar jelly, on wheat bread": 42302160,
    "Peanut butter and jelly sandwich, with reduced fat peanut butter, reduced sugar jelly, on whole wheat bread": 42302165,
    "Carob chips": 44201000,
    "Bread, pita, toasted": 51109110,
    "Bread, pita with fruit, toasted": 51109200,
    "Breadsticks, soft, with parmesan cheese, from frozen": 51184240,
    "Breadsticks, soft, topped with melted cheese": 51184250,
    "Bread, pita, whole wheat, toasted": 51301610,
    "Bread, pita, wheat or cracked wheat, toasted": 51301630,
    "Bread, rye, reduced calorie and/or high fiber": 51401060,
    "Bread, rye, reduced calorie and/or high fiber, toasted": 51401070,
    "Bread, oat bran, reduced calorie and/or high fiber": 51501060,
    "Bread, oat bran, reduced calorie and/or high fiber, toasted": 51501070,
    "Biscuit dough, fried": 52101030,
    "Crumpet": 52101040,
    "Crumpet, toasted": 52101050,
    "Biscuit, baking powder or buttermilk type, made from mix": 52101100,
    "Biscuit, baking powder or buttermilk type, made from refrigerated dough, lowfat": 52101150,
    "Cake batter, raw, chocolate": 53100050,
    "Cake batter, raw, not chocolate": 53100070,
    "Cake, angel food, with icing or filling": 53101200,
    "Cake, angel food, with fruit and icing or filling": 53101250,
    "Cake or cupcake, applesauce, without icing or filling": 53102100,
    "Cake or cupcake, banana, without icing or filling": 53102600,
    "Cake or cupcake, carrot, without icing or filling": 53104100,
    "Cake, carrot, diet": 53104300,
    "Cake, chocolate, with icing, diet": 53105500,
    "Snack cake, chocolate, with icing or filling, reduced fat and calories": 53108220,
    "Snack cake, not chocolate, with icing or filling, reduced fat and calories": 53109220,
    "Cake, Dobos Torte": 53109300,
    "Cake, ice cream and cake roll, chocolate": 53112000,
    "Cake or cupcake, lemon, without icing or filling": 53114000,
    "Cake or cupcake, marble, without icing or filling": 53115100,
    "Cake or cupcake, nut, without icing or filling": 53115310,
    "Cake or cupcake, nut, with icing or filling": 53115320,
    "Cake or cupcake, oatmeal": 53115410,
    "Cake, pound, with icing or filling": 53116020,
    "Cake, pound, chocolate": 53116270,
    "Cake, pound, Puerto Rican style": 53116350,
    "Cake, pound, reduced fat, cholesterol free": 53116390,
    "Cake or cupcake, pumpkin, without icing or filling": 53116500,
    "Cake or cupcake, raisin-nut": 53116550,
    "Cake, Ravani": 53116570,
    "Cake, rice flour, without icing or filling": 53116600,
    "Cake, Quezadilla, El Salvadorian style": 53116650,
    "Cake or cupcake, spice, without icing or filling": 53117100,
    "Cake, sponge, with icing or filling": 53118200,
    "Cake, sponge, chocolate": 53118300,
    "Rum cake, without icing": 53118410,
    "Cake or cupcake, white, without icing or filling": 53120275,
    "Cake, shortcake, biscuit type, with whipped cream and fruit": 53122070,
    "Cake, shortcake, biscuit type, with fruit": 53122080,
    "Cake, shortcake, sponge type, with fruit": 53123080,
    "Cake, shortcake, with whipped topping and fruit, diet": 53123500,
    "Cookie, brownie, fat free, NS as to icing": 53204860,
    "Cookie, fruit-filled bar, fat free": 53220010,
    "Cookie, fig bar, fat free": 53220040,
    "Pie, individual size or tart, NFS": 53300170,
    "Pie, fried, NFS": 53300180,
    "Pie, apple, fried pie": 53301080,
    "Pie, apple, one crust": 53301500,
    "Pie, apple, diet": 53301750,
    "Pie, apricot, two crust": 53302000,
    "Pie, apricot, individual size or tart": 53302070,
    "Pie, apricot, fried pie": 53302080,
    "Pie, blackberry, two crust": 53303000,
    "Pie, blackberry, individual size or tart": 53303070,
    "Pie, berry, not blackberry, blueberry, boysenberry, huckleberry, raspberry, or strawberry; one crust": 53303510,
    "Pie, berry, not blackberry, blueberry, boysenberry, huckleberry, raspberry, or strawberry, individual size or tart": 53303570,
    "Pie, blueberry, one crust": 53304050,
    "Pie, blueberry, individual size or tart": 53304070,
    "Pie, cherry, one crust": 53305010,
    "Pie, cherry, individual size or tart": 53305070,
    "Pie, cherry, fried pie": 53305080,
    "Pie, lemon, not cream or meringue, individual size or tart": 53305720,
    "Pie, lemon, fried pie": 53305750,
    "Pie, mince, two crust": 53306000,
    "Pie, mince, individual size or tart": 53306070,
    "Pie, peach, one crust": 53307050,
    "Pie, peach, individual size or tart": 53307070,
    "Pie, peach, fried pie": 53307080,
    "Pie, pear, two crust": 53307500,
    "Pie, pear, individual size or tart": 53307570,
    "Pie, pineapple, two crust": 53308000,
    "Pie, pineapple, individual size or tart": 53308070,
    "Pie, plum, two crust": 53308300,
    "Pie, prune, one crust": 53308500,
    "Pie, raisin, two crust": 53309000,
    "Pie, raisin, individual size or tart": 53309070,
    "Pie, raspberry, one crust": 53310000,
    "Pie, raspberry, two crust": 53310050,
    "Pie, rhubarb, two crust": 53311000,
    "Pie, rhubarb, one crust": 53311050,
    "Pie, rhubarb, individual size or tart": 53311070,
    "Pie, strawberry-rhubarb, two crust": 53313000,
    "Pie, strawberry, individual size or tart": 53314000,
    "Pie, apple-sour cream": 53340000,
    "Pie, cherry, made with cream cheese and sour cream": 53340500,
    "Pie, banana cream, individual size or tart": 53341070,
    "Pie, buttermilk": 53341500,
    "Pie, chess": 53341750,
    "Pie, chocolate cream, individual size or tart": 53342070,
    "Pie, coconut cream, individual size or tart": 53343070,
    "Pie, custard, individual size or tart": 53344070,
    "Mixed fruit tart filled with custard or cream cheese": 53344200,
    "Pie, lemon cream": 53345000,
    "Pie, lemon cream, individual size or tart": 53345070,
    "Pie, pumpkin, individual size or tart": 53347070,
    "Pie, raspberry cream": 53347100,
    "Pie, sour cream, raisin": 53347500,
    "Pie, squash": 53347600,
    "Pie, strawberry cream": 53348000,
    "Pie, strawberry cream, individual size or tart": 53348070,
    "Pie, yogurt, frozen": 53366000,
    "Pie, chiffon, not chocolate": 53370000,
    "Pie, chiffon, chocolate": 53371000,
    "Pie, black bottom": 53373000,
    "Pie, lemon meringue, individual size or tart": 53381070,
    "Pie, chocolate-marshmallow": 53382000,
    "Pie, pecan, individual size or tart": 53385070,
    "Pie, pudding, flavors other than chocolate": 53386000,
    "Pie, pudding, flavors other than chocolate, individual size or tart": 53386050,
    "Pie, pudding, chocolate, with chocolate coating, individual size": 53386250,
    "Pie, pudding, flavors other than chocolate, with chocolate coating, individual size": 53386500,
    "Pie, Toll house chocolate chip": 53387000,
    "Pie, shoo-fly": 53390000,
    "Pie, tofu with fruit": 53390100,
    "Vanilla wafer dessert base": 53391200,
    "Blintz, cheese-filled": 53400200,
    "Blintz, fruit-filled": 53400300,
    "Cobbler, apricot": 53410200,
    "Cobbler, pear": 53410850,
    "Cobbler, pineapple": 53410860,
    "Cobbler, plum": 53410880,
    "Cobbler, rhubarb": 53410900,
    "Fritter, banana": 53415200,
    "Fritter, berry": 53415220,
    "Crisp, rhubarb": 53415600,
    "Air filled fritter or fried puff, without syrup, Puerto Rican style": 53420300,
    "Wheat flour fritter, without syrup": 53420310,
    "Crepe, NS as to filling": 53430000,
    "Tamale, sweet, with fruit": 53430750,
    "Strudel, pineapple": 53440750,
    "Strudel, cheese and fruit": 53440800,
    "Turnover or dumpling, berry": 53450300,
    "Turnover or dumpling, cherry": 53450500,
    "Turnover or dumpling, lemon": 53450800,
    "Turnover or dumpling, peach": 53451000,
    "Turnover, guava": 53451500,
    "Turnover, pumpkin": 53451750,
    "Empanada,  Mexican turnover, pumpkin": 53453170,
    "Doughnut, cake type": 53520110,
    "Doughnut, cake type, chocolate covered, dipped in peanuts": 53520150,
    "Doughnut, Asian": 53520500,
    "Cruller, NFS": 53520600,
    "French cruller": 53520700,
    "Doughnut, chocolate, raised or yeast, with chocolate icing": 53521100,
    "Doughnut, chocolate, raised or yeast": 53521120,
    "Doughnut, chocolate cream-filled": 53521220,
    "Cookie, fruit, baby food": 53803050,
    "Cookie, teething, baby": 53803250,
    "Crackers, corn": 54339000,
    "Gerber Finger Foods, Puffs, baby food": 54350010,
    "Finger Foods, Puffs, baby food": 54350020,
    "Tortilla chips, popped": 54401095,
    "Popcorn, microwave, unsalted": 54403055,
    "Popcorn, microwave, kettle corn, light": 54403061,
    "Popcorn, microwave, other flavored": 54403062,
    "Popcorn, ready-to-eat packaged, unsalted": 54403084,
    "Popcorn, ready-to-eat packaged, butter flavored, light": 54403086,
    "Popcorn, ready-to-eat packaged, kettle corn, light": 54403091,
    "Popcorn, ready-to-eat packaged, other flavored": 54403092,
    "Pretzel, baby food": 54408100,
    "Pancakes, plain, reduced fat, from fozen": 55100015,
    "Pancakes, whole grain, reduced fat, from frozen": 55100035,
    "Pancakes, gluten free, from frozen": 55100040,
    "Pancakes, whole grain and nuts, from fast food / restaurant": 55100070,
    "Pancakes, buckwheat": 55105000,
    "Pancakes, cornmeal": 55105100,
    "Waffle, gluten free, from frozen": 55200090,
    "Waffle, cornmeal": 55204000,
    "French toast, whole grain, from frozen": 55300030,
    "French toast, gluten free, from frozen": 55300040,
    "French toast, whole grain, from fast food / restaurant": 55300055,
    "French toast, plain, reduced fat": 55301010,
    "French toast, whole grain, reduced fat": 55301020,
    "French toast sticks, NFS": 55301030,
    "French toast sticks, plain": 55301050,
    "French toast sticks, whole grain": 55301055,
    "Fried bread, Puerto Rican style": 55310100,
    "Flour and water gravy": 55502000,
    "Dumpling, fried, Puerto Rican style": 55610200,
    "Barley, NS as to fat added in cooking": 56200390,
    "Barley, fat not added in cooking": 56200400,
    "Buckwheat groats, NS as to fat added in cooking": 56200490,
    "Buckwheat groats, fat not added in cooking": 56200500,
    "Grits, NS as to regular, quick, or instant, fat not added in cooking": 56201000,
    "Grits, NS as to regular, quick, or instant, fat added in cooking": 56201040,
    "Grits, regular or quick, made with milk, NS as to fat added in cooking": 56201055,
    "Grits, regular or quick, made with milk, fat not added in cooking": 56201056,
    "Grits, regular or quick, made with milk, fat added in cooking": 56201057,
    "Grits, regular or quick, made with non-dairy milk, NS as to fat added in cooking": 56201065,
    "Grits, regular or quick, made with non-dairy milk, fat not added in cooking": 56201066,
    "Grits, regular or quick, made with non-dairy milk, fat added in cooking": 56201067,
    "Grits, instant, made with milk, fat added in cooking": 56201340,
    "Grits, instant, made with milk, fat not added in cooking": 56201342,
    "Grits, instant, made with milk, NS as to fat added in cooking": 56201344,
    "Grits, instant, made with non-dairy milk, NS as to fat added in cooking": 56201350,
    "Grits, instant, made with non-dairy milk, fat not added in cooking": 56201355,
    "Grits, instant, made with non-dairy milk, fat added in cooking": 56201360,
    "Cornmeal, Puerto Rican Style": 56201540,
    "Cornmeal dumpling": 56201550,
    "Millet, NS as to fat added in cooking": 56201990,
    "Millet, fat added in cooking": 56202100,
    "Oatmeal, from fast food, fruit flavored": 56202910,
    "Oatmeal, from fast food, other flavors": 56202920,
    "Oatmeal, NS as to regular, quick, or instant, fat not added in cooking": 56203000,
    "Oatmeal, NS as to regular, quick, or instant, fat added in cooking": 56203040,
    "Oatmeal, regular or quick, made with water, NS as to fat added in cooking": 56203055,
    "Oatmeal, regular or quick, made with milk, NS as to fat added in cooking": 56203065,
    "Oatmeal, regular or quick, made with non-dairy milk, NS as to fat added in cooking": 56203075,
    "Oatmeal, instant, plain, made with water, NS as to fat added in cooking": 56203085,
    "Oatmeal, instant, plain, made with milk, NS as to fat added in cooking": 56203095,
    "Oatmeal, instant, plain, made with non-dairy milk, NS as to fat added in cooking": 56203105,
    "Oatmeal, instant, maple flavored, NS as to fat added in cooking": 56203125,
    "Oatmeal, instant, fruit flavored, NS as to fat added in cooking": 56203150,
    "Oatmeal, instant, other flavors, NS as to fat added in cooking": 56203170,
    "Oatmeal, instant, other flavors, fat not added in cooking": 56203175,
    "Oatmeal, instant, other flavors, fat added in cooking": 56203180,
    "Oatmeal, reduced sugar, plain, NS as to fat added in cooking": 56203500,
    "Oatmeal, reduced sugar, plain, fat not added in cooking": 56203510,
    "Oatmeal, reduced sugar, plain, fat added in cooking": 56203520,
    "Oatmeal, made with milk and sugar, Puerto Rican style": 56203540,
    "Oatmeal, reduced sugar, flavored, NS as to fat added in cooking": 56203550,
    "Oatmeal, reduced sugar, flavored, fat added in cooking": 56203560,
    "Oatmeal, multigrain, NS as to fat added in cooking": 56203600,
    "Oatmeal, multigrain, fat added in cooking": 56203620,
    "Rice, creamed, made with milk and sugar, Puerto Rican style": 56205080,
    "Rice, cream of, cooked, fat added in cooking": 56205090,
    "Rice, cream of, cooked, NS as to fat added in cooking": 56205092,
    "Rice, cream of, cooked, made with milk": 56205094,
    "Rice, frozen dessert, nondairy, flavors other than chocolate": 56205200,
    "Rice dessert bar, frozen, flavors other than chocolate, nondairy, carob covered": 56205230,
    "Rice dessert bar, frozen, chocolate, nondairy, chocolate covered": 56205240,
    "Cream of wheat, NS as to regular, quick, or instant, fat not added in cooking": 56207000,
    "Cream of wheat, NS as to regular, quick, or instant, fat added in cooking": 56207005,
    "Cream of wheat, regular or quick, made with water, NS as to fat added in cooking": 56207015,
    "Cream of wheat, regular or quick, made with milk, NS as to fat added in cooking": 56207021,
    "Cream of wheat, regular or quick, made with milk, fat not added in cooking": 56207022,
    "Cream of wheat, regular or quick, made with milk, fat added in cooking": 56207023,
    "Cream of wheat, regular or quick, made with non-dairy milk, NS as to fat added in cooking": 56207025,
    "Cream of wheat, regular or quick, made with non-dairy milk, fat not added in cooking": 56207026,
    "Cream of wheat, regular or quick, made with non-dairy milk, fat added in cooking": 56207027,
    "Wheat, cream of, cooked, made with milk and sugar, Puerto Rican style": 56207050,
    "Cream of wheat, instant, made with water, NS as to fat added in cooking": 56207070,
    "Cream of wheat, instant, made with milk, fat added in cooking": 56207094,
    "Cream of wheat, instant, made with milk, fat not added in cooking": 56207095,
    "Cream of wheat, instant, made with milk, NS as to fat added in cooking": 56207096,
    "Cream of wheat, instant, made with non-dairy milk, NS as to fat added in cooking": 56207101,
    "Cream of wheat, instant, made with non-dairy milk, fat not added in cooking": 56207102,
    "Cream of wheat, instant, made with non-dairy milk, fat added in cooking": 56207103,
    "Whole wheat cereal, cooked, NS as to fat added in cooking": 56207190,
    "Whole wheat cereal, cooked, fat added in cooking": 56207210,
    "Oat bran cereal, cooked, fat added in cooking": 56208510,
    "Oat bran cereal, cooked, NS as to fat added in cooking": 56208520,
    "Cream of rye": 56209000,
    "Cereal, nestum": 56210000,
    "Cereal, oat, NFS": 57000100,
    "Cereal (Kellogg's All-Bran)": 57101000,
    "Cereal (Alpen)": 57102000,
    "Cereal (Post Alpha-Bits)": 57103000,
    "Cereal (Kellogg's Apple Jacks)": 57104000,
    "Cereal (Post Great Grains Banana Nut Crunch)": 57106050,
    "Cereal (General Mills Cheerios Banana Nut)": 57106060,
    "Cereal (General Mills Basic 4)": 57106100,
    "Cereal (General Mills Kix Berry Berry)": 57106250,
    "Cereal (General Mills Cheerios Berry Burst)": 57106260,
    "Cereal (Post Selects Blueberry Morning)": 57106530,
    "Cereal (General Mills Boo Berry)": 57107000,
    "Cereal (Kellogg's All-Bran Bran Buds)": 57110000,
    "Cereal (Quaker Cap'n Crunch)": 57117000,
    "Cereal (Quaker Christmas Crunch)": 57117500,
    "Cereal (Quaker Cap'n Crunch's Peanut Butter Crunch)": 57120000,
    "Cereal (General Mills Chex Chocolate)": 57124030,
    "Cereal (General Mills Chex Cinnamon)": 57124050,
    "Cereal (General Mills Cheerios Chocolate)": 57124100,
    "Cereal (General Mills Lucky Charms Chocolate)": 57124300,
    "Cereal (Kellogg's Cinnabon)": 57124900,
    "Cereal (General Mills 25% Less Sugar Cinnamon Toast Crunch)": 57125010,
    "Cereal (General Mills Honey Nut Clusters)": 57125900,
    "Cereal (Post Cocoa Pebbles)": 57127000,
    "Cereal (General Mills Cocoa Puffs)": 57128000,
    "Cereal (General Mills 25% Less Sugar Cocoa Puffs)": 57128005,
    "Cereal (General Mills Cookie Crisp)": 57130000,
    "Cereal (Quaker Corn Bran Crunch)": 57131000,
    "Cereal (Kellogg's Corn Flakes)": 57135000,
    "Cereal (General Mills Count Chocula)": 57139000,
    "Cereal (Kellogg's Cracklin' Oat Bran)": 57143000,
    "Cereal (Post Great Grains, Cranberry Almond Crunch)": 57143500,
    "Cereal (Kellogg's Crispix)": 57148000,
    "Cereal, crispy brown rice": 57148500,
    "Cereal (General Mills Dora The Explorer)": 57201900,
    "Cereal (Famila)": 57206000,
    "Cereal (General Mills Fiber One)": 57206700,
    "Cereal (General Mills Fiber One Caramel Delight)": 57206705,
    "Cereal (General Mills Fiber One Honey Clusters)": 57206710,
    "Cereal (General Mills Fiber One Raisin Bran Clusters)": 57206715,
    "Cereal (Healt Valley Fiber 7 Flakes)": 57206800,
    "Cereal (Kellogg's All-Bran Complete Wheat Flakes)": 57208000,
    "Cereal (Post Bran Flakes)": 57209000,
    "Cereal (General Mills Frankenberry)": 57211000,
    "Cereal (Kellogg's Froot Loops)": 57213000,
    "Cereal (Kellogg's Froot Loops Marshmallow)": 57213010,
    "Cereal (General Mills Cheerios Frosted)": 57213850,
    "Cereal (Kellogg's Frosted Krispies)": 57218000,
    "Cereal, fruit and fiber": 57219000,
    "Cereal, fiber and fruit": 57221000,
    "Cereal, fruit whirls": 57221800,
    "Cereal (General Mills Cheerios Fruity)": 57221810,
    "Cereal (General Mills Golden Grahams)": 57224000,
    "Granola, homemade": 57228000,
    "Cereal (Kellogg's Low Fat Granola)": 57229000,
    "Cereal (Kellogg's Low Fat Granola with Raisins)": 57229500,
    "Cereal (Post Grape-Nuts)": 57230000,
    "Cereal (Post Grape-Nuts Flakes)": 57231000,
    "Cereal (Post Great Grains Raisins, Dates, and Pecans)": 57231200,
    "Cereal (Post Great Grains Double Pecan Whole Grain Cereal)": 57231250,
    "Cereal (Post Honey Bunches of Oats with Vanilla Bunches)": 57237200,
    "Cereal (Post Honey Bunches of Oats with Almonds)": 57237300,
    "Cereal (Post Honey Bunches of Oats wth Pecan Bunches)": 57237310,
    "Cereal (Post Honey Bunches of Oats Just Bunches)": 57237900,
    "Cereal (Post Honeycomb)": 57238000,
    "Cereal (Kellogg's Honey Crunch Corn Flakes)": 57239100,
    "Cereal (Post Shredded Wheat Honey Nut)": 57241200,
    "Cereal (Kellogg's Honey Smacks)": 57243000,
    "Cereal (Kashi 7 Whole Grain Puffs)": 57301500,
    "Cereal (Kashi Autumn Wheat)": 57301505,
    "Cereal (Kashi GOLEAN)": 57301510,
    "Cereal (Kashi GOLEAN Crunch)": 57301511,
    "Cereal (Kashi GOLEAN Crunch Honey Almond Flax)": 57301512,
    "Cereal (Kashi Good Friends)": 57301520,
    "Cereal (Kashi Heart to Heart Honey Toasted Oat)": 57301530,
    "Cereal (Kashi Heart to Heart Oat Flakes and Blueberry Clusters)": 57301535,
    "Cereal (Kashi Honey Sunshine Squares)": 57301540,
    "Cereal (Quaker King Vitaman)": 57302100,
    "Cereal (General Mills Kix)": 57303100,
    "Cereal (General Mills Honey Kix)": 57303105,
    "Cereal (Kellogg's Krave)": 57303200,
    "Cereal (General Mills Lucky Charms)": 57305100,
    "Cereal (Malt-O-Meal Blueberry Muffin Tops)": 57305160,
    "Cereal (Malt-O-Meal Cinnamon Toasters)": 57305165,
    "Cereal (Malt-O-Meal Coco-Roos)": 57305170,
    "Cereal (Malt-O-Meal Colossal Crunch)": 57305174,
    "Cereal (Malt-O-Meal Cocoa Dyno-Bites)": 57305175,
    "Cereal (Malt-O-Meal Corn Bursts)": 57305180,
    "Cereal (Malt-O-Meal Crispy Rice)": 57305200,
    "Cereal (Malt-O-Meal Frosted Flakes)": 57305210,
    "Cereal (Malt-O-Meal Frosted Mini Spooners)": 57305215,
    "Cereal (Malt-O-Meal Fruity Dyno-Bites)": 57305300,
    "Cereal (Malt-O-Meal Honey Graham Squares)": 57305400,
    "Cereal (Malt-O-Meal Honey Nut Toasty O's)": 57305500,
    "Cereal (Malt-O-Meal Marshmallow Mateys)": 57305600,
    "Cereal (Malt-O-Meal Raisin Bran)": 57306130,
    "Cereal (Malt-O-Meal Golden Puffs)": 57306500,
    "Cereal (Malt-O-Meal Toasted Oat Cereal)": 57306700,
    "Cereal (Malt-O-Meal Tootie Fruities)": 57306800,
    "Cereal (Post Maple Pecan Crunch)": 57307010,
    "Cereal, millet, puffed": 57307500,
    "Cereal, muesli": 57308190,
    "Cereal (Nature Valley Granola)": 57309100,
    "Cereal, nutty nuggets": 57316200,
    "Cereal (Health Valley Oat Bran Flakes)": 57316300,
    "Cereal (General Mills Cheerios Oat Cluster Crunch)": 57316380,
    "Cereal (General Mills Cheerios Protein)": 57316385,
    "Cereal (General Mills Oatmeal Crisp with Almonds)": 57316450,
    "Cereal (General Mills Oatmeal Crisp with Raisins)": 57316500,
    "Cereal (Quaker Honey Graham Oh's)": 57316710,
    "Cereal (Quaker Granola with Oats, Honey, and Raisins)": 57320500,
    "Cereal (Nature's Path Organic Flax Plus)": 57321900,
    "Cereal (Quaker Sweet Crunch)": 57323000,
    "Cereal (Kellogg's Product 19)": 57325000,
    "Cereal (Barbara's Puffins)": 57326000,
    "Cereal (Quaker Toasted Oat Bran)": 57327450,
    "Cereal (Quaker Oatmeal Squares)": 57327500,
    "Cereal (Quaker Quisp)": 57328000,
    "Cereal (Kellogg's Raisin Bran)": 57330000,
    "Cereal (Kellogg's Raisin Bran Crunch)": 57330010,
    "Cereal (Post Raisin Bran)": 57331000,
    "Cereal (General Mills Total Raisin Bran)": 57332050,
    "Cereal (General Mills Raisin Nut Bran)": 57332100,
    "Cereal (General Mills Reese's Puffs)": 57335550,
    "Cereal, rice flakes": 57337000,
    "Cereal (Kellogg's Rice Krispies)": 57339000,
    "Cereal (Kellogg's Rice Krispies Treats Cereal)": 57339500,
    "Cereal, puffed rice": 57340000,
    "Cereal (Post Shredded Wheat'n Bran)": 57341000,
    "Cereal (Kellogg's Smart Start Strong)": 57341200,
    "Cereal (Kellogg's Special K Blueberry)": 57344001,
    "Cereal (Kellogg's Special K Chocolatey Delight)": 57344005,
    "Cereal (Kellogg's Special K Low Fat Granola)": 57344007,
    "Cereal (Kellogg's Special K Fruit & Yogurt)": 57344015,
    "Cereal (Kellogg's Special K Vanilla Almond)": 57344020,
    "Cereal (Kellogg's Special K Cinnamon Pecan)": 57344025,
    "Cereal (Kellogg's Frosted Flakes)": 57349000,
    "Cereal (Kellogg's Frosted Flakes, Reduced Sugar)": 57349020,
    "Cereal (Post Golden Crisp)": 57355000,
    "Cereal (General Mills Total)": 57406100,
    "Cereal (General Mills Trix)": 57407100,
    "Cereal (General Mills 25% Less Sugar Trix)": 57407110,
    "Cereal (Uncle Sam)": 57408100,
    "Cereal (Post Waffle Crisp)": 57409100,
    "Cereal (Weetabix Whole Grain)": 57410000,
    "Wheat germ, with sugar and honey": 57413000,
    "Cereal, puffed wheat, sweetened": 57416010,
    "Cereal (General Mills Cheerios Yogurt Burst)": 57419000,
    "Mixed cereal, baby food, dry, instant": 57803000,
    "Rice cereal with apples, baby food, dry, instant": 57805080,
    "Rice cereal with bananas, baby food, dry, instant": 57805100,
    "Brown rice cereal, baby food, dry, instant": 57805500,
    "Oatmeal cereal with fruit, baby food, dry, instant, toddler": 57806200,
    "Whole wheat cereal with apples, baby food, dry, instant": 57807010,
    "Rice cereal with mixed fruit, baby food, jarred": 57824500,
    "Gerber Graduates Finger Snacks Cereal, baby food": 57830100,
    "Burrito, taco, or quesadilla with egg": 58100000,
    "Burrito, taco, or quesadilla with egg and potato": 58100005,
    "Burrito, taco, or quesadilla with egg and breakfast meat": 58100010,
    "Burrito, taco, or quesadilla with egg and breakfast meat, from fast food": 58100013,
    "Burrito, taco, or quesadilla with egg, potato, and breakfast meat": 58100015,
    "Burrito, taco, or quesadilla with egg, potato, and breakfast meat, from fast food": 58100017,
    "Burrito, taco, or quesadilla with egg, beans, and breakfast meat": 58100020,
    "Burrito with meat": 58100100,
    "Burrito with meat and beans": 58100120,
    "Burrito with meat and beans, from fast food": 58100125,
    "Burrito with meat and sour cream": 58100135,
    "Burrito with meat, beans, and sour cream": 58100140,
    "Burrito with meat, beans, and sour cream, from fast food": 58100145,
    "Burrito with meat, beans, and rice": 58100160,
    "Burrito with meat, beans, rice, and sour cream": 58100165,
    "Burrito with chicken": 58100200,
    "Burrito with chicken and beans": 58100220,
    "Burrito with chicken and sour cream": 58100235,
    "Burrito with chicken, beans, and sour cream": 58100245,
    "Burrito with chicken, beans, and rice": 58100255,
    "Burrito with chicken, beans, rice, and sour cream": 58100260,
    "Burrito with beans and rice, meatless": 58100300,
    "Burrito with beans, meatless": 58100320,
    "Burrito with beans, meatless, from fast food": 58100325,
    "Burrito with beans, rice, and sour cream, meatless": 58100330,
    "Chilaquiles, tortilla casserole with salsa and cheese, no egg": 58100370,
    "Enchilada with meat and beans, red-chile or enchilada sauce": 58100520,
    "Enchilada with meat and beans, green-chile or enchilada sauce": 58100525,
    "Enchilada with meat, red-chile or enchilada sauce": 58100530,
    "Enchilada with meat, green-chile or enchilada sauce": 58100535,
    "Enchilada with chicken and beans, red-chile or enchilada sauce": 58100620,
    "Enchilada with chicken and beans, green-chile or enchilada sauce": 58100625,
    "Enchilada with chicken, red-chile or enchilada sauce": 58100630,
    "Enchilada with chicken, green-chile or enchilada sauce": 58100635,
    "Enchilada with beans, meatless, red-chile or enchilada sauce": 58100720,
    "Enchilada with beans, green-chile or enchilada sauce": 58100725,
    "Enchilada, just cheese, meatless, no beans, red-chile or enchilada sauce": 58100800,
    "Enchilada, just cheese, meatless, no beans, green-chile or enchilada sauce": 58100805,
    "Taco or tostada with meat": 58101320,
    "Taco or tostada with meat, from fast food": 58101323,
    "Taco or tostada with meat and sour cream": 58101325,
    "Soft taco with meat": 58101345,
    "Soft taco with meat, from fast food": 58101347,
    "Soft taco with meat and sour cream": 58101350,
    "Soft taco with meat and sour cream, from fast food": 58101357,
    "Soft taco with chicken": 58101450,
    "Soft taco with chicken, from fast food": 58101457,
    "Soft taco with chicken and sour cream": 58101460,
    "Taco or tostada with chicken": 58101520,
    "Taco or tostada with chicken and sour cream": 58101525,
    "Taco or tostada with fish": 58101540,
    "Soft taco with fish": 58101555,
    "Soft taco with beans": 58101610,
    "Soft taco with beans and sour cream": 58101615,
    "Soft taco with meat and beans": 58101620,
    "Soft taco with chicken and beans": 58101625,
    "Soft taco with meat, beans, and sour cream": 58101630,
    "Soft taco with chicken, beans, and sour cream": 58101635,
    "Taco or tostada with beans": 58101720,
    "Taco or tostada with beans and sour cream": 58101725,
    "Taco or tostada with meat and beans": 58101730,
    "Taco or tostada with meat and beans, from fast food": 58101733,
    "Taco or tostada with chicken and beans": 58101735,
    "Taco or tostada with meat, beans, and sour cream": 58101745,
    "Taco or tostada with chicken, beans, and sour cream": 58101750,
    "Ground beef with tomato sauce and taco seasonings on a cornbread crust": 58101800,
    "Mexican casserole made with ground beef, beans, tomato sauce, cheese, taco seasonings, and corn chips": 58101820,
    "Tamale, plain, meatless, no sauce, Puerto Rican style or Carribean Style": 58103200,
    "Tamale, meatless, with sauce, Puerto Rican or Caribbean style": 58103210,
    "Nachos with cheese and sour cream": 58104090,
    "Nachos with chili": 58104160,
    "Nachos with meat, cheese, and sour cream": 58104180,
    "Nachos with chicken, cheese, and sour cream": 58104190,
    "Gordita, sope, or chalupa with beans and sour cream": 58104270,
    "Gordita, sope, or chalupa with meat and sour cream": 58104280,
    "Gordita, sope, or chalupa with chicken and sour cream": 58104320,
    "Gordita, sope, or chalupa with chicken": 58104340,
    "Chimichanga with meat and sour cream": 58104535,
    "Chimichanga, meatless, with sour cream": 58104540,
    "Chimichanga with chicken and sour cream": 58104550,
    "Quesadilla, just cheese, from fast food": 58104720,
    "Quesadilla with chicken, from fast food": 58104745,
    "Taquito or flauta with meat": 58104820,
    "Taquito or flauta with chicken": 58104830,
    "Taquito or flauta with egg and breakfast meat": 58104905,
    "Wonton, fried, filled with meat, poultry, or seafood, and vegetable": 58111130,
    "Tamale casserole, Puerto Rican style": 58115110,
    "Tamal in a leaf, Puerto Rican style": 58115150,
    "Taco with crab meat, Puerto Rican style": 58115210,
    "Meat turnover, Puerto Rican style": 58116110,
    "Meat pie, Puerto Rican style": 58116210,
    "Cheese turnover, Puerto Rican style": 58116310,
    "Cornmeal fritter, Puerto Rican style": 58117110,
    "Cornstarch coconut dessert, Puerto Rican style": 58118110,
    "Cornmeal coconut dessert, Puerto Rican style": 58118210,
    "Crepe, filled with meat, poultry, or seafood, no sauce": 58120120,
    "Dumpling, meat-filled": 58121510,
    "Dumpling, vegetable": 58121620,
    "Kishke, stuffed derma": 58122250,
    "Knish, potato": 58122310,
    "Knish, meat": 58122330,
    "Turnover filled with ground beef and cabbage": 58126000,
    "Turnover, meat-filled, no gravy": 58126110,
    "Turnover, meat-filled, with gravy": 58126120,
    "Turnover, meat- and bean-filled, no gravy": 58126140,
    "Turnover filled with meat and vegetable, no potatoes, no gravy": 58126170,
    "Turnover, meat-, potato-, and vegetable-filled, no gravy": 58126180,
    "Turnover, chicken- or turkey-, and vegetable-filled, lower in fat": 58126280,
    "Turnover, meat- and cheese-filled, tomato-based sauce, lower in fat": 58126300,
    "Turnover, chicken, with gravy": 58126310,
    "Turnover, filled with egg, meat, and cheese, lower in fat": 58126410,
    "Vegetables in pastry": 58127110,
    "Vegetables and cheese in pastry": 58127150,
    "Croissant sandwich, filled with broccoli and cheese": 58127200,
    "Croissant sandwich, filled with ham and cheese": 58127210,
    "Croissant sandwich, filled with chicken, broccoli, and cheese sauce": 58127220,
    "Croissant sandwich with sausage and egg": 58127270,
    "Croissant sandwich with bacon and egg": 58127290,
    "Croissant sandwich with ham, egg, and cheese": 58127310,
    "Croissant sandwich with sausage, egg, and cheese": 58127330,
    "Croissant sandwich with bacon, egg, and cheese": 58127350,
    "Vegetable submarine sandwich, with fat free spread": 58127500,
    "Vegetable submarine sandwich, with spread": 58127510,
    "Lasagna with meat, whole wheat noodles": 58130610,
    "Lasagna, meatless, whole wheat noodles": 58130810,
    "Lasagna with meat, spinach noodles": 58130910,
    "Lasagna, meatless, spinach noodles": 58130950,
    "Tortellini, cheese-filled, meatless, with vegetables and vinaigrette dressing": 58134630,
    "Pasta, meat-filled, with gravy, canned": 58146200,
    "Noodle pudding, with milk": 58149160,
    "Somen salad with noodles, lettuce, egg, fish, and pork": 58149210,
    "Rice meal fritter, Puerto Rican style": 58155610,
    "Pigeon pea asopao, Asopao de gandules": 58156610,
    "Spicy rice pudding, Puerto Rican style": 58157110,
    "Rice pudding made with coconut milk, Puerto Rican style": 58157210,
    "Rice with beans and chicken": 58160130,
    "Rice with beans and beef": 58160135,
    "Rice with beans and pork": 58160140,
    "Hopping John": 58160160,
    "Rice, brown, with beans and tomatoes": 58161325,
    "Wrap sandwich, filled with meat, poultry, or fish, vegetables, and rice": 58200100,
    "Wrap sandwich, filled with vegetables and rice": 58200200,
    "Wrap sandwich, filled with meat, poultry, or fish, vegetables, rice, and cheese": 58200300,
    "Jelly sandwich, regular jelly, on whole wheat bread": 58201025,
    "Jelly sandwich, reduced sugar jelly, on white bread": 58201035,
    "Jelly sandwich, reduced sugar jelly, on wheat bread": 58201045,
    "Jelly sandwich, reduced sugar jelly, on whole wheat bread": 58201055,
    "Lasagna with cheese and sauce, diet frozen meal": 58301020,
    "Veal lasagna, diet frozen meal": 58301030,
    "Lasagna with cheese and meat sauce, diet frozen meal": 58301050,
    "Vegetable lasagna, frozen meal": 58301110,
    "Zucchini lasagna, diet frozen meal": 58301150,
    "Macaroni and cheese, diet frozen meal": 58302000,
    "Beef and noodles with meat sauce and cheese, diet frozen meal": 58302050,
    "Rice, with broccoli, cheese sauce, frozen side dish": 58303100,
    "Rice, with green beans, water chestnuts, in sherry mushroom sauce, frozen side dish": 58303200,
    "Spaghetti and meatballs with tomato sauce, sliced apples, bread, frozen meal": 58304020,
    "Spaghetti with meat and mushroom sauce, diet frozen meal": 58304050,
    "Ravioli, cheese-filled, with tomato sauce, diet frozen meal": 58304200,
    "Rigatoni with meat sauce and cheese, diet frozen meal": 58304220,
    "Manicotti, cheese-filled, with tomato sauce, diet frozen meal": 58304250,
    "Cannelloni, cheese-filled, with tomato sauce, diet frozen meal": 58304300,
    "Linguini with vegetables and seafood in white wine sauce, diet frozen meal": 58304400,
    "Pasta with vegetable and cheese sauce, diet frozen meal": 58305250,
    "Beef enchilada dinner, NFS, frozen meal": 58306010,
    "Beef enchilada, chili gravy, rice, refried beans, frozen meal": 58306020,
    "Cheese enchilada, frozen meal": 58306070,
    "Chicken enchilada, diet frozen meal": 58306100,
    "Chicken fajitas, diet frozen meal": 58306200,
    "Chicken burritos, diet frozen meal": 58306500,
    "Sausage and french toast, frozen meal": 58310210,
    "Barley soup, sweet, with or without nuts, Asian Style": 58401200,
    "Beef dumpling soup, home recipe, canned or ready-to-serve": 58402020,
    "Beef rice soup, home recipe, canned or ready-to-serve": 58402030,
    "Noodle and potato soup, Puerto Rican style": 58403100,
    "Chicken or turkey rice soup, reduced sodium, canned, prepared with milk": 58404050,
    "Rice and potato soup, Puerto Rican style": 58404100,
    "Instant soup, noodle with egg, shrimp or chicken": 58407050,
    "Noodle soup, with fish ball, shrimp, and dark green leafy vegetable": 58409000,
    "Sopa seca, Mexican style, NFS": 58421000,
    "Macaroni, tomatoes, and beef, baby food, NS as to strained or junior": 58503000,
    "Macaroni, tomatoes, and beef, baby food, strained": 58503010,
    "Macaroni, tomatoes, and beef, baby food, junior": 58503020,
    "Macaroni with beef and tomato sauce, baby food, toddler": 58503050,
    "Macaroni and cheese, baby food, strained": 58508000,
    "Macaroni and cheese, baby food, toddler": 58508300,
    "Spaghetti, tomato sauce, and beef, baby food, junior": 58509020,
    "Ravioli, cheese-filled, with tomato sauce, baby food, toddler": 58509100,
    "Macaroni with vegetables, baby food, strained": 58509200,
    "Calamondin, raw": 61100500,
    "Grapefruit, canned or frozen, unsweetened, water pack": 61101220,
    "Grapefruit, canned or frozen, in light syrup": 61101230,
    "Grapefruit and orange sections, raw": 61104010,
    "Grapefruit and orange sections, cooked, canned, or frozen, NS as to added sweetener": 61104200,
    "Grapefruit and orange sections, cooked, canned, or frozen, unsweetened, water pack": 61104220,
    "Grapefruit and orange sections, cooked, canned, or frozen, in light syrup": 61104230,
    "Kumquat, cooked or canned, in syrup": 61110230,
    "Orange, sections, canned, juice pack": 61119020,
    "Orange, mandarin, canned or frozen, drained": 61122350,
    "Tangelo, raw": 61125000,
    "Fruit juice blend, citrus, 100% juice, with calcium added": 61213900,
    "Fruit, dried, NFS, uncooked": 62101000,
    "Apple, dried, cooked, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 62101200,
    "Apple, dried, cooked, unsweetened": 62101220,
    "Apple, dried, cooked, with sugar": 62101230,
    "Apple chips": 62101300,
    "Apricot, dried, cooked, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 62104200,
    "Apricot, dried, cooked, unsweetened": 62104220,
    "Apricot, dried, cooked, with sugar": 62104230,
    "Banana flakes, dehydrated": 62107100,
    "Fig, dried, cooked, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 62113200,
    "Fig, dried, cooked, unsweetened": 62113220,
    "Fig, dried, cooked, with sugar": 62113230,
    "Lychee, dried": 62114000,
    "Peach, dried, cooked, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 62116200,
    "Peach, dried, cooked, unsweetened": 62116220,
    "Peach, dried, cooked, with sugar": 62116230,
    "Pear, dried, cooked, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 62119200,
    "Pear, dried, cooked, unsweetened": 62119220,
    "Pear, dried, cooked, with sugar": 62119230,
    "Plum, rock salt, dried": 62121100,
    "Prune, dried, cooked, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 62122200,
    "Prune, dried, cooked, unsweetened": 62122220,
    "Prune, dried, cooked, with sugar": 62122230,
    "Raisins, cooked": 62125110,
    "Applesauce, stewed apples, with sugar": 63101130,
    "Applesauce, stewed apples, sweetened with low calorie sweetener": 63101140,
    "Apple, baked, unsweetened": 63101320,
    "Apple, baked, with sugar": 63101330,
    "Apple rings, fried": 63101410,
    "Apple, pickled": 63101420,
    "Apple, fried": 63101500,
    "Apricot, cooked or canned, unsweetened, water pack": 63103120,
    "Apricot, cooked or canned, in heavy syrup": 63103130,
    "Apricot, cooked or canned, in light syrup": 63103140,
    "Apricot, cooked or canned, drained solids": 63103150,
    "Apricot, cooked or canned, juice pack": 63103170,
    "Banana, white, Guineo blanco maduro": 63107050,
    "Banana, Chinese, raw": 63107070,
    "Banana, red, Guineo morado": 63107080,
    "Banana, red, fried": 63107090,
    "Banana, baked": 63107110,
    "Banana, ripe, fried": 63107210,
    "Banana, ripe, boiled": 63107310,
    "Cantaloupe, frozen": 63109610,
    "Starfruit, cooked, with sugar": 63109750,
    "Cassaba melon, raw": 63110010,
    "Cherries, sour, red, raw": 63113010,
    "Cherries, sour, red, cooked, unsweetened": 63113110,
    "Cherries, sweet, cooked, unsweetened, water pack": 63115120,
    "Cherries, sweet, cooked or canned, in heavy syrup": 63115130,
    "Cherries, sweet, cooked or canned, in light syrup": 63115140,
    "Cherries, sweet, cooked or canned, drained solids": 63115150,
    "Cherries, sweet, cooked or canned, juice pack": 63115170,
    "Currants, raw": 63117010,
    "Fig, cooked or canned, unsweetened, water pack": 63119120,
    "Fig, cooked or canned, in heavy syrup": 63119130,
    "Figs, cooked or canned, in light syrup": 63119140,
    "Grapes, European type, adherent skin, raw": 63123010,
    "Grapes, American type, slip skin, raw": 63123020,
    "Grapes, seedless, cooked or canned, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 63123110,
    "Grapes, seedless, cooked or canned, unsweetened, water pack": 63123120,
    "Grapes, seedless, cooked or canned, in heavy syrup": 63123130,
    "Guava shell, canned in heavy syrup": 63125100,
    "Juneberry, raw": 63126010,
    "Lychee, cooked or canned, in sugar or syrup": 63126600,
    "Longans, raw": 63126700,
    "Honeydew, frozen": 63127610,
    "Mango, pickled": 63129020,
    "Nectarine, cooked": 63131110,
    "Papaya, green, cooked": 63133050,
    "Peach, cooked or canned, unsweetened, water pack": 63135120,
    "Peach, cooked or canned, in heavy syrup": 63135130,
    "Peach, cooked or canned, drained solids": 63135150,
    "Peach, frozen, NS as to added sweetener": 63135610,
    "Peach, frozen, with sugar": 63135630,
    "Peach, pickled": 63135650,
    "Peach, spiced": 63135660,
    "Pear, cooked or canned, unsweetened, water pack": 63137120,
    "Pear, cooked or canned, in heavy syrup": 63137130,
    "Pear, cooked or canned, drained solids": 63137150,
    "Pineapple, cooked or canned, unsweetened, waterpack": 63141120,
    "Pineapple, cooked or canned, in heavy syrup": 63141130,
    "Pineapple, cooked or canned, drained solids": 63141150,
    "Plum, cooked or canned, unsweetened, water pack": 63143120,
    "Plum, cooked or canned, in heavy syrup": 63143130,
    "Plum, cooked or canned, in light syrup": 63143140,
    "Plum, cooked or canned, drained solids": 63143150,
    "Plum, cooked or canned, juice pack": 63143170,
    "Plum, pickled": 63143650,
    "Rhubarb, raw": 63147010,
    "Rhubarb, cooked or canned, unsweetened": 63147120,
    "Rhubarb, cooked or canned, in heavy syrup": 63147130,
    "Rhubarb, cooked or canned, in light syrup": 63147140,
    "Rhubarb, cooked or canned, drained solids": 63147150,
    "Rhubarb, frozen, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 63147600,
    "Rhubarb, frozen, with sugar": 63147620,
    "Berries, frozen, NFS": 63200200,
    "Blackberries, cooked or canned, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 63201110,
    "Blackberries, cooked or canned, in heavy syrup": 63201130,
    "Blackberries, frozen, sweetened, NS as to type of sweetener": 63201800,
    "Blueberries, cooked or canned, unsweetened, water pack": 63203120,
    "Blueberries, cooked or canned, in light syrup": 63203125,
    "Blueberries, cooked or canned, in heavy syrup": 63203130,
    "Blueberries, frozen, sweetened": 63203550,
    "Blueberries, frozen, NS as to sweetened or unsweetened": 63203570,
    "Boysenberries, raw": 63205010,
    "Boysenberries, frozen": 63205600,
    "Cranberries, NS as to raw, cooked, or canned": 63207000,
    "Dewberries, raw": 63208000,
    "Huckleberries, raw": 63214000,
    "Loganberries, raw": 63215010,
    "Loganberries, frozen": 63215600,
    "Mulberries, raw": 63217010,
    "Raspberries, black, raw": 63219010,
    "Raspberries, red, raw": 63219020,
    "Raspberries, cooked or canned, NS as to sweetened or unsweetened; sweetened, NS as to type of sweetener": 63219110,
    "Raspberries, cooked or canned, unsweetened, water pack": 63219120,
    "Raspberries, cooked or canned, in heavy syrup": 63219130,
    "Raspberries, frozen, NS as to added sweetener": 63219600,
    "Raspberries, frozen, with sugar": 63219620,
    "Strawberries, raw, with sugar": 63223030,
    "Strawberries, cooked or canned, unsweetened, water pack": 63223120,
    "Strawberries, cooked or canned, in syrup": 63223130,
    "Strawberries, frozen, NS as to added sweetener": 63223600,
    "Strawberries, frozen, with sugar": 63223620,
    "Youngberries, raw": 63224000,
    "Cranberry-orange relish, uncooked": 63307010,
    "Cranberry-raspberry Sauce": 63307100,
    "Fruit cocktail or mix, frozen": 63311080,
    "Fruit cocktail, cooked or canned, unsweetened, water pack": 63311120,
    "Fruit cocktail, cooked or canned, in heavy syrup": 63311130,
    "Tropical fruit cocktail, cooked or canned, in light syrup": 63311145,
    "Fruit cocktail, cooked or canned, drained solids": 63311150,
    "Fruit salad, Puerto Rican style": 63320100,
    "Banana, chocolate-covered with nuts": 63401990,
    "Banana whip": 63402010,
    "Prune whip": 63402030,
    "Fried dwarf banana, Puerto Rican style": 63402045,
    "Fried dwarf banana with cheese, Puerto Rican style": 63402050,
    "Fruit dessert with cream and/or pudding and nuts": 63403100,
    "Cranberry salad, congealed": 63411010,
    "Fruit juice bar, frozen, orange flavor": 63420100,
    "Fruit juice bar, frozen, flavor other than orange": 63420110,
    "Fruit juice bar, frozen, sweetened with low calorie sweetener, flavors other than orange": 63420200,
    "Sorbet, fruit, noncitrus flavor": 63430100,
    "Sorbet, fruit, citrus flavor": 63430110,
    "Fruit juice bar with cream, frozen": 63430500,
    "Fruit bar, with added vitamin C, baby food, toddler": 67100110,
    "Tropical fruit medley, baby food, strained": 67100200,
    "Apples, baby food, toddler": 67100300,
    "Apple-raspberry, baby food, NS as to strained or junior": 67101000,
    "Apple-raspberry, baby food, strained": 67101010,
    "Apple-raspberry, baby food, junior": 67101020,
    "Applesauce, baby food, NS as to strained or junior": 67102000,
    "Applesauce, baby food, junior": 67102020,
    "Applesauce and apricots, baby food, NS as to strained or junior": 67104000,
    "Applesauce and apricots, baby food, strained": 67104010,
    "Applesauce and apricots, baby food, junior": 67104020,
    "Applesauce with bananas, baby food, NS as to strained or junior": 67104030,
    "Applesauce with bananas, baby food, strained": 67104040,
    "Applesauce with bananas, baby food, junior": 67104060,
    "Applesauce with cherries, baby food, strained": 67104070,
    "Applesauce with cherries, baby food, junior": 67104080,
    "Applesauce with cherries, baby food, NS as to strained or junior": 67104090,
    "Bananas with apples and pears, baby food, strained": 67106010,
    "Bananas with orange, baby food, strained": 67106030,
    "Banana with mixed berries, baby food, strained": 67106050,
    "Peaches, baby food, NS as to strained or junior": 67108000,
    "Peaches, baby food, junior": 67108020,
    "Peaches, baby food, toddler": 67108030,
    "Pears, baby food, NS as to strained or junior": 67109000,
    "Pears, baby food, junior": 67109020,
    "Pears, baby food, toddler": 67109030,
    "Apples and pears, baby food, NS as to strained or junior": 67113000,
    "Apples and pears, baby food, strained": 67113010,
    "Apples and pears, baby food, junior": 67113020,
    "Pears and pineapple, baby food, NS as to strained or junior": 67114000,
    "Pears and pineapple, baby food, strained": 67114010,
    "Pears and pineapple, baby food, junior": 67114020,
    "Apple juice, with added calcium, baby food": 67202010,
    "Apple-fruit juice blend, baby food": 67203000,
    "Apple-banana juice, baby food": 67203200,
    "Apple-cherry juice, baby food": 67203400,
    "Apple-grape juice, baby food": 67203500,
    "Apple-peach juice, baby food": 67203600,
    "Apple-prune juice, baby food": 67203700,
    "Mixed fruit juice, not citrus, with added calcium, baby food": 67204100,
    "Orange juice, baby food": 67205000,
    "Orange-apple-banana juice, baby food": 67211000,
    "Apple-sweet potato juice, baby food": 67230000,
    "Orange-carrot juice, baby food": 67230500,
    "Banana juice with lowfat yogurt, baby food": 67250100,
    "Fruit juice and water drink, with high vitamin C and added calcium, baby food": 67260000,
    "Plums, baby food, NS as to strained or junior": 67304000,
    "Plums, baby food, strained": 67304010,
    "Plums, baby food, junior": 67304020,
    "Plums, bananas, and rice, baby food strained": 67304030,
    "Prunes with oatmeal, baby food, strained": 67304500,
    "Apricots, baby food, NS as to strained or junior": 67307000,
    "Apricots, baby food, strained": 67307010,
    "Apricots, baby food, junior": 67307020,
    "Bananas, baby food, NS as to strained or junior": 67308000,
    "Bananas, baby food, junior": 67308020,
    "Bananas and pineapple, baby food, NS as to strained or junior": 67309000,
    "Bananas and pineapple, baby food, strained": 67309010,
    "Bananas and pineapple, baby food, junior": 67309020,
    "Bananas and strawberry, baby food, junior": 67309030,
    "Fruit dessert, baby food, NS as to strained or junior": 67404000,
    "Fruit dessert, baby food, strained": 67404010,
    "Fruit dessert, baby food, junior": 67404020,
    "Fruit Supreme dessert, baby food": 67404050,
    "Apple yogurt dessert, baby food, strained": 67404070,
    "Banana apple dessert, baby food, strained": 67404110,
    "Blueberry yogurt dessert, baby food, strained": 67404300,
    "Mixed fruit yogurt dessert, baby food, strained": 67404500,
    "Cherry cobbler, baby food, junior": 67404550,
    "Peach cobbler, baby food, NS as to strained or junior": 67405000,
    "Peach cobbler, baby food, strained": 67405010,
    "Peach cobbler, baby food, junior": 67405020,
    "Banana yogurt dessert, baby food, strained": 67408500,
    "Cherry vanilla pudding, baby food, strained": 67410000,
    "Dutch apple dessert, baby food, NS as to strained or junior": 67412000,
    "Dutch apple dessert, baby food, strained": 67412010,
    "Dutch apple dessert, baby food, junior": 67412020,
    "Peach yogurt dessert, baby food, strained": 67413700,
    "Pineapple dessert, baby food, strained": 67414010,
    "Mango dessert, baby food": 67414100,
    "Tutti-fruitti pudding, baby food, NS as to strained or junior": 67415000,
    "Tutti-fruitti pudding, baby food, strained": 67415010,
    "Tutti-fruitti pudding, baby food, junior": 67415020,
    "Fruit flavored snack, baby food": 67430000,
    "Apples and chicken, baby food, strained": 67501000,
    "Apples with ham, baby food, strained": 67501100,
    "Apples and sweet potatoes, baby food, strained": 67600100,
    "Potato chips, fat free": 71201060,
    "Potato chips, restructured, fat free": 71201210,
    "Potato chips, reduced fat, unsalted": 71202100,
    "Potato pudding": 71702000,
    "Green plantains, boiled": 71901010,
    "Fried green plantain, Puerto Rican style": 71901110,
    "Ripe plantain, boiled": 71905010,
    "Fried ripe plantain, Puerto Rican style": 71905110,
    "Plantain, ripe, rolled in flour, fried": 71905120,
    "Candied ripe plantain, Puerto Rican style": 71905210,
    "Pickled green bananas, Puerto Rican style": 71910310,
    "Cassava, cooked, NS as to fat added in cooking": 71930090,
    "Cassava, cooked, fat not added in cooking": 71930100,
    "Cassava, cooked, made with oil": 71930130,
    "Cassava, cooked, made with butter": 71930140,
    "Cassava, cooked, made with margarine": 71930150,
    "Cassava with creole sauce, Puerto Rican style": 71931010,
    "Yam, cooked, Puerto Rican": 71945010,
    "Yam buns; Puerto Rican style": 71945020,
    "Tannier, cooked": 71950010,
    "Celeriac, cooked": 71961010,
    "Dasheen, boiled": 71962010,
    "Starchy vegetables, NFS, Puerto Rican style": 71970110,
    "Starchy vegetables, including tannier, white sweet potato and yam, with green or ripe plantains, Puerto Rican style": 71970120,
    "Starchy vegetables, including tannier, white sweet potato and yam, no plantain, Puerto Rican style": 71970130,
    "Poi": 71980100,
    "Beet greens, cooked, NS as to fat added in cooking": 72101200,
    "Beet greens, cooked, fat not added in cooking": 72101210,
    "Beet greens, cooked, made with oil": 72101230,
    "Beet greens, cooked, made with butter": 72101240,
    "Beet greens, cooked, made with margarine": 72101250,
    "Broccoli raab, cooked, NS as to fat added in cooking": 72103010,
    "Broccoli raab, cooked, fat not added in cooking": 72103020,
    "Broccoli raab, cooked, made with oil": 72103040,
    "Broccoli raab, cooked, made with butter": 72103050,
    "Broccoli raab, cooked, made with margarine": 72103060,
    "Chard, cooked, NS as to fat added in cooking": 72104200,
    "Chard, cooked, fat not added in cooking": 72104210,
    "Chard, cooked, made with oil": 72104230,
    "Chard, cooked, made with butter": 72104240,
    "Chard, cooked, made with margarine": 72104250,
    "Collards, cooked, NS as to form, NS as to fat added in cooking": 72107200,
    "Collards, cooked, from fresh, NS as to fat added in cooking": 72107201,
    "Collards, cooked, from frozen, NS as to fat added in cooking": 72107202,
    "Collards, cooked, from canned, NS as to fat added in cooking": 72107203,
    "Collards, cooked, NS as to form, fat not added in cooking": 72107210,
    "Collards, cooked, from canned, fat added in cooking, NS as to type of fat": 72107223,
    "Collards, cooked, NS as to form, made with oil": 72107224,
    "Collards, cooked, NS as to form, made with butter": 72107225,
    "Collards, cooked, NS as to form, made with margarine": 72107226,
    "Collards, cooked, from fresh, made with margarine": 72107229,
    "Collards, cooked, from frozen, made with margarine": 72107232,
    "Collards, cooked, from canned, made with margarine": 72107235,
    "Cress, cooked, NS as to form, NS as to fat added in cooking": 72110200,
    "Cress, cooked, from fresh, NS as to fat added in cooking": 72110201,
    "Cress, cooked, from canned, NS as to fat added in cooking": 72110203,
    "Cress, cooked, NS as to form, fat not added in cooking": 72110210,
    "Cress, cooked, from fresh, fat not added in cooking": 72110211,
    "Cress, cooked, from canned, fat not added in cooking": 72110213,
    "Cress, cooked, NS as to form, fat added in cooking, NS as to type of fat": 72110220,
    "Cress, cooked, from canned, fat added in cooking, NS as to type of fat": 72110223,
    "Cress, cooked, NS as to form, made with oil": 72110230,
    "Cress, cooked, NS as to form, made with butter": 72110231,
    "Cress, cooked, NS as to form, made with margarine": 72110232,
    "Cress, cooked, from fresh, made with oil": 72110233,
    "Cress, cooked, from fresh, made with butter": 72110234,
    "Cress, cooked, from fresh, made with margarine": 72110235,
    "Cress, cooked, from canned, made with oil": 72110236,
    "Cress, cooked, from canned, made with butter": 72110237,
    "Cress, cooked, from canned, made with margarine": 72110238,
    "Dandelion greens, cooked, NS as to fat added in cooking": 72113200,
    "Dandelion greens, cooked, fat not added in cooking": 72113210,
    "Dandelion greens, cooked, made with oil": 72113230,
    "Dandelion greens, cooked, made with butter": 72113240,
    "Dandelion greens, cooked, made with margarine": 72113250,
    "Escarole, cooked, NS as to fat added in cooking": 72116200,
    "Escarole, cooked, fat not added in cooking": 72116210,
    "Escarole, cooked, made with oil": 72116221,
    "Escarole, cooked, made with butter": 72116222,
    "Escarole, cooked, made with margarine": 72116223,
    "Escarole, creamed": 72116230,
    "Greens, cooked, NS as to form, NS as to fat added in cooking": 72118200,
    "Greens, cooked, from fresh, NS as to fat added in cooking": 72118201,
    "Greens, cooked, from frozen, NS as to fat added in cooking": 72118202,
    "Greens, cooked, from canned, NS as to fat added in cooking": 72118203,
    "Greens, cooked, NS as to form, fat not added in cooking": 72118210,
    "Greens, cooked, from canned, fat not added in cooking": 72118213,
    "Greens, cooked, NS as to form, made with oil": 72118224,
    "Greens, cooked, NS as to form, made with butter": 72118225,
    "Greens, cooked, NS as to form, made with margarine": 72118226,
    "Greens, cooked, from fresh, made with oil": 72118227,
    "Greens, cooked, from fresh, made with butter": 72118228,
    "Greens, cooked, from fresh, made with margarine": 72118229,
    "Greens, cooked, from frozen, made with oil": 72118230,
    "Greens, cooked, from frozen, made with butter": 72118231,
    "Greens, cooked, from frozen, made with margarine": 72118232,
    "Greens, cooked, from canned, made with oil": 72118233,
    "Greens, cooked, from canned, made with butter": 72118234,
    "Greens, cooked, from canned, made with margarine": 72118235,
    "Chamnamul, cooked, NS as to fat added in cooking": 72118300,
    "Chamnamul, cooked, fat not added in cooking": 72118305,
    "Chamnamul, cooked, fat added in cooking": 72118310,
    "Kale, cooked, NS as to form, NS as to fat added in cooking": 72119200,
    "Kale, cooked, from fresh, NS as to fat added in cooking": 72119201,
    "Kale, cooked, from frozen, NS as to fat added in cooking": 72119202,
    "Kale, cooked, from canned, NS as to fat added in cooking": 72119203,
    "Kale, cooked, NS as to form, fat not added in cooking": 72119210,
    "Kale, cooked, from canned, fat added in cooking, NS as to type of fat": 72119223,
    "Kale, cooked, NS as to form, made with oil": 72119224,
    "Kale, cooked, NS as to form, made with butter": 72119225,
    "Kale, cooked, NS as to form, made with margarine": 72119226,
    "Kale, cooked, from fresh, made with oil": 72119227,
    "Kale, cooked, from fresh, made with butter": 72119228,
    "Kale, cooked, from fresh, made with margarine": 72119229,
    "Kale, cooked, from frozen, made with oil": 72119230,
    "Kale, cooked, from frozen, made with butter": 72119231,
    "Kale, cooked, from frozen, made with margarine": 72119232,
    "Kale, cooked, from canned, made with oil": 72119233,
    "Kale, cooked, from canned, made with butter": 72119234,
    "Kale, cooked, from canned, made with margarine": 72119235,
    "Lambsquarter, cooked, NS as to fat added in cooking": 72120200,
    "Lambsquarter, cooked, fat not added in cooking": 72120210,
    "Lambsquarter, cooked, made with oil": 72120230,
    "Lambsquarter, cooked, made with butter": 72120240,
    "Lambsquarter, cooked, made with margarine": 72120250,
    "Mustard Cabbage, cooked, NS as to fat added in cooking": 72121209,
    "Mustard cabbage, cooked, fat not added in cooking": 72121210,
    "Mustard Cabbage, cooked, fat added in cooking": 72121211,
    "Mustard greens, cooked, NS as to form, NS as to fat added in cooking": 72122200,
    "Mustard greens, cooked, from fresh, NS as to fat added in cooking": 72122201,
    "Mustard greens, cooked, from frozen, NS as to fat added in cooking": 72122202,
    "Mustard greens, cooked, from canned, NS as to fat added in cooking": 72122203,
    "Mustard greens, cooked, NS as to form, fat not added in cooking": 72122210,
    "Mustard greens, cooked, from canned, fat added in cooking, NS as to type of fat": 72122223,
    "Mustard greens, cooked, NS as to form, made with oil": 72122224,
    "Mustard greens, cooked, NS as to form, made with butter": 72122225,
    "Mustard greens, cooked, NS as to form, made with margarine": 72122226,
    "Mustard greens, cooked, from fresh, made with oil": 72122227,
    "Mustard greens, cooked, from fresh, made with butter": 72122228,
    "Mustard greens, cooked, from fresh, made with margarine": 72122229,
    "Mustard greens, cooked, from frozen, made with oil": 72122230,
    "Mustard greens, cooked, from frozen, made with butter": 72122231,
    "Mustard greens, cooked, from frozen, made with margarine": 72122232,
    "Mustard greens, cooked, from canned, made with oil": 72122233,
    "Mustard greens, cooked, from canned, made with butter": 72122234,
    "Mustard greens, cooked, from canned, made with margarine": 72122235,
    "Poke greens, cooked, NS as to fat added in cooking": 72123000,
    "Poke greens, cooked, fat not added in cooking": 72123010,
    "Poke greens, cooked, made with oil": 72123030,
    "Poke greens, cooked, made with butter": 72123040,
    "Poke greens, cooked, made with margarine": 72123050,
    "Spinach, cooked, NS as to form, NS as to fat added in cooking": 72125200,
    "Spinach, cooked, from fresh, NS as to fat added in cooking": 72125201,
    "Spinach, cooked, from frozen, NS as to fat added in cooking": 72125202,
    "Spinach, cooked, from canned, NS as to fat added in cooking": 72125203,
    "Spinach, cooked, NS as to form, fat not added in cooking": 72125210,
    "Spinach, cooked, NS as to form, made with oil": 72125214,
    "Spinach, cooked, NS as to form, made with butter": 72125215,
    "Spinach, cooked, NS as to form, made with margarine": 72125216,
    "Spinach, cooked, from fresh, made with margarine": 72125219,
    "Spinach, cooked, from frozen, made with margarine": 72125226,
    "Spinach, cooked, from canned, made with margarine": 72125229,
    "Spinach, from fresh, creamed": 72125231,
    "Spinach, from frozen, creamed": 72125232,
    "Spinach, from canned, creamed": 72125233,
    "Spinach, cooked, NS as to form, with cheese sauce": 72125250,
    "Spinach, cooked, from fresh, with cheese sauce": 72125251,
    "Spinach, cooked, from frozen, with cheese sauce": 72125252,
    "Spinach, cooked, from canned, with cheese sauce": 72125253,
    "Taro leaves, cooked, NS as to fat added in cooking": 72125999,
    "Taro leaves, cooked, fat not added in cooking": 72126000,
    "Thistle leaves, cooked, NS as to fat added in cooking": 72126999,
    "Thistle leaves, cooked, fat not added in cooking": 72127000,
    "Thistle leaves, cooked, fat added in cooking": 72127001,
    "Turnip greens, cooked, NS as to form, NS as to fat added in cooking": 72128200,
    "Turnip greens, cooked, from fresh, NS as to fat added in cooking": 72128201,
    "Turnip greens, cooked, from frozen, NS as to fat added in cooking": 72128202,
    "Turnip greens, cooked, from canned, NS as to fat added in cooking": 72128203,
    "Turnip greens, cooked, NS as to form, fat not added in cooking": 72128210,
    "Turnip greens, cooked, NS as to form, made with oil": 72128224,
    "Turnip greens, cooked, NS as to form, made with butter": 72128225,
    "Turnip greens, cooked, NS as to form, made with margarine": 72128226,
    "Turnip greens, cooked, from fresh, made with oil": 72128227,
    "Turnip greens, cooked, from fresh, made with butter": 72128228,
    "Turnip greens, cooked, from fresh, made with margarine": 72128229,
    "Turnip greens, cooked, from frozen, made with oil": 72128230,
    "Turnip greens, cooked, from frozen, made with butter": 72128231,
    "Turnip greens, cooked, from frozen, made with margarine": 72128232,
    "Turnip greens, cooked, from canned, made with oil": 72128233,
    "Turnip greens, cooked, from canned, made with butter": 72128234,
    "Turnip greens, cooked, from canned, made with margarine": 72128235,
    "Turnip greens, canned, reduced sodium, cooked, NS as to fat added in cooking": 72128236,
    "Turnip greens, canned, reduced sodium, cooked, fat not added in cooking": 72128237,
    "Turnip greens, canned, reduced sodium, cooked, fat added in cooking, made with any type of fat": 72128238,
    "Turnip greens with roots, cooked, NS as to form, NS as to fat added in cooking": 72128400,
    "Turnip greens with roots, cooked, from fresh, NS as to fat added in cooking": 72128401,
    "Turnip greens with roots, cooked, from frozen, NS as to fat added in cooking": 72128402,
    "Turnip greens with roots, cooked, from canned, NS as to fat added in cooking": 72128403,
    "Turnip greens with roots, cooked, NS as to form, fat not added in cooking": 72128410,
    "Turnip greens with roots, cooked, from fresh, fat not added in cooking": 72128411,
    "Turnip greens with roots, cooked, from frozen, fat not added in cooking": 72128412,
    "Turnip greens with roots, cooked, from canned, fat not added in cooking": 72128413,
    "Turnip greens with roots, cooked, NS as to form, fat added in cooking": 72128420,
    "Turnip greens with roots, cooked, from fresh, fat added in cooking": 72128421,
    "Turnip greens with roots, cooked, from frozen, fat added in cooking": 72128422,
    "Turnip greens with roots, cooked, from canned, fat added in cooking": 72128423,
    "Watercress, cooked, NS as to fat added in cooking": 72130199,
    "Watercress, cooked, fat not added in cooking": 72130200,
    "Bitter melon leaves, horseradish leaves, jute leaves, or radish leaves, cooked, NS as to fat added in cooking": 72132199,
    "Bitter melon leaves, horseradish leaves, jute leaves, or radish leaves, cooked, fat not added in cooking": 72132200,
    "Sweet potato leaves, squash leaves, pumpkin leaves, chrysanthemum leaves, bean leaves, or swamp cabbage, cooked, NS as to fat added in cooking": 72133199,
    "Sweet potato leaves, squash leaves, pumpkin leaves, chrysanthemum leaves, bean leaves, or swamp cabbage, cooked, fat not added in cooking": 72133200,
    "Broccoli, cooked, NS as to form, NS as to fat added in cooking": 72201200,
    "Broccoli, cooked, from fresh, NS as to fat added in cooking": 72201201,
    "Broccoli, cooked, from frozen, NS as to fat added in cooking": 72201202,
    "Broccoli, cooked, NS as to form, fat not added in cooking": 72201210,
    "Broccoli, cooked, NS as to form, made with oil": 72201213,
    "Broccoli, cooked, NS as to form, made with butter": 72201214,
    "Broccoli, cooked, NS as to form, made with margarine": 72201215,
    "Broccoli, cooked, from fresh, made with margarine": 72201225,
    "Broccoli, cooked, from frozen, made with margarine": 72201228,
    "Broccoli, cooked, NS as to form, with cheese sauce": 72201230,
    "Broccoli, cooked, from fresh, with cheese sauce": 72201231,
    "Broccoli, cooked, from frozen, with cheese sauce": 72201232,
    "Broccoli, cooked, NS as to form, with mushroom sauce": 72201240,
    "Broccoli, cooked, from fresh, with mushroom sauce": 72201241,
    "Broccoli, cooked, from frozen, with mushroom sauce": 72201242,
    "Broccoli, cooked, NS as to form, with cream sauce": 72201250,
    "Broccoli, cooked, from fresh, with cream sauce": 72201251,
    "Broccoli, cooked, from frozen, with cream sauce": 72201252,
    "Broccoli, chinese, cooked, NS as to form, NS as to fat added in cooking": 72203010,
    "Broccoli, chinese, cooked, from fresh, NS as to fat added in cooking": 72203020,
    "Broccoli, chinese, cooked, from frozen, NS as to fat added in cooking": 72203030,
    "Broccoli, chinese, cooked, NS as to form, fat not added in cooking": 72203040,
    "Broccoli, chinese cooked, from fresh, fat not added in cooking": 72203050,
    "Broccoli, chinese, cooked, from frozen, fat not added in cooking": 72203060,
    "Broccoli, chinese, cooked, NS as to form, made with oil": 72203080,
    "Broccoli, chinese, cooked, NS as to form, made with butter": 72203090,
    "Broccoli, chinese, cooked, NS as to form, made with margarine": 72203100,
    "Broccoli, chinese, cooked, from fresh, fat added in cooking, NS as to type of fat": 72203110,
    "Broccoli, chinese, cooked, from fresh, made with oil": 72203120,
    "Broccoli, chinese, cooked, from fresh, made with butter": 72203130,
    "Broccoli, chinese, cooked, from fresh, made with margarine": 72203140,
    "Broccoli, chinese, cooked, from frozen, fat added in cooking, NS as to type of fat": 72203150,
    "Broccoli, chinese, cooked, from frozen, made with oil": 72203160,
    "Broccoli, chinese, cooked, from frozen, made with butter": 72203170,
    "Broccoli, chinese, cooked, from frozen, made with margarine": 72203180,
    "Watercress broth with shrimp": 72306000,
    "Carrots, cooked, NS as to form, NS as to fat added in cooking": 73102200,
    "Carrots, cooked, from fresh, NS as to fat added in cooking": 73102201,
    "Carrots, cooked, from frozen, NS as to fat added in cooking": 73102202,
    "Carrots, cooked, from canned, NS as to fat added in cooking": 73102203,
    "Carrots, cooked, NS as to form, fat not added in cooking": 73102210,
    "Carrots, cooked, NS as to form, made with oil": 73102214,
    "Carrots, cooked, NS as to form, made with butter": 73102215,
    "Carrots, cooked, NS as to form, made with margarine": 73102216,
    "Carrots, cooked, from fresh, made with margarine": 73102219,
    "Carrots, cooked, from frozen, made with margarine": 73102226,
    "Carrots, cooked, from canned, made with margarine": 73102229,
    "Carrots, cooked, NS as to form, creamed": 73102230,
    "Carrots, cooked, from fresh, creamed": 73102231,
    "Carrots, cooked, from frozen, creamed": 73102232,
    "Carrots, cooked, from canned, creamed": 73102233,
    "Carrots, cooked, NS as to form, glazed": 73102240,
    "Carrots, cooked, from frozen, glazed": 73102242,
    "Carrots, cooked, from canned, glazed": 73102243,
    "Carrots, cooked, NS as to form, with cheese sauce": 73102250,
    "Carrots, cooked, from fresh, with cheese sauce": 73102251,
    "Carrots, cooked, from frozen, with cheese sauce": 73102252,
    "Carrots, cooked, from canned, with cheese sauce": 73102253,
    "Carrots, canned, low sodium, NS as to fat added in cooking": 73103000,
    "Carrots, canned, low sodium, made with margarine": 73103023,
    "Peas and carrots, NS as to form, creamed": 73111030,
    "Peas and carrots, from fresh, creamed": 73111031,
    "Peas and carrots, from frozen, creamed": 73111032,
    "Peas and carrots, from canned, creamed": 73111033,
    "Peas and carrots, cooked, NS as to form, NS as to fat added in cooking": 73111200,
    "Peas and carrots, cooked, from fresh, NS as to fat added in cooking": 73111201,
    "Peas and carrots, cooked, from frozen, NS as to fat added in cooking": 73111202,
    "Peas and carrots, cooked, from canned, NS as to fat added in cooking": 73111203,
    "Peas and carrots, cooked, NS as to form, fat not added in cooking": 73111210,
    "Peas and carrots, cooked, NS as to form, made with oil": 73111224,
    "Peas and carrots, cooked, NS as to form, made with butter": 73111225,
    "Peas and carrots, cooked, NS as to form, made with margarine": 73111226,
    "Peas and carrots, cooked, from fresh, made with oil": 73111227,
    "Peas and carrots, cooked, from fresh, made with butter": 73111228,
    "Peas and carrots, cooked, from fresh, made with margarine": 73111229,
    "Peas and carrots, cooked, from frozen, made with oil": 73111230,
    "Peas and carrots, cooked, from frozen, made with butter": 73111231,
    "Peas and carrots, cooked, from frozen, made with margarine": 73111232,
    "Peas and carrots, cooked, from canned, made with oil": 73111233,
    "Peas and carrots, cooked, from canned, made with butter": 73111234,
    "Peas and carrots, cooked, from canned, made with margarine": 73111235,
    "Carrots in tomato sauce": 73111400,
    "Pumpkin, cooked, NS as to form, NS as to fat added in cooking": 73201000,
    "Pumpkin, cooked, from fresh, NS as to fat added in cooking": 73201001,
    "Pumpkin, cooked, from frozen, NS as to fat added in cooking": 73201002,
    "Pumpkin, cooked, from canned, NS as to fat added in cooking": 73201003,
    "Pumpkin, cooked, NS as to form, fat not added in cooking": 73201010,
    "Pumpkin, cooked, from fresh, fat not added in cooking": 73201011,
    "Pumpkin, cooked, from frozen, fat not added in cooking": 73201012,
    "Pumpkin, cooked, from fresh, fat added in cooking, NS as to type of fat": 73201021,
    "Pumpkin, cooked, from frozen, fat added in cooking, NS as to type of fat": 73201022,
    "Pumpkin, cooked, from canned, fat added in cooking, NS as to type of fat": 73201023,
    "Pumpkin, cooked, NS as to form, made with oil": 73201024,
    "Pumpkin, cooked, NS as to form, made with butter": 73201025,
    "Pumpkin, cooked, NS as to form, made with margarine": 73201026,
    "Pumpkin, cooked, from fresh, made with oil": 73201027,
    "Pumpkin, cooked, from fresh, made with butter": 73201028,
    "Pumpkin, cooked, from fresh, made with margarine": 73201029,
    "Pumpkin, cooked, from frozen, made with oil": 73201030,
    "Pumpkin, cooked, from frozen, made with butter": 73201031,
    "Pumpkin, cooked, from frozen, made with margarine": 73201032,
    "Pumpkin, cooked, from canned, made with oil": 73201033,
    "Pumpkin, cooked, from canned, made with butter": 73201034,
    "Pumpkin, cooked, from canned, made with margarine": 73201035,
    "Calabaza, cooked": 73210010,
    "Pumpkin fritters, Puerto Rican style": 73210110,
    "Sweet potato and pumpkin casserole, Puerto Rican style": 73211110,
    "Squash, winter type, mashed, NS as to fat or sugar added in cooking": 73301000,
    "Squash, winter type, mashed, no fat or sugar added in cooking": 73301010,
    "Squash, winter type, mashed, fat added in cooking, no sugar added in cooking": 73301020,
    "Squash, winter type, mashed, fat and sugar added in cooking": 73301030,
    "Squash, winter type, baked, NS as to fat or sugar added in cooking": 73303000,
    "Squash, winter type, baked, no sugar added in cooking, made with oil": 73303021,
    "Squash, winter type, baked, no sugar added in cooking, made with butter": 73303022,
    "Squash, winter type, baked, no sugar added in cooking, made with margarine": 73303023,
    "Squash, winter type, baked, fat and sugar added in cooking": 73303030,
    "Squash, winter type, baked, no fat added in cooking, sugar added in cooking": 73303040,
    "Squash fritter or cake": 73304010,
    "Squash, winter, baked with cheese": 73305010,
    "Sweet potato, baked, peel eaten, NS as to fat added in cooking": 73402000,
    "Sweet potato, baked, peel eaten, fat not added in cooking": 73402010,
    "Sweet potato, baked, peel eaten, fat added in cooking, NS as to type of fat": 73402020,
    "Sweet potato, baked, peel eaten, made with oil": 73402021,
    "Sweet potato, baked, peel eaten, made with butter": 73402022,
    "Sweet potato, baked, peel eaten, made with margarine": 73402023,
    "Sweet potato, baked, peel not eaten, made with oil": 73403021,
    "Sweet potato, baked, peel not eaten, made with butter": 73403022,
    "Sweet potato, baked, peel not eaten, made with margarine": 73403023,
    "Sweet potato, boiled, made with oil": 73405021,
    "Sweet potato, boiled, made with butter": 73405022,
    "Sweet potato, boiled, made with margarine": 73405023,
    "Sweet potato fries, NS as to fresh or frozen": 73410300,
    "Sweet potato fries, frozen, fried": 73410310,
    "Sweet potato fries, from fresh, fried": 73410330,
    "Sweet potato tots, NFS": 73420000,
    "Sweet potato tots, from frozen, fried": 73420010,
    "Sweet potato tots, from frozen, NS as to fried or baked": 73420030,
    "Sweet potato, yellow, Puerto Rican, cooked": 73421000,
    "Carrot soup, cream of, prepared with milk, home recipe, canned or ready-to-serve": 73501000,
    "Carrot with rice soup, cream of, prepared with milk, home recipe, canned or ready-to-serve": 73501010,
    "Tomatoes, green, raw": 74102000,
    "Tomatoes, NS as to form, broiled": 74202010,
    "Tomatoes, from fresh, broiled": 74202011,
    "Tomatoes, red, NS as to form, fried": 74202050,
    "Tomatoes, red, from fresh, fried": 74202051,
    "Tomatoes, from fresh, scalloped": 74203011,
    "Tomatoes, NS as to form, stewed": 74204010,
    "Tomatoes, from fresh, stewed": 74204011,
    "Tomatoes, from canned, stewed": 74204013,
    "Tomatoes, green, cooked, from fresh": 74205011,
    "Tomato relish": 74405010,
    "Tomato and corn, cooked, NS as to fat added in cooking": 74503009,
    "Tomato and corn, cooked, fat not added in cooking": 74503010,
    "Tomato and corn, cooked, fat added in cooking": 74503011,
    "Tomato and okra, cooked, NS as to fat added in cooking": 74504000,
    "Tomato and okra, cooked, fat not added in cooking": 74504010,
    "Tomato and okra, cooked, fat added in cooking, NS as to type of fat": 74504020,
    "Tomato and okra, cooked, made with oil": 74504021,
    "Tomato and okra, cooked, made with butter": 74504022,
    "Tomato and okra, cooked, made with margarine": 74504023,
    "Tomato and onion, cooked, NS as to fat added in cooking": 74504100,
    "Tomato and onion, cooked, fat not added in cooking": 74504110,
    "Tomato and onion, cooked, fat added in cooking, NS as to type of fat": 74504120,
    "Tomato and onion, cooked, made with oil": 74504121,
    "Tomato and onion, cooked, made with butter": 74504122,
    "Tomato and onion, cooked, made with margarine": 74504123,
    "Tomato and celery, cooked, NS as to fat added in cooking": 74504148,
    "Tomato and celery, cooked, fat added in cooking": 74504149,
    "Tomato and celery, cooked, fat not added in cooking": 74504150,
    "Tomato with corn and okra, cooked, NS as to fat added in cooking": 74505000,
    "Tomato with corn and okra, cooked, fat not added in cooking": 74505010,
    "Tomato with corn and okra, cooked, fat added in cooking, NS as to type of fat": 74505020,
    "Tomato with corn and okra, cooked, made with oil": 74505021,
    "Tomato with corn and okra, cooked, made with butter": 74505022,
    "Tomato with corn and okra, cooked, made with margarine": 74505023,
    "Tomato soup, canned, reduced sodium, prepared with milk": 74602300,
    "Tomato beef noodle soup, prepared with water": 74604010,
    "Tomato beef rice soup, prepared with water": 74604100,
    "Tomato noodle soup, canned, prepared with milk": 74604600,
    "Tomato rice soup, prepared with water": 74605010,
    "Leek, raw": 75112500,
    "Lettuce, manoa": 75113070,
    "Pepper, poblano, raw": 75121400,
    "Pepper, Serrano, raw": 75121500,
    "Cabbage salad or coleslaw, made with light coleslaw dressing": 75141005,
    "Cabbage salad or coleslaw, made with Italian dressing": 75141020,
    "Cabbage salad or coleslaw, made with light Italian dressing": 75141025,
    "Cabbage salad or coleslaw, made with creamy dressing": 75141030,
    "Cabbage salad or coleslaw, made with light creamy dressing": 75141035,
    "Cabbage salad or coleslaw with pineapple, with dressing": 75141200,
    "Cabbage, Chinese, salad, with dressing": 75141300,
    "Celery, stuffed with cheese": 75141500,
    "Vegetables, NS as to type, cooked, NS as to fat added in cooking": 75200100,
    "Vegetables, NS as to type, cooked, fat not added in cooking": 75200110,
    "Vegetables, NS as to type, cooked, fat added in cooking, NS as to type of fat": 75200120,
    "Vegetables, NS as to type, cooked, made with oil": 75200121,
    "Vegetables, NS as to type, cooked, made with butter": 75200122,
    "Vegetables, NS as to type, cooked, made with margarine": 75200123,
    "Artichoke, cooked, NS as to form, NS as to fat added in cooking": 75201000,
    "Artichoke, cooked, from fresh, NS as to fat added in cooking": 75201001,
    "Artichoke, cooked, from frozen, NS as to fat added in cooking": 75201002,
    "Artichoke, cooked, from canned, NS as to fat added in cooking": 75201003,
    "Artichoke, cooked, NS as to form, fat not added in cooking": 75201010,
    "Artichoke, cooked, from frozen, fat not added in cooking": 75201012,
    "Artichoke, cooked, from fresh, fat added in cooking, NS as to type of fat": 75201021,
    "Artichoke, cooked, from frozen, fat added in cooking, NS as to type of fat": 75201022,
    "Artichoke, cooked, from canned, fat added in cooking, NS as to type of fat": 75201023,
    "Artichoke salad in oil": 75201030,
    "Artichoke, cooked, NS as to form, made with oil": 75201031,
    "Artichoke, cooked, NS as to form, made with butter": 75201032,
    "Artichoke, cooked, NS as to form, made with margarine": 75201033,
    "Artichoke, cooked, from fresh, made with oil": 75201034,
    "Artichoke, cooked, from fresh, made with butter": 75201035,
    "Artichoke, cooked, from fresh, made with margarine": 75201036,
    "Artichoke, cooked, from canned, made with oil": 75201037,
    "Artichoke, cooked, from canned, made with butter": 75201038,
    "Artichoke, cooked, from canned, made with margarine": 75201039,
    "Artichoke, cooked, from frozen, made with oil": 75201040,
    "Artichoke, cooked, from frozen, made with butter": 75201041,
    "Artichoke, cooked, from frozen, made with margarine": 75201042,
    "Asparagus, cooked, NS as to form, NS as to fat added in cooking": 75202000,
    "Asparagus, cooked, from fresh, NS as to fat added in cooking": 75202001,
    "Asparagus, cooked, from frozen, NS as to fat added in cooking": 75202002,
    "Asparagus, cooked, from canned, NS as to fat added in cooking": 75202003,
    "Asparagus, cooked, NS as to form, fat not added in cooking": 75202010,
    "Asparagus, cooked, NS as to form, made with oil": 75202024,
    "Asparagus, cooked, NS as to form, made with butter": 75202025,
    "Asparagus, cooked, NS as to form, made with margarine": 75202026,
    "Asparagus, cooked, from fresh, made with margarine": 75202029,
    "Asparagus, cooked, from frozen, made with margarine": 75202033,
    "Asparagus, cooked, from canned, made with margarine": 75202036,
    "Bamboo shoots, cooked, fat not added in cooking": 75203000,
    "Bamboo shoots, cooked, fat added in cooking": 75203020,
    "Beans, lima, immature, cooked, NS as to form, NS as to fat added in cooking": 75204000,
    "Beans, lima, immature, cooked, from fresh, NS as to fat added in cooking": 75204001,
    "Beans, lima, immature, cooked, from frozen, NS as to fat added in cooking": 75204002,
    "Beans, lima, immature, cooked, from canned, NS as to fat added in cooking": 75204003,
    "Beans, lima, immature, cooked, NS as to form, fat not added in cooking": 75204010,
    "Beans, lima, immature, cooked, from fresh, fat not added in cooking": 75204011,
    "Beans, lima, immature, cooked, from canned, fat not added in cooking": 75204013,
    "Beans, lima, immature, cooked, NS as to form, fat added in cooking, NS as to type of fat": 75204020,
    "Beans, lima, immature, cooked, from fresh, fat added in cooking, NS as to type of fat": 75204021,
    "Beans, lima, immature, cooked, NS as to form, made with oil": 75204024,
    "Beans, lima, immature, cooked, NS as to form, made with butter": 75204025,
    "Beans, lima, immature, cooked, NS as to form, made with margarine": 75204026,
    "Beans, lima, immature, cooked, from fresh, made with oil": 75204027,
    "Beans, lima, immature, cooked, from fresh, made with butter": 75204028,
    "Beans, lima, immature, cooked, from fresh, made with margarine": 75204029,
    "Beans, lima, immature, cooked, from frozen, made with oil": 75204030,
    "Beans, lima, immature, cooked, from frozen, made with butter": 75204031,
    "Beans, lima, immature, cooked, from frozen, made with margarine": 75204032,
    "Beans, lima, immature, cooked, from canned, made with oil": 75204033,
    "Beans, lima, immature, cooked, from canned, made with butter": 75204034,
    "Beans, lima, immature, cooked, from canned, made with margarine": 75204035,
    "Beans, string, cooked, NS as to form, NS as to color, made with oil": 75204960,
    "Beans, string, cooked, NS as to form, NS as to color, made with butter": 75204961,
    "Beans, string, cooked, NS as to form, NS as to color, made with margarine": 75204962,
    "Beans, string, cooked, from fresh, NS as to color, made with oil": 75204963,
    "Beans, string, cooked, from fresh, NS as to color, made with butter": 75204964,
    "Beans, string, cooked, from fresh, NS as to color, made with margarine": 75204965,
    "Beans, string, cooked, from frozen, NS as to color, made with oil": 75204966,
    "Beans, string, cooked, from frozen, NS as to color, made with butter": 75204967,
    "Beans, string, cooked, from frozen, NS as to color, made with margarine": 75204968,
    "Beans, string, cooked, from canned, NS as to color, made with oil": 75204969,
    "Beans, string, cooked, from canned, NS as to color, made with butter": 75204970,
    "Beans, string, cooked, from canned, NS as to color, made with margarine": 75204971,
    "Beans, string, cooked, NS as to form, NS as to color, fat added in cooking, NS as to type of fat": 75204980,
    "Beans, string, cooked, from fresh, NS as to color, fat added in cooking, NS as to type of fat": 75204981,
    "Beans, string, cooked, from frozen, NS as to color, fat added in cooking, NS as to type of fat": 75204982,
    "Beans, string, cooked, from canned, NS as to color, fat added in cooking, NS as to type of fat": 75204983,
    "Beans, string, cooked, NS as to form, NS as to color, fat not added in cooking": 75204990,
    "Beans, string, cooked, from fresh, NS as to color, fat not added in cooking": 75204991,
    "Beans, string, cooked, from frozen, NS as to color, fat not added in cooking": 75204992,
    "Beans, string, cooked, from canned, NS as to color, fat not added in cooking": 75204993,
    "Beans, string, cooked, NS as to form, NS as to color, NS as to fat added in cooking": 75205000,
    "Beans, string, cooked, from fresh, NS as to color, NS as to fat added in cooking": 75205001,
    "Beans, string, cooked, from frozen, NS as to color, NS as to fat added in cooking": 75205002,
    "Beans, string, cooked, from canned, NS as to color, NS as to fat added in cooking": 75205003,
    "Beans, string, green, cooked, NS as to form, NS as to fat added in cooking": 75205010,
    "Beans, string, green, cooked, from fresh, NS as to fat added in cooking": 75205011,
    "Beans, string, green, cooked, from frozen, NS as to fat added in cooking": 75205012,
    "Beans, string, green, cooked, from canned, NS as to fat added in cooking": 75205013,
    "Beans, string, green, cooked, NS as to form, fat not added in cooking": 75205020,
    "Beans, string, green, cooked, NS as to form, made with oil": 75205041,
    "Beans, string, green, cooked, NS as to form, made with butter": 75205042,
    "Beans, string, green, cooked, NS as to form, made with margarine": 75205043,
    "Beans, string, green, cooked, from fresh, made with margarine": 75205046,
    "Beans, string, green, cooked, from frozen, made with margarine": 75205049,
    "Beans, string, green, cooked, from canned, made with margarine": 75205052,
    "Beans, string, green, canned, low sodium, NS as to fat added in cooking": 75205110,
    "Beans, string, green, canned, low sodium, made with margarine": 75205133,
    "Beans, string, yellow, cooked, NS as to form, NS as to fat added in cooking": 75206000,
    "Beans, string, yellow, cooked, from fresh, NS as to fat added in cooking": 75206001,
    "Beans, string, yellow, cooked, from frozen, NS as to fat added in cooking": 75206002,
    "Beans, string, yellow, cooked, from canned, NS as to fat added in cooking": 75206003,
    "Beans, string, yellow, cooked, NS as to form, fat not added in cooking": 75206010,
    "Beans, string, yellow, cooked, from fresh, fat not added in cooking": 75206011,
    "Beans, string, yellow, cooked, from frozen, fat not added in cooking": 75206012,
    "Beans, string, yellow, cooked, from canned, fat not added in cooking": 75206013,
    "Beans, string, yellow, cooked, from fresh, fat added in cooking, NS as to type of fat": 75206021,
    "Beans, string, yellow, cooked, from frozen, fat added in cooking, NS as to type of fat": 75206022,
    "Beans, string, yellow, cooked, from canned, fat added in cooking, NS as to type of fat": 75206023,
    "Beans, string, yellow, cooked, NS as to form, made with oil": 75206030,
    "Beans, string, yellow, cooked, NS as to form, made with butter": 75206031,
    "Beans, string, yellow, cooked, NS as to form, made with margarine": 75206032,
    "Beans, string, yellow, cooked, from fresh, made with oil": 75206033,
    "Beans, string, yellow, cooked, from fresh, made with butter": 75206034,
    "Beans, string, yellow, cooked, from fresh, made with margarine": 75206035,
    "Beans, string, yellow, cooked, from frozen, made with oil": 75206036,
    "Beans, string, yellow, cooked, from frozen, made with butter": 75206037,
    "Beans, string, yellow, cooked, from frozen, made with margarine": 75206038,
    "Beans, string, yellow, cooked, from canned, made with oil": 75206039,
    "Beans, string, yellow, cooked, from canned, made with butter": 75206040,
    "Beans, string, yellow, cooked, from canned, made with margarine": 75206041,
    "Bean sprouts, cooked, NS as to form, NS as to fat added in cooking": 75207000,
    "Bean sprouts, cooked, from fresh, NS as to fat added in cooking": 75207001,
    "Bean sprouts, cooked, NS as to form, fat not added in cooking": 75207010,
    "Bean sprouts, cooked, from fresh, fat not added in cooking": 75207011,
    "Bean sprouts, cooked, NS as to form, fat added in cooking": 75207020,
    "Beets, cooked, NS as to form, NS as to fat added in cooking": 75208000,
    "Beets, cooked, from fresh, NS as to fat added in cooking": 75208001,
    "Beets, cooked, from frozen, NS as to fat added in cooking": 75208002,
    "Beets, cooked, from canned, NS as to fat added in cooking": 75208003,
    "Beets, cooked, NS as to form, fat not added in cooking": 75208010,
    "Beets, cooked, from frozen, fat not added in cooking": 75208012,
    "Beets, cooked, from frozen, fat added in cooking, NS as to type of fat": 75208022,
    "Beets, canned, low sodium, NS as to fat added in cooking": 75208100,
    "Beets, canned, low sodium, made with oil": 75208111,
    "Beets, canned, low sodium, made with butter": 75208112,
    "Beets, canned, low sodium, made with margarine": 75208113,
    "Beets, canned, low sodium, fat added in cooking, NS as to type of fat": 75208120,
    "Beets, cooked, NS as to form, made with oil": 75208121,
    "Beets, cooked, NS as to form, made with butter": 75208122,
    "Beets, cooked, NS as to form, made with margarine": 75208123,
    "Beets, cooked, from fresh, made with oil": 75208124,
    "Beets, cooked, from fresh, made with butter": 75208125,
    "Beets, cooked, from fresh, made with margarine": 75208126,
    "Beets, cooked, from frozen, made with oil": 75208127,
    "Beets, cooked, from frozen, made with butter": 75208128,
    "Beets, cooked, from frozen, made with margarine": 75208129,
    "Beets, cooked, from canned, made with oil": 75208130,
    "Beets, cooked, from canned, made with butter": 75208131,
    "Beets, cooked, from canned, made with margarine": 75208132,
    "Bitter melon, cooked, NS as to fat added in cooking": 75208290,
    "Bitter melon, cooked, fat not added in cooking": 75208300,
    "Breadfruit, cooked, NS as to fat added in cooking": 75208499,
    "Breadfruit, cooked, fat not added in cooking": 75208500,
    "Breadfruit, fried": 75208520,
    "Broccoflower, cooked, NS as to fat added in cooking": 75208700,
    "Broccoflower, cooked, fat not added in cooking": 75208710,
    "Broccoflower, cooked, made with oil": 75208730,
    "Broccoflower, cooked, made with butter": 75208740,
    "Broccoflower, cooked, made with margarine": 75208750,
    "Brussels sprouts, cooked, NS as to form, NS as to fat added in cooking": 75209000,
    "Brussels sprouts, cooked, from fresh, NS as to fat added in cooking": 75209001,
    "Brussels sprouts, cooked, from frozen, NS as to fat added in cooking": 75209002,
    "Brussels sprouts, cooked, NS as to form, fat not added in cooking": 75209010,
    "Brussels sprouts, cooked, NS as to form, made with oil": 75209030,
    "Brussels sprouts, cooked, NS as to form, made with butter": 75209031,
    "Brussels sprouts, cooked, NS as to form, made with margarine": 75209032,
    "Brussels sprouts, cooked, from fresh, made with oil": 75209040,
    "Brussels sprouts, cooked, from fresh, made with butter": 75209041,
    "Brussels sprouts, cooked, from fresh, made with margarine": 75209042,
    "Brussels sprouts, cooked, from frozen, made with oil": 75209050,
    "Brussels sprouts, cooked, from frozen, made with butter": 75209051,
    "Brussels sprouts, cooked, from frozen, made with margarine": 75209052,
    "Burdock, cooked, NS as to fat added in cooking": 75209499,
    "Burdock, cooked, fat not added in cooking": 75209500,
    "Cabbage, Chinese, cooked, NS as to fat added in cooking": 75210000,
    "Cabbage, Chinese, cooked, made with oil": 75210021,
    "Cabbage, Chinese, cooked, made with butter": 75210022,
    "Cabbage, Chinese, cooked, made with margarine": 75210023,
    "Cabbage, green, cooked, NS as to fat added in cooking": 75211010,
    "Cabbage, green, cooked, made with margarine": 75211033,
    "Cabbage, red, cooked, NS as to fat added in cooking": 75212000,
    "Cabbage, red, cooked, fat not added in cooking": 75212010,
    "Cabbage, red, cooked, made with oil": 75212021,
    "Cabbage, red, cooked, made with butter": 75212022,
    "Cabbage, red, cooked, made with margarine": 75212023,
    "Cabbage, savoy, cooked, NS as to fat added in cooking": 75213000,
    "Cabbage, savoy, cooked, fat not added in cooking": 75213010,
    "Cabbage, savoy, cooked, made with oil": 75213021,
    "Cabbage, savoy, cooked, made with butter": 75213022,
    "Cabbage, savoy, cooked, made with margarine": 75213023,
    "Cactus, cooked, NS as to fat added in cooking": 75213100,
    "Cactus, cooked, made with oil": 75213121,
    "Cactus, cooked, made with butter": 75213122,
    "Cactus, cooked, made with margarine": 75213123,
    "Cauliflower, cooked, NS as to form, NS as to fat added in cooking": 75214000,
    "Cauliflower, cooked, from fresh, NS as to fat added in cooking": 75214001,
    "Cauliflower, cooked, from frozen, NS as to fat added in cooking": 75214002,
    "Cauliflower, cooked, from canned, NS as to fat added in cooking": 75214003,
    "Cauliflower, cooked, NS as to form, fat not added in cooking": 75214010,
    "Cauliflower, cooked, from canned, fat not added in cooking": 75214013,
    "Cauliflower, cooked, from canned, fat added in cooking, NS as to type of fat": 75214023,
    "Cauliflower, cooked, NS as to form, made with oil": 75214024,
    "Cauliflower, cooked, NS as to form, made with butter": 75214025,
    "Cauliflower, cooked, NS as to form, made with margarine": 75214026,
    "Cauliflower, cooked, from fresh, made with margarine": 75214029,
    "Cauliflower, cooked, from frozen, made with margarine": 75214032,
    "Cauliflower, cooked, from canned, made with oil": 75214033,
    "Cauliflower, cooked, from canned, made with butter": 75214034,
    "Cauliflower, cooked, from canned, made with margarine": 75214035,
    "Celery, cooked, NS as to fat added in cooking": 75215000,
    "Celery, cooked, fat not added in cooking": 75215010,
    "Celery, cooked, made with oil": 75215030,
    "Celery, cooked, made with butter": 75215040,
    "Celery, cooked, made with margarine": 75215050,
    "Fennel bulb, cooked, NS as to fat added in cooking": 75215100,
    "Fennel bulb, cooked, fat not added in cooking": 75215110,
    "Fennel bulb, cooked, made with oil": 75215121,
    "Fennel bulb, cooked, made with butter": 75215122,
    "Fennel bulb, cooked, made with margarine": 75215123,
    "Christophine, cooked, NS as to fat added in cooking": 75215509,
    "Christophine, cooked, fat not added in cooking": 75215510,
    "Corn, cooked, NS as to form, NS as to color, NS as to fat added in cooking": 75216000,
    "Corn, cooked, from fresh, NS as to color, NS as to fat added in cooking": 75216001,
    "Corn, cooked, from frozen, NS as to color, NS as to fat added in cooking": 75216002,
    "Corn, cooked, from canned, NS as to color, NS as to fat added in cooking": 75216003,
    "Corn, cooked, NS as to form, NS as to color, fat not added in cooking": 75216010,
    "Corn, cooked, from fresh, NS as to color, fat not added in cooking": 75216011,
    "Corn, cooked, from frozen, NS as to color, fat not added in cooking": 75216012,
    "Corn, cooked, from canned, NS as to color, fat not added in cooking": 75216013,
    "Corn, cooked, NS as to form, NS as to color, fat added in cooking, NS as to type of fat": 75216020,
    "Corn, cooked, from fresh, NS as to color, fat added in cooking, NS as to type of fat": 75216021,
    "Corn, cooked, from frozen, NS as to color, fat added in cooking, NS as to type of fat": 75216022,
    "Corn, cooked, from canned, NS as to color, fat added in cooking, NS as to type of fat": 75216023,
    "Corn, cooked, NS as to form, NS as to color, made with oil": 75216024,
    "Corn, cooked, NS as to form, NS as to color, made with butter": 75216025,
    "Corn, cooked, NS as to form, NS as to color, made with margarine": 75216026,
    "Corn, cooked, from fresh, NS as to color, made with oil": 75216027,
    "Corn, cooked, from fresh, NS as to color, made with butter": 75216028,
    "Corn, cooked, from fresh, NS as to color, made with margarine": 75216029,
    "Corn, cooked, from frozen, NS as to color, made with oil": 75216031,
    "Corn, cooked, from frozen, NS as to color, made with butter": 75216032,
    "Corn, cooked, from frozen, NS as to color, made with margarine": 75216033,
    "Corn, cooked, from canned, NS as to color, made with oil": 75216034,
    "Corn, cooked, from canned, NS as to color, made with butter": 75216035,
    "Corn, cooked, from canned, NS as to color, made with margarine": 75216036,
    "Corn, NS as to form, NS as to color, cream style": 75216050,
    "Corn, from canned, NS as to color, cream style": 75216053,
    "Corn, dried, cooked": 75216070,
    "Corn, yellow, cooked, NS as to form, NS as to fat added in cooking": 75216100,
    "Corn, yellow, cooked, from fresh, NS as to fat added in cooking": 75216101,
    "Corn, yellow, cooked, from frozen, NS as to fat added in cooking": 75216102,
    "Corn, yellow, cooked, from canned, NS as to fat added in cooking": 75216103,
    "Corn, yellow, cooked, NS as to form, fat not added in cooking": 75216110,
    "Corn, yellow, cooked, NS as to form, made with oil": 75216131,
    "Corn, yellow, cooked, NS as to form, made with butter": 75216132,
    "Corn, yellow, cooked, NS as to form, made with margarine": 75216133,
    "Corn, yellow, cooked, from fresh, made with margarine": 75216136,
    "Corn, yellow, cooked, from frozen, made with margarine": 75216139,
    "Corn, yellow, cooked, from canned, made with margarine": 75216143,
    "Corn, yellow, NS as to form, cream style": 75216150,
    "Corn, yellow and white, cooked, NS as to form, NS as to fat added in cooking": 75216160,
    "Corn, yellow and white, cooked, from fresh, NS as to fat added in cooking": 75216161,
    "Corn, yellow and white, cooked, from frozen, NS as to fat added in cooking": 75216162,
    "Corn, yellow and white, cooked, from canned, NS as to fat added in cooking": 75216163,
    "Corn, yellow and white, cooked, NS as to form, fat not added in cooking": 75216170,
    "Corn, yellow and white, cooked, from fresh, fat not added in cooking": 75216171,
    "Corn, yellow and white, cooked, from frozen, fat not added in cooking": 75216172,
    "Corn, yellow and white, cooked, from canned, fat not added in cooking": 75216173,
    "Corn, yellow and white, cooked, NS as to form, made with oil": 75216174,
    "Corn, yellow and white, cooked, NS as to form, made with butter": 75216175,
    "Corn, yellow and white, cooked, NS as to form, made with margarine": 75216176,
    "Corn, yellow and white, cooked, from fresh, made with oil": 75216177,
    "Corn, yellow and white, cooked, from fresh, made with butter": 75216178,
    "Corn, yellow and white, cooked, from fresh, made with margarine": 75216179,
    "Corn, yellow and white, cooked, NS as to form, fat added in cooking, NS as to type of fat": 75216180,
    "Corn, yellow and white, cooked, from fresh, fat added in cooking, NS as to type of fat": 75216181,
    "Corn, yellow and white, cooked, from frozen, fat added in cooking, NS as to type of fat": 75216182,
    "Corn, yellow and white, cooked, from canned, fat added in cooking, NS as to type of fat": 75216183,
    "Corn, yellow and white, cooked, from frozen, made with oil": 75216184,
    "Corn, yellow and white, cooked, from frozen, made with butter": 75216185,
    "Corn, yellow and white, cooked, from frozen, made with margarine": 75216186,
    "Corn, yellow and white, cooked, from canned, made with oil": 75216187,
    "Corn, yellow and white, cooked, from canned, made with butter": 75216188,
    "Corn, yellow and white, cooked, from canned, made with margarine": 75216189,
    "Corn, yellow, NS as to form, cream style, fat added in cooking": 75216190,
    "Corn, yellow, from canned, cream style, fat added in cooking": 75216193,
    "Corn, white, cooked, NS as to form, NS as to fat added in cooking": 75216200,
    "Corn, white, cooked, from fresh, NS as to fat added in cooking": 75216201,
    "Corn, white, cooked, from frozen, NS as to fat added in cooking": 75216202,
    "Corn, white, cooked, from canned, NS as to fat added in cooking": 75216203,
    "Corn, white, cooked, NS as to form, fat not added in cooking": 75216210,
    "Corn, white, cooked, from fresh, fat not added in cooking": 75216211,
    "Corn, white, cooked, from frozen, fat not added in cooking": 75216212,
    "Corn, white, cooked, from canned, fat not added in cooking": 75216213,
    "Corn, white, cooked, NS as to form, fat added in cooking, NS as to type of fat": 75216220,
    "Corn, white, cooked, from fresh, fat added in cooking, NS as to type of fat": 75216221,
    "Corn, white, cooked, from frozen, fat added in cooking, NS as to type of fat": 75216222,
    "Corn, white, cooked, from canned, fat added in cooking, NS as to type of fat": 75216223,
    "Corn, white, cooked, NS as to form, made with oil": 75216224,
    "Corn, white, cooked, NS as to form, made with butter": 75216225,
    "Corn, white, cooked, NS as to form, made with margarine": 75216226,
    "Corn, white, cooked, from fresh, made with oil": 75216227,
    "Corn, white, cooked, from fresh, made with butter": 75216228,
    "Corn, white, cooked, from fresh, made with margarine": 75216229,
    "Corn, white, cooked, from frozen, made with oil": 75216232,
    "Corn, white, cooked, from frozen, made with butter": 75216233,
    "Corn, white, cooked, from frozen, made with margarine": 75216234,
    "Corn, white, cooked, from canned, made with oil": 75216235,
    "Corn, white, cooked, from canned, made with butter": 75216236,
    "Corn, white, cooked, from canned, made with margarine": 75216237,
    "Corn, white, NS as to form, cream style": 75216250,
    "Corn, white, from canned, cream style": 75216253,
    "Corn, yellow, canned, low sodium, NS as to fat added in cooking": 75216300,
    "Corn, yellow, canned, low sodium, made with margarine": 75216323,
    "Cucumber, cooked, NS as to fat added in cooking": 75216700,
    "Cucumber, cooked, fat not added in cooking": 75216710,
    "Cucumber, cooked, made with oil": 75216721,
    "Cucumber, cooked, made with butter": 75216722,
    "Cucumber, cooked, made with margarine": 75216723,
    "Eggplant, cooked, NS as to fat added in cooking": 75217000,
    "Eggplant, cooked, made with oil": 75217021,
    "Eggplant, cooked, made with butter": 75217022,
    "Eggplant, cooked, made with margarine": 75217023,
    "Flowers or blossoms of sesbania, squash, or lily, NS as to fat added in cooking": 75217299,
    "Flowers or blossoms of sesbania, squash, or lily, fat not added in cooking": 75217300,
    "Hominy, cooked, NS as to fat added in cooking": 75217490,
    "Hominy, cooked, fat not added in cooking": 75217500,
    "Kohlrabi, cooked, NS as to fat added in cooking": 75218009,
    "Kohlrabi, cooked, fat not added in cooking": 75218010,
    "Lotus root, cooked, NS as to fat added in cooking": 75218499,
    "Lotus root, cooked, fat not added in cooking": 75218500,
    "Mushrooms, cooked, NS as to form, NS as to fat added in cooking": 75219000,
    "Mushrooms, cooked, from fresh, NS as to fat added in cooking": 75219001,
    "Mushrooms, cooked, from frozen, NS as to fat added in cooking": 75219002,
    "Mushrooms, cooked, from canned, NS as to fat added in cooking": 75219003,
    "Mushrooms, cooked, NS as to form, fat not added in cooking": 75219010,
    "Mushrooms, cooked, from frozen, fat not added in cooking": 75219012,
    "Mushrooms, cooked, from canned, fat not added in cooking": 75219013,
    "Mushrooms, cooked, from frozen, fat added in cooking, NS as to type of fat": 75219022,
    "Mushrooms, cooked, NS as to form, made with oil": 75219030,
    "Mushrooms, cooked, NS as to form, made with butter": 75219031,
    "Mushrooms, cooked, NS as to form, made with margarine": 75219032,
    "Mushrooms, cooked, from fresh, made with margarine": 75219035,
    "Mushrooms, cooked, from frozen, made with oil": 75219036,
    "Mushrooms, cooked, from frozen, made with butter": 75219037,
    "Mushrooms, cooked, from frozen, made with margarine": 75219038,
    "Mushrooms, cooked, from canned, made with oil": 75219039,
    "Mushrooms, cooked, from canned, made with butter": 75219040,
    "Mushrooms, cooked, from canned, made with margarine": 75219041,
    "Okra, cooked, NS as to form, NS as to fat added in cooking": 75220000,
    "Okra, cooked, from fresh, NS as to fat added in cooking": 75220001,
    "Okra, cooked, from frozen, NS as to fat added in cooking": 75220002,
    "Okra, cooked, from canned, NS as to fat added in cooking": 75220003,
    "Okra, cooked, NS as to form, fat not added in cooking": 75220010,
    "Okra, cooked, from canned, fat not added in cooking": 75220013,
    "Okra, cooked, from canned, fat added in cooking, NS as to type of fat": 75220023,
    "Okra, cooked, NS as to form, made with oil": 75220024,
    "Okra, cooked, NS as to form, made with butter": 75220025,
    "Okra, cooked, NS as to form, made with margarine": 75220026,
    "Okra, cooked, from fresh, made with oil": 75220027,
    "Okra, cooked, from fresh, made with butter": 75220028,
    "Okra, cooked, from fresh, made with margarine": 75220029,
    "Okra, cooked, from frozen, made with oil": 75220031,
    "Okra, cooked, from frozen, made with butter": 75220032,
    "Okra, cooked, from frozen, made with margarine": 75220033,
    "Okra, cooked, from canned, made with oil": 75220034,
    "Okra, cooked, from canned, made with butter": 75220035,
    "Okra, cooked, from canned, made with margarine": 75220036,
    "Lettuce, cooked, NS as to fat added in cooking": 75220049,
    "Lettuce, cooked, fat not added in cooking": 75220050,
    "Luffa, cooked, NS as to fat added in cooking": 75220099,
    "Luffa, cooked, fat not added in cooking": 75220100,
    "Luffa, cooked, fat added in cooking": 75220101,
    "Onions, cooked, NS as to form, NS as to fat added in cooking": 75221000,
    "Onions, cooked, from fresh, NS as to fat added in cooking": 75221001,
    "Onions, cooked, from frozen, NS as to fat added in cooking": 75221002,
    "Onions, cooked, NS as to form, fat not added in cooking": 75221010,
    "Onions, cooked, from frozen, fat not added in cooking": 75221012,
    "Onions, cooked, NS as to form, made with oil": 75221014,
    "Onions, cooked, NS as to form, made with butter": 75221015,
    "Onions, cooked, NS as to form, made with margarine": 75221016,
    "Onions, cooked, from fresh, made with oil": 75221017,
    "Onions, cooked, from fresh, made with butter": 75221018,
    "Onions, cooked, from fresh, made with margarine": 75221019,
    "Onions, cooked, NS as to form, fat added in cooking, NS as to type of fat": 75221020,
    "Onions, cooked, from frozen, fat added in cooking, NS as to type of fat": 75221022,
    "Onions, cooked, from frozen, made with oil": 75221023,
    "Onions, cooked, from frozen, made with butter": 75221024,
    "Onions, cooked, from frozen, made with margarine": 75221025,
    "Onions, pearl, cooked, from fresh": 75221031,
    "Onions, pearl, cooked, from frozen": 75221032,
    "Onions, pearl, cooked, from canned": 75221033,
    "Onions, green, cooked, NS as to form, NS as to fat added in cooking": 75221040,
    "Onions, green, cooked, from fresh, NS as to fat added in cooking": 75221041,
    "Onions, green, cooked, NS as to form, fat not added in cooking": 75221050,
    "Onions, green, cooked, from fresh, fat not added in cooking": 75221051,
    "Onions, green, cooked, NS as to form, fat added in cooking": 75221060,
    "Parsnips, cooked, NS as to fat added in cooking": 75222000,
    "Parsnips, cooked, fat not added in cooking": 75222010,
    "Parsnips, cooked, made with oil": 75222021,
    "Parsnips, cooked, made with butter": 75222022,
    "Parsnips, cooked, made with margarine": 75222023,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, NS as to form, NS as to fat added in cooking": 75223000,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from fresh, NS as to fat added in cooking": 75223001,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from frozen, NS as to fat added in cooking": 75223002,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from canned, NS as to fat added in cooking": 75223003,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, NS as to form, fat not added in cooking": 75223010,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from fresh, fat not added in cooking": 75223011,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from frozen, fat not added in cooking": 75223012,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from canned, fat not added in cooking": 75223013,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, NS as to form, fat added in cooking, NS as to type of fat": 75223020,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from fresh, fat added in cooking, NS as to type of fat": 75223021,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, NS as to form, made with oil": 75223024,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, NS as to form, made with butter": 75223025,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, NS as to form, made with margarine": 75223026,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from fresh, made with oil": 75223027,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from fresh, made with butter": 75223028,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from fresh, made with margarine": 75223029,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from frozen, made with oil": 75223030,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from frozen, made with butter": 75223031,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from frozen, made with margarine": 75223032,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from canned, made with oil": 75223033,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from canned, made with butter": 75223034,
    "Peas, cowpeas, field peas, or blackeye peas, not dried, cooked, from canned, made with margarine": 75223035,
    "Peas, green, cooked, NS as to form, NS as to fat added in cooking": 75224010,
    "Peas, green, cooked, from fresh, NS as to fat added in cooking": 75224011,
    "Peas, green, cooked, from frozen, NS as to fat added in cooking": 75224012,
    "Peas, green, cooked, from canned, NS as to fat added in cooking": 75224013,
    "Peas, green, cooked, NS as to form, fat not added in cooking": 75224020,
    "Peas, green, cooked, NS as to form, made with oil": 75224040,
    "Peas, green, cooked, NS as to form, made with butter": 75224041,
    "Peas, green, cooked, NS as to form, made with margarine": 75224042,
    "Peas, green, cooked, from fresh, made with margarine": 75224045,
    "Peas, green, cooked, from frozen, made with margarine": 75224048,
    "Peas, green, cooked, from canned, made with margarine": 75224051,
    "Peas, green, canned, low sodium, NS as to fat added in cooking": 75224110,
    "Peas, green, canned, low sodium, made with margarine": 75224133,
    "Pigeon peas, cooked, NS as to form, fat not added in cooking": 75225010,
    "Pigeon peas, cooked, from fresh, fat not added in cooking": 75225011,
    "Pigeon peas, cooked, from canned, fat not added in cooking": 75225013,
    "Pigeon peas, cooked, NS as to form, NS as to fat added in cooking": 75225014,
    "Pigeon peas, cooked, NS as to form, fat added in cooking": 75225015,
    "Pigeon peas, cooked, from fresh, NS as to fat added in cooking": 75225016,
    "Pigeon peas, cooked, from fresh, fat added in cooking": 75225017,
    "Pigeon peas, cooked, from canned, NS as to fat added in cooking": 75225018,
    "Pigeon peas, cooked, from canned, fat added in cooking": 75225019,
    "Peppers, green, cooked, NS as to fat added in cooking": 75226000,
    "Peppers, green, cooked, fat not added in cooking": 75226010,
    "Peppers, green, cooked, made with oil": 75226021,
    "Peppers, green, cooked, made with butter": 75226022,
    "Peppers, green, cooked, made with margarine": 75226023,
    "Peppers, red, cooked, NS as to fat added in cooking": 75226040,
    "Peppers, red, cooked, fat not added in cooking": 75226050,
    "Peppers, red, cooked, made with oil": 75226061,
    "Peppers, red, cooked, made with butter": 75226062,
    "Peppers, red, cooked, made with margarine": 75226063,
    "Peppers, hot, cooked, NS as to form, NS as to fat added in cooking": 75226090,
    "Peppers, hot, cooked, from fresh, NS as to fat added in cooking": 75226091,
    "Peppers, hot, cooked, from frozen, NS as to fat added in cooking": 75226092,
    "Peppers, hot, cooked, from canned, NS as to fat added in cooking": 75226093,
    "Peppers, hot, cooked, NS as to form, fat not added in cooking": 75226100,
    "Peppers, hot, cooked, from fresh, fat not added in cooking": 75226101,
    "Peppers, hot, cooked, from frozen, fat not added in cooking": 75226102,
    "Peppers, hot, cooked, from canned, fat not added in cooking": 75226103,
    "Peppers, hot, cooked, NS as to form, fat added in cooking, NS as to type of fat": 75226110,
    "Peppers, hot, cooked, from frozen, fat added in cooking, NS as to type of fat": 75226112,
    "Peppers, hot, cooked, from canned, fat added in cooking, NS as to type of fat": 75226113,
    "Radish, daikon, cooked, NS as to fat added in cooking": 75227099,
    "Radish, daikon, cooked, fat not added in cooking": 75227100,
    "Rutabaga, cooked, NS as to fat added in cooking": 75228000,
    "Rutabaga, cooked, fat not added in cooking": 75228010,
    "Rutabaga, cooked, made with oil": 75228021,
    "Rutabaga, cooked, made with butter": 75228022,
    "Rutabaga, cooked, made with margarine": 75228023,
    "Salsify, cooked, NS as to fat added in cooking": 75229009,
    "Salsify, cooked, fat not added in cooking": 75229010,
    "Sauerkraut, cooked, fat not added in cooking": 75230010,
    "Sauerkraut, cooked, fat added in cooking": 75230020,
    "Snowpea, cooked, NS as to form, NS as to fat added in cooking": 75231000,
    "Snowpea, cooked, from fresh, NS as to fat added in cooking": 75231001,
    "Snowpea, cooked, from frozen, NS as to fat added in cooking": 75231002,
    "Snowpea, cooked, NS as to form, fat not added in cooking": 75231010,
    "Snowpea, cooked, NS as to form, made with oil": 75231023,
    "Snowpea, cooked, NS as to form, made with butter": 75231024,
    "Snowpea, cooked, NS as to form, made with margarine": 75231025,
    "Snowpea, cooked, from fresh, made with oil": 75231026,
    "Snowpea, cooked, from fresh, made with butter": 75231027,
    "Snowpea, cooked, from fresh, made with margarine": 75231028,
    "Snowpea, cooked, from frozen, made with oil": 75231029,
    "Snowpea, cooked, from frozen, made with butter": 75231030,
    "Snowpea, cooked, from frozen, made with margarine": 75231031,
    "Seaweed, prepared with soy sauce": 75232050,
    "Seaweed, cooked, NS as to fat added in cooking": 75232100,
    "Seaweed, cooked, made with oil": 75232121,
    "Seaweed, cooked, made with butter": 75232122,
    "Seaweed, cooked, made with margarine": 75232123,
    "Squash, summer, yellow or green, cooked, NS as to form, NS as to fat added in cooking": 75233000,
    "Squash, summer, yellow or green, cooked, from fresh, NS as to fat added in cooking": 75233001,
    "Squash, summer, yellow or green, cooked, from frozen, NS as to fat added in cooking": 75233002,
    "Squash, summer, yellow or green, cooked, from canned, NS as to fat added in cooking": 75233003,
    "Squash, summer, yellow or green, cooked, NS as to form, fat not added in cooking": 75233010,
    "Squash, summer, yellow or green, cooked, NS as to form, made with oil": 75233024,
    "Squash, summer, yellow or green, cooked, NS as to form, made with butter": 75233025,
    "Squash, summer, yellow or green, cooked, NS as to form, made with margarine": 75233026,
    "Squash, summer, yellow or green, cooked, from fresh, made with margarine": 75233029,
    "Squash, summer, yellow or green, cooked, from frozen, made with margarine": 75233032,
    "Squash, summer, yellow or green, cooked, from canned, made with margarine": 75233035,
    "Squash, spaghetti, cooked, NS as to fat added in cooking": 75233200,
    "Squash, spaghetti, cooked, fat added in cooking, NS as to type of fat": 75233210,
    "Squash, spaghetti, cooked, made with oil": 75233221,
    "Squash, spaghetti, cooked, made with butter": 75233222,
    "Squash, spaghetti, cooked, made with margarine": 75233223,
    "Turnip, cooked, NS as to form, NS as to fat added in cooking": 75234000,
    "Turnip, cooked, from fresh, NS as to fat added in cooking": 75234001,
    "Turnip, cooked, from frozen, NS as to fat added in cooking": 75234002,
    "Turnip, cooked, from canned, NS as to fat added in cooking": 75234003,
    "Turnip, cooked, NS as to form, fat not added in cooking": 75234010,
    "Turnip, cooked, from fresh, fat not added in cooking": 75234011,
    "Turnip, cooked, from frozen, fat not added in cooking": 75234012,
    "Turnip, cooked, from canned, fat not added in cooking": 75234013,
    "Turnip, cooked, NS as to form, fat added in cooking, NS as to type of fat": 75234020,
    "Turnip, cooked, from frozen, fat added in cooking, NS as to type of fat": 75234022,
    "Turnip, cooked, from canned, fat added in cooking, NS as to type of fat": 75234023,
    "Turnip, cooked, from canned, made with oil": 75234024,
    "Turnip, cooked, from canned, made with butter": 75234025,
    "Turnip, cooked, from canned, made with margarine": 75234026,
    "Turnip, cooked, from fresh, made with oil": 75234027,
    "Turnip, cooked, from fresh, made with butter": 75234028,
    "Turnip, cooked, from fresh, made with margarine": 75234029,
    "Turnip, cooked, from frozen, made with oil": 75234030,
    "Turnip, cooked, from frozen, made with butter": 75234031,
    "Turnip, cooked, from frozen, made with margarine": 75234032,
    "Turnip, cooked, NS as to form, made with oil": 75234033,
    "Turnip, cooked, NS as to form, made with butter": 75234034,
    "Turnip, cooked, NS as to form, made with margarine": 75234035,
    "Beans, lima and corn, cooked, NS as to fat added in cooking": 75301100,
    "Beans, lima and corn, cooked, made with oil": 75301121,
    "Beans, lima and corn, cooked, made with butter": 75301122,
    "Beans, lima and corn, cooked, made with margarine": 75301123,
    "Beans, string, green, with tomatoes, cooked, NS as to fat added in cooking": 75302009,
    "Beans, string, green, with tomatoes, cooked, fat not added in cooking": 75302010,
    "Beans, string, green, with tomatoes, cooked, fat added in cooking": 75302011,
    "Beans, string, green, with chickpeas, cooked, NS as to fat added in cooking": 75302029,
    "Beans, string, green, with chickpeas, cooked, fat not added in cooking": 75302030,
    "Beans, string, green, with chickpeas, cooked, fat added in cooking": 75302031,
    "Beans, string, green, with almonds, cooked, NS as to fat added in cooking": 75302039,
    "Beans, string, green, with almonds, cooked, fat not added in cooking": 75302040,
    "Beans, string, green, with almonds, cooked, fat added in cooking, NS as to type of fat": 75302045,
    "Beans, string, green, with almonds, cooked, made with oil": 75302046,
    "Beans, string, green, with almonds, cooked, made with butter": 75302047,
    "Beans, string, green, with almonds, cooked, made with margarine": 75302048,
    "Beans, string, green, with pinto beans, cooked, NS as to fat added in cooking": 75302059,
    "Beans, string, green, with pinto beans, cooked, fat not added in cooking": 75302060,
    "Beans, string, green, with pinto beans, cooked, fat added in cooking": 75302061,
    "Beans, string, green, with spaetzel, cooked, NS as to fat added in cooking": 75302069,
    "Beans, string, green, with spaetzel, cooked, fat not added in cooking": 75302070,
    "Beans, string, green, with spaetzel, cooked, fat added in cooking": 75302071,
    "Beans, string, green, with onions, NS as to fat added in cooking": 75302200,
    "Beans, string, green, with onions, cooked, fat not added in cooking": 75302205,
    "Beans, string, green, with onions, cooked, fat added in cooking, NS as to type of fat": 75302210,
    "Beans, string, green, with onions, cooked, made with oil": 75302211,
    "Beans, string, green, with onions, cooked, made with butter": 75302212,
    "Beans, string, green, with onions, cooked, made with margarine": 75302213,
    "Beans, string, green, and potatoes, cooked, NS as to fat added in cooking": 75302500,
    "Beans, string, green, and potatoes, cooked, fat not added in cooking": 75302505,
    "Beans, string, green, and potatoes, cooked, fat added in cooking, NS as to type of fat": 75302510,
    "Beans, string, green, and potatoes, cooked, made with oil": 75302511,
    "Beans, string, green, and potatoes, cooked, made with butter": 75302512,
    "Beans, string, green, and potatoes, cooked, made with margarine": 75302513,
    "Corn with peppers, red or green, cooked, NS as to fat added in cooking": 75303000,
    "Corn with peppers, red or green, cooked, fat not added in cooking": 75303010,
    "Corn with peppers, red or green, cooked, fat added in cooking, NS as to type of fat": 75303020,
    "Corn with peppers, red or green, cooked, made with oil": 75303021,
    "Corn with peppers, red or green, cooked, made with butter": 75303022,
    "Corn with peppers, red or green, cooked, made with margarine": 75303023,
    "Eggplant in tomato sauce, cooked, fat not added in cooking": 75306010,
    "Green peppers and onions, cooked, NS as to fat added in cooking": 75306999,
    "Green peppers and onions, cooked, made with oil": 75307001,
    "Green peppers and onions, cooked, made with butter": 75307002,
    "Green peppers and onions, cooked, made with margarine": 75307003,
    "Mixed vegetables, cooked, NS as to form, NS as to fat added in cooking": 75311000,
    "Mixed vegetables, cooked, from frozen, NS as to fat added in cooking": 75311002,
    "Mixed vegetables, cooked, from canned, NS as to fat added in cooking": 75311003,
    "Mixed vegetables, cooked, NS as to form, fat not added in cooking": 75311010,
    "Mixed vegetables, cooked, NS as to form, made with oil": 75311024,
    "Mixed vegetables, cooked, NS as to form, made with butter": 75311025,
    "Mixed vegetables, cooked, NS as to form, made with margarine": 75311026,
    "Mixed vegetables, cooked, from frozen, made with margarine": 75311029,
    "Mixed vegetables, cooked, from canned, made with margarine": 75311032,
    "Mixed vegetables, canned, low sodium, NS as to fat added in cooking": 75311100,
    "Mixed vegetables, canned, low sodium, made with margarine": 75311123,
    "Peas and corn, cooked, NS as to fat added in cooking": 75315000,
    "Peas and corn, cooked, made with oil": 75315021,
    "Peas and corn, cooked, made with butter": 75315022,
    "Peas and corn, cooked, made with margarine": 75315023,
    "Peas and onions, cooked, NS as to fat added in cooking": 75315100,
    "Peas and onions, cooked, fat not added in cooking": 75315110,
    "Peas and onions, cooked, fat added in cooking, NS as to type of fat": 75315120,
    "Peas and onions, cooked, made with oil": 75315121,
    "Peas and onions, cooked, made with butter": 75315122,
    "Peas and onions, cooked, made with margarine": 75315123,
    "Peas and mushrooms, cooked, NS as to fat added in cooking": 75315200,
    "Peas and mushrooms, cooked, fat not added in cooking": 75315210,
    "Peas and mushrooms, cooked, fat added in cooking, NS as to type of fat": 75315215,
    "Peas and mushrooms, cooked, made with oil": 75315216,
    "Peas and mushrooms, cooked, made with butter": 75315217,
    "Peas and mushrooms, cooked, made with margarine": 75315218,
    "Cowpeas with snap beans, cooked, NS as to fat added in cooking": 75315249,
    "Cowpeas with snap beans, cooked, fat not added in cooking": 75315250,
    "Cowpeas with snap beans, cooked, fat added in cooking": 75315251,
    "Peas and potatoes, cooked, fat not added in cooking": 75315300,
    "Peas and potatoes, cooked, NS as to fat added in cooking": 75315305,
    "Peas and potatoes, cooked, fat added in cooking, NS as to type of fat": 75315310,
    "Peas and potatoes, cooked, made with oil": 75315311,
    "Peas and potatoes, cooked, made with butter": 75315312,
    "Peas and potatoes, cooked, made with margarine": 75315313,
    "Squash, summer, yellow or green, and onions, cooked, NS as to fat added in cooking": 75315999,
    "Squash, summer, yellow or green, and onions, cooked, fat not added in cooking": 75316000,
    "Squash, summer, yellow or green, and onions, cooked, fat added in cooking": 75316020,
    "Squash, summer, yellow or green, and onions, cooked, made with oil": 75316022,
    "Squash, summer, yellow or green, and onions, cooked, made with butter": 75316023,
    "Squash, summer, yellow or green, and onions, cooked, made with margarine": 75316024,
    "Squash, summer, yellow or green, with tomato sauce, cooked, fat not added in cooking": 75316030,
    "Squash, summer, yellow or green, with tomato sauce, cooked, fat added in cooking": 75316031,
    "Squash, summer, yellow or green, with tomato sauce, cooked, NS as to fat added in cooking": 75316032,
    "Vegetables, stew type, cooked, NS as to fat added in cooking": 75317000,
    "Vegetables, stew type, cooked, made with oil": 75317011,
    "Vegetables, stew type, cooked, made with butter": 75317012,
    "Vegetables, stew type, cooked, made with margarine": 75317013,
    "Vegetable combination, including carrots, broccoli, and/or dark-green leafy; cooked, no sauce, NS as to fat added in cooking": 75330100,
    "Vegetable combination, including carrots, broccoli, and/or dark-green leafy; cooked, no sauce, fat not added in cooking": 75330110,
    "Vegetable combination, including carrots, broccoli, and/or dark-green leafy; cooked, no sauce, fat added in cooking, NS as to type of fat": 75330120,
    "Vegetable combination, including carrots, broccoli, and/or dark-green leafy; cooked, no sauce, made with oil": 75330121,
    "Vegetable combination, including carrots, broccoli, and/or dark-green leafy; cooked, no sauce, made with butter": 75330122,
    "Vegetable combination, including carrots, broccoli, and/or dark-green leafy; cooked, no sauce, made with margarine": 75330123,
    "Vegetable combination, excluding carrots, broccoli, and dark-green leafy; cooked, no sauce, NS as to fat added in cooking": 75330130,
    "Vegetable combination, excluding carrots, broccoli, and dark-green leafy; cooked, no sauce,  fat not added in cooking": 75330140,
    "Vegetable combination, excluding carrots, broccoli, and dark-green leafy; cooked, no sauce, fat added in cooking, NS as to type of fat": 75330150,
    "Vegetable combination, excluding carrots, broccoli, and dark-green leafy; cooked, no sauce, made with oil": 75330151,
    "Vegetable combination, excluding carrots, broccoli, and dark-green leafy; cooked, no sauce, made with butter": 75330152,
    "Vegetable combination, excluding carrots, broccoli, and dark-green leafy; cooked, no sauce, made with margarine": 75330153,
    "Vegetable combinations, Asian style, broccoli, green pepper, water chestnut, etc., cooked, NS as to fat added in cooking": 75340000,
    "Vegetable combinations, Asian style, broccoli, green pepper, water chestnut, etc., cooked, made with oil": 75340021,
    "Vegetable combinations, Asian style, broccoli, green pepper, water chestnut, etc., cooked, made with butter": 75340022,
    "Vegetable combinations, Asian style, broccoli, green pepper, water chestnut, etc., cooked, made with margarine": 75340023,
    "Vegetable and pasta combinations with cream or cheese sauce, broccoli, pasta, carrots, corn, zucchini, peppers, cauliflower, peas, etc., cooked": 75340160,
    "Pinacbet": 75340300,
    "Asparagus, NS as to form, creamed or with cheese sauce": 75401010,
    "Asparagus, from fresh, creamed or with cheese sauce": 75401011,
    "Asparagus, from frozen, creamed or with cheese sauce": 75401012,
    "Asparagus, from canned, creamed or with cheese sauce": 75401013,
    "Beans, lima, immature, NS as to form, creamed or with cheese sauce": 75402010,
    "Beans, lima, immature, from fresh, creamed or with cheese sauce": 75402011,
    "Beans, lima, immature, from frozen, creamed or with cheese sauce": 75402012,
    "Beans, lima, immature, from canned, creamed or with cheese sauce": 75402013,
    "Beans, lima, immature, cooked, NS as to form, with mushroom sauce": 75402020,
    "Beans, lima, immature, cooked, from fresh, with mushroom sauce": 75402021,
    "Beans, lima, immature, cooked, from frozen, with mushroom sauce": 75402022,
    "Beans, lima, immature, cooked, from canned, with mushroom sauce": 75402023,
    "Beans, string, green, NS as to form, creamed or with cheese sauce": 75403010,
    "Beans, string, green, from fresh, creamed or with cheese sauce": 75403011,
    "Beans, string, green, from frozen, creamed or with cheese sauce": 75403012,
    "Beans, string, green, from canned, creamed or with cheese sauce": 75403013,
    "Beans, string, green, cooked, from fresh, with mushroom sauce": 75403021,
    "Beans, string, green, cooked, from frozen, with mushroom sauce": 75403022,
    "Beans, string, green, cooked, from canned, with mushroom sauce": 75403023,
    "Beans, string, yellow, NS as to form, creamed or with cheese sauce": 75404010,
    "Beans, string, yellow, from fresh, creamed or with cheese sauce": 75404011,
    "Beans, string, yellow, from frozen, creamed or with cheese sauce": 75404012,
    "Beans, string, yellow, from canned, creamed or with cheese sauce": 75404013,
    "Beets with Harvard sauce": 75405010,
    "Brussels sprouts, NS as to form, creamed": 75406010,
    "Brussels sprouts, from fresh, creamed": 75406011,
    "Brussels sprouts, from frozen, creamed": 75406012,
    "Cabbage, creamed": 75407010,
    "Cauliflower, NS as to form, creamed": 75409010,
    "Cauliflower, from fresh, creamed": 75409011,
    "Cauliflower, from frozen, creamed": 75409012,
    "Cauliflower, from canned, creamed": 75409013,
    "Celery, creamed": 75410010,
    "Corn, cooked, NS as to form, with cream sauce, made with milk": 75411030,
    "Corn, cooked, from fresh, with cream sauce, made with milk": 75411031,
    "Corn, cooked, from frozen, with cream sauce, made with milk": 75411032,
    "Corn, cooked, from canned, with cream sauce, made with milk": 75411033,
    "Kohlrabi, creamed": 75413010,
    "Mushrooms, NS as to form, creamed": 75414010,
    "Mushrooms, from fresh, creamed": 75414011,
    "Mushrooms, from frozen, creamed": 75414012,
    "Mushrooms, from canned, creamed": 75414013,
    "Onions, NS as to form, creamed": 75415010,
    "Onions, from fresh, creamed": 75415011,
    "Onion rings, NS as to form, batter-dipped, baked or fried": 75415020,
    "Onion rings, from fresh, batter-dipped, baked or fried": 75415021,
    "Parsnips, creamed": 75416010,
    "Peas, NS as to form, creamed": 75417010,
    "Peas, from fresh, creamed": 75417011,
    "Peas, from frozen, creamed": 75417012,
    "Peas, from canned, creamed": 75417013,
    "Peas, cooked, NS as to form, with mushroom sauce": 75417020,
    "Peas, cooked, from fresh, with mushroom sauce": 75417021,
    "Peas, cooked, from frozen, with mushroom sauce": 75417022,
    "Peas, cooked, from canned, with mushroom sauce": 75417023,
    "Peas, cooked, NS as to form, with tomato sauce": 75417030,
    "Peas, cooked, from fresh, with tomato sauce": 75417031,
    "Peas, cooked, from frozen, with tomato sauce": 75417032,
    "Peas, cooked, from canned, with tomato sauce": 75417033,
    "Squash, summer, yellow or green, breaded or battered, baked": 75418000,
    "Squash, summer, NS as to form, creamed": 75418050,
    "Squash, summer, from fresh, creamed": 75418051,
    "Squash, summer, from frozen, creamed": 75418052,
    "Squash, summer, from canned, creamed": 75418053,
    "Turnips, NS as to form, creamed": 75418100,
    "Turnips, from fresh, creamed": 75418101,
    "Turnips, from frozen, creamed": 75418102,
    "Turnips, from canned, creamed": 75418103,
    "Creamed christophine, Puerto Rican style": 75418220,
    "Vegetable combination, including carrots, broccoli, and/or dark-green leafy; cooked, with soy-based sauce": 75440100,
    "Vegetable combination, excluding carrots, broccoli, and dark-green leafy; cooked, with soy-based sauce": 75440110,
    "Vegetable combinations, including carrots, broccoli, and/or dark-green leafy; cooked, with tomato sauce": 75440300,
    "Vegetable combinations, excluding carrots, broccoli, and dark-green leafy; cooked, with tomato sauce": 75440310,
    "Vegetable combinations, including carrots, broccoli, and/or dark-green leafy; cooked, with cheese sauce": 75440500,
    "Vegetable combinations, excluding carrots, broccoli, and dark-green leafy; cooked, with cheese sauce": 75440510,
    "Vegetable combination, including carrots, broccoli, and/or dark-green leafy; cooked, with cream sauce": 75450500,
    "Vegetable combination, excluding carrots, broccoli, and dark-green leafy; cooked, with cream sauce": 75450510,
    "Vegetable combination, including carrots, broccoli, and/or dark-green leafy; cooked, with butter sauce": 75450600,
    "Vegetable combinations, including carrots, broccoli, and/or dark-green leafy; cooked, with pasta": 75460700,
    "Vegetable combinations, excluding carrots, broccoli, and dark-green leafy; cooked, with pasta": 75460710,
    "Vegetable combinations, including carrots, broccoli, and/or dark-green leafy; cooked, with butter sauce and pasta": 75460800,
    "Vegetable combinations, excluding carrots, broccoli, and dark-green leafy; cooked, with butter sauce and pasta": 75460810,
    "Cabbage, mustard, salted": 75502550,
    "Cucumber pickles, sour": 75503030,
    "Mustard pickles": 75503100,
    "Cucumber pickles, dill, reduced salt": 75503110,
    "Cucumber pickles, sweet, reduced salt": 75503140,
    "Pickles, mixed": 75511200,
    "Recaito": 75512510,
    "Vegetables, pickled, Hawaiian style": 75515000,
    "Vegetable relish": 75515010,
    "Tsukemono, Japanese pickles": 75534500,
    "Soup, cream of, NFS": 75600150,
    "Asparagus soup, cream of, prepared with water": 75601020,
    "Celery soup, cream of, prepared with milk, home recipe, canned or ready-to-serve": 75603010,
    "Corn soup, cream of, prepared with milk": 75604010,
    "Corn soup, cream of, prepared with water": 75604020,
    "Leek soup, cream of, prepared with milk": 75605010,
    "Mushroom soup, NFS": 75607000,
    "Mushroom soup, cream of, low sodium, prepared with water": 75607050,
    "Mushroom with chicken soup, cream of, prepared with milk": 75607080,
    "Mushroom soup, cream of, canned, reduced sodium, NS as to made with milk or water": 75607090,
    "Mushroom soup, cream of, canned, reduced sodium, prepared with milk": 75607100,
    "Onion soup, cream of, prepared with milk": 75608010,
    "Onion soup, made from dry mix": 75608200,
    "Zucchini soup, cream of, prepared with milk": 75612010,
    "Shav soup": 75646010,
    "Vegetable beef noodle soup, prepared with water": 75651030,
    "Vegetable beef soup with rice, canned, prepared with water or ready-to-serve": 75651080,
    "Vegetable chicken rice soup, canned, prepared with water or ready-to-serve": 75651110,
    "Vegetable soup with chicken broth, home recipe, Mexican style": 75651140,
    "Vegetable noodle soup, reduced sodium, canned, prepared with water or ready-to-serve": 75651150,
    "Vegetable beef soup, canned, prepared with milk": 75652030,
    "Vegetarian vegetable soup, prepared with water": 75654010,
    "Vegetable soup, Spanish style, stew type": 75656010,
    "Vegetable soup, chunky style": 75656020,
    "Vegetable soup, with pasta, chunky style": 75656040,
    "Spinach, creamed, baby food, strained": 76102010,
    "Broccoli, carrots and cheese, baby food, junior": 76102030,
    "Carrots, baby food, NS as to strained or junior": 76201000,
    "Carrots, baby food, junior": 76201020,
    "Carrots, baby food, toddler": 76201030,
    "Carrots and peas, baby food, strained": 76202000,
    "Squash, baby food, NS as to strained or junior": 76205000,
    "Squash, baby food, junior": 76205020,
    "Squash and corn, baby food, strained": 76205030,
    "Corn and sweet potatoes, baby food, strained": 76205060,
    "Sweet potatoes, baby food, NS as to strained or junior": 76209000,
    "Sweet potatoes, baby food, junior": 76209020,
    "Beans, green string, baby food, NS as to strained or junior": 76401000,
    "Beans, green string, baby food, junior": 76401020,
    "Beans, green string, baby food, toddler": 76401060,
    "Green beans and potatoes, baby food, strained": 76402000,
    "Corn, creamed, baby food, NS as to strained or junior": 76405000,
    "Corn, creamed, baby food, strained": 76405010,
    "Corn, creamed, baby food, junior": 76405020,
    "Mixed vegetables, garden vegetables, baby food, NS as to strained or junior": 76407000,
    "Peas, baby food, NS as to strained or junior": 76409000,
    "Peas, baby food, junior": 76409020,
    "Peas, baby food, toddler": 76409030,
    "Potatoes, baby food, toddler": 76420000,
    "Vegetables and rice, baby food, strained": 76501000,
    "Peas and brown rice, baby food": 76502000,
    "Carrots and beef, baby food, strained": 76602000,
    "Vegetable and beef, baby food, NS as to strained or junior": 76603000,
    "Vegetable and beef, baby food, strained": 76603010,
    "Vegetable and beef, baby food, junior": 76603020,
    "Broccoli and chicken, baby food, strained": 76604000,
    "Sweet potatoes and chicken, baby food, strained": 76604500,
    "Vegetable and chicken, baby food, NS as to strained or junior": 76605000,
    "Vegetable and chicken, baby food, strained": 76605010,
    "Vegetable and chicken, baby food, junior": 76605020,
    "Potatoes with cheese and broccoli, baby food, toddler": 76607100,
    "Vegetable and turkey, baby food, NS as to strained or junior": 76611000,
    "Vegetable and turkey, baby food, strained": 76611010,
    "Vegetable and turkey, baby food, junior": 76611020,
    "Potato and ham fritters, Puerto Rican style": 77121110,
    "Potato chicken pie, Puerto Rican style": 77141010,
    "Ripe plantain fritters, Puerto Rican style": 77205110,
    "Cassava fritter stuffed with crab meat, Puerto Rican style": 77230510,
    "Stuffed tannier fritters, Puerto Rican style": 77250110,
    "Tannier fritters, Puerto Rican style": 77250710,
    "Spanish stew": 77513010,
    "Puerto Rican stew": 77563010,
    "Butter, whipped, stick, salted": 81101020,
    "Butter, stick, unsalted": 81101100,
    "Butter, whipped, tub, unsalted": 81101110,
    "Butter, whipped, stick, unsalted": 81101120,
    "Light butter, stick, salted": 81101500,
    "Margarine, whipped, tub, salted": 81103020,
    "Margarine, stick, unsalted": 81103030,
    "Margarine like spread, whipped, tub, salted": 81103130,
    "Margarine like spread, reduced calorie, about 40% fat, made with yogurt, tub, salted": 81104011,
    "Margarine like spread, reduced calorie, about 20% fat, tub, salted": 81104050,
    "Margarine like spread, fat free, tub, salted": 81104100,
    "Margarine like spread, fat free, liquid, salted": 81104110,
    "Vegetable oil-butter spread, reduced calorie, tub, salted": 81104560,
    "Butter-margarine blend, stick, salted": 81105010,
    "Butter-margarine blend, tub, salted": 81105020,
    "Butter-vegetable oil blend": 81105500,
    "Shortening, vegetable": 81203100,
    "Shortening, animal": 81203200,
    "Tartar sauce, reduced fat/calorie": 81312000,
    "Canola and soybean oil": 82105750,
    "Canola, soybean and sunflower oil": 82105800,
    "Soybean and sunflower oil": 82108250,
    "Creamy dressing": 83112500,
    "Creamy dressing, light": 83210100,
    "Creamy dressing, fat free": 83300300,
    "Cane and corn pancake syrup": 91301020,
    "Buttered blends syrup": 91301040,
    "Maple syrup": 91301060,
    "Chocolate syrup, thin type, sugar free": 91301082,
    "Sorghum syrup": 91301090,
    "Sugar, carmelized": 91301120,
    "Sugar, brown, and water syrup": 91301200,
    "Maple and corn and/or cane pancake syrup blends": 91301250,
    "Sugar, brown, liquid": 91303500,
    "Hard sauce": 91304050,
    "Topping, peanut butter, thick, fudge type": 91304070,
    "Topping, fruit, unsweetened": 91304080,
    "Topping, milk chocolate with cereal": 91304250,
    "Topping, chocolate, hard coating": 91304300,
    "Syrup, dietetic": 91351010,
    "Topping, dietetic": 91351020,
    "Fruit sauce": 91361020,
    "Plum sauce, Asian style": 91361070,
    "Jam, preserve, marmalade, sugar free, all flavors": 91406000,
    "Jam, preserve, marmalade, reduced sugar, all flavors": 91406600,
    "Chinese preserved sweet vegetable": 91408100,
    "Gelatin snacks": 91501015,
    "Gelatin dessert with whipped cream": 91501030,
    "Gelatin dessert with fruit and whipped cream": 91501040,
    "Gelatin dessert with cream cheese": 91501050,
    "Gelatin dessert with sour cream": 91501060,
    "Gelatin dessert with fruit and sour cream": 91501070,
    "Gelatin dessert with fruit and cream cheese": 91501080,
    "Gelatin dessert with fruit, vegetable, and nuts": 91501090,
    "Gelatin dessert with fruit and whipped topping": 91501110,
    "Gelatin dessert with fruit and vegetables": 91501120,
    "Gelatin dessert, dietetic, with whipped topping, sweetened with low calorie sweetener": 91511030,
    "Gelatin dessert, dietetic, with cream cheese, sweetened with low calorie sweetener": 91511050,
    "Gelatin dessert, dietetic, with sour cream, sweetened with low calorie sweetener": 91511060,
    "Gelatin dessert, dietetic, with fruit and sour cream, sweetened with low calorie sweetener": 91511070,
    "Gelatin dessert, dietetic, with fruit and cream cheese, sweetened with low calorie sweetener": 91511080,
    "Gelatin dessert, dietetic, with fruits and vegetables, sweetened with low calorie sweetener": 91511090,
    "Gelatin salad, dietetic, with vegetables, sweetened with low calorie sweetener": 91511100,
    "Gelatin dessert, dietetic, with fruit and whipped topping, sweetened with low calorie sweetener": 91511110,
    "Danish dessert pudding": 91512010,
    "Coconut cream cake, Puerto Rican style": 91550100,
    "Pineapple custard, Puerto Rican style": 91550300,
    "Gelatin, frozen, whipped, on a stick": 91580000,
    "Ice pop filled with ice cream, all flavor varieties": 91611050,
    "M&M's Almond Chocolate Candies": 91700500,
    "Almonds, sugar-coated": 91701020,
    "Almonds, yogurt-covered": 91701030,
    "Butterscotch morsels": 91702010,
    "Caramel, chocolate-flavored roll": 91703010,
    "Caramel, flavor other than chocolate": 91703020,
    "Caramel, with nuts": 91703030,
    "Caramel candy, chocolate covered": 91703040,
    "Caramel with nuts and cereal, chocolate covered": 91703050,
    "Caramel with nuts, chocolate covered": 91703060,
    "Rolo": 91703070,
    "Caramel, all flavors, sugar free": 91703080,
    "Toblerone, milk chocolate with honey and almond nougat": 91703150,
    "TWIX Caramel Cookie Bars": 91703200,
    "TWIX Chocolate Fudge Cookie Bars": 91703250,
    "TWIX Peanut Butter Cookie Bars": 91703300,
    "Whatchamacallit": 91703400,
    "Nuts, carob-coated": 91703500,
    "Espresso coffee beans, chocolate-covered": 91703600,
    "Kit Kat": 91705030,
    "Chocolate, milk, with nuts, not almond or peanuts": 91705040,
    "Milk chocolate candy, with fruit and nuts": 91705050,
    "Milk chocolate candy, with almonds": 91705060,
    "Chocolate, milk, with peanuts": 91705070,
    "Chocolate candy with fondant and caramel": 91705090,
    "Chocolate, sweet or dark, with almonds": 91705310,
    "Chocolate, white, with almonds": 91705410,
    "Chocolate, white, with cereal": 91705420,
    "Kit Kat White": 91705430,
    "Mexican chocolate, tablet": 91705500,
    "Coconut candy, chocolate covered": 91706000,
    "Coconut candy, no chocolate covering": 91706100,
    "Coconut candy, Puerto Rican style": 91706400,
    "Fondant": 91707000,
    "Fondant, chocolate covered": 91707010,
    "Fruit peel, candied": 91708000,
    "Date candy": 91708010,
    "Soft fruit confections": 91708020,
    "Fruit leather and fruit snacks candy": 91708030,
    "Fun Fruits Creme Supremes": 91708040,
    "Tamarind candy": 91708070,
    "Fruit snacks candy, with high vitamin C": 91708100,
    "Yogurt covered fruit snacks candy, with added vitamin C": 91708150,
    "Yogurt covered fruit snacks candy rolls, with high vitamin C": 91708160,
    "Gumdrops, chocolate covered": 91709000,
    "Fudge, chocolate, chocolate-coated": 91713010,
    "Fudge, chocolate, chocolate-coated, with nuts": 91713020,
    "Fudge, chocolate": 91713030,
    "Fudge, chocolate, with nuts": 91713040,
    "Fudge, peanut butter": 91713050,
    "Fudge, peanut butter, with nuts": 91713060,
    "Fudge, vanilla": 91713070,
    "Fudge, vanilla, with nuts": 91713080,
    "Fudge, divinity": 91713090,
    "Fudge, brown sugar, penuche": 91713100,
    "Fudge, caramel and nut, chocolate-coated candy": 91715000,
    "SNICKERS Bar": 91715100,
    "Baby Ruth": 91715200,
    "100 GRAND Bar": 91715300,
    "Halvah, plain": 91716010,
    "Halvah, chocolate covered": 91716110,
    "Honey-combed hard candy with peanut butter": 91718000,
    "Honey-combed hard candy with peanut butter, chocolate covered": 91718050,
    "Butterfinger": 91718100,
    "Butterfinger Crisp": 91718110,
    "Chocolate-flavored sprinkles": 91718200,
    "Marshmallow, chocolate covered": 91723010,
    "Marshmallow, candy-coated": 91723020,
    "Marshmallow, coconut-coated": 91723050,
    "Nougat, plain": 91726000,
    "Nougat, with caramel, chocolate covered": 91726110,
    "MILKY WAY Bar": 91726130,
    "MILKY WAY MIDNIGHT Bar": 91726140,
    "MARS Almond Bar": 91726150,
    "Nougat, chocolate covered": 91726410,
    "3 MUSKETEERS Bar": 91726420,
    "3 Musketeers Truffle Crisp Bar": 91726425,
    "Nuts, chocolate covered, not almonds or peanuts": 91727010,
    "Sugared pecans, sugar and egg white coating": 91728500,
    "M&M's Peanut Chocolate Candies": 91731010,
    "M&M's Peanut Butter Chocolate Candies": 91731060,
    "Peanuts, sugar-coated": 91731100,
    "Peanuts, yogurt covered": 91731150,
    "Peanut bar": 91732000,
    "Planters Peanut Bar": 91732100,
    "Peanut Bar, chocolate covered candy": 91733200,
    "Peanut butter, chocolate covered": 91734000,
    "Reese's Peanut Butter Cup": 91734100,
    "Reese's Pieces": 91734200,
    "Reese's Sticks": 91734300,
    "Reese's Fast Break": 91734400,
    "Reese's Crispy Crunchy Bar": 91734450,
    "Peanut butter morsels": 91734500,
    "Pralines": 91735000,
    "Pineapple candy, Puerto Rican style": 91736000,
    "Raisins, chocolate covered": 91739010,
    "Raisins, yogurt covered": 91739600,
    "Sesame Crunch, Sahadi": 91742010,
    "Hard candy": 91745020,
    "Butterscotch hard candy": 91745040,
    "Skittles": 91745100,
    "Sugar-coated chocolate discs": 91746010,
    "Sixlets": 91746120,
    "Easter egg, candy coated chocolate": 91746150,
    "M&M's Pretzel Chocolate Candies": 91746200,
    "Toffee, plain": 91760000,
    "Toffee, chocolate covered": 91760100,
    "Toffee, chocolate-coated, with nuts": 91760200,
    "Truffles": 91760500,
    "Wax candy, liquid filled": 91760700,
    "Dietetic or low calorie candy, NFS": 91770000,
    "Dietetic or low calorie gumdrops": 91770010,
    "Dietetic or low calorie hard candy": 91770020,
    "Dietetic or low calorie candy, chocolate covered": 91770030,
    "Dietetic or low calorie mints": 91770050,
    "Chewing gum, NFS": 91800100,
    "Frozen daiquiri mix, frozen concentrate, not reconstituted": 92512040,
    "Wine, light, nonalcoholic": 92802000,
    "Beer, light, higher alcohol": 93102200,
    "Alcoholic malt beverage, higher alcohol, sweetened": 93106010,
    "Bacardi cocktail": 93301020,
    "Grasshopper": 93301070,
    "High ball": 93301080,
    "Salty Dog": 93301139,
    "Stinger": 93301290,
    "Golden Cadillac": 93301340,
    "Zombie": 93302100,
    "Water, bottled, flavored (Propel Water)": 94210100,
    "Water, bottled, flavored (Glaceau Vitamin Water)": 94210200,
    "Water, bottled, flavored (SoBe Life Water)": 94210300,
    "Water, bottled, flavored, sugar free (Glaceau Vitamin Water)": 94220215,
    "Water, bottled, flavored, sugar free (SoBe)": 94220310
};
export default IngredientsDictionary;
