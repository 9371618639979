import { useState, useEffect, useContext } from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { AppContext } from "../../../App";
import { useSnackbar } from "../../../context/SnackbarContext";
import { findIngredientNames } from "../../../dictionary/utils";
import { LightTooltip } from "../../basic/Tooltip/Tooltip";

export interface IIngredient {
	id: string;
    name: string;
    foodId: number;
    amount: number;
}

interface IngredientProps {
    ingredient: IIngredient;
    filterType: "startsWith" | "includes";
    // eslint-disable-next-line no-unused-vars
    onIngredientChange: (updatedIngredient: IIngredient) => void;
}

export function IngredientInput({
    ingredient,
    filterType,
    onIngredientChange
}: IngredientProps) {
    const { isMobile } = useContext(AppContext);
    const { showSnackbar } = useSnackbar();
    const [inputValue, setInputValue] = useState<string>(ingredient.name);
    const [possibleIngredients, setPossibleIngredients] = useState<
        { name: string; foodId: number }[]
    >([]);
    const [selectedIngredient, setSelectedIngredient] = useState<{
        name: string;
        foodId: number;
    } | null>(null);

    useEffect(() => {
        const matches = findIngredientNames(inputValue, filterType);

        if (
            selectedIngredient &&
            matches.some(
                ingredient => ingredient.name === selectedIngredient.name
            )
        ) {
            setPossibleIngredients([]);
        } else {
            setPossibleIngredients(matches);
        }
        if (inputValue.length > 2 && !matches.length) {
            showSnackbar(
                "No matching ingredients found. Please enter a valid ingredient and choose from the dropdown list.",
                "error"
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType, inputValue, selectedIngredient]);

    const handleNameChange = (value: string) => {
        setInputValue(value);
        setSelectedIngredient(null);
        if (!value) {
            onIngredientChange({
                ...ingredient,
                name: "",
                foodId: -1
            });
        }
    };

    const handleAmountChange = (value: number) => {
        onIngredientChange({ ...ingredient, amount: value });
    };

    const selectIngredient = (selected: { name: string; foodId: number }) => {
        setInputValue(selected.name);
        setSelectedIngredient(selected);
        onIngredientChange({
            ...ingredient,
            name: selected.name,
            foodId: selected.foodId
        });
    };

    return (
        <div className="calculator__ingredient">
            <div className="calculator__ingredient-left">
                <p className="calculator__ingredient-text">Ingredient</p>
                <input
                    className="calculator__ingredient-name-input"
                    type="text"
                    value={inputValue}
                    onChange={e => handleNameChange(e.target.value)}
                    placeholder="Enter ingredient name..."
                />
                <div className="calculator__ingredient-tooltip-icon">
                    <LightTooltip
                        arrow
                        title={
                            <div>
                            Start inputting name of the ingredient from your
                            dish and select a value from drop down list. Min 3
                            characters
                            </div>
                        }
                        placement="top-end"
                    >
                        <HelpOutlineIcon
                            fontSize="small"
                            sx={{ fontSize: "15px" }}
                        />
                    </LightTooltip>
                </div>
                {possibleIngredients.length > 0 && (
                    <div className="calculator__dropdown">
                        {possibleIngredients.map((ing, idx) => (
                            <div
                                key={idx}
                                onClick={() => selectIngredient(ing)}
                                className="calculator__dropdown-item"
                            >
                                {ing.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="calculator__ingredient-right">
                <p className={`calculator__ingredient-text ${isMobile ? "min-width-72" : ""}`}>Amount</p>
                <input
                    className="calculator__ingredient-amount-input"
                    type="number"
                    value={ingredient.amount}
                    onChange={e => handleAmountChange(Number(e.target.value))}
                    placeholder="Amount in grams"
                />
                <p className="calculator__ingredient-text" style={{ margin: "0px", paddingBottom: "5px" }}>g</p>
                <div className="calculator__ingredient-tooltip-icon" style={{ margin: "0px" }}>
                    <LightTooltip
                        arrow
                        title={
                            <div>
                            Input amount of the ingredient in grams. Only “0-9”
                            and “.” values
                            </div>
                        }
                        placement="top-end"
                    >
                        <HelpOutlineIcon
                            fontSize="small"
                            sx={{ fontSize: "15px" }}
                        />
                    </LightTooltip>
                </div>
            </div>
        </div>
    );
}
