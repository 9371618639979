import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import auth from "./reducers/auth";
import client from "./reducers/client";
import formData from "./reducers/formData";
import { api } from "../services/connection/api";

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth,
        client,
        formData,
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware().concat(api.middleware);
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;
