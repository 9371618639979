import { api } from "./connection/api";
import { IGetQuery } from "../types/common";
import { IAddDishRequestBody, IGetDishesResponse } from "../types/dish";
import { getDishesQueryString } from "../utils/queries";

const ENDPOINT_PREFIX = "dishes";

export const dishApi = api.injectEndpoints({
    endpoints: (build) => ({
        addDish: build.mutation({
            query(data: IAddDishRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["Dish"]
        }),
        getUserDishes: build.query<IGetDishesResponse, IGetQuery>({
            query: (query) => ({
                url: `${ENDPOINT_PREFIX}?${getDishesQueryString(query)}`
            }),
            providesTags: ["Dish"]
        }),
        getSearchedDishes: build.query<IGetDishesResponse, IGetQuery>({
            query: (query) => ({
                url: `${ENDPOINT_PREFIX}/search?${getDishesQueryString(query)}`
            }),
            providesTags: ["Dish"]
        })
    })
});

export const {
    useAddDishMutation,
    useGetUserDishesQuery,
    useGetSearchedDishesQuery
} = dishApi;
