import IngredientsDictionary from "./IngredientsDictionary";

export function findIngredientNames(
    query: string,
    filterType: "startsWith" | "includes"
): { name: string; foodId: number }[] {
    if (query.length < 3) {
        return [];
    }

    const lowercasedQuery = query.toLowerCase();

    const matches: { name: string; foodId: number }[] = [];

    for (const ingredient in IngredientsDictionary) {
        const lowercasedIngredient = ingredient.toLowerCase();

        if (
            (filterType === "startsWith" && lowercasedIngredient.startsWith(lowercasedQuery)) ||
            (filterType === "includes" && lowercasedIngredient.includes(lowercasedQuery))
        ) {
            matches.push({
                name: ingredient,
                foodId: IngredientsDictionary[ingredient]
            });
        }
    }

    return matches;
}
