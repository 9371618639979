export function isBicValid(bic = "") {
    const bicPattern = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;
    return bicPattern.test(bic);
}

export function isEmailValid(email = "") {
    const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailPattern.test(email);
}

export function isPPSnValid(ppsn = "") {
    const ppsnPattern = /^[0-9]{7}[A-Za-z]{1,2}$/;
    return ppsnPattern.test(ppsn);
}

export function isNameValid(name = "") {
    const apostrophePattern = /['‘’′‛`"]/;
    return !apostrophePattern.test(name);
}

export function isPasswordLengthValid(password = "") {
    return password.length >= 6;
}
