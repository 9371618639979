import React, { useContext } from "react";

import { Navigate } from "react-router-dom";

import { AppContext } from "../../../App";

const PrivateRoute = ({
    component: Component,
    ...props
}: {
    component: React.FC<any>;
    props?: any;
}) => {
    const { isAuth } = useContext(AppContext);

    if (isAuth) {
        return <Component {...props} />;
    }

    return <Navigate replace to="/auth" />;
};

export default PrivateRoute;
