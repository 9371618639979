import React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Spinner = ({
    children,
    isLoading,
    marginTop = 0,
}: {
    children?: React.ReactNode,
    isLoading: boolean,
    marginTop?: number,
}) => {
    if (isLoading) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ width: "100%", marginTop }}
            >
                <CircularProgress size={30} />
            </Box>
        );
    }

    return <>{children}</>;
};

export default Spinner;
