import { ChangeEvent, useEffect, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    TextField,
    Button,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Checkbox,
    Modal,
    Box
} from "@mui/material";
import { Link } from "react-router-dom";

import { useSnackbar } from "../../../../context/SnackbarContext";
import useDebounce from "../../../../hooks/helpers/useDebounce";
import { useLocalStorage } from "../../../../hooks/helpers/useLocalStorage";
import { useModalOpener } from "../../../../hooks/helpers/useModalOpener";
import { useAppDispatch } from "../../../../redux/hooks";
import { login } from "../../../../redux/reducers/auth";
import { useForgotPasswordMutation, useSignInMutation } from "../../../../services/auth";
import {
    isEmailValid,
    isPasswordLengthValid
} from "../../../../utils/validation";

const SignIn = () => {
    const { open, isOpened, close } = useModalOpener();
    const dispatch = useAppDispatch();
    const [signIn] = useSignInMutation();
    const [forgotPassword] = useForgotPasswordMutation();
    const { showSnackbar } = useSnackbar();

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);

    const [emailTouched, setEmailTouched] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const [resetEmailValid, setResetEmailValid] = useState(true);
    const [resetEmailTouched, setResetEmailTouched] = useState(false);

    const debouncedResetEmail = useDebounce(resetEmail, 300);

    const debouncedEmail = useDebounce(formData.email, 300);
    const debouncedPassword = useDebounce(formData.password, 200);

    const [rememberMe, setRememberMe] = useLocalStorage<boolean | null>(
        "rememberMe",
        null
    );

    const handleResetEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setResetEmail(event.target.value);
        setResetEmailTouched(true);
    };

    useEffect(() => {
        if (resetEmailTouched) {
            setResetEmailValid(isEmailValid(debouncedResetEmail));
        }
    }, [debouncedResetEmail, resetEmailTouched]);

    const handleRememberMe = (e: any) => {
        setRememberMe(e.target.checked);
    };

    const handleSendResetEmail = async () => {
        await forgotPassword({ email: resetEmail })
            .then(({ data }: any) => {
                if (data.success) {
                    showSnackbar("New password sent successfully!", "success");
                }
            })
            .catch(e => {
                console.log("reset password error", e);
                showSnackbar(
                    "Reset password failed: " + (e?.data?.message || "Unknown error"),
                    "error"
                );
            });
        setResetEmail("");
        setResetEmailTouched(false);
        close();
    };

    const handleChange =
        (prop: keyof typeof formData) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, [prop]: event.target.value });
                if (prop === "email") {
                    setEmailTouched(true);
                } else if (prop === "password") {
                    setPasswordTouched(true);
                }
            };

    const handleSignIn = async () => {
        try {
            const payload = await signIn(formData).unwrap();
            showSnackbar("Login successful!", "success");
            dispatch(login(payload.token));
        } catch (error: any) {
            showSnackbar(
                "Login failed: " + (error?.data?.message || "Unknown error"),
                "error"
            );
        }
    };

    useEffect(() => {
        if (emailTouched) {
            setEmailValid(isEmailValid(debouncedEmail));
        }
    }, [debouncedEmail, emailTouched]);

    useEffect(() => {
        if (passwordTouched) {
            setPasswordValid(isPasswordLengthValid(debouncedPassword));
        }
    }, [debouncedPassword, passwordTouched]);

    return (
        <div className="auth__form-container">
            <h2 className="auth__title">Login</h2>
            <div className="auth__form">
                <TextField
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange("email")}
                    required
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={emailTouched && !emailValid}
                    helperText={
                        emailTouched && !emailValid
                            ? "Invalid email format"
                            : ""
                    }
                />
                <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={handleChange("password")}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={passwordTouched && !passwordValid}
                    helperText={
                        passwordTouched && !passwordValid
                            ? "Password should be at least 6 characters"
                            : ""
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "16px" }}
                    className="auth__button"
                    onClick={handleSignIn}
                    disabled={
                        !emailValid ||
                        !passwordValid ||
                        !formData.password ||
                        !formData.email
                    }
                    fullWidth
                >
                    Login
                </Button>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!rememberMe}
                            onChange={handleRememberMe}
                            className="auth__remember-me-checkbox"
                        />
                    }
                    label={
                        <p className="auth__text" style={{ margin: "0px" }}>
                            Remember me
                        </p>
                    }
                />
                <p className="auth__text">
                    Forgot your password?{" "}
                    <span className="auth__reset" onClick={() => open()}>
                        Reset
                    </span>
                </p>
                <p className="auth__text">
                    Don’t have account?{" "}
                    <Link className="auth__link-text" to="/auth?type=sign-up">
                        Create new account
                    </Link>
                </p>
            </div>
            <Modal
                open={isOpened}
                onClose={close}
                aria-labelledby="forgot-password-modal"
                aria-describedby="forgot-password-modal-description"
            >
                <Box className="auth__modal-box">
                    <h3 className="auth__title">Reset Password</h3>
                    <TextField
                        label="Email"
                        type="email"
                        value={resetEmail}
                        onChange={handleResetEmailChange}
                        required
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        error={resetEmailTouched && !resetEmailValid}
                        helperText={
                            resetEmailTouched && !resetEmailValid
                                ? "Invalid email format"
                                : ""
                        }
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSendResetEmail}
                        disabled={!resetEmail || !resetEmailValid}
                    >
                        Send New Password
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={close}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default SignIn;
