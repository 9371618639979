/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useState, ReactNode } from "react";

import { AlertColor } from "@mui/material";

interface SnackbarT {
    open: boolean;
    message: string;
    severity: AlertColor;
}

interface SnackbarContextType {
    snackbar: SnackbarT;
    showSnackbar: (message: string, severity?: AlertColor) => void;
    hideSnackbar: () => void;
}

const defaultContext: SnackbarContextType = {
    snackbar: {
        open: false,
        message: "",
        severity: "info"
    },
    showSnackbar: () => {},
    hideSnackbar: () => {}
};

const SnackbarContext = createContext<SnackbarContextType>(defaultContext);

export const useSnackbar = () => {
    return useContext(SnackbarContext);
};

interface SnackbarProviderProps {
    children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
    children
}) => {
    const [snackbar, setSnackbar] = useState<SnackbarT>({
        open: false,
        message: "",
        severity: "info"
    });

    const showSnackbar = (message: string, severity: AlertColor = "info") => {
        setSnackbar({ open: true, message, severity });
    };

    const hideSnackbar = () => {
        setSnackbar(prevState => ({ ...prevState, open: false }));
    };

    return (
        <SnackbarContext.Provider
            value={{ snackbar, showSnackbar, hideSnackbar }}
        >
            {children}
        </SnackbarContext.Provider>
    );
};
