import { api } from "./connection/api";
import { IGetOrderRequestBody, ICheckOrderRequestBody, ICheckOrderResponse } from "../types/payment";

const ENDPOINT_PREFIX = "payments";

export const dishApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrder: build.mutation({
            query(data: IGetOrderRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/get-order`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["Payment"]
        }),

        checkOrder: build.query<ICheckOrderResponse, ICheckOrderRequestBody>({
            query: (data) => ({
                url: `${ENDPOINT_PREFIX}/check-order/${data.orderId}`,
            }),
            providesTags: ["Payment"],
        }),

        checkUserOrders: build.query<ICheckOrderResponse, {}>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/check-orders`,
            }),
            providesTags: ["Payment", "Auth"],
        }),
    })
});

export const {
    useGetOrderMutation,
    useCheckOrderQuery,
    endpoints: { checkUserOrders },
} = dishApi;
