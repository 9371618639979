import "./dishCard.less";

import React, { useContext, useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Divider,
    Button,
} from "@mui/material";

import { AppContext } from "../../../App";
import { useSnackbar } from "../../../context/SnackbarContext";
import { IDish } from "../../../types/dish";
import { LightTooltip } from "../Tooltip/Tooltip";

interface IDishCardProps {
    dish: IDish;
    publicID: string;
    initialExpanded?: boolean;
    withIngredients?: boolean;
    page: "my-dishes" | "search";
    onGetQRCode: () => void;
}

const DishCard: React.FC<IDishCardProps> = ({
    dish,
    publicID,
    initialExpanded = false,
    withIngredients,
    page,
    onGetQRCode,
}) => {
    const { showSnackbar } = useSnackbar();
    const { isMobile } = useContext(AppContext);

    const nutrientEntries = Object.entries(dish.nutrients);
    const midPoint = Math.ceil(nutrientEntries.length / 2);
    const totalGrams = dish.ingredients.reduce(
        (acc, ingredient) => acc + ingredient.amount,
        0
    );

    const [expanded, setExpanded] = useState(initialExpanded);

    const handleExpandClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setExpanded((prev) => !prev);
    };

    const handleCopy = (text: string) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand("copy");
            showSnackbar(`Id '${text}' successfully copied`, "success");
        } catch (err) {
            showSnackbar("Failed to copy text", "error");
            console.error("Failed to copy text: ", err);
        }
        document.body.removeChild(textarea);
    };

    return (
        <Card className="dish-card">
            <Accordion expanded={expanded}>
                <AccordionSummary
                    expandIcon={
                        <div
                            onClick={handleExpandClick}
                            style={{ cursor: "pointer" }}
                        >
                            <ExpandMoreIcon />
                        </div>
                    }
                    aria-controls="dish-content"
                    id="dish-header"
                    onClick={(event) => event.stopPropagation()}
                    style={{ cursor: "default" }}
                >
                    <div className="dish-card__header">
                        <div className="dish-card__header-item">
                            <div className="dish-card__header-sub-item">
                                <p
                                    className="dish-card__header-title"
                                    style={{ marginRight: "5px" }}
                                >
                                    Dish ID
                                </p>
                                <LightTooltip
                                    arrow
                                    title={
                                        page === "my-dishes" ? (
                                            <div>
                                                Dish ID is the ID associated
                                                with the Dish in our system. ID
                                                can be used by your customers on
                                                our Search page to look up
                                                information about a specific
                                                Dish
                                            </div>
                                        ) : (
                                            <div>
                                                Dish ID is the ID associated
                                                with the Dish in our system. ID
                                                can be used on our Search page
                                                to look up information about a
                                                specific Dish
                                            </div>
                                        )
                                    }
                                    placement="top-end"
                                >
                                    <HelpOutlineIcon
                                        fontSize="small"
                                        sx={{
                                            fontSize: "15px",
                                            cursor: "pointer"
                                        }}
                                    />
                                </LightTooltip>
                            </div>
                            <div className="dish-card__header-sub-item">
                                <p className="dish-card__header-text">
                                    {dish.dishId}
                                </p>
                                <ContentCopyIcon
                                    fontSize="small"
                                    sx={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                        marginLeft: "5px"
                                    }}
                                    onClick={() => handleCopy(dish.dishId)}
                                />
                            </div>
                        </div>
                        <div className="dish-card__header-item">
                            <p
                                className="dish-card__header-title"
                                style={{ paddingLeft: "0px" }}
                            >
                                Dish Name
                            </p>
                            <p className="dish-card__header-text">
                                {dish.name}
                            </p>
                        </div>
                        <div className="dish-card__header-item">
                            <div className="dish-card__header-sub-item">
                                <p
                                    className="dish-card__header-title"
                                    style={{ marginRight: "5px" }}
                                >
                                    Public ID
                                </p>
                                <LightTooltip
                                    arrow
                                    title={
                                        page === "my-dishes" ? (
                                            <div>
                                                Public ID is the ID you can set
                                                up in your Profile Page. ID can
                                                be used by your customers on our
                                                Search page to look up all
                                                dishes associated with your
                                                account
                                            </div>
                                        ) : (
                                            <div>
                                                Public ID is the ID you can use
                                                on our Search page to look up
                                                all dishes associated with a
                                                specific restaurant
                                            </div>
                                        )
                                    }
                                    placement="top-end"
                                >
                                    <HelpOutlineIcon
                                        fontSize="small"
                                        sx={{
                                            fontSize: "15px",
                                            cursor: "pointer"
                                        }}
                                    />
                                </LightTooltip>
                            </div>
                            <div className="dish-card__header-sub-item">
                                <p className="dish-card__header-text">
                                    {publicID}
                                </p>
                                <ContentCopyIcon
                                    fontSize="small"
                                    sx={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                        marginLeft: "5px"
                                    }}
                                    onClick={() => handleCopy(publicID)}
                                />
                            </div>
                        </div>
                        {page === "my-dishes" && (
                            <Button variant="outlined" onClick={onGetQRCode} sx={{ ml: isMobile ? 0 : 8 }}>
                                Get Dish QR Code
                            </Button>
                        )}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <CardContent>
                        {withIngredients && (
                            <>
                                <div className="dish-card__ingredients">
                                    <p className="dish-card__content-text">
                                        Ingredients:
                                    </p>
                                    <ul>
                                        {dish.ingredients.map((ingredient) => (
                                            <li
                                                key={ingredient.foodId}
                                                className="dish-card__ingredient"
                                            >
                                                <p className="dish-card__content-text">
                                                    - {ingredient.name}
                                                </p>
                                                <p className="dish-card__content-text dish-card__gram">
                                                    {ingredient.amount} g
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="dish-card__ingredient-total">
                                        <p>Total: {totalGrams} g</p>
                                    </div>
                                </div>

                                <Divider style={{ margin: "20px 0" }} />
                            </>
                        )}

                        <div className="dish-card__nutrients">
                            {withIngredients && (
                                <p className="dish-card__content-text">
                                    Nutrients:
                                </p>
                            )}
                            <div className="dish-card__nutrients-container">
                                <ul className="dish-card__nutrients-list">
                                    {nutrientEntries
                                        .slice(0, midPoint)
                                        .map(([key, nutrient]) => (
                                            <li
                                                key={key}
                                                className="dish-card__nutrient"
                                            >
                                                <p className="dish-card__content-text">
                                                    {
                                                        nutrient.originalColumnName
                                                    }
                                                </p>
                                                <p className="dish-card__content-text dish-card__gram">
                                                    {nutrient.value}{" "}
                                                    {nutrient.measurementUnit}
                                                </p>
                                            </li>
                                        ))}
                                </ul>
                                <ul className="dish-card__nutrients-list">
                                    {nutrientEntries
                                        .slice(midPoint)
                                        .map(([key, nutrient]) => (
                                            <li
                                                key={key}
                                                className="dish-card__nutrient"
                                            >
                                                <p className="dish-card__content-text">
                                                    {
                                                        nutrient.originalColumnName
                                                    }
                                                </p>
                                                <p className="dish-card__content-text dish-card__gram">
                                                    {nutrient.value}{" "}
                                                    {nutrient.measurementUnit}
                                                </p>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </CardContent>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default DishCard;
