import React, { useState, useEffect, useCallback } from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button, TextField, IconButton, InputAdornment, CircularProgress } from "@mui/material";

import "./profile.less";

import refreshIcon from "../../../assets/icons/refresh-arrow.svg";
import { useSnackbar } from "../../../context/SnackbarContext";
import { useUser } from "../../../hooks/common/useUser";
import { useAppDispatch } from "../../../redux/hooks";
import { getCurrentUser, logout } from "../../../redux/reducers/auth";
import { useUpdateCurrentUserMutation } from "../../../services/auth";
import { checkUserOrders } from "../../../services/payment";
import sendGaEvent from "../../../utils/react-ga";
import PageWrapper from "../../basic/PageWrapper/PageWrapper";
import { LightTooltip } from "../../basic/Tooltip/Tooltip";

export default function Profile() {
    const dispatch = useAppDispatch();
    const [updateCurrentUser] = useUpdateCurrentUserMutation();
    const { user } = useUser();

    const { showSnackbar } = useSnackbar();

    const [editMode, setEditMode] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [profile, setProfile] = useState({
        name: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        publicID: "",
        password: ""
    });

    const handleRefresh = async () => {
        sendGaEvent("click", "calculations-refresh");
        if (refreshing) return;
        setRefreshing(true);
        await dispatch(checkUserOrders.initiate({}, { forceRefetch: true })).unwrap();
        dispatch(getCurrentUser());
        setRefreshing(false);
    };

    const handleAutoRefresh = useCallback(async () => {
        if (user?.orders && user.orders.some(order => order.state === "authorised")) {
            setRefreshing(true);
            await dispatch(checkUserOrders.initiate({}, { forceRefetch: true })).unwrap();
            dispatch(getCurrentUser());
            setRefreshing(false);
        }
    }, [user, dispatch]);

    const handleChange =
        (field: keyof typeof profile) =>
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setProfile({
                    ...profile,
                    [field]: e.target.value
                });
            };

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleSave = () => {
        updateCurrentUser({
            info: {
                email: profile.email,
                name: profile.name,
                companyName: profile.companyName,
                phoneNumber: profile.phoneNumber,
                publicID: profile.publicID,
                password: profile.password
            }
        })
            .unwrap()
            .then(async () => {
                showSnackbar("Profile successfully updated!", "success");
            })
            .catch(async (error) => {
                setProfile({
                    name: user?.info.name || "",
                    companyName: user?.info.companyName || "",
                    email: user?.info.email || "",
                    phoneNumber: user?.info.phoneNumber || "",
                    publicID: user?.info.publicID || "",
                    password: ""
                });
                showSnackbar(
                    "Profile update error: " +
                        (error?.data?.message || "Unknown error"),
                    "error"
                );
            });
        setEditMode(false);
    };

    const handleCancel = () => {
        setEditMode(false);
        setProfile({
            name: user?.info.name || "",
            companyName: user?.info.companyName || "",
            email: user?.info.email || "",
            phoneNumber: user?.info.phoneNumber || "",
            publicID: user?.info.publicID || "",
            password: ""
        });
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    useEffect(() => {
        if (user) {
            setProfile({
                name: user.info.name,
                companyName: user.info.companyName,
                email: user.info.email,
                phoneNumber: user.info.phoneNumber,
                publicID: user.info.publicID,
                password: ""
            });
        }
    }, [user]);

    useEffect(() => {
        dispatch(getCurrentUser());
        const timer = setTimeout(() => {
            handleAutoRefresh();
        }, 1000);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageWrapper>
            <div className="profile__form-container">
                <h2 className="profile__form-title">Profile</h2>
                <TextField
                    className="profile__input"
                    variant="outlined"
                    label="Email"
                    value={profile.email}
                    onChange={handleChange("email")}
                    fullWidth
                    disabled={true}
                />
                <TextField
                    className="profile__input"
                    variant="outlined"
                    label="Name"
                    value={profile.name}
                    onChange={handleChange("name")}
                    fullWidth
                    disabled={!editMode}
                />
                <TextField
                    className="profile__input"
                    variant="outlined"
                    label="Company Name"
                    value={profile.companyName}
                    onChange={handleChange("companyName")}
                    fullWidth
                    disabled={!editMode}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LightTooltip
                                    arrow
                                    title="Company Name is the name of your business that will be displayed next to the Dish information. Use a name that is recognizable by your customers."
                                    placement="right"
                                >
                                    <IconButton edge="end">
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </LightTooltip>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    className="profile__input"
                    variant="outlined"
                    label="Phone Number"
                    value={profile.phoneNumber}
                    onChange={handleChange("phoneNumber")}
                    fullWidth
                    disabled={!editMode}
                />
                <TextField
                    className="profile__input"
                    variant="outlined"
                    label="Public ID"
                    value={profile.publicID}
                    onChange={handleChange("publicID")}
                    fullWidth
                    disabled={!editMode}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LightTooltip
                                    arrow
                                    title="Public ID is the ID you can set up in your Profile Page. ID can be used by your customers on our Search page to look up all dishes associated with your account."
                                    placement="right"
                                >
                                    <IconButton edge="end">
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </LightTooltip>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    className="profile__input"
                    variant="outlined"
                    label="Password"
                    value={profile.password}
                    onChange={handleChange("password")}
                    fullWidth
                    disabled={!editMode}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LightTooltip
                                    arrow
                                    title="Enter your new password here to reset it."
                                    placement="right"
                                >
                                    <IconButton edge="end">
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </LightTooltip>
                            </InputAdornment>
                        )
                    }}
                />

                {editMode ? (
                    <>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="profile__button"
                            onClick={handleCancel}
                            style={{ marginTop: "20px" }}
                        >
                            Cancel Edit
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="profile__button"
                            onClick={handleSave}
                            style={{ marginTop: "20px" }}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                    <Button
                        variant="outlined"
                        color="primary"
                        className="profile__button"
                        onClick={handleEdit}
                        style={{ marginTop: "20px" }}
                    >
                        Edit
                    </Button>
                )}
                <div className="profile__calculations-row">
                    <p className="profile__text">{`Number of calculations available: ${
                        user?.calculationsAvailable || 0
                    }`}</p>
                    <div className="profile__calculations-refresh" onClick={handleRefresh}>
                        {refreshing
                            ? <CircularProgress size={18} />
                            : <img src={refreshIcon} alt="refresh" />
                        }
                    </div>

                </div>
                <Button
                    variant="outlined"
                    color="primary"
                    className="profile__button"
                    onClick={handleLogout}
                    style={{ marginTop: "20px" }}
                >
                    Logout
                </Button>
            </div>
        </PageWrapper>
    );
}
