import React, { useContext } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Modal, Box, Button, IconButton } from "@mui/material";
import QRCode from "qrcode.react";

import { AppContext } from "../../../App";

interface QRCodeModalProps {
    open: boolean;
    handleClose: () => void;
    url: string;
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({ open, handleClose, url }) => {
    const { isMobile } = useContext(AppContext);
    const handleCopy = () => {
        const canvas = document.querySelector("canvas");
        if (canvas) {
            canvas.toBlob((blob) => {
                if (blob) {
                    const item = new ClipboardItem({ "image/png": blob });
                    navigator.clipboard.write([item]);
                } else {
                    console.error("Failed to copy QR code: Blob is null");
                }
            });
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="qr-code-modal-title"
            aria-describedby="qr-code-modal-description"
        >
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                p: 5
            }}>
                <Box sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    p: 5
                }}>
                    <IconButton
                        sx={{ position: "absolute", top: 8, right: 8 }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <QRCode value={url} size={isMobile ? 200 : 512}/>
                    <Button onClick={handleCopy} variant="contained" sx={{ mt: 2 }}>Copy QR Code</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default QRCodeModal;
