import { Typography } from "@mui/material";

import "./privacyPolicy.less";

import PageWrapper from "../../basic/PageWrapper/PageWrapper";

export default function PrivacyPolicy() {
    return (
        <PageWrapper>
            <Typography
                sx={{
                    color: "#333",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    margin: "63px auto 0px"
                }}
            >
                Privacy Policy
            </Typography>
            <Typography className="pp-title" sx={{ marginTop: "44px" }}>
                Privacy Policy
            </Typography>
            <Typography className="pp-title">Introduction</Typography>
            <Typography className="pp-text">
                Welcome to DATA TRANSMUTATIONS LTD trading as Meal-lords, a
                company registered in England and Wales under company number
                15216339, with its registered office located at 71-75 Shelton
                Street, Covent Garden, London, WC2H 9JQ, United Kingdom. ("we,"
                "us," "our"). Our website, www.meal-lords.com, is referred to as
                the "Site," and our mobile app is referred to as the "APP."
            </Typography>
            <Typography className="pp-text">
                Your privacy is important to us, and we are committed to
                protecting your personal data. This Privacy Policy explains how
                we collect, use, and protect your personal information when you
                access the Site, download the APP, or provide us with personal
                data, whether manually or electronically. Please take the time
                to read and understand this Privacy Policy to ensure you are
                aware of how we handle your personal data and how you can
                protect your privacy.
            </Typography>
            <Typography className="pp-text">
                If you have any questions about this policy, the APP, the Site,
                or how we use your personal information, please feel free to
                contact us at contact@meal-lords.com or in writing at DATA
                TRANSMUTATIONS LTD, 71-75 Shelton Street, Covent Garden, London,
                WC2H 9JQ, United Kingdom.
            </Typography>

            <Typography className="pp-title">
                1. Information Collection and Use
            </Typography>

            <Typography className="pp-text">
                We are dedicated to safeguarding the privacy of our Site and APP
                users. We are the sole owner of the information collected on the
                Site and through the APP, and we do not sell, share, or rent
                this information to third parties for purposes different from
                those disclosed in this Privacy Policy.
            </Typography>
            <Typography className="pp-text">
                We collect information from you at various points when you use
                the Site and APP, including but not limited to your use of the
                Site, your device type, location data, and usage duration. By
                submitting your information, you consent to its use as described
                in this policy. Any changes to our privacy policy will be posted
                on this page, with notices potentially placed on other Site
                pages, so please check for updates periodically. Continued use
                of the Site and/or the APP constitutes your acceptance of any
                changes to this policy. Please be aware that when you use the
                Site and/or the APP, your data may be transferred and stored
                outside the UK but within the European Economic Area ("EEA"). By
                providing information to the Site, you explicitly consent to
                such transfers or storage.
            </Typography>
            <Typography className="pp-text">
                While we take reasonable steps to protect your personal data, we
                cannot guarantee its complete security, as internet transmission
                is not entirely secure. Any transmission of personal data to the
                Site is done at your own risk. We will retain your personal data
                only for as long as necessary to allow you to use the Site
                and/or the APP.
            </Typography>
            <Typography className="pp-text">
                If you provide us with someone else's information, you confirm
                that they have authorized you to:
                <ul>
                    <li>Consent to the processing of their personal data.</li>
                    <li>Receive data protection notices on their behalf.</li>
                    <li>
                        Consent to the transfer of their personal data abroad.
                    </li>
                    <li>
                        Consent to the processing of their personal information.
                    </li>
                </ul>
            </Typography>

            <Typography className="pp-title">User Categories</Typography>

            <Typography className="pp-text">
                a. Public (Unregistered) Users:
            </Typography>
            <Typography className="pp-text">
                We collect limited data about public (unregistered) Site users,
                including their IP addresses and session details such as viewed
                pages and browser type. This information is automatically logged
                by our web server and helps us evaluate Site usage and
                performance.
            </Typography>
            <Typography className="pp-text">
                b. APP Users and Registered Users:
            </Typography>
            <Typography className="pp-text">
                We do not sell personally identifiable data to third parties.
                Information collected from APP users and/or Registered Users
                includes:
                <ul>
                    <li>
                        Name, email address, location, gender, age, and
                        preference data: Collected and stored by Meal-lords for
                        core functionality and app personalization.
                    </li>
                    <li>
                        Device ID: Collected and stored by Meal-lords for core
                        functionality and shared with third-party partners,
                        including Facebook, Google and Apple for non-commercial
                        operational purposes only.
                    </li>
                    <li>
                        Page views and taps in the app, as well as in-app search
                        history: Collected and stored by Meal-lords for core
                        functionality.
                    </li>
                    <li>
                        Crash logs and diagnostics: Collected and stored by
                        Meal-lords for core functionality.
                    </li>
                </ul>
            </Typography>

            <Typography className="pp-title">Registration</Typography>

            <Typography className="pp-text">
                To use the Site or download the APP, you must complete the
                registration form and provide requested details, including
                contact information (such as name, email address, postcode, and
                password). This information is used to contact you about Site
                services of interest. Providing additional personal information
                is optional but encouraged to personalize your experience.
            </Typography>

            <Typography className="pp-title">Sharing</Typography>

            <Typography className="pp-text">
                We may share aggregated demographic information (not personally
                identifiable) with partners and clients in the health sector and
                academia related to health. Such aggregated data helps monitor
                Site usage and may be provided to third parties.
            </Typography>

            <Typography className="pp-title">Cookies</Typography>

            <Typography className="pp-text">
                Cookies are pieces of data stored on your hard drive containing
                information about you. Usage of a cookie is not linked to
                personally identifiable information on the Site. Cookies can
                save time by avoiding multiple logins. By accepting this policy,
                you consent to our use of cookies. You can adjust your web
                browser settings to opt out of cookies if desired.
            </Typography>

            <Typography className="pp-title">Log Files</Typography>

            <Typography className="pp-text">
                We use IP addresses to analyze trends, administer the Site, and
                gather aggregate demographic information. IP addresses are not
                linked to personally identifiable data.
            </Typography>

            <Typography className="pp-title">Links</Typography>

            <Typography className="pp-text">
                The Site contains links to other websites. Please be aware that
                we are not responsible for the privacy practices of other sites.
                We encourage you to read the privacy policies of websites
                collecting personally identifiable information. This privacy
                policy applies solely to information collected on our Site.
            </Typography>

            <Typography className="pp-title">Direct Mailings</Typography>

            <Typography className="pp-text">
                We may send newsletters, emails, or alerts, and provide
                information about special Site features, services, or products.
                You can manage your email preferences and disable further emails
                through your Site personal profile.
            </Typography>

            <Typography className="pp-title">Surveys and Contests</Typography>

            <Typography className="pp-text">
                From time to time, we may request information through surveys or
                contests. Participation is voluntary, and you can choose whether
                to disclose requested information. We use this data to monitor
                and improve Site satisfaction and may use contact details to
                notify contest winners.
            </Typography>

            <Typography className="pp-title">Data Protection</Typography>

            <Typography className="pp-text">
                We strive to comply with relevant data protection legislation,
                ensuring your personal data is processed in accordance with
                legal requirements, principles, and best practices. For details
                about your rights, data collection and storage rules, and data
                record access, visit www.dataprotection.gov.uk.
            </Typography>

            <Typography className="pp-title">
                Updating/Removal of Personal Information
            </Typography>

            <Typography className="pp-text">
                It is your responsibility to inform us of corrections, updates,
                or changes to your information. If you no longer wish to use the
                APP, we will correct, update, or remove your personal data upon
                request. Note that we may retain archived information for
                record-keeping and audit purposes. You can change preferences or
                unsubscribe from email communications at any time.
            </Typography>

            <Typography className="pp-title">
                Deleting Personal Information
            </Typography>

            <Typography className="pp-text">
                Personal information can be deleted upon request, rendering the
                APP unusable. If you wish to continue using the APP, you will
                need to re-register.
            </Typography>

            <Typography className="pp-title">Contact Us</Typography>

            <Typography className="pp-text" sx={{ marginBottom: "130px" }}>
                If you have any questions about this policy, the Site, or how we
                use your personal information, please contact us via email at
                contact@meal-lords.com or in writing at DATA TRANSMUTATIONS LTD,
                71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, United
                Kingdom.Thank you for entrusting us with your privacy. We value
                your trust and are committed to protecting your personal data.
            </Typography>
        </PageWrapper>
    );
}
