import { api } from "./connection/api";
import {
    IAddClientDocumentRequestBody,
    ICreateRequestBody,
    IEmailAvailabilityQuery,
    IEmailAvailabilityResponse,
    IPPSNAvailabilityQuery,
    IPPSNAvailabilityResponse,
    ISignAgreementRequestBody,
} from "../types/client";

const ENDPOINT_PREFIX = "clients";

export const clientApi = api.injectEndpoints({
    endpoints: build => ({
        create: build.mutation({
            query(data: ICreateRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Client"],
        }),
        signAgreement: build.mutation({
            query(data: ISignAgreementRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/${data.clientId}/sign-agreement`,
                    method: "POST",
                    body: { signature: data.signature },
                };
            },
            invalidatesTags: ["Client"],
        }),
        addClientDocument: build.mutation({
            query(data: IAddClientDocumentRequestBody) {
                const { clientId, ...body } = data;
                return {
                    url: `${ENDPOINT_PREFIX}/${clientId}/documents`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["Client", "Auth"],
        }),
        checkPPSNAvailability: build.query<
            IPPSNAvailabilityResponse,
            IPPSNAvailabilityQuery
        >({
            query: (data: IPPSNAvailabilityQuery) => ({
                url: `${ENDPOINT_PREFIX}/availability?ppsn=${data.ppsn}`,
            }),
            providesTags: ["Client"],
        }),
        checkClientEmailAvailability: build.query<
            IEmailAvailabilityResponse,
            IEmailAvailabilityQuery
        >({
            query: (data: IEmailAvailabilityQuery) => ({
                url: `${ENDPOINT_PREFIX}/availability?email=${data.email}`,
            }),
            // providesTags: ["Client"],
        }),
    }),
});

export const {
    useCreateMutation,
    useAddClientDocumentMutation,
    useSignAgreementMutation,
    endpoints: { checkPPSNAvailability, checkClientEmailAvailability },
} = clientApi;
