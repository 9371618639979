import React, { useEffect, useState } from "react";

import {
    Button,
} from "@mui/material";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

import cookie from "../../../assets/icons/union.svg";
import { useLocalStorage } from "../../../hooks/helpers/useLocalStorage";

import "./cookiesModal.less";

interface CookieConsentModalProps {}

const CookieConsentModal: React.FC<CookieConsentModalProps> = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookieConsent, setCookieConsent] = useLocalStorage<string | null>(
        "cookieConsent",
        null
    );

    useEffect(() => {
        if (cookieConsent === null) {
            setOpen(true);
        } else if (
            cookieConsent === "accepted" &&
            process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID
        ) {
            ReactGA.initialize(
                process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookieConsent]);

    const handleAccept = () => {
        setCookieConsent("accepted");
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
            ReactGA.initialize(
                process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID
            );
        }
        setOpen(false);
    };

    const handleDecline = () => {
        setCookieConsent("declined");
        setOpen(false);
    };

    if (!open) return null;

    return (
        <div className="cookies__modal">
            <div className="cookies__modal-content">
                <div className="cookies__modal-header">
                    <img src={cookie} alt="cookie" style={{ marginRight: "10px" }}/>
                    Cookie settings
                </div>
                <div className="cookies__modal-body">
                    Our website use cookies. By continuing, we assume your
                    permission to deploy cookies as detailed in our{" "}
                    <span
                        onClick={() => {
                            setOpen(false);
                            navigate("/privacy-policy");
                        }}
                        className="link"
                    >
                        Privacy Policy
                    </span>
                </div>
                <div className="cookies__modal-actions">
                    <Button onClick={handleAccept} color="primary" className="accept-button">
                        Accept cookies
                    </Button>
                    <Button onClick={handleDecline} color="primary" className="decline-button">
                        Decline
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsentModal;
