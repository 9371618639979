import "./calculator.less";
import { ChangeEvent, useContext, useMemo, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControlLabel,
    Switch,
    Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import HowItWorks from "./HowItWorks";
import { IIngredient, IngredientInput } from "./IngredientInput";
import Plans from "./Plans";
import { AppContext } from "../../../App";
import { useSnackbar } from "../../../context/SnackbarContext";
import { useAddDishMutation } from "../../../services/dish";
import sendGaEvent from "../../../utils/react-ga";
import PageWrapper from "../../basic/PageWrapper/PageWrapper";
import Spinner from "../../basic/Spinner/Spinner";
import { LightTooltip } from "../../basic/Tooltip/Tooltip";

const initialIngredients = [
    { id: `${Date.now()}1`, name: "", foodId: -1, amount: 0 },
    { id: `${Date.now()}2`, name: "", foodId: -1, amount: 0 },
    { id: `${Date.now()}3`, name: "", foodId: -1, amount: 0 },
    { id: `${Date.now()}4`, name: "", foodId: -1, amount: 0 }
];

const infoBlock = (
    <div>
        <p className="calculator__text">
            Are you a restaurant owner looking to stay ahead in a dynamic
            industry? At <span className="text-accent">Meal-lords</span>, we've
            crafted an exclusive offer tailored specifically for you – the
            restaurateur who values innovation and customer satisfaction.
        </p>
        <p className="calculator__text-title">
            Introducing Our Comprehensive Nutritional Calculator Service
        </p>
        <p className="calculator__text">
            Our Nutrients Calculator is more than just a tool; it's your secret
            ingredient to culinary success. Picture this: with a few simple
            clicks, you can effortlessly analyze and display precise nutritional
            information for every delectable dish on your menu. From{" "}
            <span className="text-accent">calorie counts </span>
            to <span className="text-accent">macronutrient breakdowns </span>
            and <span className="text-accent">vitamins details</span>, our
            state-of-the-art platform takes the guesswork out of menu
            management.
        </p>
        <p className="calculator__text-title">Why Choose Our Offer?</p>
        <div className="calculator__text">
            <ul>
                <li>
                    <span className="text-accent">
                        Unique Instant results:{" "}
                    </span>
                    Receive calculated results with{" "}
                    <span className="text-accent">
                        24 unique Nutritional Components instantly
                    </span>{" "}
                    after inputting information of your dish in our calculator.
                </li>
                <li>
                    <span className="text-accent">Boost Customer Trust: </span>
                    Today's diners are more health-conscious than ever. By
                    showcasing transparent nutritional information, you're not
                    just meeting customer expectations – you're exceeding them.
                    Gain their trust, enhance their dining experience, and keep
                    them coming back for more.
                </li>
                <li>
                    <span className="text-accent">
                        Stay Ahead of the Competition:{" "}
                    </span>
                    In the fiercely competitive restaurant industry, staying
                    relevant is crucial. Our Nutrition Components Calculator
                    sets you apart as an industry innovator. Embrace this tool,
                    and you'll be ahead of the curve, attracting
                    health-conscious patrons and boosting your bottom line.
                </li>
                <li>
                    <span className="text-accent">Compliance Made Easy: </span>
                    Say goodbye to the complexities of regulatory compliance.
                    We've designed our service with your convenience in mind,
                    ensuring that you effortlessly adhere to industry standards.
                </li>
            </ul>
        </div>
        <p className="calculator__text-title" style={{ marginBlock: "30px" }}>
            Take Action Today for a Healthier Bottom Line Tomorrow
        </p>
        <p className="calculator__text">
            Don't wait for your competition to catch up – seize this opportunity
            now. Embrace the power of transparency, customer satisfaction, and
            compliance with Meal-lords Nutritional Components Calculator. Your
            customers will thank you, and your business will thrive. Join the
            ranks of successful restaurant owners who have already harnessed
            this game-changing service. Elevate your culinary journey and propel
            your business forward by clicking{" "}
            <span className="text-accent">"Choose this Package" </span>
            button today!
        </p>
    </div>
);

export default function Calculator() {
    const { isAuth, isMobile } = useContext(AppContext);
    const [addDish, isLoading] = useAddDishMutation();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [dishName, setDishName] = useState<string>("");

    const [ingredients, setIngredients] =
        useState<IIngredient[]>(initialIngredients);

    const [filterType, setFilterType] = useState<"startsWith" | "includes">(
        "startsWith"
    );

    const disableProcess = useMemo(() => {
        if (!dishName) return true;
        if (ingredients.some((ingredient) => ingredient.foodId !== -1))
            return false;
        return true;
    }, [ingredients, dishName]);

    const addNewIngredient = () => {
        const newIngredient = {
            id: `${Date.now()}`,
            name: "",
            foodId: -1,
            amount: 0
        };
        setIngredients([...ingredients, newIngredient]);
    };

    const handleDeleteIngredient = (index: number) => {
        setIngredients((prev) => {
            const newIngredients = [...prev];
            newIngredients.splice(index, 1);
            return newIngredients;
        });
    };

    const handleProceed = async () => {
        sendGaEvent("click", "add-dish");
        const dish = {
            name: dishName,
            ingredients: ingredients
                .filter((i) => i.foodId !== -1)
                .map((i) => {
                    // eslint-disable-next-line no-unused-vars
                    const { id, ...rest } = i;
                    return rest;
                })
        };
        try {
            await addDish(dish).unwrap();
            showSnackbar("Dish added successfully!", "success");
            setDishName("");
            setIngredients(initialIngredients);
            navigate("/my-dishes");
        } catch (error: any) {
            showSnackbar(
                "Add Dish failed: " + (error?.data?.message || "Unknown error"),
                "error"
            );
        }
    };

    const handleAccordionChange = (
        event: ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        if (isExpanded === false && isAuth === false) {
            event.preventDefault();
        }
    };

    return (
        <PageWrapper>
            <div className="calculator__component">
                <h2 className="calculator__page-title">
                    Calculate Nutritional Components in the dishes from your
                    menu
                </h2>
                {isAuth ? (
                    <>
                        <Accordion
                            sx={{
                                boxShadow: "none",
                                borderRadius: "5px"
                            }}
                            onChange={handleAccordionChange}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <p
                                    className="calculator__text-title"
                                    style={{ margin: "0px" }}
                                >
                                    Unlock the Power of Nutritional Transparency
                                    for Your Restaurant Business!
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>{infoBlock}</AccordionDetails>
                        </Accordion>
                        <Accordion
                            sx={{
                                boxShadow: "none",
                                borderRadius: "5px"
                            }}
                            onChange={handleAccordionChange}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <p
                                    className="calculator__text-title"
                                    style={{ margin: "0px" }}
                                >
                                    Choose the package that suits you
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="calculator__payment-content">
                                    <Plans />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            sx={{
                                boxShadow: "none",
                                borderRadius: "5px"
                            }}
                            onChange={handleAccordionChange}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <p
                                    className="calculator__text-title"
                                    style={{ margin: "0px" }}
                                >
                                    How does it work?
                                </p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="calculator__payment-content">
                                    <HowItWorks />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </>
                ) : (
                    <>
                        <p className="calculator__text-title">
                            Unlock the Power of Nutritional Transparency for
                            Your Restaurant Business!
                        </p>
                        {infoBlock}
                        <p className="calculator__payment-title">
                            Ready to start calculating your dishes?
                        </p>
                        <p
                            className="calculator__text-title"
                            style={{ textAlign: "center", margin: "20px" }}
                        >
                            Choose the package that suits you
                        </p>
                        <Plans />
                        <p className="text-accent calculator__payment-title ">
                            How does it work?
                        </p>
                        <HowItWorks />
                    </>
                )}

                {isAuth && (
                    <div className="calculator__container">
                        <h3 className="calculator__title">
                            Input ingredients of your dish below
                        </h3>
                        <div className="calculator__dish-name-wrapper">
                            <p className="calculator__dish-name-text">
                                Name of the Dish
                            </p>
                            <input
                                type="text"
                                className="calculator__dish-name-input"
                                value={dishName}
                                onChange={(e) => setDishName(e.target.value)}
                            />
                        </div>

                        {ingredients.map((ingredient, idx) => (
                            <div
                                className="calculator__ingredient-wrapper"
                                key={ingredient.id}
                            >
                                <IngredientInput
                                    ingredient={ingredient}
                                    filterType={filterType}
                                    onIngredientChange={(updatedIngredient) => {
                                        const newIngredients = [...ingredients];
                                        newIngredients[idx] = updatedIngredient;
                                        setIngredients(newIngredients);
                                    }}
                                />
                                <Button
                                    onClick={() => handleDeleteIngredient(idx)}
                                    className="calculator__ingredient-delete-button"
                                >
                                    <DeleteIcon color="secondary" />
                                </Button>
                                {isMobile &&
                                    <Divider style={{ marginBottom: "30px" }} />
                                }
                            </div>
                        ))}

                        <div className="calculator__add-button-wrapper">
                            <Button
                                variant="contained"
                                className="calculator__add-button"
                                onClick={addNewIngredient}
                            >
                                + Add Ingredient
                            </Button>
                        </div>
                        <div className="calculator__footer">
                            <div>
                                <div className="calculator__dropdown-settings">
                                    <LightTooltip
                                        arrow
                                        title={
                                            <div>
                                                Choose “Starts with” if you want
                                                the drop-down menu to display
                                                exact matches to your input.{" "}
                                                <br />
                                                “Contains” if you want to see
                                                options that contain your
                                                ingredient but not necessarily
                                                match it. <br />
                                                For most of the cases we
                                                recommend keeping “Starts with”
                                                option.
                                            </div>
                                        }
                                        placement="top-start"
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <p className="calculator__dropdown-settings-text">
                                                Ingredient drop down settings
                                            </p>
                                            <HelpOutlineIcon
                                                fontSize="small"
                                                sx={{ fontSize: "15px" }}
                                            />
                                        </div>
                                    </LightTooltip>
                                </div>

                                <div>
                                    <span className="calculator__dropdown-settings-text">
                                        Start With
                                    </span>
                                    <FormControlLabel
                                        sx={{ margin: "0px" }}
                                        control={
                                            <Switch
                                                checked={
                                                    filterType === "includes"
                                                }
                                                onChange={() =>
                                                    setFilterType(
                                                        filterType ===
                                                            "startsWith"
                                                            ? "includes"
                                                            : "startsWith"
                                                    )
                                                }
                                                color="secondary"
                                                size="small"
                                            />
                                        }
                                        label=""
                                    />
                                    <span className="calculator__dropdown-settings-text">
                                        Includes
                                    </span>
                                </div>
                            </div>
                            <div className="calculator__submit-section">
                                <Button
                                    color="secondary"
                                    disabled={disableProcess}
                                    className="calculator__proceed-button"
                                    variant="contained"
                                    onClick={handleProceed}
                                >
                                    <div className="auth__spinner">
                                        <Spinner
                                            isLoading={isLoading.isLoading}
                                        >
                                            <span>Process</span>
                                        </Spinner>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    <p
                        className="calculator__help-text"
                        style={{ marginTop: "60px" }}
                    >
                        Have any questions?
                    </p>
                    <p className="calculator__help-text">
                        Please don’t hesitate to reach out to us at{" "}
                        <a
                            href="mailto:contact@meal-lords.com"
                            style={{
                                color: "#85BB65",
                                textDecoration: "none",
                                display: "inline-block"
                            }}
                        >
                            contact@meal-lords.com
                        </a>
                    </p>
                </div>
            </div>
        </PageWrapper>
    );
}
