// SnackbarComponent.js
import { Snackbar, Alert } from "@mui/material";

import { useSnackbar } from "../../../context/SnackbarContext";

const SnackbarComponent = () => {
    const { snackbar, hideSnackbar } = useSnackbar();

    return (
        <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={hideSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <Alert onClose={hideSnackbar} severity={snackbar.severity}>
                {snackbar.message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarComponent;
