import qs from "qs";

import { IFilter, ISortBy, IGetQuery } from "../types/common";

export const withPagination = (pageIndex: number, pageSize: number) => {
    return {
        page: pageIndex,
        limit: pageSize,
    };
};

const withGlobalFilter = (global: string) => {
    return {
        global,
    };
};

const withFilters = (filters?: IFilter[]) => {
    return {
        filters,
    };
};

const withSortBy = (sortBy?: ISortBy[]) => {
    return {
        ...sortBy?.[0] && {
            sort: sortBy[0].id,
            desc: sortBy[0].desc ? "desc" : "asc",
        },
    };
};

export const getDishesQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    filters = [],
    sortBy = [],
    globalFilter = "",
}: IGetQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withFilters(filters),
        ...withSortBy(sortBy),
        ...withGlobalFilter(globalFilter),
    }, { encode: false });
};
