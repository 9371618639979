export const setAccessToken = (token: string) => {
    try {
        const rememberMe = localStorage.getItem("rememberMe");
        if (rememberMe && JSON.parse(rememberMe)) {
            localStorage.setItem("token", JSON.stringify(token));
        } else {
            sessionStorage.setItem("token", JSON.stringify(token));
        }
    } catch (e) {
        console.error(e);
    }
};

export const getAccessToken = () => {
    try {
        const rememberMe = localStorage.getItem("rememberMe");
        let token;
        if (rememberMe && JSON.parse(rememberMe)) {
            token = localStorage.getItem("token");
        } else {
            token = sessionStorage.getItem("token");
        }

        return `${JSON.parse(token || "")}`;
    } catch (e) {
        return "";
    }
};

export const removeAccessToken = () => {
    try {
        const rememberMe = localStorage.getItem("rememberMe");
        if (rememberMe && JSON.parse(rememberMe)) {
            localStorage.removeItem("token");
        } else {
            sessionStorage.removeItem("token");
        }
    } catch (e) {
        console.error(e);
    }
};

export const getPassword = ({
    length = 12,
}: {
    length: number;
}) => {
    // Define the character set to use in the password
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!";

    // Start with an empty password
    let password = "";

    // Add characters to the password until it reaches the desired length
    while (password.length < length) {
        // Choose a random character from the character set
        const randomIndex = Math.floor(Math.random() * chars.length);
        const randomChar = chars.charAt(randomIndex);

        // Add the new character to the password
        password += randomChar;
    }

    // Return the generated password
    return password;
};
