export default function HowItWorks() {
    return (
        <div className="how-it-works">
            <div className="how-it-works__item">
                <span className="how-it-works__item-count">1.</span>{" "}
                <div>
                    <span className="text-accent">Sign up</span> for our service
                    and <span className="text-accent">choose the package</span>{" "}
                    that suits you.
                </div>
            </div>
            <div className="how-it-works__item">
                <span className="how-it-works__item-count">2.</span>
                <div>
                    Enter the ingredients information of your dish in the{" "}
                    <span className="text-accent">Calculator</span> in your
                    account.
                </div>
            </div>
            <div className="how-it-works__item">
                <span className="how-it-works__item-count">3.</span>
                <div>
                    <span className="text-accent">Done.</span> Get{" "}
                    <span className="text-accent">Instant Results</span> with{" "}
                    <span className="text-accent">
                        24 Nutritional components
                    </span>{" "}
                    for your Dish on{" "}
                    <span className="text-accent">My Dishes</span> page.
                </div>
            </div>
            <div className="how-it-works__text">
                You can now add the Nutritional Information of your Dishes to
                your menu for your customers to see. Or you can direct your
                Customers to our{" "}
                <span className="text-accent">Search page</span> to look up your
                dishes using the <span className="text-accent">Dish ID</span> or
                your company’s <span className="text-accent">Public ID</span>.
            </div>
        </div>
    );
}
