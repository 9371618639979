import { api } from "./connection/api";
import {
    IDeleteDocumentReceiptRequestBody,
    IEmail,
    IEmailAvailabilityQuery,
    IEmailAvailabilityResponse,
    IGetUserDataResponse,
    IGoogleSignInRequestBody,
    IResetPassword,
    ISignInRequestBody,
    ISignUpRequestBody,
    IUpdateUserDocAdditionalInfoRequestBody,
    IConfirmDocPaymentPlanRequestBody,
    IUpdateUserDocumentRequestBody,
    IUpdateUserRequestBody,
    IGetNotificationsResponse
} from "../types/auth";

const ENDPOINT_PREFIX = "auth";

export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        signIn: build.mutation({
            query(data: ISignInRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/sign-in`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["Auth", "Dish"]
        }),
        signInGoogle: build.mutation({
            query(data: IGoogleSignInRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/sign-in-google`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["Auth"]
        }),
        signUp: build.mutation({
            query(data: ISignUpRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/sign-up`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["Auth"]
        }),
        getUserData: build.query<IGetUserDataResponse, void>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/`
            }),
            providesTags: ["Auth"]
        }),
        getUserNotifications: build.query<IGetNotificationsResponse, void>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/notifications`
            }),
            providesTags: ["Notifications"]
        }),
        setNotificationSeen: build.mutation({
            query(notificationId: string) {
                return {
                    url: `${ENDPOINT_PREFIX}/notifications/${notificationId}/seen`,
                    method: "PATCH"
                };
            },
            invalidatesTags: ["Notifications"]
        }),
        updateCurrentUser: build.mutation({
            query(data: IUpdateUserRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/`,
                    method: "PATCH",
                    body: data
                };
            },
            invalidatesTags: []
        }),
        updateUserDocument: build.mutation({
            query({ documentId, ...data }: IUpdateUserDocumentRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}`,
                    method: "PATCH",
                    body: data
                };
            },
            invalidatesTags: []
        }),
        updateUserDocAdditionalInfo: build.mutation({
            query({
                documentId,
                ...data
            }: IUpdateUserDocAdditionalInfoRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}/additional-info`,
                    method: "PATCH",
                    body: data
                };
            },
            invalidatesTags: []
        }),
        confirmDocPaymentPlan: build.mutation({
            query({ documentId, ...data }: IConfirmDocPaymentPlanRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}/confirm`,
                    method: "PATCH",
                    body: data
                };
            },
            invalidatesTags: []
        }),
        deleteDocumentReceipt: build.mutation({
            query({ documentId, ...data }: IDeleteDocumentReceiptRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}/receipts`,
                    method: "DELETE",
                    body: data
                };
            },
            invalidatesTags: []
        }),
        checkEmailAvailability: build.query<
            IEmailAvailabilityResponse,
            IEmailAvailabilityQuery
        >({
            query: (data: IEmailAvailabilityQuery) => ({
                url: `${ENDPOINT_PREFIX}/availability?email=${data.email}&userId=${data.id}`
            }),
            providesTags: ["Auth"]
        }),
        forgotPassword: build.mutation({
            query(data: IEmail) {
                return {
                    url: `${ENDPOINT_PREFIX}/forgot-password`,
                    method: "PUT",
                    body: data
                };
            }
        }),
        resetPassword: build.mutation({
            query(data: IResetPassword) {
                return {
                    url: `${ENDPOINT_PREFIX}/password`,
                    method: "PUT",
                    body: data
                };
            },
            invalidatesTags: ["Auth"]
        })
    })
});

export const {
    useSignInMutation,
    useSignInGoogleMutation,
    useSignUpMutation,
    useUpdateCurrentUserMutation,
    useSetNotificationSeenMutation,
    useUpdateUserDocumentMutation,
    useUpdateUserDocAdditionalInfoMutation,
    useConfirmDocPaymentPlanMutation,
    useDeleteDocumentReceiptMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    endpoints: { getUserData, checkEmailAvailability, getUserNotifications }
} = authApi;
