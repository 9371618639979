import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { clientApi } from "../../services/client";
import { IClientState, ICreateResponseBody } from "../../types/client";

const initialState: IClientState = {
    havePassword: false,
    ppsn: "",
    clientId: "",
};

export const clientsSlice = createSlice({
    name: "client",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            clientApi.endpoints.create.matchFulfilled,
            (state, action: PayloadAction<ICreateResponseBody>) => {
                state.havePassword = !!action.payload.client.info.password;
                state.ppsn = action.payload.client.ppsn;
                state.clientId = action.payload.client._id;
            }
        );
    },
});

export default clientsSlice.reducer;
