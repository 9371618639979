import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from "@reduxjs/toolkit/query/react";

import { logout } from "../../redux/reducers/auth";
import { getAccessToken } from "../../utils/auth";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: headers => {
        const token = getAccessToken();

        if (token) {
            headers.set("Authorization", "Bearer " + token);
        }

        return headers;
    }
});

const baseQueryWithIntercept: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        api.dispatch(logout());
        // window.location.href = "/auth";
    }

    return result;
};

export const api = createApi({
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Auth", "Client", "Dish", "Files", "Notifications", "Payment"],
    endpoints: () => ({})
});
