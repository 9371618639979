import "./myDishes.less";

import React, { useContext, useState } from "react";

import { TablePagination, Button } from "@mui/material";

import { AppContext } from "../../../App";
import { useUser } from "../../../hooks/common/useUser";
import { useGetUserDishesQuery } from "../../../services/dish";
import { extractErrorMessage } from "../../../utils/errors";
import DishCard from "../../basic/dish/DishCard";
import PageWrapper from "../../basic/PageWrapper/PageWrapper";
import QRCodeModal from "../../basic/QRCodeModal/QRCodeModal";
import Spinner from "../../basic/Spinner/Spinner";

export default function MyDishes() {
    const { user } = useUser();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
    const [currentDishUrl, setCurrentDishUrl] = useState("");

    const { isMobile } = useContext(AppContext);

    const handlePageChange = (
        _event: any,
        newPage: React.SetStateAction<number>
    ) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: { target: { value: string } }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleQRCodeModalOpen = (id: string) => {
        const dishUrl = `${window.location.origin}/search?id=${id}`;
        setCurrentDishUrl(dishUrl);
        setIsQRCodeModalOpen(true);
    };

    const handleQRCodeModalClose = () => {
        setIsQRCodeModalOpen(false);
        setCurrentDishUrl("");
    };

    const { data, isLoading, isError, error } = useGetUserDishesQuery({
        pageIndex: page,
        pageSize: rowsPerPage
    });

    if (isLoading) {
        return (
            <PageWrapper>
                <div className="auth__spinner" style={{ marginTop: "40px" }}>
                    <Spinner isLoading />
                </div>
            </PageWrapper>
        );
    }

    if (isError) {
        const errorMessage = extractErrorMessage(error);

        return (
            <PageWrapper>
                <div>Error: {errorMessage}</div>
            </PageWrapper>
        );
    }

    if (!user) {
        return (
            <PageWrapper>
                <div>User not found</div>
            </PageWrapper>
        );
    }

    return (
        <PageWrapper>
            <div className="my-dishes__container">
                <h2 className="my-dishes__title">My Dishes</h2>
                <div className="my-dishes__header">
                    <div style={{ marginRight: isMobile ? "20px" : "70px", marginLeft: "20px" }}>
                        <p className="my-dishes__header-title">Public ID</p>
                        <p className="my-dishes__header-text">
                            {user.info.publicID ||
                                "Please add 'Public Id' on Profile page"}
                        </p>

                    </div>
                    <div>
                        <p className="my-dishes__header-title">Company Name</p>
                        <p className="my-dishes__header-text">
                            {user.info.companyName ||
                                "Please add 'Company Name' on Profile page"}
                        </p>
                    </div>
                    <div style={{ marginLeft: isMobile ? "0px" : "20px" }}>
                        {user.info.publicID && (
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    handleQRCodeModalOpen(user.info.publicID)
                                }
                                sx={{ mt: 2 }}
                            >
                                Get Public ID QR Code
                            </Button>
                        )}
                    </div>
                </div>

                {data?.dishes &&
                    data.dishes.map((dish) => (
                        <DishCard
                            key={dish.dishId}
                            dish={dish}
                            publicID={user.info.publicID}
                            withIngredients={false}
                            page="my-dishes"
                            onGetQRCode={() => handleQRCodeModalOpen(dish.dishId)}
                        />
                    ))}

                <TablePagination
                    component="div"
                    count={data?.pagination?.totalDocs ?? 0}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPageOptions={[2, 5, 10, 25]}
                    labelRowsPerPage={isMobile ? "Dishes:" : "Dishes per page:"}

                />

                <QRCodeModal
                    open={isQRCodeModalOpen}
                    handleClose={handleQRCodeModalClose}
                    url={currentDishUrl}
                />
            </div>
        </PageWrapper>
    );
}
