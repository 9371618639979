import "./search.less";

import { useEffect, useState } from "react";

import { Button, TextField, TablePagination } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetSearchedDishesQuery } from "../../../services/dish";
import { IDish } from "../../../types/dish";
import sendGaEvent from "../../../utils/react-ga";
import DishCard from "../../basic/dish/DishCard";
import PageWrapper from "../../basic/PageWrapper/PageWrapper";
import QRCodeModal from "../../basic/QRCodeModal/QRCodeModal";
import Spinner from "../../basic/Spinner/Spinner";

export default function Search() {
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [globalFilter, setGlobalFilter] = useState("");
    const [dishes, setDishes] = useState<IDish[] | null>(null);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const { data, isLoading, isError, error } = useGetSearchedDishesQuery(
        {
            pageIndex: page,
            pageSize: rowsPerPage,
            globalFilter
        },
        {
            skip: !globalFilter
        }
    );

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get("id");
        if (id) {
            setQuery(id);
            setGlobalFilter(id);
        }
    }, [location.search]);

    const handleSearch = () => {
        sendGaEvent("click", "search");
        navigate(`?id=${query}`);
    };

    const handleQRCodeModalOpen = () => {
        setIsQRCodeModalOpen(true);
    };

    const handleQRCodeModalClose = () => {
        setIsQRCodeModalOpen(false);
    };

    useEffect(() => {
        if (isError) {
            setDishes(null);
        } else if (data) {
            setDishes(data.dishes);
        }
    }, [isError, data]);

    const currentUrl = `${window.location.origin}${location.pathname}?id=${query}`;

    return (
        <PageWrapper>
            <div className="search__search">
                <div className="search__search-wrapper">
                    <h2 className="search__title">Find your Dish</h2>
                    {/* {!!globalFilter &&
                        <Button variant="outlined" onClick={handleQRCodeModalOpen} sx={{ ml: 2 }}>
                            Get QR Code
                        </Button>
                    } */}
                </div>
                <h2 className="search__sub-title">
                    Search dishes that you eat
                </h2>

                <div className="search__input-container">
                    <TextField
                        label="Public ID or Dish ID"
                        className="search__input"
                        variant="outlined"
                        value={query}
                        onChange={(e) => setQuery(e.target.value.toString())}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                        className="search__button"
                    >
                        Search
                    </Button>
                </div>
            </div>

            <QRCodeModal
                open={isQRCodeModalOpen}
                handleClose={handleQRCodeModalClose}
                url={currentUrl}
            />

            {isLoading && (
                <div className="search__spinner" style={{ marginTop: "40px" }}>
                    <Spinner isLoading />
                </div>
            )}

            {isError && (
                <div className="search__error">
                    {(error as any).data!.message}
                </div>
            )}

            {dishes && (
                <>
                    <div className="search__company-info">
                        <div className="search__company-info-column">
                            <h3 className="search__company-info-title">
                                Public ID
                            </h3>
                            <p className="search__company-info-text">
                                {dishes[0].publicId}
                            </p>
                        </div>
                        <div className="search__company-info-column">
                            <h3 className="search__company-info-title">
                                Company Name
                            </h3>
                            <p className="search__company-info-text">
                                {dishes[0].companyName || ""}
                            </p>
                        </div>
                    </div>
                    {dishes.map((dish, index) => (
                        <DishCard
                            key={index}
                            dish={dish}
                            publicID={dish?.publicId || "PID"}
                            initialExpanded={!data?.pagination?.totalDocs}
                            withIngredients={false}
                            page="search"
                            onGetQRCode={() => handleQRCodeModalOpen()}
                        />
                    ))}
                    {data?.pagination?.totalDocs && (
                        <TablePagination
                            component="div"
                            count={data?.pagination?.totalDocs ?? 0}
                            page={page}
                            onPageChange={(_, newPage) => setPage(newPage)}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(e) => {
                                setRowsPerPage(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                            rowsPerPageOptions={[2, 5, 10, 25]}
                            labelRowsPerPage="Dishes per page:"
                        />
                    )}
                </>
            )}
        </PageWrapper>
    );
}
