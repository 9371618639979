import ReactGA from "react-ga4";

const sendGaEvent = (action: string, category: string) => {
    ReactGA.event({
        action: action,
        category: category,
    });
};

export default sendGaEvent;
