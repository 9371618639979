import "./pageWrapper.less";
import React, { ReactNode } from "react";

import { Container, Box, Paper } from "@mui/material";

import Footer from "../Footer/Footer";
import AppHeader from "../Header/Header";

interface PageWrapperProps {
    children: ReactNode;
    maxWidth?: string;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children, maxWidth = "1000px" }) => {
    return (
        <Container
            maxWidth={false}
            disableGutters
            className="info-wrapper-layout"
        >
            <AppHeader />
            <Paper elevation={0} square className="page-content-wrapper">
                <Box className="page-content" sx={{ maxWidth }}>
                    <Box sx={{ padding: "0 20px" }}>{children}</Box>
                    <Footer />
                </Box>
            </Paper>
        </Container>
    );
};
export default PageWrapper;
