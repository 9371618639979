import React, { useContext } from "react";

import "./header.less";

import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Box,
    Container
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { AppContext } from "../../../App";
import logo from "../../../assets/icons/meal-lords.svg";
import personIcon from "../../../assets/icons/person.svg";
import { useAppDispatch } from "../../../redux/hooks";
import { logout } from "../../../redux/reducers/auth";

const AppHeader: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAuth, isMobile } = useContext(AppContext);

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <AppBar position="fixed" style={{ background: "#0080FE" }}>
            <Container maxWidth="lg" disableGutters>
                <Toolbar style={{ minHeight: "63px" }}>
                    <Box
                        display="flex"
                        flexGrow={1}
                        justifyContent="space-between"
                    >
                        <Box display="flex" alignItems="center">
                            <img
                                className="header__logo"
                                src={logo}
                                alt="Logo"
                            />
                            <Link
                                to="/calculator"
                                className="header__nav-button"
                            >
                                <Typography className="header__nav-button-text">
                                    Calculator
                                </Typography>
                            </Link>
                            <Link to="/search" className="header__nav-button">
                                <Typography className="header__nav-button-text">
                                    Search
                                </Typography>
                            </Link>
                            {isAuth && (
                                <Link
                                    to="/my-dishes"
                                    className="header__nav-button"
                                >
                                    <Typography className="header__nav-button-text">
                                        My Dishes
                                    </Typography>
                                </Link>
                            )}
                        </Box>
                        <Box display="flex" alignItems="center">
                            {isAuth ? (
                                <>
                                    {!isMobile &&
                                        <Button
                                            className="header__sign-up-button"
                                            onClick={handleLogout}
                                        >
                                            Log Out
                                        </Button>
                                    }
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        aria-label="profile"
                                        onClick={() => navigate("/profile")}
                                    >
                                        <img src={personIcon} alt="Profile" />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                    {!isMobile &&
                                        <Button
                                            className="header__sign-up-button"
                                            onClick={() =>
                                                navigate("/auth?type=sign-up")
                                            }
                                        >
                                            Sign Up
                                        </Button>
                                    }
                                    <Button
                                        className="header__login-button"
                                        onClick={() =>
                                            navigate("/auth?type=login")
                                        }
                                    >
                                        Log In
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default AppHeader;
