import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getCurrentUser } from "../../redux/reducers/auth";
import { IAuthData } from "../../types/auth";
import { getAccessToken } from "../../utils/auth";

export const useAuth = (): {
    authData?: IAuthData;
    isAuth: boolean;
} => {
    const dispatch = useAppDispatch();

    const [isAuth, setIsAuth] = useState(false);

    const authData = useAppSelector(state => state.auth.authData);

    useEffect(() => {
        const token = getAccessToken();

        if (token) dispatch(getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const token = getAccessToken();
        setIsAuth(!!token);
    }, [authData]);

    return {
        authData,
        isAuth,
    };
};
