import "./checkout.less";

import { ChangeEvent, useEffect, useState } from "react";

import { Button, TextField } from "@mui/material";
import RevolutCheckout from "@revolut/checkout";
import { CountryCode } from "@revolut/checkout/types/types";
import { getData } from "country-list";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";

import logo from "../../../assets/icons/meal-lords-black.svg";
import { useSnackbar } from "../../../context/SnackbarContext";
import useDebounce from "../../../hooks/helpers/useDebounce";
import { useGetOrderMutation, useCheckOrderQuery } from "../../../services/payment";
import sendGaEvent from "../../../utils/react-ga";
import { isEmailValid } from "../../../utils/validation";
import PageWrapper from "../../basic/PageWrapper/PageWrapper";
import Spinner from "../../basic/Spinner/Spinner";
import { paymentPlans, PaymentPlansT } from "../Calculator/Plans";

const initFormValues = {
    fullName: "",
    address: "",
    city: "",
    region: "",
    country: "" as CountryCode,
    postcode: "",
    email: ""
};

const errors = {
    fullName: "",
    address: "",
    city: "",
    region: "",
    country: "",
    postcode: "",
    email: ""
};

export default function Checkout() {
    const { showSnackbar } = useSnackbar();
    const [getOrder, isGetLoading] = useGetOrderMutation();
    const navigate = useNavigate();

    const [plan, setPlan] = useState<PaymentPlansT | null>(null);
    const [formData, setFormData] = useState(initFormValues);
    const [emailValid, setEmailValid] = useState(true);
    const [emailTouched, setEmailTouched] = useState(false);
    const [formErrors, setFormErrors] = useState(errors);
    const [orderIDToCheck, setOrderIDToCheck] = useState("");

    const debouncedEmail = useDebounce(formData.email, 300);

    const { data, isLoading } = useCheckOrderQuery(
        { orderId: orderIDToCheck },
        { skip: !orderIDToCheck }
    );

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === "email") {
            setEmailTouched(true);
        }

        if (value.trim() !== "") {
            setFormErrors((prevState) => ({
                ...prevState,
                [name]: ""
            }));
        }
    };

    const countryOptions = getData()
        .map((country) => ({
            value: country.code,
            label: country.name === "United Kingdom of Great Britain and Northern Ireland"
                ? "United Kingdom"
                : country.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const ukIndex = countryOptions.findIndex(option => option.label === "United Kingdom");
    const irelandIndex = countryOptions.findIndex(option => option.label === "Ireland");

    const ukOption = countryOptions.splice(ukIndex, 1)[0];
    const irelandOption = countryOptions.splice(irelandIndex, 1)[0];

    countryOptions.unshift(ukOption, irelandOption);

    const handleCountryChange = (
        selectedOption: { value: string; label: string } | null
    ) => {
        if (selectedOption) {
            setFormData({ ...formData, country: selectedOption.value as CountryCode });
        }
    };

    const validateForm = () => {
        if (formData.fullName.trim() === "") {
            errors.fullName = "Full Name is required.";
        }

        if (formData.address.trim() === "") {
            errors.address = "Address is required.";
        }

        if (formData.city.trim() === "") {
            errors.city = "City is required.";
        }

        if (formData.country.trim() === "") {
            errors.country = "Country is required.";
        }

        if (formData.postcode.trim() === "") {
            errors.postcode = "Zip Code is required.";
        }

        if (!formData.email.trim()) {
            errors.email = "Email is required";
        } else if (!emailValid) {
            errors.email = "Invalid email format";
        }

        setFormErrors(errors);
        const isValid = !Object.values(errors).some((error) => error !== "");

        return isValid;
    };

    const handleSubmit = () => {
        const isValid = validateForm();
        if (isValid && plan) {
            sendGaEvent("click", "get-order");
            getOrder({ plan: plan.id, billing: formData })
                .then((paymentResponse: any) => {
                    const token = paymentResponse?.data?.order?.token;
                    if (token) {
                        RevolutCheckout(token)
                            .then((instance) => {
                                instance.payWithPopup({
                                    email: formData.email,
                                    name: formData.fullName,
                                    billingAddress: {
                                        streetLine1: formData.address,
                                        city: formData.city,
                                        region: formData.region,
                                        countryCode: formData.country,
                                        postcode: formData.postcode,
                                    },
                                    onSuccess() {
                                        sendGaEvent("payment", "success");
                                        showSnackbar(
                                            "Payment Successful",
                                            "success"
                                        );
                                        setOrderIDToCheck(paymentResponse?.data?.order?.id);
                                    },
                                    onError(message) {
                                        sendGaEvent("payment", "error");
                                        showSnackbar(
                                            "Payment Failed: " + (message || "Unknown error"),
                                            "error"
                                        );
                                        setOrderIDToCheck(paymentResponse?.data?.order?.id);
                                    }
                                });
                            });
                    }
                })
                .catch((error: any) => {
                    console.log("Payment failed:", error);
                });
        }
    };

    useEffect(() => {
        const selectedPlan = sessionStorage.getItem("selectedPlan");
        const paymentPlan = paymentPlans.find((p) => p.id === selectedPlan);
        paymentPlan && setPlan(paymentPlan);
    }, []);

    useEffect(() => {
        if (emailTouched) {
            setEmailValid(isEmailValid(debouncedEmail));
        }
    }, [debouncedEmail, emailTouched]);

    useEffect(() => {
        if (data?.status === "completed") {
            sessionStorage.removeItem("selectedPlan");
            navigate("/profile");
            showSnackbar(
                `${plan?.calculations} Calculations Added`,
                "success"
            );
        }
    }, [data, navigate, plan?.calculations, showSnackbar]);

    if (!plan) return null;

    return (
        <PageWrapper maxWidth="1200px">
            <h2 className="checkout__page-title">Checkout</h2>
            <div className="checkout__content">
                <div className="checkout__billing">
                    <h4 className="checkout__billing-title">
                        Billing Information
                    </h4>
                    <div>
                        <TextField
                            className="checkout__input"
                            label="Email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            error={
                                (emailTouched && !emailValid) ||
                                !!formErrors.email
                            }
                            helperText={
                                emailTouched && !emailValid
                                    ? "Invalid email format"
                                    : formErrors.email
                            }
                        />
                        <TextField
                            className="checkout__input"
                            variant="outlined"
                            label="Full Name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            error={!!formErrors.fullName}
                            helperText={formErrors.fullName}
                        />
                        <TextField
                            className="checkout__input"
                            variant="outlined"
                            label="Address"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            error={!!formErrors.address}
                            helperText={formErrors.address}
                        />
                        <TextField
                            className="checkout__input"
                            variant="outlined"
                            label="City"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            error={!!formErrors.city}
                            helperText={formErrors.city}
                        />
                        <TextField
                            className="checkout__input"
                            variant="outlined"
                            label="Region"
                            name="region"
                            value={formData.region}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <ReactSelect
                            className="checkout__country-code-input"
                            options={countryOptions}
                            placeholder="Select Country *"
                            isSearchable={true}
                            onChange={handleCountryChange}
                            classNamePrefix="select"
                        />
                        {!!formErrors.country && (
                            <span className="checkout__country-code-input-error-message">
                                {formErrors.country}
                            </span>
                        )}
                        <TextField
                            className="checkout__input"
                            variant="outlined"
                            label="Post Code"
                            name="postcode"
                            value={formData.postcode}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            error={!!formErrors.postcode}
                            helperText={formErrors.postcode}
                        />
                    </div>
                </div>
                <div className="checkout__summary">
                    <div className="checkout__summary-card">
                        <h5 className="checkout__summary-card-title">
                            Summary of the Order
                        </h5>
                        <div className="checkout__summary-card-row">
                            <img
                                src={logo}
                                alt="Logo"
                                height={80}
                                width={80}
                                style={{ marginRight: "10px" }}
                            />
                            <div>
                                <p className="checkout__summary-card-plan-name">
                                    {plan.name} Package
                                </p>
                                <p className="checkout__summary-card-plan-price">
                                    £{plan.price}
                                </p>
                            </div>
                            <p className="checkout__summary-card-info">
                                You will be able to calculate{" "}
                                {plan.calculations} dishes in your account (you
                                can always top up)
                            </p>
                        </div>
                        <div className="checkout__summary-card-total">
                            <div>Total:</div>
                            <div>£{plan.price}</div>
                        </div>
                    </div>
                    <div className="checkout__summary-button-wrapper">
                        <Button
                            variant="contained"
                            className="checkout__summary-button"
                            onClick={handleSubmit}
                        >
                            <div className="auth__spinner">
                                <Spinner isLoading={isLoading || isGetLoading.isLoading}>
                                    <span>Continue to Payment</span>
                                </Spinner>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
}
