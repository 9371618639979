// import "./index.less";
import { createContext } from "react";

import { ThemeProvider, CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Navigate, Route, Routes } from "react-router-dom";

import CookieConsentModal from "./components/basic/Cookies/CookieConsentModal";
import PrivateRoute from "./components/basic/PrivateRoute/PrivateRoute";
import SnackbarComponent from "./components/basic/Snackbar/Snackbar";
import Auth from "./components/pages/Auth/Auth";
import Calculator from "./components/pages/Calculator/Calculator";
import Checkout from "./components/pages/Checkout/Checkout";
import MyDishes from "./components/pages/MyDishes/MyDishes";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy";
import Profile from "./components/pages/Profile/Profile";
import Search from "./components/pages/Search/Search";
import TermsConditions from "./components/pages/TermsConditions/TermsConditions";
import { SnackbarProvider } from "./context/SnackbarContext";
import { useAuth } from "./hooks/auth/useAuth";
// import { useLoading } from "./hooks/common/useLoading";
import { useAppSize } from "./hooks/helpers/useAppSize";
import { IAuthData } from "./types/auth";

export const AppContext = createContext<{
    authData: IAuthData | undefined;
    isAuth: boolean;
    isMobile: boolean;
}>({
    authData: undefined,
    isAuth: false,
    isMobile: false
});

const theme = createTheme({
    typography: {
        fontWeightBold: 700
    },
    palette: {
        primary: {
            main: "#0080FE"
        },
        secondary: {
            main: "#85BB65"
        }
    }
});

const App = () => {
    const { authData, isAuth } = useAuth();
    const isMobile = useAppSize();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
                <AppContext.Provider
                    value={{
                        authData,
                        isAuth,
                        isMobile
                    }}
                >
                    <Routes>
                        <Route path="/auth" element={<Auth />} />
                        <Route path="/calculator" element={<Calculator />} />
                        <Route path="/search" element={<Search />} />
                        <Route
                            path="/my-dishes"
                            element={<PrivateRoute component={MyDishes} />}
                        />
                        <Route path="/checkout" element={<Checkout />} />
                        <Route
                            path="/terms-and-conditions"
                            element={<TermsConditions />}
                        />
                        <Route
                            path="/privacy-policy"
                            element={<PrivacyPolicy />}
                        />
                        <Route
                            path="/profile"
                            element={<PrivateRoute component={Profile} />}
                        />
                        <Route
                            path="*"
                            element={
                                <Navigate to="/calculator" replace={true} />
                            }
                        />
                    </Routes>
                </AppContext.Provider>
                <SnackbarComponent />
            </SnackbarProvider>
            <CookieConsentModal />
        </ThemeProvider>
    );
};

export default App;
