import { useContext, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import { AppContext } from "../../../App";
import PageWrapper from "../../basic/PageWrapper/PageWrapper";
import Spinner from "../../basic/Spinner/Spinner";

import "./auth.less";

const Auth = () => {
    const { isAuth } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authType = queryParams.get("type");

    useEffect(() => {
        if (isAuth) {
            const selectedPlan = sessionStorage.getItem("selectedPlan");
            navigate(selectedPlan ? "/checkout" : "profile");
        }
    }, [isAuth, navigate]);

    if (!isAuth) {
        return (
            <PageWrapper>
                {authType === "sign-up" ? <SignUp /> : <SignIn />}
            </PageWrapper>
        );
    }

    return (
        <div className="auth__spinner">
            <Spinner isLoading />
        </div>
    );
};

export default Auth;
