import { useCallback, useContext } from "react";

import { useNavigate } from "react-router-dom";

import { AppContext } from "../../../App";
import sendGaEvent from "../../../utils/react-ga";

export type PaymentPlansT = {
    id: string;
    name: string;
    price: number;
    calculations: number;
}

export const paymentPlans: PaymentPlansT[] = [
    { id: "double", name: "Double", price: 4.95, calculations: 2 },
    { id: "starter", name: "Starter", price: 49.95, calculations: 25 },
    { id: "restaurant", name: "Restaurant", price: 99.95, calculations: 100 },
    { id: "enterprise", name: "Enterprise", price: 199.95, calculations: 250 },
];

export default function Plans() {
    const { isAuth } = useContext(AppContext);
    const navigate = useNavigate();

    const handleChoose = useCallback(
        (id: string) => {
            sendGaEvent("click", `select-plan-${id}`);
            sessionStorage.setItem("selectedPlan", id);
            navigate(isAuth ? "/checkout" : "/auth?type=sign-up");
        },
        [isAuth, navigate],
    );

    return (
        <div className="payment-plans__container">
            {paymentPlans.filter(p => p.id !== "double").map((plan) => (
                <div className="payment-plans__card" key={plan.id}>
                    <div className="payment-plans__card-description">
                        <h6 className="payment-plans__card-title">
                            {plan.name}
                        </h6>
                        <p className="payment-plans__card-text">
                            {`Calculate up to ${plan.calculations} of your dishes in your account with the ability to top up anytime`}
                        </p>
                        <div className="payment-plans__card-price">
                            £{plan.price}
                        </div>
                    </div>
                    <div>
                        <button className="payment-plans__card-button" onClick={() => handleChoose(plan.id)}>
                            Choose this Package
                        </button>
                        <p className="payment-plans__card-calculations-info">
                            {`${plan.calculations} calculations for your dishes`}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
}
