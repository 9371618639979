import { FC } from "react";

import "./footer.less";

import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer: FC = () => {
    return (
        <Box component="footer" className="footer__wrapper">
            <Box className="footer__content">
                <Box sx={{ maxWidth: "380px", margin: "0 auto 50px", padding: "0 20px" }}>
                    <Typography
                        sx={{
                            color: "white",
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                            textAlign: "start",
                            marginBottom: "24px"
                        }}
                    >
                            Meal-Lords
                    </Typography>
                    <Typography
                        sx={{
                            color: "white",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textAlign: "start"
                        }}
                    >
                            We help you to grow your business while your
                            customers enjoin your food more and stay healthy at
                            the same time.
                    </Typography>
                </Box>
                <Box sx={{ maxWidth: "400px", margin: "0 auto" }}>
                    <Typography
                        sx={{
                            color: "white",
                            fontFamily: "Inter",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                            textAlign: "start",
                            marginBottom: "24px",
                            marginLeft: "22px"
                        }}
                    >
                            Company
                    </Typography>
                    <Link
                        to="/terms-and-conditions"
                        style={{ textDecoration: "none" }}
                    >
                        <Typography
                            sx={{
                                cursor: "pointer",
                                color: "white",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                                textAlign: "start",
                                marginLeft: "14px",
                                padding: "10px"
                            }}
                        >
                                Terms and Conditions
                        </Typography>
                    </Link>
                    <Link
                        to="/privacy-policy"
                        style={{ textDecoration: "none" }}
                    >
                        <Typography
                            sx={{
                                cursor: "pointer",
                                color: "white",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                                textAlign: "start",
                                marginLeft: "14px",
                                padding: "10px",
                                marginBottom: "20px"
                            }}
                        >
                                Privacy Policy
                        </Typography>
                    </Link>
                    <Typography
                        sx={{
                            color: "white",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px",
                            textAlign: "start",
                            marginLeft: "24px"
                        }}
                    >
                            Contact us: <br />
                        <a
                            href="mailto:contact@meal-lords.com"
                            style={{
                                color: "white",
                                textDecoration: "none",
                                display: "inline-block"
                            }}
                        >
                                contact@meal-lords.com
                        </a>
                    </Typography>
                </Box>
                <Box sx={{ maxWidth: "400px", margin: "0 auto" }}>
                    <Typography
                        sx={{
                            color: "white",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            marginTop: "71px"
                        }}
                    >
                        Meal-Lords content is not intended to be a substitute
                        for professional medical advice.
                    </Typography>
                    <Typography
                        sx={{
                            color: "white",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            marginTop: "18px"
                        }}
                    >
                        © 2023 Meal-Lords
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
